export const setCallSession = data =>{
    return{
        type:"SET_CALL_SESSION",
        payload:data
    }
}
export const setIsCallJoined = data =>{
    return{
        type:"SET_IS_CALL_JOINED",
        payload:data
    }
}
export const setIsCallDeclined = data =>{
    return{
        type:"SET_IS_CALL_DECLINED",
        payload:data
    }
}
export const setIsCallPicked = data =>{
    return{
        type:"SET_IS_CALL_PICKED",
        payload:data
    }
}
export const setIsCalling = data =>{
    return{
        type:"SET_IS_CALLING",
        payload:data
    }
}