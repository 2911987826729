const initialState = {
    callSessionObj:{
        apiKey:"apiKey",
        sessionId:"sessionid",
        token:"token"
    },
    isCallJoined:false,
    isCallDeclined:false,
    isCalling:false,
    isCallPicked:false
}
const callSessionReducer = (state = initialState, action)=>{
    switch(action.type){
        case "SET_CALL_SESSION" : return {...state, callSessionObj:action.payload}
        case "SET_IS_CALL_JOINED" : return {...state, isCallJoined:action.payload}
        case "SET_IS_CALL_DECLINED" : return {...state, isCallDeclined:action.payload}
        case "SET_IS_CALLING" : return {...state, isCalling:action.payload}
        case "SET_IS_CALL_PICKED" : return {...state, isCallPicked:action.payload}
        default: return state
    }
}
export default callSessionReducer;