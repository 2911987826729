import all from "gsap/all";
import { combineReducers } from "redux";
import mainSessionReducer from "./mainSessionReducer";
import RefreshStatReducer from "./RefreshStatReducer";
import callSessionReducer from './callSessionReducer';
import supportStatusReducer from "./supportStatusReducer";
import groupChatReducer from "./groupChatReducer";
import activeLinkReducer from "./activeLinkReducer";

const allReducers = combineReducers({
    RefreshStatReducer,
    mainSessionReducer,
    callSessionReducer,
    supportStatusReducer,
    groupChatReducer,
    activeLinkReducer,
});
export default allReducers;