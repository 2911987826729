import React from "react";
import { Link } from "react-router-dom";


class OfficeServicesListItems extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <ul className="list-group">
            <li className="list-group-item">
                <input name="rdSubServicesOffice" checked={this.props.SubServiceValue == "1"} onChange={this.props.SubServiceEvent} value="1" type="radio"/>
                <span style={{marginLeft:'1%'}}> Migration Support  </span> 
            </li>
        </ul> 
      </div>
    );
  }
}

export default OfficeServicesListItems;
