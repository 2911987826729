import * as ActionType from '../actions/ActionType';

const InitialState = {
    CompletedProgress : -1,
    Inprogress : -1,
    TotalProgress : -1
}

const RefreshStatReducer = ( state = InitialState, action) => {
    // we will use action check later depending upon usage. 
    // debugger;
    if(action.type == ActionType.LOADSTATS){
        if(action.stats.status == "success"){
            return{
                ...state,
                CompletedProgress : action.stats.completed,
                Inprogress : action.stats.inprogress,
                TotalProgress : action.stats.totalproject
            }
        }
        return state;
    }
    else if (action.type == ActionType.CLEARSTATS){
        return{
            ...state,
            CompletedProgress : -1,
            Inprogress : -1,
            TotalProgress : -1
        }
    }
    else{
        return state;
    } 
    
};

export default RefreshStatReducer;