import React from "react";
import Service from "../../components/Service/Api/Api";
import queryString from "query-string";
import Swal from "sweetalert2";
import cookie from "react-cookies";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../Loader/Loader";
import CustomLoader from "../Loader/Loader";
import { authCodeTemplate } from "../../components/EmailTemplates/authcodeTemplate";
import SignUpJoureny from "./SignUpJourney";

export default class SignInSignUp extends React.Component{
    constructor(props){
        super(props);
        this.state={
            CustomerOrderInfo: "",
            isShowSignUp: false,
            email: "",
            password: "",
            emailError: "",
            customLoader: false,
            // Signup Section state
            UserFirstName: '',
            UserLastName: '',
            CompanyName:'',
            UserEmailAddress: '',
            UserPhoneNumber: '',
            UserPassword: '',
            UserPasswordRepeat: '',
            selectedFile:'',
            isAlert:false,
            isLoader:false,
            startSignUpJourney:false
          }
    }

    LoginFunctionality = () => {
        try {
          this.setState({ customLoader: true });
          var _email = this.state.email;
          var _password = this.state.password;
          if (_email !== "" && _password !== "") {
            const isValidated = this.OnValidate();
            if (isValidated) {
              var data = {
                username: this.state.email,
                password: this.state.password
              };
              Service.login(data)
                .then((res) => {
                  let allowedRoles = ["User", "CompanyUser", "CompanyAdmin"];
                  if (res && res.api_key) {
                    // OLD code=============================================================================================>
                    // if (allowedRoles.includes(res.role[0]) && res.user.emailConfirmed) {
                    //   cookie.save("bareer_Token", res.api_key, {
                    //     path: "/",
                    //     maxAge: 5 * 3600,
                    //   });
    
                    //   cookie.save("user_Id", res.user.userName, {
                    //     path: "/",
                    //     maxAge: 5 * 3600,
                    //   });
                    //   cookie.save("company_Id", res.companyid, {
                    //     path: "/",
                    //     maxAge: 5 * 3600,
                    //   });
    
                    //   cookie.save("Id", res.user.id, {
                    //     path: "/",
                    //     maxAge: 5 * 3600,
                    //   });
    
                    //   cookie.save(
                    //     "user_Name",
                    //     res.user.firstName + " " + res.user.lastName,
                    //     {
                    //       path: "/",
                    //       maxAge: 5 * 3600,
                    //     }
                    //   );
    
                    //   cookie.save("Orgnization", res.user.comapnyName, {
                    //     path: "/",
                    //     maxAge: 5 * 3600,
                    //   });
    
                    //   cookie.save("User_Role", res.user.userRole, {
                    //     path: "/",
                    //     maxAge: 5 * 3600,
                    //   });
    
                    //   cookie.save("admin_email", res.adminemail, {
                    //     path: "/",
                    //     maxAge: 5 * 3600,
                    //   });
    
                    //   Service.GetSession(res.user.userName).then((responce) => {
                    //     if (responce) {
                    //       cookie.save("apiKey", responce.apiKey, {
                    //         path: "/",
                    //         maxAge: 5 * 3600,
                    //       });
                    //       cookie.save("sessionId", responce.sessionId, {
                    //         path: "/",
                    //         maxAge: 5 * 3600,
                    //       });
                    //       cookie.save("token", responce.token, {
                    //         path: "/",
                    //         maxAge: 5 * 3600,
                    //       });
                    //       window.connect(
                    //         responce.apiKey,
                    //         responce.sessionId,
                    //         responce.token,
                    //         res.user.userName
                    //       );
                    //     }
                    //   });
    
                    // //   this.setState({ isUserLogin: true });
                    //   this.props.setIsUserLogin(true,res.api_key);
                    //   this.setState({ customLoader: false });
                    // }
                    if (allowedRoles.includes(res.role[0]) && res.status ==="success") {
                      cookie.save("bareer_Token", res.api_key, {
                        path: "/",
                        maxAge: 5 * 3600,
                      });
    
                      cookie.save("user_Id", res.user.userName, {
                        path: "/",
                        maxAge: 5 * 3600,
                      });
                      cookie.save("company_Id", res.companyid, {
                        path: "/",
                        maxAge: 5 * 3600,
                      });
    
                      cookie.save("Id", res.user.id, {
                        path: "/",
                        maxAge: 5 * 3600,
                      });
    
                      cookie.save(
                        "user_Name",
                        res.user.firstName + " " + res.user.lastName,
                        {
                          path: "/",
                          maxAge: 5 * 3600,
                        }
                      );
    
                      cookie.save("Orgnization", res.user.comapnyName, {
                        path: "/",
                        maxAge: 5 * 3600,
                      });
                      // Commented because role is not available in the response
                      // cookie.save("User_Role", res.user.userRole, {
                      //   path: "/",
                      //   maxAge: 5 * 3600,
                      // });
                      cookie.save("User_Role", res.role[0], {
                        path: "/",
                        maxAge: 5 * 3600,
                      });
    
                      cookie.save("admin_email", res.adminemail, {
                        path: "/",
                        maxAge: 5 * 3600,
                      });
    
                      Service.GetSession(res.user.userName).then((responce) => {
                        if (responce) {
                          cookie.save("apiKey", responce.apiKey, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          cookie.save("sessionId", responce.sessionId, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          cookie.save("token", responce.token, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          window.connect(
                            responce.apiKey,
                            responce.sessionId,
                            responce.token,
                            res.user.userName
                          );
                        }
                      });
    
                    //   this.setState({ isUserLogin: true });
                      this.props.setIsUserLogin(true,res.api_key);
                      this.setState({ customLoader: false });
                    }
                    else {
                      this.setState({ customLoader: false });
                      Swal.fire({
                        icon: "error",
                        title: "Invalid credentials",
                        text: "Please enter valid credentials to login!",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  }
                })
                .catch((err) => {
                  this.setState({ customLoader: false });
                  if (err.toString().includes(401)) {
                    Swal.fire({
                      icon: "error",
                      title: "Cannot login with this account.",
                      text: "Either account does not exist or it may needs to be activated.",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong. Please try again later.",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                });
    
            } // end of if isvalidate section 
            else {
              this.setState({ customLoader: false });
            }
          }
          else {
            this.setState({ customLoader: false });
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Enter valid credentials",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (ex) {
          console.log(ex);
          this.setState({ customLoader: false });
        }
    };

    onChange = (e) => {
        this.setState({
        [e.target.name]: e.target.value,
        });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    OnValidate = (e) => {
        let emailError = "";
        let passwordError = "";
    
        if (!this.state.email.includes("@") && !this.state.email.includes(".")) {
          emailError = "Plesae enter your valid email";
        }
        if (
          !this.state.password.match(
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          )
        ) {
          passwordError = "Please enter your valid password";
        }
        if (emailError || passwordError) {
          this.setState({ emailError, passwordError, loading: true });
    
          return false;
        } else {
          return true;
        }
    };
    ValidateEmail = (email) =>{
      if(!email){
        return false;
      }
      else if(!email.includes("@") || !email.includes(".")){
        return false;
      }
      else if (email.includes('@gmail')) {
        return false;
      }
      else if (email.includes('@google')) {
        return false;
      }
      else if (email.includes('@yahoo')) {
          return false;
      }
      else if (email.includes('@outlook')) {
          return false;
      }
      else if (email.includes('@hotmail')) {
          return false;
      }
      else{
        return true
      }
    }
    SignUpFunctionality = () => {
        try {
        var passw = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
        if(this.state.UserFirstName === ""){
          Swal.fire({
            icon: 'error',
            title: 'First Name is required',
            text: 'Please fill in the required fields.',
            showConfirmButton: false,
            timer: 1500
            });
        }
        else if(this.state.UserLastName === ""){
          Swal.fire({
            icon: 'error',
            title: 'Last Name is required',
            text: 'Please fill in the required fields.',
            showConfirmButton: false,
            timer: 1500
            });
        }
        else if (this.ValidateEmail(this.state.UserEmailAddress)){
          if (this.state.UserPhoneNumber.length < 7 || this.state.UserPhoneNumber.length > 15) {
            Swal.fire({
            icon: 'error',
            title: 'Invalid action',
            text: 'Phone number should greater then 7  and less then 15 digits.',
            showConfirmButton: false,
            timer: 1500
            });
        }
        else if (this.state.UserPassword !== this.state.UserPasswordRepeat) {
            Swal.fire({
            icon: 'error',
            title: 'Invalid action',
            text: 'Password and retype pasword must be same',
            showConfirmButton: false,
            timer: 1500
            });
        }
        // temporary commented
        // else if (this.state.CompanyName === "" || this.state.CompanyName === undefined || this.state.CompanyName === null ) {
        //     Swal.fire({
        //     icon: 'error',
        //     title: 'Invalid action',
        //     text: "Please enter your company's name",
        //     showConfirmButton: false,
        //     timer: 1500
        //     });
        // }
        else if (this.state.UserPassword.match(passw)) {
            this.setState({ customLoader: true });
            // var payload = {
            // Email: this.state.UserEmailAddress,
            // UserName: this.state.UserEmailAddress,
            // PhoneNumber: this.state.UserPhoneNumber,
            // FirstName: this.state.UserFirstName,
            // LastName: this.state.UserLastName,
            // CompanyName:this.state.CompanyName,
            // TwoFactorEnable: true,
            // Role: 'User',
            // Password: this.state.UserPassword
            // };
            var payload = {
            email: this.state.UserEmailAddress,
            username: this.state.UserEmailAddress,
            PhoneNumber: this.state.UserPhoneNumber,
            FirstName: this.state.UserFirstName,
            LastName: this.state.UserLastName,
            CompanyName:this.state.CompanyName,
            TwoFactorEnable: true,
            Role: 'User',
            Password: this.state.UserPassword,
            Repassword:this.state.UserPasswordRepeat,
            };
            Service.createCustomer(payload).then(res => {
              // OLD code=======================================================================================================
            // if (res.status == "success") {
            //     let allowedRoles = ["User", "CompanyUser", "CompanyAdmin"];
            //     if (res && res.api_key) {
            //     if (allowedRoles.includes(res.role[0]) && res.user.emailConfirmed) {
            //         cookie.save("bareer_Token", res.api_key, {
            //         path: "/",
            //         maxAge: 5 * 3600,
            //         });

            //         cookie.save("user_Id", res.user.userName, {
            //         path: "/",
            //         maxAge: 5 * 3600,
            //         });
                    
            //         cookie.save("company_Id", res.companyid, {
            //           path: "/",
            //           maxAge: 5 * 3600,
            //         });

            //         cookie.save("Id", res.user.id, {
            //         path: "/",
            //         maxAge: 5 * 3600,
            //         });

            //         cookie.save(
            //         "user_Name",
            //         res.user.firstName + " " + res.user.lastName,
            //         {
            //             path: "/",
            //             maxAge: 5 * 3600,
            //         }
            //         );

            //         cookie.save("Orgnization", res.user.comapnyName, {
            //         path: "/",
            //         maxAge: 5 * 3600,
            //         });

            //         cookie.save("User_Role", res.user.userRole, {
            //         path: "/",
            //         maxAge: 5 * 3600,
            //         });

            //         cookie.save("admin_email", res.adminemail, {
            //         path: "/",
            //         maxAge: 5 * 3600,
            //         });

            //         Service.GetSession(res.user.userName).then((responce) => {
            //         if (responce) {
            //             cookie.save("apiKey", responce.apiKey, {
            //             path: "/",
            //             maxAge: 5 * 3600,
            //             });
            //             cookie.save("sessionId", responce.sessionId, {
            //             path: "/",
            //             maxAge: 5 * 3600,
            //             });
            //             cookie.save("token", responce.token, {
            //             path: "/",
            //             maxAge: 5 * 3600,
            //             });
            //             window.connect(
            //             responce.apiKey,
            //             responce.sessionId,
            //             responce.token,
            //             res.user.userName
            //             );
            //         }
            //         });

            //         // this.setState({ isUserLogin: true });
            //         this.props.setIsUserLogin(true,res.api_key);
            //         this.setState({ customLoader: false });
            //     }
            //     else {
            //         this.setState({ customLoader: false });
            //         Swal.fire({
            //         icon: "error",
            //         title: "Invalid credentials",
            //         text: "Please enter valid credentials to login!",
            //         showConfirmButton: false,
            //         timer: 1500,
            //         });
            //     }
            //     }

            // }

            if (res.status == "Success") {
              this.ShowLoginSection();
              this.setState({ customLoader: false, email: this.state.UserEmailAddress });
              Swal.fire("Account created", "Please login to your account", "success");
            }
            // if (res.status === "Success") {
            //   this.setState({startSignUpJourney: true, verificationUrl:res.url});
            // }
            else {
                // error response in 
                this.setState({ customLoader: false });
                Swal.fire({
                icon: "error",
                title: "Error",
                text: "Something went wrong ! 1",
                showConfirmButton: false,
                timer: 1500,
                });
            }
            }).catch(err => {
            // alert(err);
            this.setState({ customLoader: false });
            const response = err.request.response;
            if(response){
              const { status, message} = JSON.parse(response);
              if(status == "Error" && message == "User already exists!" ){
                Swal.fire("Error", "Email is already registered. Please login.", "error");
                this.ShowLoginSection();
                this.setState({ email:this.state.UserEmailAddress });
                this.setState({ customLoader: false });
              }
              else{
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Something went wrong ! error',
                    showConfirmButton: false,
                    timer: 1500
                });
              }
            }
            });
        } // end of true condition 
        else {
            Swal.fire({
            icon: 'error',
            title: 'Invalid action',
            text: 'Password must contain uppercase ,lowercase, number, special character!',
            showConfirmButton: false,
            timer: 1500
            });

        }
        }
        else{
            Swal.fire({
              icon: 'error',
              title: 'Invalid action',
              text: 'Kindly use your business email.',
              showConfirmButton: false,
              timer: 5000
              });
        }
        } catch (ex) {
        console.log(ex);
        }
    };

    ShowSignUpSection = () => {
        try {
          this.setState({ isShowSignUp: true });
        } catch (ex) {
          console.log(ex);
        }
    };
    
    ShowLoginSection = () => {
        try {
          this.setState({ isShowSignUp: false });
        } catch (ex) {
          console.log(ex);
        }
    };

    render(){
        return<>
        {this.state.isLoader ? <Loader/> : ""}
        {this.state.customLoader ? <CustomLoader /> : null}
        {this.state.isLoader ? <Loader/> : ""}
        {
        this.state.isShowSignUp == false ? (
        <div className="container">
          <div className="col-md-12">
            <form style={{ marginTop: "5%" }}>
                <div id="signup-form" className="login-form animated preFadeInLeft fadeInLeft">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>Email address</label>
                      <input
                          type="email"
                          className="form-control"
                          placeholder=" "
                          name="email"
                          required
                          onChange={this.onChange}
                          value={this.state.email}
                          pattern="^[a-zA-Z]+.*@[A-Za-z]+([.][a-zA-Z]+)+$"
                      />
                      {this.state.emailError ? (
                          <div
                          className="error"
                          style={{ fontSize: 12, color: "red" }}
                          >
                          {this.state.emailError}
                          </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Password</label>
                      <input
                          type="password"
                          className="form-control"
                          placeholder=" "
                          name="password"
                          required
                          onChange={this.onChange}
                          value={this.state.password}
                          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$"
                      />
                      {this.state.passwordError ? (
                          <div
                          className="error"
                          style={{ fontSize: 12, color: "red" }}
                          >
                          {this.state.passwordError}
                          </div>
                      ) : null}
                    </div>
                  </div>
                </div>
            </form>
          </div>
          <div className="col-md-12 text-center mt-4">
            <button className="custom-button mx-1 bg-sc" onClick={this.LoginFunctionality}>Login</button>
            <button className="custom-button mx-1" onClick={this.ShowSignUpSection}>Signup</button>
          </div>
        </div>
        ) : // Signup Section Content 
        <div className="container">
            {
              this.state.startSignUpJourney ?
              <SignUpJoureny 
                setJourney = {(option)=>this.setState({startSignUpJourney:option})} 
                parentState={this.state}
                setIsUserLogin = {option=>this.setState({isUserLogin:option})}
                setIsUserLoginMain = {this.props.setIsUserLogin}
                setCustomLoader = { option => this.setState({customLoader: option}) }

                />
              :
              <>
                <div className="col-md-12">
                  <form style={{ marginTop: "5%" }}>
                      <div id="signup-form" className="login-form animated preFadeInLeft fadeInLeft">
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label>First Name</label>
                            <input
                                className="form-control"
                                name="UserFirstName"
                                placeholder=" "
                                type="text"
                                required
                                value={this.state.UserFirstName}
                                onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Last Name</label>
                            <input
                                className="form-control"
                                name="UserLastName"
                                required
                                placeholder=" "
                                type="text"
                                value={this.state.UserLastName}
                                onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      {/* Commented temporary */}
                      {/* <div className="field pb-10">
                          <div className="floating-label">
                          <input
                              className="floating-input"
                              name="CompanyName"
                              required
                              placeholder=" "
                              type="text"
                              value={this.state.CompanyName}
                              onChange={this.handleChange}
                          />
                          <label>Company Name</label>
                          </div>
                      </div> */}
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>Email address</label>
                          <input
                              className="form-control"
                              name="UserEmailAddress"
                              placeholder=" "
                              type="email"
                              required
                              value={this.state.UserEmailAddress}
                              onChange={this.handleChange}
                          ></input>
                        </div>
                        <div className="form-group col-md-6">
                          <label>Phone number</label>
                          <input
                              className="form-control"
                              type="tel"
                              autocomplete="tel"
                              required
                              placeholder=" "
                              name="UserPhoneNumber"
                              value={this.state.UserPhoneNumber}
                              onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>Password</label>
                          <input
                              className="form-control"
                              name="UserPassword"
                              required
                              placeholder=" "
                              type="password"
                              value={this.state.UserPassword}
                              onChange={this.handleChange}
                          ></input>
                          <span style={{ fontStyle: 'italic', display: 'block' }}>Password must contain uppercase, lowercase, number, special character </span>
                        </div>
                        <div className="form-group col-md-6">
                          <label>Re-type Password</label>
                          <input
                              className="form-control"
                              name="UserPasswordRepeat"
                              required
                              placeholder=""
                              type="password"
                              value={this.state.UserPasswordRepeat}
                              onChange={this.handleChange}
                          ></input>
                        </div>
                      </div>
                      </div>
                  </form>
                </div>
                <div className="col-md-12 text-center">
                  <button className="custom-button mx-1" onClick={this.ShowLoginSection}>Login</button>
                  <button className="custom-button mx-1 bg-sc" onClick={this.SignUpFunctionality}>Signup</button>
                </div>
              </>
            }
        </div>
        }
        </>
    }
}