import React, { useState, useEffect, useRef } from "react";
import "./GroupChat.css";
import cookie from 'react-cookies'
import { connect } from 'react-redux';
import { utcStringToLocale } from "../../common/date.utils";
import Service from "../Service/Api/Api";
import { setMessagesFromDb } from "../../store/actions/groupChatAction";


const GroupChat = ({ jiraKey, detailId, assignedUsers, sendMessage: { sendGroupMessage }, setMessages, messages }) => {
    const srollableChat = useRef();

    const currentUser = cookie.load("user_Id");
    // const [ messages, setMessages ] = useState([]);
    const [messageInput, setMessageInput] = useState("");
    const userList = assignedUsers.map(user => user.userEmail);

    const sendMessageToUsers = (e) => {
        e.preventDefault();
        if (messageInput !== "") {
            sendGroupMessage(userList, jiraKey, messageInput);
            setMessageInput("");
        }
    }

    let prevId = "";
    let prevDate = "";

    function fetchPreviousMessages(jiraKey) {
        const isAlreadyFetched = messages.find(data => data.projectId === jiraKey);
        if (!isAlreadyFetched) {
            Service.getMessagesByJiraKey(jiraKey).then(res => {
                if (res.length) {
                    setMessages(res)
                }
            });
        }
    }

    function scrollToBottom() {
        const scroll = srollableChat.current;
        if (scroll) {
            setTimeout(() => {
                scroll.scrollTo({ left: 0, top: scroll.scrollHeight, behavior: 'smooth' });
            }, 50)
        }
    }
    useEffect(() => {
        // To get previous messages and store them in the Redux state
        fetchPreviousMessages(jiraKey);
        // const messages = [
        //     {
        //         name:"Support",
        //         id:"support@managex.com",
        //         message:"Chat feature is under development"
        //     },
        //     {
        //         name:"Simon Sais",
        //         id:"zaijaz@vaporvm.com",
        //         message:"ok."
        //     },
        // ];
        // setMessages(messages);
    },[]);

    useEffect(()=>{
        scrollToBottom();
    },[messages]);
    
    return(
        <div className="mx-chat-widget" ref={srollableChat}>
            {
                messages.length ?
                    messages.map((data, i, arr) => {
                        if (i === 0) {
                            prevId = "";
                        }
                        else {
                            prevId = arr[i - 1].senderEmail;
                        }

                        const [date, time] = utcStringToLocale(data.dateTime);
                        if (i === 0) {
                            prevDate = "";
                        }
                        else {
                            const [date, time] = utcStringToLocale(arr[i - 1].dateTime);
                            prevDate = date;
                        }
                        // console.log(prevDate, date)
                        return (
                            jiraKey === data.projectId ?
                                <>
                                    {
                                        prevDate !== date && jiraKey === data.projectId ?
                                            <div className="mx-chat-widget-message-date">
                                                <div className="mx-chat-widget-message-line"></div>
                                                {date}
                                                <div className="mx-chat-widget-message-line"></div>
                                            </div>
                                            : null
                                    }
                                    <div className={`mx-chat-widget-message ${currentUser === data.senderEmail ? "is-own" : ""}`}>
                                        {
                                            prevId !== data.senderEmail ?
                                                <p className="mx-chat-widget-message-name">
                                                    {data.senderName}
                                                </p>
                                                : ""
                                        }
                                        <p className="mx-chat-widget-message-text">
                                            {data.message}
                                            <span className="mx-chat-widget-message-time">{time}</span>
                                        </p>
                                    </div>
                                </>
                                : null
                        );
                    })
                    : <p>This is the start of conversation.</p>
            }
            <form className="mx-chat-widget-send" onSubmit={sendMessageToUsers}>
                <input type="text" value={messageInput} onChange={(e) => setMessageInput(e.target.value)} />
                <button>Send</button>
            </form>
        </div>
    )
}
const mapStateToProps = state => ({
    sendMessage: state.groupChatReducer,
    messages: state.groupChatReducer.messages
});

const mapDispatchToProps = dispatch => ({
    setMessages: (payload) => dispatch(setMessagesFromDb(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupChat);