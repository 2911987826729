import React from "react";
import { connect } from "react-redux";
import CustomLoader from "../Loader/section/SectionLoader";
import "./RoundStyle.css";

class CompletedProjectComponent extends React.Component{
   
    

    render(){
        
        return(
            <div className="column is-4">
                <div className="card-counter">
                    <div className="flex-card light-bordered hover-inset">
                        <div className="round-icon">
                            <i className="material-icons iconStyle">folder_special</i>
                        </div>
                        {
                            this.props.completedCount < 0 ? 
                             <CustomLoader />
                            :
                            <div className="counter counter-number text-bold">{this.props.completedCount}</div>
                        }
                        <div className="counter-text">Project Completed</div>
                    </div>
                </div>
            </div>
        );


    }
}

const mapStateToProps = state =>{
    return{
        completedCount : state.CompletedProgress
    }
};

export default connect(mapStateToProps)(CompletedProjectComponent);