export const setGroupChat = data => {
    return{
        type:"SET_GROUP_CHAT",
        payload:data
    }
}

export const setMessages = data => {
    return{
        type:"SET_MESSAGES",
        payload:data
    }
}
export const setMessagesFromDb = data => {
    return{
        type:"SET_MESSAGES_FROM_DB",
        payload:data
    }
}

export const clearMessages = () => {
    return{
        type:"CLEAR_MESSAGES"
    }
}