import React from "react";
import {ADMIN_EMAIl} from '../Service/Constent/Constent'
import Swal from "sweetalert2";
import Service from '../../components/Service/Api/Api'
export default class ContactUs extends React.Component {

    constructor(props)
    {
      super(props);
        this.state = { 
            FullName : '',
            PhoneNumber : '',
            EmailAddress : '',
            Message : ''        
        };
    }

    onChangeHandler = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    
    ValidateFields = () => {
        try{
            // debugger;
           var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
           var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
           const {FullName,PhoneNumber,EmailAddress,Message} = this.state;
           if(FullName == "" ||  !(/^[a-zA-Z-. ]*$/.test(FullName))){
                return 1;
           }
           else if(PhoneNumber != "" && !phoneno.test(PhoneNumber)){ //&& (PhoneNumber.length < 7 || PhoneNumber.length > 15)){
                return 2;
           }
           else if(EmailAddress == "" || !mailformat.test(EmailAddress)){
                return 3;
           } 
           else if(Message == ""){
                return 4;
           }
           else{
                return 5;
           }
        }
        catch(e){
            console.log(e);
        }
    }

    ClearFields = () => {
        try{
           this.setState({
                FullName : '',
                PhoneNumber : '',
                EmailAddress : '',
                Message : ''
            });
        }
        catch(e){
            console.log(e);
        }
    }

    SubmitDetails = (event) => {
        event.preventDefault();
        try{
            const returnVal = this.ValidateFields();
            if(returnVal == 1){
                Swal.fire({  
                    icon: 'error',  
                    title: 'Error',  
                    text: 'Full Name is not valid',
                    showConfirmButton: false,  
                    timer: 1500  
                  });
            }
            else if(returnVal == 2){
                Swal.fire({  
                    icon: 'error',  
                    title: 'Error',  
                    text: 'Phone number is not valid',
                    showConfirmButton: false,  
                    timer: 1500  
                  }); 
            }
            else if(returnVal == 3){
                Swal.fire({  
                    icon: 'error',  
                    title: 'Error',  
                    text: 'Email Address is not valid',
                    showConfirmButton: false,  
                    timer: 1500  
                  }); 
            }
            else if(returnVal == 4){
                Swal.fire({  
                    icon: 'error',  
                    title: 'Error',  
                    text: 'Message cannot be empty.',
                    showConfirmButton: false,  
                    timer: 1500  
                  }); 
            }
            else{
                var EamilData = new FormData();
                EamilData.append('ToEmail', ADMIN_EMAIl);
                EamilData.append('Subject', 'Contact US Form');
                EamilData.append('Body', 'Name : '+this.state.FullName + '<br><br> Phone Number : ' + this.state.PhoneNumber +'<br><br> Email Address : '+this.state.EmailAddress+'<br><br>Message:'+this.state.Message);
                Service.SendEmail(EamilData);
                this.ClearFields();
                Swal.fire({  
                    icon: 'success',  
                    title: 'Email sent',  
                    text: 'Thanks for contacting us, our support team will contact you shortly.',
                    showConfirmButton: false,  
                    timer: 3000  
                });
            }
        }
        catch(e){
            console.log(e);
        }
    }

    render() {

        const styleTextArea = {
            resize : 'none'
        }

        return (
            <>
        <div className="section">
        <div className="container">

            <div className="contact-page-1">
                <div className="columns">
                    <div className="column is-6">
                        <h1 className="title is-2">
                            Contact us
                        </h1>
                        <p>
                            Do you want to learn how ManageX can help you out in streamlining your mundane activities? Or need to have a one-to-one demo scheduled with our representative? Get in touch with us, we would love to hear you out.
                        </p>
                        <h4><b>Headquarter:</b></h4>
                        <p>
                          <div>
                              <p>
                                 <span>
                                    <i className="fa fa-address-book fa-2x"></i>
                                 </span>
                               </p>
                          </div>
                          <div>
                                <span>One Lake Plaza, Cluster T,</span> 
                          </div>
                          <div>
                                <span>Office # 1301-02</span>
                          </div>
                          <div>
                                <span>JLT Dubai, U.A.E.</span> 
                          </div>
                           
                        </p>
                        <p>
                           <div>
                               <p><span><i className="fa fa-envelope fa-2x"></i></span></p>
                           </div>
                           <div>
                                <a href="mailto:support@managex.ae">support@managex.ae</a> 
                           </div>
                        </p>
                        <p>
                           <div>
                               <p><span><i className="fa fa-phone fa-2x"></i></span></p>
                           </div>
                           <div>
                                <a href="tel:+971(4)4474594">+971 (4) 447 4594</a> 
                           </div>
                        </p>
                    </div>
                    <div className="column is-6">
                        <div className="contact-page-form flex-card">
                            {/* <h3>Contact Us</h3> */}
                            <p><b>Use the form below to send us a message.</b></p>
                            <div className="columns is-multiline">
                                <div className="column is-12">
                                    <div className="field">
                                        <label>Full Name *</label>
                                        <div className="control has-icons-left">
                                            <input 
                                                type="text" 
                                                name="FullName" 
                                                required
                                                className="input is-medium"
                                                onChange={this.onChangeHandler}
                                                value = {this.state.FullName} 
                                                placeholder="Your Name" 
                                            />
                                            <span className="icon is-left is-small">
                                                <i className="sl sl-icon-user"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">

                                    <div className="field">
                                        <label>Phone Number</label>
                                        <div className="control has-icons-left">
                                            <input 
                                                type="tel"
                                                name="PhoneNumber" 
                                                pattern="[0-9]*"
                                                className="input is-medium" 
                                                value = {this.state.PhoneNumber}
                                                placeholder="Your Phone Number"
                                                onChange={this.onChangeHandler}
                                            />
                                            <span className="icon is-left is-small">
                                                <i className="sl sl-icon-phone"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">

                                    <div className="field">
                                        <label>Email Address *</label>
                                        <div className="control has-icons-left">
                                            <input 
                                                type="text" 
                                                className="input is-medium"
                                                required
                                                name="EmailAddress" 
                                                placeholder="Your Email"
                                                value ={this.state.EmailAddress}
                                                onChange={this.onChangeHandler} 
                                            />
                                            <span className="icon is-left is-small">
                                                <i className="sl sl-icon-envelope-open"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">
                                    <div className="field">
                                        <label>Message *</label>
                                        <div className="control">
                                            <textarea 
                                            className="textarea" 
                                            rows="4"
                                            required
                                            onChange={this.onChangeHandler}
                                            name="Message" 
                                            style = {styleTextArea}
                                            value = {this.state.Message}
                                            placeholder="Write your message">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-12">
                                    <div className="field">
                                        <div className="control">
                                            <button type="button"  onClick={this.SubmitDetails}
                                            className="button button-cta primary-btn no-lh is-bold raised is-fullwidth">Send
                                                Message</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="columns has-text-centered pt-80 pb-80">
                    <div className="column is-hidden-mobile"></div>
                    <div className="column is-3">
                        <div className="shadow-icon-box rounded">
                            <span><i className="fa fa-map fa-lg"></i></span>
                        </div>
                        <div className="shadow-title dark-text">
                            Address
                        </div>
                        <div className="description shadow-description">
                           <h3>UAE</h3>
                           <p>
                           <b>One Lake Plaza, Cluster T, Office # 1301-02, JLT Dubai, U.A.E.</b>
                           </p>
                           <h3>USA</h3>
                           <p>
                           190 Route 27 , L2, Edison, New Jersey 08820
                           </p>
                           <h3>CANADA</h3>
                           <p>
                           VaporVM Ltd. Yonge Eglinton Centre, 2300 Yonge Street, Suite 1600Toronto, ON M4PIE4
                           </p>
                           <h3>Pakistan
                           </h3>
                           <p>
                           VaporVM, 2nd floor, The Enterprise Building, Near Thokar Niaz Baig, Lahore, Pakistan
                           </p>
                        </div>
                    </div>
                    <div className="column is-3">
                        <div className="shadow-icon-box rounded">
                            <span><i className="fa fa-envelope fa-lg"></i></span>
                        </div>
                        <div className="shadow-title dark-text">
                            Email
                        </div>
                        <div className="description shadow-description">
                            <br></br>
                            <b>support@managex.ae</b>
                        </div>
                    </div>
                    <div className="column is-3">
                        <div className="shadow-icon-box rounded">
                            <span><i className="fa fa-phone-square fa-lg"></i></span>
                        </div>
                        <div className="shadow-title dark-text">
                            Phone
                        </div>
                        <div className="description shadow-description">
                           <h3>UAE</h3>
                           <p>+971 (4) 447 4594</p>
                           <h3>USA</h3>
                           <p>+1 (201) 779 3877</p>
                           <h3>CANADA</h3>
                           <p> +1(647) 878 7524</p>
                           <h3>Pakistan</h3>
                           <p>+92 (42) 3751 1990</p>
                        </div>
                    </div>
                    <div className="column is-hidden-mobile"></div>
                </div> */}
            </div>

        </div>
        </div>
            </>


        )
    }
}

