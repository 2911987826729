import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from '../Login/index';
import Header from '../Header'
import SideBar from '../SideBar';
import TopBar from '../TopBar';
import cookie from 'react-cookies';

export default class PublicRoutes extends Component{
    constructor(props){
        super(props);
    }
    
    render(){
        let user = JSON.parse(localStorage.getItem('user'));
        var token  = cookie.load("bareer_Token");
        return(
            <div className='d-flex'>
            {/* <Header></Header> */}
            {/* {token ? <SideBar/> : ""} */}
            <main className='main-content'>
                {/* {token ? <TopBar/> : ""} */}
                <Route path={this.props.path}  component={this.props.component} />
            </main>
            </div>
        )
    }
}