import { useState, useRef, useEffect } from 'react';
import {  Routes, Route, Link } from 'react-router-dom';
import OpenTokGuest from './OpenTokGuest';
import { BrowserRouter } from 'react-router-dom';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';

function SupportCall ( { apiKey, sessionId, token, endCall } ){
    const otSession = useRef();
    const [session, setSession] = useState({});
    const [incomingCall, setIncomingCall] = useState(false);
    const [callerName, setCallerName] = useState('');
    const [connection, setConnection] = useState({});
    const [streams, setStreams] = useState([]);
    const [messages, setMessages] = useState([]);
    const [callAlert, setCallAlert] = useState('');
    const [myUserName, setMyUserName] = useState("Guest");

    // setting session on first mount
    // this session object will be passed to our custom opentok component
    // this object is used to send signals

    useEffect(()=>{
        setSession(otSession.current.sessionHelper.session);
    },[]);

    // Events START

    const sessionEvents = {
        sessionConnected: (e) => {
          console.log("Connected");
          const data = JSON.parse(e.target.connection.data);
          setMyUserName(data.name);
          setConnection(e.target.connection);
        },
        
        sessionDisconnected: () => {
          console.log("DisConnected")
        },
  
        streamCreated: event => {
          console.log("Someone joined!", event);
          setStreams(otSession.current.state.streams);
        },
        streamDestroyed: event => {
          console.log("destroy", event);
          setStreams(otSession.current.state.streams);
        },
        signal: event => {
           if(event.type==="signal:incoming-call" && event.data !== `signal:${myUserName}`){
             setIncomingCall(true);
             setCallerName("");
             console.log(event.data);
           }
           if(event.type === "signal:text-message"){
             const data = JSON.parse(event.data);
             setMessages(prevState=>{
                 return [...prevState,data];
             });
             const chatPanel = document.getElementById("chat-panel");
             if(chatPanel){
              chatPanel.scrollTo({
                top:chatPanel.scrollHeight,
                left:0,
                behavior:"smooth"
              })
             }
           }
           else if(event.type === "signal:leftCall"){
            const data = JSON.parse(event.data);
            setCallAlert(`${data.sentBy} has left the call`);
            setTimeout(()=>{
              setCallAlert("");
            },5000);
           }
        }  
  
    }

    // Events END



    return(
        <OTSession ref={otSession} eventHandlers={sessionEvents} apiKey={apiKey} sessionId={sessionId} token={token}>
            <OpenTokGuest 
               SessionObj={session} 
               streams={streams} 
               messages={messages}
               callAlert={callAlert}
               userName={myUserName}
               endCall = {endCall}
            />
        </OTSession>
    );
}



export default SupportCall;

