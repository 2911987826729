import React from "react";
import Service from "../../components/Service/Api/Api";
import queryString from "query-string";
import Swal from "sweetalert2";
//import "./PublicServicesStyle.css";

export default class Wizard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    try {
    } catch (e) {
      console.log(e);
    }
  }

 

  render() {
   
    return (
      <>
        <div style={{width:'100%'}}>
            <ul className="progress-indicator mb-5 mt-3">
                <li className={this.props.currentSteps >= 1 ? "completed" : ""}> <span className="bubble"></span> Services </li>
                {/* <li className={this.props.currentSteps >= 2 ? "completed" : ""}> <span className="bubble"></span> Sub-Services </li> */}
                <li className={this.props.currentSteps >= 2 ? "completed" : ""}> <span className="bubble"></span> Packages </li>
                <li className={this.props.currentSteps >= 3 ? "completed" : ""}> <span className="bubble"></span> Quotation & SOW </li>
                <li className={this.props.currentSteps >= 4 ? "completed" : ""}> <span className="bubble"></span> Payment </li>
                <li className={this.props.currentSteps >= 5 ? "completed" : ""}> <span className="bubble"></span> Connect with Engineer </li>
            </ul>
        </div>
     </>
    );
  }
}
