import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
class ManagedServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: cookie.load('user_Id'),
            userName: cookie.load('user_Name'),
            AssignedUserName: ""
        };
        this.getQuestionaire = this.getQuestionaire.bind(this);

    }
    componentDidMount() {

        Service.GetUserByServiceId(6).then(res => {
            //console.log(res);
            this.setState({ AssignedUserName: res.UserName })
        })
    }
    startCall(CallingTo) {
        if (cookie.load('user_Id') == undefined) {
            this.props.history.push('login')
        }
        else
            window.call(CallingTo, this.state.userId)
    }
    getQuestionaire() {
        this.props.history.push('/Questionnaire/6');
    }
    render() {
        return (
            <>
                <div className="hero parallax"
                    style={{ backgroundSize: "100% 100%", "background-image": "url(https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/MnagedServices.jpg" }}>
                    {/* <!-- Hero caption --> */}
                    <div id="main-hero" className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-5 signup-column has-text-left">
                                    <h1 className="title main-title text-bold is-2" style={{ marginTop: 140 }}>
                                        MANAGE SERVICES
                                    </h1>
                                    <h2 className="subtitle is-5 no-margin-bottom is-light">
                                        COST EFFECTIVE, SECURE, AND RELIABLE
                                    </h2>
                                    <br />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Intro section --> */}
                <div className="container">
                    <div className="section-title-wrapper has-text-centered" style={{ marginTop: 100 }}>
                        <div className="bg-number">1</div>
                        <h2 className="section-title-landing">
                            Managed services with a difference
                        </h2>
                        <div className="container">
                            <div className="Peragraph">
                                <p>
                                    The ManageX team is a trusted provider of 360 degrees
                                    IT Managed Services for decades. Our managed services is a highly flexible
                                    IT delivery program, which allows our customers to choose focus on their core
                                    business, while the ManageX team becomes their trusted IT partner.
                                    The ManageX team's philosophy revolves around providing the best services that show visible
                                    improvements in our customer's business that leads to tangible outcomes.
                                    The ManageX team has transformed its customers who do not consider us to be
                                    their outsourced IT company but as an enablement partner who have positively
                                    contributed to their business.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="content-wrapper">
                            <div className="columns is-vcentered pb-40 pt-40">
                                <div className="column is-4 is-offset-1">
                                    <div className="icon-subtitle"></div>
                                    <div className="title quick-feature has-numbers text-bold">
                                        <div>
                                            Why ManageX Managed Services?
                                        </div>
                                    </div>
                                    <div className="title-divider is-small"></div>
                                    <span className="body-text">
                                        <div className="Peragraph">
                                            <p>
                                                Agilant leverages it's state of the art
                                                Network-Operations-Center (NOC) and strategic
                                                partnerships with key technology vendors and
                                                to provide our customers with seamless IT operations.
                                            </p>
                                        </div>
                                    </span>
                                    <div className="pt-10 pb-10">
                                        <div className='row'>
                                            <button className="button btn-align btn btn-primary col-4" onClick={this.getQuestionaire}>Buy Now</button>
                                            {this.state.AssignedUserName != "" ?
                                                <div classNameName='col-2'><button className="button btn-align btn btn-success" onClick={() => this.startCall(this.state.AssignedUserName)}>Talk to an expert</button></div>
                                                : ""}

                                        </div>


                                    </div>
                                </div>
                                <div className="column is-6 is-offset-1">
                                    <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/services.png"
                                        data-base-url="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/services.png" data-extension=".svg" alt="" />
                                </div>
                            </div>
                        </div>
                        {/* <!-- Benefits section --> */}
                        <div className="section is-medium">
                            <div className="section-title-wrapper has-text-centered">


                                <div className="container" style={{ marginTop: 20 }}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h3>Other IT Service Providers
                                                Focus on
                                            </h3>
                                            <centre>
                                                <ul>
                                                    <li>Device Failure</li>
                                                    <li>Network Failure</li>
                                                    <li>Data Loss</li>
                                                    <li>Viruses & Malware</li>
                                                </ul>
                                            </centre>
                                        </div>
                                        <div className="col-sm-6">
                                            <h3>What ManageX Focuses on
                                            </h3>
                                            <ul>
                                                <li>Device Uptime</li>
                                                <li>Network Uptime</li>
                                                <li>Data Availability</li>
                                                <li>Malware Free Devices </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div id="start" className="container">
                                {/* <!-- Title --> */}
                                <div className="content-wrapper">
                                    <div className="columns" style={{ "margin-left": "-3.75rem" }}>
                                        {/* <!-- Benefit box --> */}
                                        <div className="column is-4 mr-25">
                                            <div id="CardHeight" className="feature-card card-lg light-bordered hover-inset mb-20">
                                                <div className="card-img">
                                                    <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/Servers.jpg" alt="" data-demo-src="/assets/img/photos/CustomImages/Servers.jpg" />
                                                </div>
                                                <div className="card-title pb-10 pt-10">
                                                    <h4>
                                                        Server, virtualization desktop monitoring
                                                    </h4>
                                                </div>
                                                <div className="card-img-description has-text-left">
                                                    <span className="">
                                                        Server, VM, Desktops: Keep a constant check on your device's CPU,
                                                        memory, storage and connectivity to ensure your
                                                        application's optimum performance
                                                        <br />
                                                        <br />
                                                    </span>
                                                </div>
                                                <div className="pt-10 has-text-left">
                                                    <button className="button btn-align btn btn-primary"
                                                        onClick={this.getQuestionaire} style={{ marginLeft: 80 }}>Get Pricing</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column is-4 mr-25">
                                            <div className="feature-card card-lg light-bordered hover-inset mb-20">
                                                <div className="card-img">
                                                    <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/Network.jpg" alt="" data-demo-src="/assets/img/photos/CustomImages/Network.jpg" />
                                                </div>
                                                <div className="card-title pb-10 pt-10">
                                                    <h4>Networking monitoring </h4>
                                                </div>
                                                <div className="card-img-description has-text-left">
                                                    <span className="">
                                                        Network Monitoring Services allow customers to ensure the optimal performance of
                                                        their network devices by monitoring connectivity, errors and device utilization.
                                                        <br />
                                                        <br />

                                                    </span>
                                                </div>
                                                <div className="pt-10 has-text-left">
                                                    <button className="button btn-align btn btn-primary"
                                                        onClick={this.getQuestionaire} style={{ marginLeft: 80 }}>Get Pricing</button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="column is-4 mr-25">
                                            <div className="feature-card card-lg light-bordered hover-inset mb-20">
                                                <div className="card-img">
                                                    <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/design.jpg" alt="" data-demo-src="/assets/img/photos/CustomImages/design.jpg" />
                                                </div>
                                                <div className="card-title pb-10 pt-10">
                                                    <h4>Other IT devices </h4>
                                                </div>
                                                <div className="card-img-description has-text-left">
                                                    <span className="">
                                                        Printers, UPS, mobile devices? Ensure the availability of any device through our dashboard access
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </span>
                                                </div>
                                                <div className="pt-10 has-text-left">
                                                    <button className="button btn-align btn btn-primary"
                                                        onClick={this.getQuestionaire} style={{ marginLeft: 80 }}>Get Pricing</button>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!-- Process section --> */}
                    </div>
                </div>
            </>
        )
    }
}

export default ManagedServices;