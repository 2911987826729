import { event } from 'jquery';
import React, { Component } from 'react'
import './PackageSelection.css'
import Swal from "sweetalert2";
import Service from '../../components/Service/Api/Api'  
import { packageTemplate } from '../EmailTemplates/PackageTemplate';   
export default class PackageSelection extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            packageName:"",
            interest:"Security Operations Center - Silver Package",
            budget:"",
            itAssets:"",
            email:"",
            phone:""
        }
    }


    onValueChange = (event)=>
    {
        try{
            this.setState({packageName : event.target.value});
        }
        catch(e){
            console.log(e);
        }
    }

    OnValidate = (e) => {
        let emailError = "";
        let passwordError = "";
    
        if (!this.state.email.includes("@") && !this.state.email.includes(".")) {
          emailError = "Plesae enter your valid email";
        }
        if (emailError || passwordError) {
          this.setState({ emailError, passwordError, loading: true });
        
          return false;
        } else {
          return true;
        }
      };

    handleSubmit = (event)=>
    {
        const{email,phone}=this.state;
        const isValidated = this.OnValidate();
        if(email!=="" && phone!==""){
            if(isValidated)
            {
                var EmailData = new FormData();
                EmailData.append('ToEmail', "safgillani@gmail.com");
                EmailData.append('Subject', 'Customer Inquiry');
                var _bodyTemplate = packageTemplate;
                var Replaceversion = _bodyTemplate.replace("{ServiceOwner}","Waqas").
                                    replace("{Interest}",this.state.interest).
                                    replace("{Budget}",this.state.budget).
                                    replace("{Assets}",this.state.itAssets).
                                    replace("{Email}",this.state.email).
                                    replace("{Phone}",this.state.phone);
                EmailData.append('Body',Replaceversion);
                Service.SendEmail(EmailData);
                Swal.fire({  
                    icon: 'success',  
                    title: 'Email sent successfully',  
                    text: 'Thank you for submitting your Cyber Security Solution request. Our Certified Engineer will be in touch with you in a jiffy!',
                    showConfirmButton: false,  
                    timer: 3000  
                });
            }
            else{
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Please enter valid email",
                    showConfirmButton: false,
                    timer: 1500,
                  });
            } 
        }
        else{
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter Email / Phone Number first.",
                showConfirmButton: false,
                timer: 1500,
              });
        }
        event.preventDefault();
        
    }

    handleChange = (event) =>
    {
        this.setState({[event.target.name]:  event.target.value});
    }

    render() {
        const List = ['Security Operations Center - Silver Package',
        'Security Operations Center - Gold Package',
        'Security Operations Center - Platinum Package',
        'Customize Your Package'
    ];
        return (
            <div className='row'>
                <div className='col-lg-4 col-md-8 col-sm-12' style={{marginLeft:"2%",marginRight:"2%"}}>
                   <h4 style={{marginLeft:"1%"}}>Select Your Services Package</h4>
                   <div>
                        <div className = "row" style={{alignItems:"center"}}>
                            <input className="col-1" data-toggle="collapse" href="#collapse1" aria-expanded="false" type="radio" value="1" name="PackageSelected" onChange={this.onValueChange}/>
                            <span  className="panelHeading col-11">Security Operations Center - Silver Package</span>
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                        <div className="collapse col-11" id="collapse1" style={{border:"1px solid grey",padding:"20px"}}>
                            <h4>Silver Package (up to 250 IT Assets)</h4>
                            <ul className="stylingList">
                                <li style={{fontWeight:"bold"}}>Price: 8000$ /month</li>
                                <br/>
                                <li>24/7 Managed SOC</li>
                                <li>Automated Asset discovery </li>
                                <li>Vulnerability assessment</li>
                                <li>Intrusion detection</li>
                                <li>Vulnerability Monitoring & Management </li>
                                <li>SIEM event correlation</li>
                                <li>Incident response</li>
                                <li>Log management</li>
                                <li>Ticket Management</li>
                                <li>Email alerts</li>
                            </ul> 
                            <button type="submit" style={{float:"right"}} className="btn btn-success" onClick={this.BuyNow}>Buy Now</button>
                                    
                            
                        </div>
                        </div>
                        <div className = "row"style={{alignItems:"center"}}>
                            <input className="col-1" data-toggle="collapse" href="#collapse2" aria-expanded="false" type="radio" value="2" name="PackageSelected" onChange={this.onValueChange}/>
                            <span className="panelHeading col-11"> Security Operations Center - Gold Package </span>
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className="collapse col-11" id="collapse2" style={{border:"1px solid grey",padding:"20px"}}>
                            <h4>Gold Package (up to 250 IT Assets)</h4>
                                <ul className="stylingList">
                                    <li style={{fontWeight:"bold"}}>Price: 14,000$ /month</li>
                                    <br/>
                                    <li>24/7 Managed SOC</li>
                                    <li>Automated Asset discovery </li>
                                    <li>Vulnerability Monitoring & Management (IT/OT)</li>
                                    <li>Vulnerability assessment</li>
                                    <li>Intrusion detection</li>
                                    <li>Threat Hunting</li>
                                    <li>Incident Response & Triage</li>
                                    <li>Threat Advisory Services</li>
                                    <li>Devices Health Test Monitoring</li>
                                    <li>Security Devices Tuning </li>
                                </ul>
                                <button type="submit" style={{float:"right"}} className="btn btn-success" onClick={this.BuyNow}>Buy Now</button>
                            </div>
                        </div>
                        <div className = "row" style={{alignItems:"center"}}>
                            <input className="col-1" data-toggle="collapse" href="#collapse3" aria-expanded="false" type="radio" value="3" name="PackageSelected" onChange={this.onValueChange}/>
                            <span className="panelHeading col-11">Security Operations Center - Platinum Package</span>
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className="collapse col-11" id="collapse3" style={{border:"1px solid grey",padding:"20px"}}>
                            <h4>Platinum Package (up to 250 IT Assets)</h4>
                                <ul className="stylingList">
                                    <li style={{fontWeight:"bold"}}>Price: 18,000 $ /month</li>
                                    <br/>
                                    <li>24/7 Security Monitoring & Triage</li>
                                    <li>Asset discovery & inventory</li>
                                    <li>Intrusion detection</li>
                                    <li>SIEM event correlation</li>
                                    <li>Incident response </li>
                                    <li>Endpoint detection and response</li>
                                    <li>Log management</li>
                                    <li>Compliance based reporting</li>
                                    <li>Ticket Management & Email alerts</li>
                                    <li>Devices Health Test Monitoring</li>
                                </ul>
                                <button type="submit" style={{float:"right"}} className="btn btn-success" onClick={this.BuyNow}>Buy Now</button>
                            </div>
                        </div>
                        <div className = "row" style={{alignItems:"center"}}>
                            <input className="col-1" data-toggle="collapse" href="#collapse4" aria-expanded="false" type="radio" value="4" name="PackageSelected" onChange={this.onValueChange}/>
                            <span  className="panelHeading col-11">Customize Your Package</span>
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className="collapse col-11" id="collapse4"  style={{border:"1px solid grey",padding:"20px"}}>
                            <form>
                            <div className="form-group row">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Interest</label>
                                <div className="col-sm-7">
                                    <select className="form-control" name ="interest" value = {this.state.interest}  id="exampleFormControlSelect1" onChange={this.handleChange}>
                                        <option value = "Security Operations Center - Silver Package"> Security Operations Center - Silver Package  </option>
                                        <option value = "Security Operations Center - Gold Package"> Security Operations Center - Gold Package    </option>
                                        <option value = "Security Operations Center - Platinum Package"> Security Operations Center - Platinum Package</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="inputPassword3" className="col-sm-2 col-form-label">Budget</label>
                                    <div className="col-sm-7">
                                    <input name ="budget" value={this.state.value} type="text" className="form-control" id="inputPassword3" placeholder="Budget" onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="inputPassword3" className="col-sm-2 col-form-label">IT Assets</label>
                                    <div className="col-sm-7">
                                    <input name = "itAssets" value={this.state.value} type="text" className="form-control" id="inputPassword3" placeholder="Number of IT Assets" onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-2 col-form-label">Email<span style={{marginLeft:"5px",color:"red"}}>*</span></label>
                                    <div className="col-sm-7">
                                    <input name = "email" value={this.state.value} type="email" className="form-control" id="inputEmail3" placeholder="Email" onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="inputPassword3" className="col-sm-2 col-form-label">Phone#<span style={{marginLeft:"5px",color:"red"}}>*</span></label>
                                    <div className="col-sm-7">
                                    <input name = "phone" value={this.state.value} type="text" className="form-control" id="inputPassword3" placeholder="Phone#" onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-9" style={{textAlign:"right"}}>
                                    <button type="submit" className="btn btn-success" onClick={this.handleSubmit}>Submit</button>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                   </div>
                   
                   
                </div>
            </div>
        )
    }
}
