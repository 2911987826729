import React, { Component } from 'react';
import './QuotationForm.css';
import Service from '../../components/Service/Api/Api'
// import FormRow from './QuotationFormRow';
import { GetCartItems } from '../Common/Cart';
import parser from 'html-react-parser';
import { AddToCart, RemoveFromCart, CheckCartItem } from '../Common/Cart';
import cookie from "react-cookies";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CustomLoader from '../Loader/Loader';

import SignInSignUp from './SignInSignUp';

import axios from 'axios';
import { BASE_URl_Pdf_API } from '../../components/Service/Constent/Constent';

export default class QuotationForm extends Component {

    pdfServiceUrl = BASE_URl_Pdf_API
    constructor(props) {
        super(props);
        this.TotalPriceVal = 0;
        this.totalPrice = 0;
        this.state = {
            isUserLogin: false,
            CartItems: [],
            ReferenceNumber: 'Loading...',
            token: cookie.load("bareer_Token"),
            isLoader: false,
            localCartItems: [],
            quotationData: {
                statusCode: null,
                message: "",
                data: {
                    refNum: "Loading...",
                    lstService: [
                        {
                            serviceName: "Loading...",
                            lstSubServices: []
                        }
                    ],
                    lstPackagesInfo: [],
                    totalPrice: "Calculating..."
                }
            }
        }
    }
    setIsUserLogin = (isLoggedIn, tokenVal) => {
        this.setState({
            isUserLogin: isLoggedIn,
            token: tokenVal
        });
        this.QuotationWork();
    }
    componentDidMount() {
        // this.FetchCartItems();
        //this.QuotationWork();
        try {
            // if(this.props.QuotationNumber != null && this.props.QuotationNumber != undefined && this.props.QuotationNumber != ""){
            //     this.getCustomerOrder(this.props.QuotationNumber);
            // }
            var _isUserLogin = cookie.load("user_Id");
            if (_isUserLogin) {
                this.setState({ isUserLogin: true });
                this.QuotationWork();
            }
        } catch (e) {
            console.log(e);
        }
    }
    printQuotation = (e) => {
        this.setState({ isLoader: true });
        if (this.state.ReferenceNumber !== "Loading..." && this.TotalPriceVal !== 0) {
            // const payload = {
            //     refNum: this.state.ReferenceNumber,
            //     serviceName: this.props.ParentServiceList[0].name, //taking only first index for now
            //     totalPrice: "$ " + this.TotalPriceVal,
            //     subServiceNames: this.state.CartItems.map(item => {
            //         return item.subServiceName
            //     }),
            //     packageNames: this.state.CartItems.map(item => {
            //         return item.packageName
            //     }),
            //     prices: this.state.CartItems.map(item => {
            //         return "$ " + item.price
            //     }),
            //     // Hard coded terms and conditions for now
            //     termsAndConditions: [
            //         "Valid for 15 business days from the date of generation",
            //         "Quotation is without Tax and Discount",
            //         "For further details, call our support"
            //     ]
            // }
            const payload1 = {
                refNum: this.state.ReferenceNumber,
                customerName: cookie.load("user_Name"),
                customerEmail: cookie.load("user_Id"),
                quotationDate: this.state.quotationData.data.quotationDate,
                discount: `$ 0`,
                vat: `$ 0`,
                subTotal: `$ ${this.state.quotationData.data.totalPrice}`,
                grandTotal: `$ ${this.state.quotationData.data.totalPrice}`,
                termsAndConditions: [
                    "Valid for 15 business days from the date of generation",
                    "Quotation is without Tax and Discount",
                    "For further details, call our support"
                ],
                serviceDetails: [{
                    name: this.state.quotationData.data.lstService[0].serviceName,
                    subServices: this.state.quotationData.data.lstPackagesInfo.map(item => {
                        return {
                            id: 1,
                            subServiceName: item.subservicename,
                            packageName: item.packagename,
                            price: `$ ${Number(item.price) * Number(item.qty)}`,
                            qty: item.qty,
                            totalPrice: `$ ${Number(item.price) * Number(item.qty)}`
                        }
                    })
                }]
            }
            // console.log(payload)
            this.downloadQuotation(payload1);
        }
    }
    generateQuotation = (e) => {
        this.setState({ isLoader: true });
        if (this.state.ReferenceNumber !== "Loading...") {
            const payload = {
                isQuoteRefNum: true,
                refnum: this.state.ReferenceNumber,
                username: cookie.load("user_Name"),
                JiraProjectKey: ""
            }
            Service.getPDFDetail(payload).then(res => {
                if (res.statusCode === 200) {
                    const { data } = res;
                    let PackageArray = data.packageNames.split("¿");
                    let PackageDetailArr = []; // Object List Contains packages and its respective details 
                    for (const value of PackageArray) {
                        if (value) {
                            let DetailArr = value.split("¥");
                            if (PackageArray.length === 0) { // Call only once when list is empty
                                let DetailPayloadArr = [];
                                DetailPayloadArr.push(DetailArr[1]);
                                let objPayload = {
                                    Name: DetailArr[0],
                                    Details: DetailPayloadArr
                                };
                                PackageDetailArr.push(objPayload);
                            }
                            else {
                                const isFound = PackageDetailArr.some(item => item.Name == DetailArr[0]);
                                if (isFound) {
                                    PackageDetailArr[PackageDetailArr.length - 1].Details.push(DetailArr[1]);
                                }
                                else {
                                    let DetailPayloadArr = [];
                                    DetailPayloadArr.push(DetailArr[1]);
                                    let objPayload = {
                                        Name: DetailArr[0],
                                        Details: DetailPayloadArr
                                    };
                                    PackageDetailArr.push(objPayload);
                                }
                            }
                        }
                    }

                    const reqObj = {
                        UserName: data.userName,
                        RefNum: data.refNum,
                        ServiceName: data.serviceNames,
                        SubServiceName: data.subServiceNames,
                        packageName: PackageDetailArr,//data.packageNames.split(","),
                        supportEmail: data.supportEmails.split(","),
                        userEmail: [
                            cookie.load("user_Id") ? cookie.load("user_Id") : "Guest User",
                        ],
                        userPhone: data.phone,
                        terms: data.terms.split(",")
                    }
                    this.downloadSOW(reqObj);
                }
            });
        }
    }
    // Huzaifa's change START
    downloadSOW = (data) => {
        axios.post(`${this.pdfServiceUrl}file/sow`, data, {
            responseType: "blob",
        }).then((response) => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", `SOW-${cookie.load("user_Name")}.pdf`);
            // document.body.appendChild(link);
            link.click();
            this.setState({ isLoader: false });
        });
    }
    downloadQuotation = (data) => {
        axios.post(`${this.pdfServiceUrl}PrintQuotation`, data, {
            responseType: "blob",
        }).then((response) => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", `Quotation-${cookie.load("user_Name")}.pdf`);
            // document.body.appendChild(link);
            link.click();
            this.setState({ isLoader: false });
        });
    }

    // Huzaifa's change End
    FetchCartItems = () => {
        try {
            let _arr = GetCartItems("cart_items");
            let packageIDs = '';
            if (_arr !== null) {
                for (let value of _arr) {
                    packageIDs += value.id + ",";
                }
            }

            packageIDs = packageIDs.slice(0, packageIDs.length - 1);
            Service.FetchCartItems(packageIDs).then(res => {
                if (res.statusCode === 200) {
                    this.setState({ CartItems: res.data });
                    this.GenerateQuotation();
                }
            }).catch(err => {
                console.log(err);
            });
            // temporary work START
            const localCartItems = GetCartItems("cart_items")
            this.setState({ localCartItems })

            // temporary work END
        }
        catch (e) {
            console.log(e);
        }
    }

    DeleteCartItem = (param) => {
        try {
            var array = [...this.state.CartItems];
            const index = array.findIndex(obj => obj.packageId == param);
            if (index !== -1) {
                RemoveFromCart("cart_items", param.toString());
                RemoveFromCart("cart_sub_services", array[index].subServiceID.toString());
                array.splice(index, 1);
                this.setState({ CartItems: array });
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    setTotalPrice = (price) => {
        try {
            this.props.SetTotalAmount(price);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    setServiceName = (servicename) => {
        try {
            this.props.SetServiceName(servicename);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    setQuoteNumber = (QuoteNumber) => {
        try {
            this.props.SetQuotationNumberInfo(QuoteNumber);
        }
        catch (ex) {
            console.log(ex);
        }
    }


    FetchCompleteData = (RefId) => {
        try {
            Service.FetchCartItems(RefId).then(res => {
                if (res.statusCode === 200) {
                    this.setState({ quotationData: res });
                    this.setState({ CartItems: res.data });
                    //this.GenerateQuotation();
                }
            }).catch(err => {
                console.log(err);
            });
        }
        catch (exx) {
            console.log(exx);
        }
    }

    // Check if ref exist if not then create new quotation otherwise it will update quotation on basis 
    // of cart item modificaion if cart items are not modified then it will show quotation data 
    // otherwise it will update quotation
    QuotationWork = () => {
        try {
            var _refNum = cookie.load("QuoteReference");
            if (_refNum !== undefined && _refNum !== "") {
                this.setState({ ReferenceNumber: _refNum });
                this.setQuoteNumber(_refNum);
                let _arr = GetCartItems("cart_items");
                let _QuoteItems = cookie.load("QuoteItems");
                let packagePayLoad = [];
                let _TotalPrice = 0.0;
                let packageIDs = "";
                if (_arr !== null) {
                    for (let value of _arr) {
                        let objPayload = {
                            PackageID: parseInt(value.id),
                            Qty: parseInt(value.qty)
                        }
                        packageIDs += value.id + ",";
                        // _TotalPrice += parseFloat(value.price).toFixed(2);
                        _TotalPrice += parseFloat(value.price) * parseInt(value.qty);
                        packagePayLoad.push(objPayload);
                    }
                    packageIDs = packageIDs.slice(0, packageIDs.length - 1);
                }
                this.setTotalPrice(_TotalPrice.toFixed(2).toString());
                if (packageIDs !== _QuoteItems) {
                    // Update call 
                    cookie.remove('QuoteItems');
                    cookie.save("QuoteItems", packageIDs, {
                        path: "/",
                        maxAge: 5 * 3600,
                    });
                    let _isGuestUser = true;
                    let _tempUser = "";
                    let _iCreatedByUserId = "-1";

                    const { token } = this.state;
                    if (token !== undefined && token !== "") {
                        _isGuestUser = false;
                        _iCreatedByUserId = cookie.load("Id");
                    }
                    else {
                        var _Updatedate = new Date();
                        // Creating temp user 
                        _tempUser = "temp_" +
                            _Updatedate.getDate() + "_" +
                            _Updatedate.getMonth() + "_" +
                            _Updatedate.getFullYear() + "_" +
                            _Updatedate.getHours() + "_" +
                            _Updatedate.getMinutes() + "_" +
                            _Updatedate.getSeconds() + "_" +
                            _Updatedate.getMilliseconds();
                    }
                    //this.setTotalPrice(_TotalPrice.toString());
                    var UpdatePayload = {
                        TotalPrice: _TotalPrice.toFixed(2).toString(), //this.TotalPriceVal.toString(),
                        iCreatedByUserId: parseInt(_iCreatedByUserId),
                        isGuestUser: _isGuestUser,
                        GuestId: _tempUser,
                        //SelectedPackageIds: packageIDs,
                        RefNum: _refNum,
                        lstPackageID: packagePayLoad
                    };
                    Service.UpdateQuotationN(UpdatePayload).then(res => {
                        if (res.statusCode === 200) {
                            this.FetchCompleteData(res.data);
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                } // update call end 
                else {
                    this.setTotalPrice(_TotalPrice.toFixed(2).toString());
                    this.FetchCompleteData(_refNum);
                }



            }// else start here 
            else {
                // IT will call first time only 
                let _isGuestUser = true;
                let _tempUser = "";
                let _iCreatedByUserId = "-1";
                const { token } = this.state;
                if (token !== undefined && token !== "") {
                    _isGuestUser = false;
                    _iCreatedByUserId = cookie.load("Id");
                }
                else {
                    var _date = new Date();
                    // Creating temp user 
                    _tempUser = "temp_" +
                        _date.getDate() + "_" +
                        _date.getMonth() + "_" +
                        _date.getFullYear() + "_" +
                        _date.getHours() + "_" +
                        _date.getMinutes() + "_" +
                        _date.getSeconds() + "_" +
                        _date.getMilliseconds();
                }
                let _arr = GetCartItems("cart_items");
                let packagePayLoad = [];
                let _TotalPrice = 0.0;
                let packageIDs = "";

                if (_arr !== null) {
                    for (let value of _arr) {
                        let objPayloadNew = {
                            PackageID: parseInt(value.id),
                            Qty: parseInt(value.qty)
                        }
                        packageIDs += value.id + ",";
                        // _TotalPrice += parseFloat(value.price).toFixed(2);
                        _TotalPrice += parseFloat(value.price) * parseInt(value.qty);
                        packagePayLoad.push(objPayloadNew);
                    }
                    packageIDs = packageIDs.slice(0, packageIDs.length - 1);
                }
                this.setTotalPrice(_TotalPrice.toFixed(2).toString());
                var payload = {
                    TotalPrice: _TotalPrice.toFixed(2).toString(),
                    iCreatedByUserId: parseInt(_iCreatedByUserId),
                    isGuestUser: _isGuestUser,
                    GuestId: _tempUser,
                    lstPackageID: packagePayLoad
                    //SelectedPackageIds: packageIDs
                };
                Service.CreateQuotation(payload).then(res => {
                    if (res.statusCode === 200) {
                        this.setState({ ReferenceNumber: res.data });
                        this.setQuoteNumber(res.data);
                        this.FetchCompleteData(res.data);
                        cookie.save("QuoteReference", res.data, {
                            path: "/",
                            maxAge: 5 * 3600,
                        });
                        cookie.save("QuoteItems", packageIDs, {
                            path: "/",
                            maxAge: 5 * 3600,
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        }
        catch (exx) {
            console.log(exx);
        }
    }

    GenerateQuotation = () => {
        try {
            var _refNum = cookie.load("QuoteReference");
            if (_refNum !== undefined && _refNum !== "") {
                this.setState({ ReferenceNumber: _refNum });
                this.setQuoteNumber(_refNum);
                let _QuoteItems = cookie.load("QuoteItems");
                let _arr = GetCartItems("cart_items");
                let packageIDs = "";
                if (_arr !== null) {
                    for (let value of _arr) {
                        packageIDs += value + ",";
                    }
                    packageIDs = packageIDs.slice(0, packageIDs.length - 1);
                    if (packageIDs !== _QuoteItems) {
                        // Update call 
                        cookie.remove('QuoteItems');
                        cookie.save("QuoteItems", packageIDs, {
                            path: "/",
                            maxAge: 5 * 3600,
                        });
                        let _isGuestUser = true;
                        let _tempUser = "";
                        let _iCreatedByUserId = "-1";

                        const { token } = this.state;
                        if (token !== undefined && token !== "") {
                            _isGuestUser = false;
                            _iCreatedByUserId = cookie.load("Id");
                        }
                        else {
                            var _Updatedate = new Date();
                            // Creating temp user 
                            _tempUser = "temp_" +
                                _Updatedate.getDate() + "_" +
                                _Updatedate.getMonth() + "_" +
                                _Updatedate.getFullYear() + "_" +
                                _Updatedate.getHours() + "_" +
                                _Updatedate.getMinutes() + "_" +
                                _Updatedate.getSeconds() + "_" +
                                _Updatedate.getMilliseconds();
                        }
                        this.setTotalPrice(this.TotalPriceVal.toString());
                        var UpdatePayload = {
                            TotalPrice: this.TotalPriceVal.toString(),
                            iCreatedByUserId: parseInt(_iCreatedByUserId),
                            isGuestUser: _isGuestUser,
                            GuestId: _tempUser,
                            SelectedPackageIds: packageIDs,
                            RefNum: _refNum
                        };
                        Service.UpdateQuotationN(UpdatePayload).then(res => {
                            if (res.statusCode === 200) {
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                    } // update call end 
                    else {
                        this.setTotalPrice(this.TotalPriceVal.toString());
                    }
                }
            }
            else {
                // New entry
                let _isGuestUser = true;
                let _tempUser = "";
                let _iCreatedByUserId = "-1";

                const { token } = this.state;
                if (token !== undefined && token !== "") {
                    _isGuestUser = false;
                    _iCreatedByUserId = cookie.load("Id");
                }
                else {
                    var _date = new Date();
                    // Creating temp user 
                    _tempUser = "temp_" +
                        _date.getDate() + "_" +
                        _date.getMonth() + "_" +
                        _date.getFullYear() + "_" +
                        _date.getHours() + "_" +
                        _date.getMinutes() + "_" +
                        _date.getSeconds() + "_" +
                        _date.getMilliseconds();

                }


                let _arr = GetCartItems("cart_items");

                let packageIDs = "";
                if (_arr !== null) {
                    for (let value of _arr) {
                        packageIDs += value.id + ",";
                    }
                }
                this.setTotalPrice(this.TotalPriceVal.toString());
                packageIDs = packageIDs.slice(0, packageIDs.length - 1);
                var payload = {
                    totalPrice: this.TotalPriceVal.toString(),
                    iCreatedByUserId: parseInt(_iCreatedByUserId),
                    isGuestUser: _isGuestUser,
                    guestId: _tempUser,
                    lstPackageID: packageIDs
                };
                Service.CreateQuotation(payload).then(res => {
                    if (res.statusCode === 200) {
                        this.setState({ ReferenceNumber: res.data });
                        this.setQuoteNumber(res.data);
                        this.FetchCompleteData(res.data);
                        cookie.save("QuoteReference", res.data, {
                            path: "/",
                            maxAge: 5 * 3600,
                        });
                        cookie.save("QuoteItems", packageIDs, {
                            path: "/",
                            maxAge: 5 * 3600,
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    render() {


        var _self = this;
        // var _data = this.state.CartItems.length > 0 ? this.state.CartItems.map(function (item) {
        //     return (
        //         <>
        //             <li>{item.subServiceName}<i className="fa fa-trash delete-icon" onClick={() => _self.DeleteCartItem(item.packageId)}></i></li>
        //         </>
        //     );
        // }) : "Loading...";
        var _data = this.state.quotationData.data.lstService.length > 0 ? this.state.quotationData.data.lstService.map(function (item) {
            return (
                <>
                    {
                        item.lstSubServices.map(subService => {
                            return <li>{subService}<i className="fa fa-trash delete-icon" onClick={() => _self.DeleteCartItem(item.packageId)}></i></li>
                        })
                    }
                </>
            );
        }) : "Loading...";
        var _newData = this.state.quotationData.data.lstService.length > 0 ? this.state.quotationData.data.lstService.map(function (item) {
            return (
                <>
                    {
                        item.lstSubServices.map(subService => {
                            return <li>{subService}<i className="fa fa-trash delete-icon" onClick={() => _self.DeleteCartItem(item.packageId)}></i></li>
                        })
                    }
                </>
            );
        }) : "Loading...";

        var _data1 = this.state.CartItems.length > 0 ? this.state.CartItems.map(function (item, i) {
            return (
                <>
                    <li>{item.packageName} (Quantity: {_self.state.localCartItems[i].qty})</li>
                </>
            );
        }) : "Loading...";

        var _totalPrice = 0;
        var _data2 = this.state.quotationData.data.lstPackagesInfo.length > 0 ? this.state.quotationData.data.lstPackagesInfo.map(function (item) {
            _totalPrice += item.price;
            _self.TotalPriceVal = _totalPrice;
            return (
                <>
                    <li>$ {Number(item.price) * Number(item.qty)} {item.packagename} {item.subservicename} (Quantity: {item.qty})</li>
                </>
            );
        }) : "Loading...";
        var _data3 = this.state.quotationData.data.lstPackagesInfo.length > 0 ? this.state.quotationData.data.lstPackagesInfo.map(function (item, i) {
            _totalPrice += item.price;
            _self.TotalPriceVal = _totalPrice;
            return (
                <div className='custom-table-row'>
                    <div className='custom-table-data'>{i + 1}</div>
                    <div className='custom-table-data'>{_self.state.quotationData.data.lstService[0].serviceName}</div>
                    <div className='custom-table-data'>{item.subservicename}</div>
                    <div className='custom-table-data'>{item.packagename}</div>
                    <div className='custom-table-data'>{item.qty}</div>
                    <div className='custom-table-data'>{"$ " + Number(item.price)}</div>
                    <div className='custom-table-data'>{"$ " + Number(item.price) * Number(item.qty)}</div>
                </div>
            );
        }) : "Loading...";

        return (
            <>
                {
                    this.state.isUserLogin ?
                        <div className='is-flex is-flex-direction-column is-align-items-stretch' style={{ width: "100%" }}>
                            {this.state.isLoader ? <CustomLoader /> : ""}
                            <div className="quotation-card-wrap">
                                <div class="top-section-wrap text-center">
                                    <div class="top-section-left">
                                        <img class="logo-image" src="https://mxprodstorage.blob.core.windows.net/managex/assets/EmailTemplate/Logo.png" alt="" />
                                    </div>
                                    <div class="top-section-right">
                                        <h3 class="address">
                                            Office 1301, One Lake Plaza, JLT, Cluster T, Dubai, UAE <br />+971 (4) 447 4594<br />support@managex.ae
                                        </h3>
                                    </div>
                                </div>
                                <div class="bottom-section-wrap">
                                    <div class="details-wrap">
                                        <div class="details-left mobile-order-1">
                                            <div className='inner-wrap'>
                                                <p class="heading-text">For</p>
                                                <p class="details-text"><span class="bold">Name: </span>{cookie.load("user_Name")}</p>
                                                <p class="details-text"><span class="bold">Email: </span>{cookie.load("user_Id")}</p>
                                            </div>
                                        </div>
                                        <div class="details-right">
                                            <div className='inner-wrap'>
                                                <h3 class="details-right-heading color-2 audiowide">QUOTATION</h3>
                                                <ul class="" style={{ padding: "0px", listStylePosition: 'inside' }}>
                                                    <li>Quotation No : {this.state.ReferenceNumber}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-table-wrap">
                                        <div class="custom-table-row">
                                            <div class="custom-table-data custom-table-head bg-2 color-white">No</div>
                                            <div class="custom-table-data custom-table-head bg-2 color-white">Domain</div>
                                            <div class="custom-table-data custom-table-head bg-2 color-white">Subservice</div>
                                            <div class="custom-table-data custom-table-head bg-1">Package</div>
                                            <div class="custom-table-data custom-table-head bg-1">Units</div>
                                            <div class="custom-table-data custom-table-head bg-1">Unit Price</div>
                                            <div class="custom-table-data custom-table-head bg-1">Total</div>
                                        </div>
                                        {/* Below is the actual data from API */}
                                        {_data3}
                                    </div>
                                    <div class="details-wrap">
                                        <div class="details-left">
                                            <div class="terms">
                                                <p class="terms-title bold color-2">Terms & Conditions</p>
                                                <ul className='' style={{ fontSize: "12px", padding: '0px', listStylePosition: "inside", marginTop: "0.5em" }}>
                                                    <li>Valid for 15 business days from the date of generation</li>
                                                    <li>Quotation is without Tax and Discount</li>
                                                    <li>For further details, call our support</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="details-right">
                                            <div class="total-wrap">
                                                <div class="total-top">
                                                    <p class="total-row"><span class="bold">SUB TOTAL: </span>{"$ " + this.state.quotationData.data.totalPrice} </p>
                                                    <p class="total-row"><span class="bold">Taxl: VAT </span>$ 0.00 </p>
                                                    <p class="total-row"><span class="bold">Discount: </span>$ 0.00 </p>
                                                </div>
                                                <div class="total-bottom bg-2 color-white">
                                                    <p class="total-row"><span class="">Total Due: </span>{"$ " + this.state.quotationData.data.totalPrice}</p>
                                                </div>
                                                <div className="quotation-row-icons">
                                                    <a className="quotation-row-icon" title="Download Scope of work" onClick={this.generateQuotation}>Scope of work  <i className="zmdi zmdi-download"></i>
                                                    </a>
                                                    <a className="quotation-row-icon" title="Download Quotation" onClick={this.printQuotation}>Quotation  <i className="zmdi zmdi-download"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="quotation-buttons-wrap text-center mt-5">
                                        <button className="button bg-sc" onClick={this.props.ContactClientEvent}>Contact</button>
                                        <button className="button bg-pr" onClick={() => { if (this.state.quotationData.data.lstPackagesInfo.length > 0) { this.props.NextClickEvent() } }}>Checkout</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : <SignInSignUp setIsUserLogin={this.setIsUserLogin} />
                }
                <div className="container">
                    <div className="col-md-12 text-right">
                        <button className="button is-large is-success is-inverted" style={this.props.buttonStyles} onClick={this.props.BackClickEvent} > &lt; Back</button>
                    </div>
                </div>
            </>
        )

    }




}

