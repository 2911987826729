import React, { Component} from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,withRouter,
    Link
  } from "react-router-dom";
import Service from '../../components/Service/Api/Api'  
import cookie from "react-cookies";
import { UncontrolledTooltip, Tooltip  } from 'reactstrap';
 import { supportformtemplate } from '../EmailTemplates/SupportFormTemplate'; 
import Swal from "sweetalert2";  
export default class SupportForm extends Component {
    constructor(props) {
        super(props);
        
        this.state = 
        {
         isUserLogin: false,
         noOfAssets:"",
         serviceName:"",
         name: '' ,
         email: '',
         PhoneNumber: '' ,
         Description:"",
         width: window.innerWidth,
         height: window.innerHeight   
        };


      }
      updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
      componentDidMount() {
        var _isUserLogin = cookie.load("user_Id");
        if (_isUserLogin) {
            this.setState({ isUserLogin: true });
          }
        window.addEventListener('resize', this.updateDimensions);
      }
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
      //handleChange = this.handleChange.bind(this)
      handleChange = (event) => {
        this.setState({[event.target.name]:  event.target.value});
      }
      OnValidate = (e) => {
        let emailError = "";
        let passwordError = "";
    
        if (!this.state.email.includes("@") && !this.state.email.includes(".")) {
          emailError = "Plesae enter your valid email";
        }
        if (emailError || passwordError) {
          this.setState({ emailError, passwordError, loading: true });
    
          return false;
        } else {
          return true;
        }
      };
      handleSubmit = (event) => { 
          
        const { email, PhoneNumber } = this.state;
        event.preventDefault();
        const isValidated = this.OnValidate();
        if(email!=="" && PhoneNumber!=="")
        {
            if (isValidated) {
              var EmailData = new FormData();
                        EmailData.append('ToEmail', "zyousaf@vaporvm.com");
                        EmailData.append('Subject', 'Customer Inquiry');
                        var _bodyTemplate = supportformtemplate;
                        var Replaceversion = _bodyTemplate.replace("{ServiceOwner}","Waqas")
                                            .replace("{Name}",this.state.name).
                                            replace("{Phone}",this.state.PhoneNumber).
                                            replace("{Email}",this.state.email).
                                            replace("{Description}",this.state.Description).
                                            replace("{ServiceName}",this.props.ServiceName).
                                            replace("{Assets}",this.props.noofAssets==undefined?"Custom":this.props.noofAssets);
                        EmailData.append('Body',Replaceversion);
                        Service.SendEmail(EmailData);
            Swal.fire({  
                icon: 'success',  
                title: 'Email sent successfully',  
                text: 'Thank you for submitting your Cyber Security Solution request. Our Certified Engineer will be in touch with you in a jiffy!',
                showConfirmButton: false,  
                timer: 3000  
              });
              this.props.resetToStepOne();
            //   if(this.state.isUserLogin){ 
            //     alert('I am login');  
            //     this.props.history.push("/dashboard")}else
            //   {alert('I am not logged In'); 
            //       this.props.history.push('/steps')}
                }
            else{
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Please enter Valid Email",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
        }
        else{
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please enter Email / Phone Number first.",
                showConfirmButton: false,
                timer: 1500,
              });
        }
        
    }

    render() {
        return (
                <div className="row m-0 p-0" style={{justifyContent:"center",width:"100%",marginBottom:"2%"}}>
                    <div className="col-lg-6 col-xs-12 ">
                                {/* <div className="auth-content" style={{marginBottom:"1%"}}>
                                    <h2>Contact Support</h2>
                                </div> */}
                                <li style={{listStyle:"none",width:"100%",marginBottom:"1%",height:"50px",backgroundColor:"#010138",padding:"10px"}}>
                                    <a style={{fontFamily:'"Nexa Bold",sans-serif', color:'white'}} >Contact Support</a> 
                                    <i style={{marginLeft:'1%',color:"white"}} id="TooltipInfo" className="sl sl-icon-info"></i>
                                    <UncontrolledTooltip placement="right"  target="TooltipInfo">
                                    For more then 300 IT Assets kindly contact our support
                                    </UncontrolledTooltip>
                                </li>

                                <form>
                                    <div id="signup-form" className="login-form animated preFadeInLeft fadeInLeft" style={{marginTop:"3%"}}>
                                        <div className="field pb-10">
                                            <div className="floating-label">
                                            <input 
                                             className="floating-input" 
                                             name="name"
                                             placeholder=" " 
                                             type="text"
                                             required
                                             value={this.state.value}
                                             onChange={this.handleChange}
                                            /> 
                                            <label>Name</label>
                                        </div>  
                                    </div>
                                    <div className="field pb-10">
                                        <div className="floating-label">
                                            <input 
                                             className="floating-input" 
                                             name="email"
                                             required
                                             placeholder=" " 
                                             type="email"
                                             value={this.state.value}
                                             onChange={this.handleChange}
                                            />
                                            <label>Email<span style={{color:"red"}}>*</span></label>
                                        </div>
                                    </div>
                                        <div className="field pb-10">
                                            <div className="floating-label">
                                                <input 
                                                className="floating-input"
                                                name="PhoneNumber" 
                                                placeholder=" " 
                                                type="text"
                                                required 
                                                value={this.state.value}
                                                onChange={this.handleChange}
                                                ></input>
                                                <label>Phone Number<span style={{color:"red"}}>*</span></label>
                                            </div>
                                        </div> 
                                        <div className="field pb-10">
                                            <div className="floating-label">
                                                <textarea
                                                className="floating-input"
                                                type="tel"
                                                rows = "6" 
                                                autocomplete="tel" 
                                                placeholder=" " 
                                                name="Description"  
                                                value={this.state.value}
                                                onChange={this.handleChange} style={{width:"100%"}}/>
                                                <label>Details</label>
                                            </div>
                                           
                                        </div>
                                        <div className="row m-0 p-0" style={{justifyContent:"space-between"}}>
                                            <button className="button" style={{backgroundColor:"#010138",color:"white",width:"20%"}} onClick={this.props.BackClickEvent}>Back</button>
                                            <button className="button"style={{backgroundColor:"#010138",color:"white",width:"20%"}} onClick={this.handleSubmit}>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
        )
    }
}
