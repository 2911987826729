const initialState = {
    sendGroupMessage:null,
    messages:[]
}
const groupChatReducer = (state = initialState, action)=>{
    switch(action.type){
        case "SET_GROUP_CHAT" : return {...state, sendGroupMessage:action.payload}
        case "SET_MESSAGES" : return {...state, messages:[...state.messages, action.payload]}
        case "SET_MESSAGES_FROM_DB": return {...state, messages:[...state.messages, ...action.payload]}
        case "CLEAR_MESSAGES" : return {...state, messages:[]}
        default: return state
    }
}
export default groupChatReducer;