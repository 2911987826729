import React, { useState } from "react";
import './ContactForm.css';
import Service from "../../components/Service/Api/Api";
import Swal from 'sweetalert2';

const ContactForm = (props)=>{
    const [formObj,setFormObj] = useState({
        name:"",
        email:"",
        designation:"",
        businessContact:"",
        message:""
    });
    const formChangeHandler = e => {
        setFormObj(prevState=>{
            return {
                ...prevState,
                [e.target.name]:e.target.value
            }
        })
    }
    const formSubmitHandler = e =>{
        // preventing default behaviour
        e.preventDefault();

        // destructuring values from state object for cleaner code
        const { name, email, designation, businessContact, message } = formObj;
        // The 'mailTo' is an array which will hold the email addresses of the admins
        let mailTo = [];
        Service.getSupportAdmins().then(res=>{
            mailTo = res;
            const parsedMessage = createMessage(message);
            const messageToSend = `<p>Howdy,</p><p>A person is trying to contact you through ManageX Contact form</p> <p>Name: ${name}</p> <p>Email: ${email}</p> <p>Desgination: ${designation}</p> <p>Business Contact: ${businessContact}</p> <p>Message: ${parsedMessage}</p>`;
            // we need to submit only when all the values are inserted
            if(name && email && designation && businessContact && message){
                if(validateEmail(email)){
                    if(mailTo.length > 0){
                        mailTo.forEach(emailAddress=>{
                            const EmailData = new FormData();
                            EmailData.append('ToEmail', emailAddress.email);
                            EmailData.append('Subject', name + " is trying to contact you!");
                            EmailData.append('Body', messageToSend);
                            Service.SendEmail(EmailData);
                        });
                        Swal.fire({
                            title: "Success!",
                            text:'Our team will contact you shortly.',
                            icon: "success",
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: `Okay`,
                          });
                          props.BackClickEvent();
                    }
                }
                else{
                    Swal.fire({
                        title: "Error!",
                        text:'Email format is not correct',
                        icon: "error",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: `Okay`,
                      });
                }
            }
            else{
                Swal.fire({
                    title: "Error!",
                    text:'Some fields are missing',
                    icon: "error",
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `Okay`,
                  });
            }
        });
        


        // this is the function for email format validation
        function validateEmail(inputText){
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(inputText.match(mailformat)){
                return true;
            }
            else{
                return false;
            }
        }
        // this is the function that takes textarea input and puts new lines in a paragraph tag
        function createMessage(message){
            const messageArray = message.split("\n");
            let messageToReturn="";
            messageArray.forEach(text=>{
                messageToReturn += `<p>${text}</p>`;
            });
            return messageToReturn;

        }
    }
    return(
        <div style={{width:"100%"}}>
            <div className="form-wrap">
                <form>
                    <h4 className="form-title">Contact Form</h4>
                    <div className="form-row">
                        <div className="form-input-wrap">
                            <input type="text" placeholder="Name" name="name" onChange={formChangeHandler}/>
                        </div>
                        <div className="form-input-wrap">
                            <input type="text" placeholder="Designation" name="designation" onChange={formChangeHandler}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-input-wrap">
                            <input type="text" placeholder="Business Contact#" name="businessContact" onChange={formChangeHandler}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-input-wrap">
                            <input type="email" placeholder="Email" name="email" onChange={formChangeHandler}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-input-wrap">
                            <textarea rows={4} placeholder="Enter your message" name="message" onChange={formChangeHandler}></textarea>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-input-wrap justify-center">
                            <button onClick={formSubmitHandler} type="submit" className="button btn-LoginScreen rounded raised bigger mt-3">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="row text-right" style={{maxWidth:"870px",margin:"auto"}}>
                <div className="col-md-12">
                {/* <button className="button btnNextBack" onClick={this.props.BackClickEvent} > &lt; Previous</button> */}
                <button onClick={props.BackClickEvent} className="button is-large is-success is-inverted" style={props.buttonStyles}>&lt; Back</button>
                </div>
            </div> 
        </div>
    )
}

export default ContactForm;