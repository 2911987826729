import './TextLoader.css';
function TextLoader(props){

    const checkAck = () => {
        const interval = setInterval(() => {
            console.log("Checking Acknowledgement!!!")
        }, 1000)
        setTimeout(() => {
            clearInterval(interval);
        }, 60000)
    }
    // checkAck();
    return(
        <div className="is-flex is-justify-content-center">
            <div className="box m-3 is-flex is-flex-direction-column is-align-items-center p-5">
                <h3 style={{color:"#000066", minWidth:"350px"}} className="has-text-weight-normal has-text-centered animated-text-loader">{props.loaderTitle ? props.loaderTitle : "Loader Title"}<span>.</span><span>.</span><span>.</span></h3>
            </div>
        </div>
    )
}
export default TextLoader;