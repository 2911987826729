import * as ActionType from '../actions/ActionType';
import Service from '../../components/Service/Api/Api';  //'../Service/Api/Api'

export const loadstats = (payload) => {
    // async part
    //var userID = payload;
    return dispatch => {
        // api call here 
        Service.GetProjectsStatsByUserId(payload).then(res =>{
            dispatch(saveStatsResults(res));
        });
    }
}

export const saveStatsResults = ( res ) => {
    // sync part
   // console.log("THE ACTION CREATOR DATA",res)
    return {
        type : ActionType.LOADSTATS, // response to reducer 
        stats : res
    }
}

export const ClearStas = () => {
    return {
        type : ActionType.CLEARSTATS
    }
}