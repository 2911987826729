import React, { Component } from 'react';

export class CheckBox extends React.Component
{
    selectedAnswer = null;
    checked = false;
    constructor(props)
    {
        super(props);
        this.selectedAnswer = this.props.selectedAnswer;
        this.state = { checked:false};
    }


    render()
    {
        if (this.selectedAnswer == this.props.answerId)
        {
            this.checked = true;
        }

        return (
            <div><input name={this.props.controlName} type="radio" defaultChecked={this.checked} questionid={this.props.questionid} controltype={this.props.controlType} value={this.props.answerId} onChange={this.props.change} /> &nbsp;{this.props.title}</div>
            );
    }
}

export default CheckBox;