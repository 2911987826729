import React, { Component } from 'react';
import PackageCard from './PackageCard';
import { CheckCartItem } from '../Common/Cart';



class PackagePreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPackagesAddedToCart: false
        }
    }
    isStepsScreen = this.props.isStepsScreen;
    componentDidMount() {
        this.props.FuncPackageList();
    }
    checkPackageInCart = () => {
        // console.log("PROPS HERE---------------------------------->",this.props);
        //    checking if the selected packages exists in the cart. If so, then proceed otherwise, prevent user from moving to quotation screen
        let isAdded = false;
        this.props.SubServiceList.forEach(item => {
            // console.log(CheckCartItem("cart_sub_services",item.id))
            if (CheckCartItem("cart_sub_services", item.id)) {
                isAdded = true;
            }
        });
        if (isAdded) {
            this.setState({ isPackagesAddedToCart: true })
        }
        else {
            this.setState({ isPackagesAddedToCart: false })
        }
    }
    nextClickHandler = () => {
        if (this.state.isPackagesAddedToCart) {
            this.props.NextClickEvent();
            this.setState({ isPackagesAddedToCart: false })
        }
    }
    backClickHandler = () => {
        this.setState({ isPackagesAddedToCart: false })
        this.props.BackClickEvent();
    }


    render() {
        return (
            <div className='contaienr'>
                {/* <h3>Packages</h3> */}
                <div className={`${this.isStepsScreen ? "container" :"" }`}>
                    {this.props.PackagesList.map((item, i) => {
                        return (
                            <PackageCard
                                PackagesObj={item}
                                checkPackageInCart={this.checkPackageInCart}
                            />
                        )
                    })}
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="button is-large is-success is-inverted" style={this.props.buttonStyles} onClick={this.backClickHandler} > &lt; Back</button>
                            <button className="button is-large is-success is-inverted ml-2" style={this.props.buttonStyles} onClick={this.nextClickHandler} >Next &gt;</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PackagePreview;