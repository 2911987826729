import React from "react";
import Service from '../../components/Service/Api/Api'
import Swal from "sweetalert2";
import Wizard from './Wizard';
import ParentService from './ParentServices';
import StripePaymentContainer from "./StripePaymentContainer";
import ConnectEngineer from "./ConnectEngineer";
import logo from './quote_bg_manage_x.svg';
import cookie from "react-cookies";
import { paymentCompletedTemplate } from "../EmailTemplates/paymentCompletedTemplate";
import { projectCreationConfirmationTemplate } from "../EmailTemplates/projectCreationConfirmationTemplate";
import CustomLoader from "../Loader/Loader";
import { Animated } from "react-animated-css";
import PackagePreview from "./PackagePreview";
import QuotationForm from "./QuotationForm";
import ContactForm from "./ContactForm";
import BreadCrumb from "../BreadCrumb";
import bgImage from "../../img/isometric-cubes.webp"



export default class QuestionnaireContainer extends React.Component {

    constructor(props) {
        super(props);

        // these styles will be passed to child components
        this.buttonStyles = {
            backgroundColor: "var(--bg-color-pr)",
            fontSize: "16px",
            color: "var(--white)",
            boxShadow: '0px 15px 10px -10px #0003',
            padding: "0.4em 1em",
            height: "auto",
            margin: "1em 1em",
            minWidth: "115px",
            border:"none",
            borderRadius:"5px"
        }
        this.isStepsScreen = this.props.location.pathname === "/steps";
        var _currentSteps = 0;
        var _PaymentId = '';
        var queryString = this.props.location.pathname.split('/');
        if (queryString.length === 4) {
            var payresponse = queryString[queryString.length - 2];
            if (payresponse === "success") {
                _currentSteps = 5;
                // logic for payment sucess 
                _PaymentId = queryString[queryString.length - 1];

            }
        }
        else {
            var ServiceName = queryString[queryString.length - 1];
            // console.log("Service Name ", ServiceName);
            var _serviceName = "";
            var _serviceSubServiceName = "";
            if (ServiceName != null && ServiceName != undefined && ServiceName != "") {
                var _splitedResult = ServiceName.split('-');
                if (_splitedResult.length > 0) {
                    _serviceName = _splitedResult[0];
                    _serviceSubServiceName = _splitedResult[1];
                }
            }
        }

        this.state = {
            ServiceName: _serviceName != "" ? _serviceName : '',
            // SubServiceName : _serviceSubServiceName != "" ? _serviceSubServiceName : '',
            currentStep: _currentSteps > 0 ? _currentSteps : 1,
            isContactForm: false,
            // ProjectType : '',
            // QuotationNumber : '',
            TotalAmount: '',
            // QuotationServiceName : '',
            PaymentId: _PaymentId,
            Loader: false,
            // SubServiceNameValue:"",
            // For new logic
            ParentServiceList: [],
            SelectedParentService: "",
            SubServiceList: [],
            PackagesList: [],
            SubServiceIDs: [],
            SelectedPackageID: -1,
            // SelectedPackageAssets : 0,
            // merge issue
        }
    }
    setParentService = (e) => {
        this.setState({ SelectedParentService: e.target.value });
    }
    componentDidMount() {
        try {
            // Getting the list of support persons/Admins from API 
            Service.getSupportAdmins().then(res => {
                this.setState({
                    admins: res
                });
            });
            if (this.state.currentStep === 5 && this.state.PaymentId !== "") {
                this.setState({ Loader: true });
                //cookie.remove("TempUserInfo");
                try {
                    cookie.remove("TempUserInfo", { path: '/' });
                    cookie.remove("QuoteReference", { path: '/' });
                    cookie.remove('QuoteItems', { path: '/' });
                }
                catch (ex) {
                    console.log(ex);
                }

                localStorage.removeItem("cart_items");
                localStorage.removeItem("cart_sub_services");
                this.PaymentSuccess(this.state.PaymentId);
            }
            else {
                this.FetchParentService();
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };



    onSubmit = e => {
        try {
            var payload = {
                TwoFactorCode: this.state.TwoFactorCode,
                email: 'adasd' //cookie.load("User_Email")
            }
            Service.VerifyOTP(payload).then(response => {
                if (response.status === "Success") {
                    this.props.history.push("/dashboard");
                }
            }).catch(err => {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
        catch (e) {
            console.log(e);
        }
    };

    handleServiceNameChange = (e) => {
        try {
            this.fetchSubServices(e.target.value);
            this.setState({ ServiceName: e.target.value });
        }
        catch (e) {
            console.log(e);
        }
    }

    handleSubServiceNameChange = (e) => {
        const CyberSecuritySolutions = [
            { "key": "100", "value": "IAM/PAM" },
            { "key": "101", "value": "SIEM" },
            { "key": "102", "value": "Data & Classification & Encryption" },
            { "key": "103", "value": "Privacy" },
            { "key": "104", "value": "EDR/MDR & XDR" },
            { "key": "105", "value": "Data Loss Prevention" },
            { "key": "106", "value": "End Point Security" }
        ]
        var filterKey = e.target.value;
        const value = CyberSecuritySolutions.filter(Service => Service.key === filterKey)
        if (value.length !== 0) {
            this.setState({ SubServiceNameValue: value[0].value })
            // console.log(this.state.SubServiceNameValue);
        }
        try {
            this.setState({ SubServiceName: e.target.value });
        }
        catch (e) {
            console.log(e);
        }
    }

    NextClickEvent = (e) => {
        try {
            let currentStep = this.state.currentStep;
            if (currentStep >= 4) {
                currentStep = 5;
            } else {
                currentStep = currentStep + 1;
            }
            const cartItemInLocalStorage = localStorage.getItem("cart_items");
            // console.log("Items in cart ", cartItemInLocalStorage);
            if (this.state.ServiceName !== "steps" && this.state.SubServiceIDs.length > 0) {
                this.setState({
                    currentStep: currentStep
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    BackClickEvent = (e) => {
        try {
            let currentStep = this.state.currentStep;
            if (currentStep <= 1) {
                currentStep = 1;
            } else {
                currentStep = currentStep - 1;
            }
            this.setState({
                currentStep: currentStep,
                PackagesList: [], //changed by zargham. A bug that was causing disturbance in the packages is fixed here
            });
        }
        catch (e) {
            console.log(e);
        }
    }

    SetQuotationNumberInfo = (QuotID) => {
        try {
            this.setState({ QuotationNumber: QuotID });
        }
        catch (e) {
            console.log(e);
        }
    }

    SetTotalAmount = (amount) => {
        try {
            this.setState({ TotalAmount: amount });
        }
        catch (e) {
            console.log(e);
        }
    }

    SetQuotationServiceName = (servicename) => {
        try {
            this.setState({ QuotationServiceName: servicename });
        }
        catch (e) {
            console.log(e);
        }
    }

    Reset = () => {
        this.setState({ currentStep: 1 });
    }

    FetchParentService = () => {
        try {
            Service.FetchParentServices().then(res => {
                if (res.statusCode === 200) {
                    var _tempArr = [];
                    res.data.map((item, index) => {
                        var ParentServiceObj = {
                            id: item.id,
                            name: item.name
                        };
                        _tempArr.push(ParentServiceObj);
                    });
                    this.setState({ ParentServiceList: _tempArr });
                }
                else {
                    // not found anything show error msg here 
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Error occured will processing...',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
        catch (e) {
            console.log(e);
        }
    }

    fetchSubServices = (serviceId) => {
        try {
            Service.FetchSubServices(serviceId).then(res => {
                if (res.statusCode === 200) {
                    var _tempArr = [];
                    res.data.map((item, index) => {
                        var SubServiceObj = {
                            id: item.id,
                            name: item.subServiceName
                        };
                        _tempArr.push(SubServiceObj);
                    });
                    this.setState({ SubServiceList: _tempArr });
                }
                else {
                    // not found anything show error msg here 
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error while fetching sub services',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
        catch (e) {
            console.log(e);
        }
    }

    FetchPackages = () => {
        try {
            Service.FetchPackages(this.state.SubServiceIDs).then(res => {
                if (res.statusCode === 200) {
                    this.setState({ PackagesList: res.data }); //_tempArr});
                }
                else {
                    // not found anything show error msg here 
                }
                this.setState({ Loader: false });
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error while fetching packages',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
            this.setState({ Loader: false });
        }
        catch (e) {
            console.log(e);
            this.setState({ Loader: false });
        }
    }

    HandleSelectSubServiceID = (e) => {
        try {
            const targetElement = e.target;
            let targetValue = targetElement.value;
            if (targetElement.checked) {
                this.setState(prevState => ({
                    SubServiceIDs: [...prevState.SubServiceIDs, targetValue]
                }));
            }
            else {
                this.setState({
                    SubServiceIDs: this.state.SubServiceIDs.filter(e => e !== targetValue)
                });
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    SelectionChangePackage = (e) => {
        try {
            this.setState({
                SelectedPackageID: e.target.getAttribute("btnpackageid"),
                SelectedPackageAssets: e.target.getAttribute("assets")
            });
        }
        catch (ex) {
            console.log(ex);
        }
    }

    ResetPackageSelection = (e) => {
        try {
            this.setState({
                SelectedPackageID: -1,
                SelectedPackageAssets: 0
            });
        }
        catch (ex) {
            console.log(ex);
        }
    }



    RenderStepScreen() {
        try {
            if (this.state.currentStep === 1) {
                return (

                    <Animated style={{ width: "100%" }} animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                        <ParentService
                            buttonStyles={this.buttonStyles}
                            SubServiceIDs={this.state.SubServiceIDs}
                            ParentServicesList={this.state.ParentServiceList}
                            SelectedParentService={this.state.SelectedParentService}
                            setParentService={this.setParentService}
                            SubServicesList={this.state.SubServiceList}
                            SelectSubServiceIdEvent={this.HandleSelectSubServiceID}
                            SelectedServiceName={this.state.ServiceName}
                            SelectedServiceEvent={this.handleServiceNameChange}
                            SelectedSubServiceEvent={this.handleSubServiceNameChange}
                            SelectedSubServiceName={this.state.SubServiceName}
                            NextClickEvent={this.NextClickEvent}
                            BackClickEvent={this.BackClickEvent}
                            isStepsScreen={this.isStepsScreen}
                        />
                    </Animated>
                );
            }
            else if (this.state.currentStep === 2) {
                return (
                    <>
                        <Animated style={{ width: "100%" }} animationIn="bounceInLeft" animationOut="fadeOut" isVisible={this.state.currentStep === 2 ? true : false}>
                            <PackagePreview
                                SubServiceList={this.state.SubServiceList}
                                buttonStyles={this.buttonStyles}
                                PackagesList={this.state.PackagesList}
                                BackClickEvent={this.BackClickEvent}
                                NextClickEvent={this.NextClickEvent}
                                SelectedPackageID={this.state.SelectedPackageID}
                                PackageChangeEvent={this.SelectionChangePackage}
                                ResetPackageSelection={this.ResetPackageSelection}
                                FuncPackageList={this.FetchPackages} // Function Fetch packages according to selection i.e. Checkbox selection 
                                isStepsScreen={this.isStepsScreen}
                            />
                        </Animated>

                    </>
                );
            }
            else if (this.state.currentStep === 3 && !this.state.isContactForm) {
                return (
                    <>
                        <QuotationForm
                            ParentServiceList={this.state.ParentServiceList}
                            buttonStyles={this.buttonStyles}
                            BackClickEvent={this.BackClickEvent}
                            NextClickEvent={this.NextClickEvent}
                            // ContactClientEvent={() => this.setState({ currentStep: 5 })}
                            ContactClientEvent={() => this.setState({ isContactForm: true })}
                            SetServiceName={this.SetQuotationServiceName}
                            SetTotalAmount={this.SetTotalAmount}
                            SetQuotationNumberInfo={this.SetQuotationNumberInfo}
                        />
                    </>
                );
            }
            else if (this.state.currentStep === 4) {
                return (
                    <>
                        <StripePaymentContainer
                            QuotationNumber={this.state.QuotationNumber}
                            NextClickEvent={this.NextClickEvent}
                            BackClickEvent={this.BackClickEvent}
                            buttonStyles={this.buttonStyles}
                            TotalAmount={this.state.TotalAmount}
                            ServiceName={this.state.QuotationServiceName}
                        />
                    </>
                );
            }
            else if (this.state.currentStep === 3 && this.state.isContactForm) {
                return (
                    <>
                        <ContactForm BackClickEvent={() => this.setState({ currentStep: 3, isContactForm: false })} buttonStyles={this.buttonStyles} />
                    </>
                )
            }
            else {
                return (
                    <>
                        <ConnectEngineer />
                    </>
                );
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    PaymentSuccess = (PaymentId) => {
        //alert("CAlling payment success method");
        var qoutationId = "";
        // var ProjectTemplateId = "O3M";
        // var date = new Date();
        var payload = {
            paymentid: PaymentId,
            userid: cookie.load('user_Id')
        };
        Service.SuccessfullPaymentGuest(payload).then(res => {
            //alert("Calling guest");
            if (res) {
                qoutationId = res.quotationid;
                var _amount = "USD " + res.amount;
                var _paymentDate = res.date;
                var QuotationPayload = {
                    quotationId: qoutationId,
                    userid: parseInt(cookie.load('Id'))
                }
                // Need to change here 
                Service.UpdateQuotationStatus(QuotationPayload).then(res => { //res).then(res => {
                    //console.log(res);
                    var _ConcateServiceName = '';
                    if (res.statusCode === 200) {

                        var _tempArr = [];
                        res.data.map((item, index) => {
                            _ConcateServiceName += item.serviceName + " ,";
                            var PackageObj = {
                                servicename: item.serviceName,
                                projecttemplate: item.projectTemplate
                            };
                            _tempArr.push(PackageObj);
                        });


                        // alert("Calling quotation guest");
                        //var serviceName=res.ServiceName;
                        //console.log(res);

                        var Payload = {
                            "quotationId": qoutationId,
                            "userId": cookie.load('user_Id'),
                            "lstdetails": _tempArr
                        }

                        Service.CreateJiraPackages(Payload).then(res => {
                            //alert("Creating jira");
                            if (res != "Already Created") {
                                var EamilData = new FormData();
                                const userId = cookie.load("user_Id");
                                const userName = cookie.load('user_Name');
                                EamilData.append('ToEmail', userId);
                                EamilData.append('Subject', 'Thank you for the Payment!');
                                var _bodyTemplate = paymentCompletedTemplate;
                                var _details = 'Your projects : ' + _ConcateServiceName + ' has been created kindly <a href="http://' + window.location.host + '/#/dashboard">click here</a> to access your project details';
                                var Replaceversion = _bodyTemplate.replace("{User}", userName)
                                    .replace("{ServiceName}", _ConcateServiceName)
                                    .replace("{Details}", _details)
                                    .replace("{Currency}{amount}", _amount)
                                    .replace("{DateTime}", _paymentDate)
                                    .replace("{URL}", 'http://' + window.location.host + '/#/dashboard');

                                EamilData.append('Body', Replaceversion);
                                Service.SendEmail(EamilData);
                                this.setState({ Loader: false });
                                // creating a hardcoded list of receivers on admin panel. Will change this later to a better logic
                                const receivers = this.state.admins;
                                // const receivers = [ { email: "zaijaz@vaporvm.com", userName: "Zargham" } ];
                                receivers.forEach(receiver => {
                                    const resourceAllocationLink = 'https://admin.managex.ae/#/Resource';
                                    const EamilData = new FormData();
                                    EamilData.append('ToEmail', receiver.email);
                                    EamilData.append('Subject', `New Project Created – ${_ConcateServiceName}`);
                                    var _bodyTemplate = projectCreationConfirmationTemplate;
                                    var Replaceversion = _bodyTemplate
                                        .replace("{project ID}", _ConcateServiceName)
                                        .replace("{customer email}", userId)
                                        .replace("{date and time stamp}", _paymentDate)
                                        .replace("{resource allocation link}", resourceAllocationLink);
                                    EamilData.append('Body', Replaceversion);
                                    Service.SendEmail(EamilData);
                                });
                                this.props.history.push('/dashboard');
                            }
                            else {
                                this.setState({ Loader: false });
                                this.props.history.push('/dashboard')
                            }
                        })
                    }
                });
            }
        });
    }




    render() {
        return (
            <>
                {/* <div className="column is-12 pl-5 pr-5">
                    <div className="card-counter">
                        <div className="flex-card light-bordered hover-inset">
                            <div className="title section-title has-text-centered dark-text text-bold">Services</div>
                        </div>
                    </div>
                </div> */}
                {/* The contact form below is inserted for testing purpose */}
                {this.state.Loader ? <CustomLoader /> : null}
                <div className={`container-fluid ${this.isStepsScreen ? "d-flex flex-column justify-content-center" :"" }`} style={{backgroundImage:`url(${this.isStepsScreen ? bgImage : ""})`, backgroundAttachment:"fixed", backgroundSize:"contain", backgroundPosition:"right", minHeight:"100vh", backgroundRepeat:"no-repeat", }}>
                    {
                        this.isStepsScreen ?
                        null
                        : <BreadCrumb/>
                    }
                    {/* <div className="row">
                        <img style={{ height: '200px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={logo} alt="React Logo" />
                    </div> */}
                    <div className="container">
                        <div className="row p-0 m-0" style={{ marginTop: '2%' }}>
                            {/* <div className="col-md-2">

                            </div> */}
                            <div className={`col-12 mx-auto my-3 py-5`}>
                                <div className="text-center mb-2">
                                    <img className="mb-3" style={{width:"250px"}} src="https://mxprodstorage.blob.core.windows.net/managex/assets/EmailTemplate/Logo.png" alt="managex logo"/>
                                    <p style={{fontSize:"20px"}}>Select the services that you want to buy</p>
                                </div>
                                <div className="row justify-content-center align-items-center">
                                    <Wizard currentSteps={this.state.currentStep} />
                                </div>
                                <div className={`${this.isStepsScreen ? "container" : "" } row justify-content-center align-items-center ml-auto mr-auto`} style={{ marginTop: '2%' }}>
                                    {this.RenderStepScreen()}
                                </div>
                            </div>
                            {/* <div className="col-md-2">

                            </div> */}
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

