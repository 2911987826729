import React from "react";
import Service from '../../../components/Service/Api/Api'
import queryString from 'query-string'
import Swal from "sweetalert2";
import { resetPasswordTemplate } from "../../EmailTemplates/resetPasswordTemplate";
import InputComponent from "../../inputComponents/Input";
import Logo from '../../../img/logo.png';
import Loader from "../../Loader/Loader";


export default class ForgetPassword extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            email: '',
            isLoader:false
        }
    }

    componentDidMount() {
        const queryParam = queryString.parse(this.props.location.search);
        this.setState({ userid: queryParam.userId });
        this.setState({ token: queryParam.Token });
    }

    ShowMessage = (MsgPayload) => {
        try {
            Swal.fire({
                icon: MsgPayload.icon,
                title: MsgPayload.title,
                text: MsgPayload.text,
                showConfirmButton: false,
                timer: 1500
            });
        }
        catch (e) {
            console.log(e + " : in ShowMessage function")
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onSubmit = e => {
        e.preventDefault();
        try {
            if (this.state.email === "") {
                var payload = {
                    icon: 'error',
                    title: 'Error',
                    text: 'Please enter your email first.'
                };
                this.ShowMessage(payload);
            }
            else {
                var payload = {
                    emailaddress: this.state.email
                }
                this.setState({isLoader:true});

                Service.ForgetPassword(payload).then(response => {
                    if (response.status === "Success") {
                        this.setState({isLoader:false});
                        var EamilData = new FormData();
                        EamilData.append('ToEmail', this.state.email);
                        EamilData.append('Subject', 'Forgot your password? Time to get you a fresh one!');
                        var _bodyTemplate = resetPasswordTemplate;
                        var Replaceversion = _bodyTemplate.replace("{CustomerName}", "").
                            replace("{URL}", response.url);
                        //var _emailBody = '<p>A request has been received to change the password for your ManageX account. Click ' + '<a href="'+ response.url +'">link</a>' + ' to reset your account password.</p>';
                        EamilData.append('Body', Replaceversion); //_emailBody);
                        Service.SendEmail(EamilData);
                        Swal.fire({
                            title: 'Reset Password Request',
                            text: 'Please check your email for reset your password.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok',
                            cancelButtonText: 'No, keep it'
                        }).then((result) => {
                            if (result.value) {
                                this.props.history.push("/login");
                            }
                        });
                    }
                }).catch(err => {
                    this.setState({isLoader:false});
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1500
                    });
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    render() {
        return (
            <>
            {
                this.state.isLoader ? 
                <Loader/>
                : null
            }
                <div class="authentication">
                    <div class="container">
                        <div class="col-md-12 content-center">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="company_detail">
                                        <h4 class="logo"><img src={Logo} alt="" /></h4>
                                        <h3>Forget password?</h3>
                                        <div class="footer">
                                        <ul class="social_link list-unstyled">
                                            <li><a href="https://twitter.com/managexae" title="LinkedIn"><i class="zmdi zmdi-linkedin"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/managexae/" title="Facebook"><i class="zmdi zmdi-facebook"></i></a></li>
                                            <li><a href="https://www.facebook.com/managex.ae" title="Twitter"><i class="zmdi zmdi-twitter"></i></a></li>
                                        </ul>
                                        <hr />
                                        <ul>
                                            <li><a href="https://www.managex.ae/contact-us/" target="_blank">Contact Us</a></li>
                                            <li><a href="https://www.managex.ae/about-us/" target="_blank">About Us</a></li>
                                            <li><a href="https://www.managex.ae/privacy-policy/" target="_blank">Privacy Policy</a></li>
                                            <li><a href="https://www.managex.ae/terms-of-services/">Terms Of Services</a></li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-12 offset-lg-1">
                                    <div class="card-plain text-center">
                                        <div class="header">
                                            <h5>Forget Password?</h5>
                                            <p className="my-3">Enter your e-mail address below to reset your password.</p>
                                        </div>
                                        <form class="form" onSubmit={this.onSubmit}>
                                            <div class="input-group">
                                                {/* <input type="text" class="form-control" placeholder="Enter Email" /> */}
                                                <InputComponent
                                                    inputType="inputField"
                                                    type="email"
                                                    name="email"
                                                    label="Enter your account email"
                                                    error={this.state.emailError}
                                                    floatingLabel
                                                    variant="primary"
                                                    onChange={this.onChange}
                                                    classes="form-control"
                                                    // commented pattern because it was not allowing "-" in the middle of domain name
                                                    // pattern="^[a-zA-Z]+.*@[A-Za-z]+([.][a-zA-Z]+)+$"
                                                    // new pattern
                                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                                />
                                                <span class="input-group-addon"><i class="zmdi zmdi-email"></i></span>
                                            </div>
                                            <div className="input-group">
                                                <InputComponent
                                                    inputType="button"
                                                    fullWidth
                                                    type="submit"
                                                    name="RESET"
                                                    variant="secondary"
                                                    classes="mb-1"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}