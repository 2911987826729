import { event } from 'jquery';
import React, { Component } from 'react'

export default class AdminContainer extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            serviceName:"Cyber Security",
            subServiceName:"Cyber Security",
            packageName:"",
            unitPrice:"",
            addSubType:false,
            newSubtypeValue:""
        }
    }
    handleChange = (event) =>
    {
        this.setState({[event.target.name]:  event.target.value});
    }
    addSubService = (event)=>
    {
        event.preventDefault();
        this.setState({addSubType:!this.state.addSubType})
    }

    handlePreview = (event)=>
    {
        event.preventDefault()
    }
    handleSubmit = (event)=>
    {
        event.preventDefault()
    }



    render() {
        return (
            <>
                <div className='row m-0' style={{marginLeft:"2%"}}>
                    <div className='col-lg-7'>
                        <h4>Package Design</h4>
                        <form>
                            <div className="form-group row">
                                <label for="inputPassword3" className="col-sm-2 col-form-label">Service Name</label>
                                    <div className="col-sm-7">
                                        <select className="form-control" name ="serviceName" value = {this.state.serviceName}  id="exampleFormControlSelect1" onChange={this.handleChange}>
                                            <option value = "Cyber Security"> Cyber Security  </option>
                                            <option value = "MicrosoftOffice365"> MicrosoftOffice365   </option>
                                            <option value = "Data Science"> Data Science </option>
                                        </select>
                                    </div>
                                    
                            </div>
                            <div className="form-group row">
                                <label for="inputPassword3" className="col-sm-2 col-form-label">Service Sub-Type</label>
                                    <div className="col-sm-7">
                                        <select className="form-control" name ="subServiceName" value = {this.state.subServiceName}  id="selectBox" onChange={this.handleChange}>
                                            <option value = "Cyber Security"> Cyber Security  </option>
                                            <option value = "MicrosoftOffice365"> MicrosoftOffice365   </option>
                                            <option value = "Data Science"> Data Science </option>
                                        </select>
                                    </div>
                                    {this.state.addSubType==false?<button type="submit" className="btn btn-success" onClick={this.addSubService} style={{marginRight:"10px"}}>Add Sub-Type</button>:""}
                                    {this.state.addSubType==true?<button type="submit" className="btn btn-success" onClick={this.addSubService}>Remove Sub-Type</button>:""}
                            </div>
                            
                            {
                                this.state.addSubType==false?<div></div>:
                                <div className="form-group row">
                                    <label for="inputPassword3" className="col-sm-2 col-form-label">Add Sub-Type Name</label>
                                    <div className="col-sm-7">
                                    <input name ="newSubtypeValue" value={this.state.newSubtypeValue} type="text" className="form-control" id="inputPassword3" placeholder="Add Sub-Type Name" onChange={this.handleChange}/>
                                    </div>
                                </div>

                            }

                            <div className="form-group row">
                                <label for="inputPassword3" className="col-sm-2 col-form-label">Package Name</label>
                                <div className="col-sm-7">
                                <input name ="packageName" value={this.state.packageName} type="text" className="form-control" id="inputPassword3" placeholder="Package Name" onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="inputPassword3" className="col-sm-2 col-form-label">Unit Price</label>
                                <div className="col-sm-7">
                                <input name = "unitPrice" value={this.state.unitPrice} type="text" className="form-control" id="inputPassword3" placeholder="Unit Price" onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-9" style={{textAlign:"right"}}>
                                    <button type="submit" className="btn btn-success" onClick={this.handlePreview} style={{marginRight:"20px"}}>Preview</button>
                                    <button type="submit" className="btn btn-success" onClick={this.handleSubmit}>Publish</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
