import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { jsPDF } from "jspdf";
import {GA_Pages , Office_Pages , VA_Pages} from "../SOW/GAPAssementSOW/GA_Pages";
import domtoimage from 'dom-to-image';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from "@ramonak/react-progress-bar";
class DownlaodSOW extends React.Component {
    customerOrderId = -1;
    constructor(props)
    {
        super(props);
        var queryString =   this.props.location.pathname.split('/');
        this.customerOrderId = queryString[queryString.length - 1];
        this.state = { 
          customerOrder: null ,
          userName:'' , 
          SelectedAnswers:[],
          Progress:0,
          
      };
        this.downloadPayment = this.downloadPayment.bind(this);
        this.closewindow = this.closewindow.bind(this)
    }

    componentDidMount()
    {

        if (this.customerOrderId != null)
        {
         
        this.getCustomerOrder(this.customerOrderId)
        }
        else { console.error("this.CustomerOrderId is not greater then 0"); }
        
    }
    getCustomerOrder(customerOrderId)
    {
      Service.GetSelectedQuestionsbyId(customerOrderId).then( res =>{
         console.log(res)
         this.setState({SelectedAnswers : res})
            console.info('step1: getting customer order');
           Service.getQuotation(customerOrderId).then(res => {
            if (res) {
                this.setState({
                    customerOrder: res
                }); 
                var _usernameData = cookie.load("TempUserInfo");
                if(_usernameData == null || _usernameData == undefined || _usernameData == ""){
                    var email = res.Quotation.UserId;
                    Service.GetUserName(email).then(re=>{
                      this.setState({
                        userName:re
                      })
                      this.downloadPayment();
                    }); 
                }
                else{
                  this.setState({
                    userName:"Guest User"
                  });
                  this.downloadPayment();
                }
                           
            }
        });
           
      })
      
    }    
    async timeout(){

      return new Promise(resolve => setTimeout(resolve, 500));
    }
    async asfunction(){  
      var serviceName = this.state.customerOrder.ServiceName;
      var Pages  =[]
      if(this.state.customerOrder.Quotation.ServiceId==1){
        Pages =  Office_Pages
      }
      else if(this.state.customerOrder.Quotation.ServiceId==2){
        Pages =  VA_Pages
      }
      else if(this.state.customerOrder.Quotation.ServiceId==5){
        Pages =  GA_Pages
      }    
      
      var QATable = "<tr><th>Question</th><th>Selected Answer</th></tr>";
      if(this.state.SelectedAnswers.length>1){
        for(var i=0 ;i<this.state.SelectedAnswers.length;i++){
          QATable+="<tr><td>"+this.state.SelectedAnswers[i].question+"</td><td>"+this.state.SelectedAnswers[i].answer+"</td></tr>";
        }       
      }
      QATable+="<tr><td>Number of users</td><td>"+this.state.customerOrder.Quotation.NoOfUsers+"</td></tr>";
      const pdf = new jsPDF();  
      var index =0;
       for(var i=0; i<Pages.length;i++){
         var prog = ((((i+1)/Pages.length)).toFixed(1))
         this.setState({Progress:prog})
         console.log(this.state.Progress)
         document.getElementById("downloadpage").innerHTML=Pages[i].replace("##UserName##", this.state.userName).replace("##table##", QATable);
         await this.timeout()
         this.shot_loading = true;
       await domtoimage.toPng(document.getElementById('downloadpage'), { quality: 0.95 })
         .then(function (dataUrl) {                      
           var link = document.createElement('a'+i);
           link.download = 'my-image-name.jpeg';  
                 
           const imgProps= pdf.getImageProperties(dataUrl);          
           if(index!=0)
           pdf.addPage()
           pdf.addImage(dataUrl, 'PNG', 10, 10);
           index++;
           if(index==Pages.length){              
            pdf.save(serviceName+"_SOW.pdf");
           }
           //window.close();
         });

       }
    }
    closewindow(){
      window.close();
    }
    downloadPayment()
    { 
      this.asfunction() 
    }
    render() {
        return(
            <>  <div style={{height:"1600px", width:"100%" , backgroundColor:"white"}}>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              
              <div className="row mt-5" >
                <div className="col-3"></div>
                <div className="col-6">                                
                <ProgressBar bgColor="#8BC240" labelAlignment="center" completed={((this.state.Progress)*100)} />
              <br></br>
              <br></br>
              <span className="ml-5 mt-5" style={{paddingLeft:"15%"}}>Please wait while SOW is in progress...</span><br/>
              <br></br>
              <div className="row">
                <div className="col-6 offset-5">
                  <button onClick={this.closewindow}  className="btn btn-danger" title="Close!">Close</button>
                </div>           
              </div>
              {/* {
                (this.state.Progress)==1?
                  <button onClick={this.closewindow}  className="btn btn-danger ml-5 mt-5" title="Close!">Close</button>
                  :""
              } */}
              
                </div>
                <div className="col-2"></div>

              </div>              
              </div>          
               <div style={{'height':'3508px','width':'2480px'}} id='downloadpage'>
                  
               </div>
            </>
        )
    }
}
export default DownlaodSOW;