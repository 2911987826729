import ReCAPTCHA from "react-google-recaptcha";
function ContactForm(props){
    const { data } = props;
    return(
        <>
        {
            props.componentTitle ?
            <h4 className="m-3 mt-5 text-bold text-center">
                {props.componentTitle}
            </h4>
            :""
        }
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div style={{minWidth:"350px", maxWidth:"400px"}} className="card m-3 d-flex flex-column align-items-stretch p-4">
                <h5 style={{color:"#000066"}} className="text-center mb-3">{props.formTitle ? props.formTitle : ""}</h5>
                <input value={ data ? data.name : ""} type="text" required name="name" className="form-control mb-3" placeholder="Name*" onChange={props.setFormValues}></input>
                <input value={ data ? data.email : ""} type="text" required name="email" className="form-control mb-3" placeholder="Email*" onChange={props.setFormValues}></input>
                <input value={ data ? data.phone : ""} type="text" required name="phone" className="form-control mb-3" placeholder="Phone*" onChange={props.setFormValues}></input>
                <input value={ data ? data.companyName : ""} type="text" required name="companyName" className="form-control mb-3" placeholder="Company Name*" onChange={props.setFormValues}></input>
                {
                    props.hasCaptcha ?
                    <ReCAPTCHA
                        sitekey="6LdcXAkfAAAAAGIBxWygUVC8DozTC5ECF-vRjXjR"
                        onChange={props.handleVerify}
                        onExpired={props.expireCaptcha}
                        onErrored={props.expireCaptcha}
                    />:""
                }
                {
                    props.hasMessage ?
                    <textarea style={{height:"75px", resize:"none"}} value={ data ? data.message : ""} required name="message" className="input mb-3 form-control" placeholder="Enter your message here" onChange={props.setFormValues}></textarea>
                    : ""
                }
                <button className="custom-button mt-3" onClick={props.onSubmit}>{props.submitButtonText ? props.submitButtonText:"Send"}</button>
            </div>
        </div>
        </>
    )
}
export default ContactForm;