import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { jsPDF } from "jspdf";
class Doenlaods extends React.Component {
   customerOrderId = -1;

   constructor(props) {
      super(props);
      var queryString = this.props.location.pathname.split('/');
      this.customerOrderId = queryString[queryString.length - 1];

      this.state = {
         customerOrder: null,
         userName: '',
         userEmail: '',
         OrderDate: '',
         QuotationRef: '',
         TotalPrice: 0
      };
      this.doPayment = this.doPayment.bind(this);
      this.downloadPayment = this.downloadPayment.bind(this);
      this.getQuotation = this.getQuotation.bind(this);
   }

   componentDidMount() {
      if (this.customerOrderId != null) {
         console.info('step1: getting customer order');
         this.getCustomerOrder(this.customerOrderId);
      }
      else { console.error("this.CustomerOrderId is not greater then 0"); }

   }

   /**
    * Get the customer order info and update state variable customerOrder
    * @param {any} customerOrderId
    */
   getCustomerOrder(customerOrderId) {
      //alert(customerOrderId);
      var PayLoad = {
         refnumber: customerOrderId
      };
      Service.FetchPackageQuotationInfo(PayLoad).then(res => {
         if (res) {

            this.setState({
               userName: cookie.load("user_Name"),
               userEmail: cookie.load("user_Id"),
               customerOrder: res.data
            })



         }

      });



   }


   /** Get Quotation of request */
   getQuotation() {

      console.log("Customer order id is =>" + this.customerOrderId);
      //call the api method to generate pdf
      //var myWindow = window.open(Constants.PDFControllerUrl+"/" + this.customerOrderId, "myWindow", "width=800,height=800");
      //myWindow.document.write("<p>downloading file..'</p>");
      //myWindow.close();
   }



   /** Function which redirect to telr payment page */
   doPayment() {

      let inputModel =
      {
         "userId": cookie.load('user_Id'),
         "ivp_method": "create",
         "ivp_store": "",
         "ivp_authkey": "",
         "ivp_amount": parseFloat(this.state.customerOrder.Quotation.TotalPrice),
         "ivp_currency": "",
         "ivp_test": 1,
         "ivp_cart": this.state.customerOrder.Quotation.Id,
         "ivp_desc": 'No Description',
         "return_auth": window.location.origin + "/#/paymentSuccess",
         "return_decl": window.location.origin + "/#/PaymentReject",
         "return_can": window.location.origin + "/#/PaymentReject"
      };
      console.log(inputModel);
      Service.CreateTransaction(inputModel).then(res => {
         if (res) {
            console.log(res);
            window.location = res;
         }
      });
   }
   downloadPayment() {
      //alert('here')       
      htmlToImage.toPng(document.getElementById('downloadpage'), { quality: 0.95 })
         .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save("Quotation.pdf");
            //window.close(); 
         });
   }
   print() {
      htmlToImage.toPng(document.getElementById('downloadpage'), { quality: 0.95 })
         .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
            //pdf.save("Quotation.pdf"); 
            pdf.autoPrint();
            window.open(pdf.output('bloburl'), '_blank');
            window.close();
         });
   }
   render() {
      return (
         <>
            <div style={{ padding: '50px' }} id='downloadpage'>
               <div style={{ border: '2px solid #8cc63f' }} className='container'>
                  <div className='row'>
                     <div className='col-sm-5'> <img style={{ height: '70px' }} src='https://mxprodstorage.blob.core.windows.net/managex/assets/EmailTemplate/Logo.png' alt='VaporVm-Logo' /></div>
                     <div className='col-sm-4' style={{ marginTop: '30px' }}>
                        <h3 style={{ textAlign: 'left', fontSize: '18px' }} ><b>Invoice</b></h3>
                     </div>
                     <div className='col-sm-2 offset-1' style={{ marginTop: '10px' }}>
                        <button onClick={this.downloadPayment} className="btn" title="Download!"><i className="fa fa-download"></i></button>
                        <button onClick={this.print} className="btn ml-2" title="Print!"><i className="fa fa-print"></i></button>
                     </div>
                  </div>
                  <hr />


                  <div className='row'>
                     <div className='col-sm-6 '>
                        <h3 style={{ textAlign: 'left', fontSize: '18px' }}><b>Customer Name: </b> {this.state.customerOrder != null ? this.state.userName : ""}</h3>
                        <h3 style={{ textAlign: 'left', fontSize: '18px' }}><b>Email: </b> {this.state.customerOrder != null ? this.state.userEmail : ""}</h3>
                        <h3 style={{ textAlign: 'left', fontSize: '18px' }}><b>Order Date: </b> {this.state.customerOrder != null ? this.state.customerOrder[0].quotationDate.split('T').join(' ').split(':').slice(0, -1).join(':') : ""}</h3>
                        <h3 style={{ textAlign: 'left', fontSize: '18px' }} ><b>Quotation Ref#: </b>{this.state.customerOrder != null ? this.state.customerOrder[0].quotationID : ""}</h3>
                     </div>
                     <div className='col-sm-4 offset-2'>
                        <h3 style={{ textAlign: 'left', fontSize: '18px' }} > <b> Managex</b> <br></br> One Lake Plaza, Office # 1301-02, Cluster T, JLT Dubai, United Arab Emirates <br></br> + 971(4)447 4594 <br></br> support@managex.ae</h3>
                     </div>
                  </div>
                  <div className='card'>
                     <div style={{ backgroundColor: '#8cc63f' }} className='card text-white'>
                        <h3 style={{ fontSize: '18px', marginLeft: '10px', lineHeight: '30px' }}>Order Summary</h3>
                     </div>
                     <div className='card-body'>
                        <div className='card-body'>
                           <table className='table table-striped' >
                              <thead style={{ backgroundColor: '#424949' }}>
                                 <tr>
                                    <th style={{ color: '#FFFFFF' }}>Services Name</th>
                                    <th style={{ textAlign: 'center', color: '#FFFFFF' }}>Units</th>
                                    <th style={{ textAlign: 'right', color: '#FFFFFF' }}>Unit Price</th>
                                    <th style={{ textAlign: 'right', color: '#FFFFFF' }}>Total</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {this.state.customerOrder != null ? this.state.customerOrder.map(function (item, i) {
                                    return <tr>
                                       <td>{item.serviceName}</td>
                                       <th style={{ textAlign: 'center' }}>{1} </th>
                                       <th style={{ textAlign: 'right' }}>{item.unitPrice} USD </th>
                                    </tr>;
                                 }) : ""}
                                 <tr>
                                    <td></td>
                                    <th style={{ textAlign: 'center' }}></th>
                                    <th style={{ textAlign: 'right' }}>Total</th>
                                    <th style={{ textAlign: 'right' }}>{this.state.customerOrder != null ? this.state.customerOrder[0].totalAmount : ""} USD</th>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                  <br />
                  <div className='row'>
                     <h4 style={{ fontSize: '18px', textAlign: 'center', marginLeft: '15%' }}>Thank you for using ManageX. Feel free to share your feedback at <a href="mailto:support@managex.ae">support@managex.ae</a></h4>
                     <h4 style={{ fontSize: '18px', textAlign: 'center', marginLeft: '42%' }}>All rights reserved <span>&#169;</span> 2021</h4>
                  </div>
               </div>
            </div>


         </>
      )
   }
}
export default Doenlaods;