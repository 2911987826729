import React from "react";
class PaymentReject extends React.Component {
    GoBack() {
        window.history.back();
    }
    render() {    
        return(
            <>
            <div className="hero is-fullheight is-feature-grey">                    
                    {/* <!-- Hero caption --> */}
                    <div className="hero-body ">
                        <div className="container has-text-centered">
                            <div className="columns is-vcentered">
                                <div className="column is-12  has-text-centered">
                                    <h2 className="parallax-hero-title">
                                       Payment UnSuccessful
                                    </h2>

                                    <br />
                                    <p className="has-text-centered">
                                        <a style={{textDecoration:"none"}} href="/" className="roundButton button button-cta btn-align primary-btn rounded z-index-2">
                                            Go to DashBoard
                                        </a>
                                        <button onClick={this.GoBack} className=" roundButton ml-2 button button-cta btn-align primary-btn rounded z-index-2">
                                            Try again
                                        </button>
                                    </p>
                                    {this.props.page}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default PaymentReject;