import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import {ADMIN_EMAIl} from "../Service/Constent/Constent"
import {paymentCompletedTemplate} from "../EmailTemplates/paymentCompletedTemplate";

class PaymentSuccess extends React.Component {
    customerOrderId = -1;
    constructor(props)
    {
        super(props);
        var queryString =   this.props.location.pathname.split('/');
        this.PaymentId = queryString[queryString.length - 1];
        this.state = { 
            qoutationId: "",
            userId :cookie.load('user_Id'),
            projectName :"",
            ProjectTemplateId :""
         };
        // this.doPayment = this.doPayment.bind(this);
        // this.getQuotation = this.getQuotation.bind(this);
    }

    componentDidMount()
    {
        this.PaymentSuccess(this.PaymentId)
    }
    
    PaymentSuccess(PaymentId)
    {
        var qoutationId = "";   
        var ProjectTemplateId = "O3M";   
        var date = new Date();
        Service.SuccessfullPayment(PaymentId).then(res => {
            if (res) {
                //this.setState({qoutationId:res});
                qoutationId = res.quotationid;
                //console.log(qoutationId);
                // debugger;
                var _amount = "USD " + res.amount;
                var _paymentDate = res.date;
                Service.UpdateQoutationbyId(qoutationId).then(res=> { //res).then(res => {
                    //console.log(res);
                    if (res) {
                        var serviceName=res.ServiceName;
                        console.log(res);
                        var data = {
                            //"id": 0,
                            "qoutationId": qoutationId,
                            "userId": this.state.userId,
                            "jiraProjectKey": "string",
                            "projectName": serviceName,
                            "projectTemplateId": res.TemplateId                            
                          }
                        Service.CreateJiraProject(data).then(res =>{
                            if(res !="Already Created"){
                                var EamilData = new FormData();
                                EamilData.append('ToEmail', this.state.userId);
                                EamilData.append('Subject', 'Thank you for the Payment!');
                                var _bodyTemplate = paymentCompletedTemplate;
                                var _details = 'Your project '+serviceName+' has been created kindly <a href="http://'+window.location.host+'/#/dashboard">click here</a> to access your project details';
                                var Replaceversion = _bodyTemplate.replace("{User}",cookie.load('user_Name')).
                                                    replace("{ServiceName}",serviceName).
                                                    replace("{Details}",_details).
                                                    replace("{Currency}{amount}",_amount).
                                                    replace("{DateTime}",_paymentDate).
                                                    replace("{URL}",'http://'+window.location.host+'/#/dashboard')
                                                    ;
                                //EamilData.append('Body', 'Dear '+cookie.load('user_Name')+'<br><br>Your project '+serviceName+' has been created kindly <a href="http://'+window.location.host+'/#/dashboard">click here</a> to access your project details <br> <br> <br> Team ManageX');
                                EamilData.append('Body',Replaceversion);
                                Service.SendEmail(EamilData);

                                this.props.history.push('/dashboard')
                            }
                            else
                            {
                                alert("Already Created")
                                this.props.history.push('/dashboard')
                            }
                        })                       
                    }
                });
            }
        });
    }

    render() {
        return(
            <>
            <div className="hero is-fullheight is-feature-grey">                    
                    {/* <!-- Hero caption --> */}
                    <div className="hero-body ">
                        <div className="container has-text-centered">
                            <div className="columns is-vcentered">
                                <div className="column is-8 is-offset-2 has-text-centered">
                                    <h1 className="parallax-hero-title">
                                       Payment Success
                                    </h1> 
                                    <p>
                                        Redirecting to your dashboard.
                                    </p>                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default PaymentSuccess;