import React from "react";
import { connect } from "react-redux";
import CustomLoader from "../Loader/section/SectionLoader";

class InProgressComponent extends React.Component{
 
    render(){

        return(
            <div className="column is-4">
                <div className="card-counter">
                    <div className="flex-card light-bordered hover-inset">
                        <div className="round-icon">
                            <i className="material-icons">graphic_eq</i>
                        </div>
                        {
                            this.props.inProgressCount < 0 ? 
                                <CustomLoader />
                            :
                                <div className="counter counter-number text-bold">
                                {this.props.inProgressCount}
                                </div>
                                
                        }
                        {/* <div className="counter counter-number text-bold">
                            {this.props.inProgressCount < 0 ? 'Loading please wait' : this.props.inProgressCount}
                        </div> */}
                        <div className="counter-text">Project Inprogress</div>
                    </div>
                </div>
            </div>
        );

    }
}

const mapStateToProps = state =>{
    return{
        inProgressCount : state.Inprogress
    }
};

export default connect(mapStateToProps)(InProgressComponent);