import React, { Component } from 'react';
import DataTable from '@ashvin27/react-datatable';



export default class GridWrapper extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const ConfigObject = {
            page_size: this.props.PageSize,
            length_menu: this.props.Menu,
            show_filter: this.props.ShowFilter,
            show_pagination: this.props.ShowPagination,
            pagination: this.props.PaginationOption, // two option "basic" or "advance"
            show_info: false
        };

        return (
            <DataTable
                config={this.props.config ? this.props.config : ConfigObject}
                records={this.props.Records}
                columns={this.props.ColumnList}
                extraButtons={this.props.ExtraButton}
                className={this.props.className}
                tHeadClassName={this.props.tHeadClassName}
            />
        );
    }

}




