import React, {useEffect} from "react";
import './TopBar.css';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { activeLinkAction } from "../../store/actions/activeLinkAction";
import Dropdown from "../Dropdown";


function TopBar(props) {
    useEffect(()=>{
        if(props.location.pathname.toLowerCase() === "/editprofile"){
            props.dispatch(activeLinkAction("editprofile"));
          }
    },[]);
    const user = <div className="d-flex align-items-center" style={{ gap: "0.5em" }}>
        {/* <i className="fa fa-user mx-topbar-icon"></i> */}
        <div className="mx-profile-photo">
            <img src="https://www.morganstanley.com/content/dam/msdotcom/people/tiles/isaiah-dwuma.jpg.img.490.medium.jpg/1594668408164.jpg"/>
        </div>
        <span>Zargham Aijaz</span>
    </div>
    return (
        <div className="mx-topbar-main">
            <Dropdown parent={<i className="fa fa-bell mx-topbar-icon"><div className="mx-topbar-icon-badge danger"></div></i>} className="mx-dropdown-main" childClassName="mx-dropdown-children">
                <p>Notification 1</p>
                <p>Notification 2</p>
                <p>Notification 3</p>
            </Dropdown>
            <Link to="/" className="mx-topbar-link"><i class="fa fa-shopping-cart mx-topbar-icon"><div className="mx-topbar-icon-badge danger"></div></i></Link>
            <Link onClick={()=>props.dispatch(activeLinkAction("editprofile"))} to="/EditProfile" className="mx-topbar-link"><i className={`fa fa-gear mx-topbar-icon ${props.activeLink === "editprofile" ? "active" : ""}`}></i></Link>
            <Dropdown parent={user} className="mx-dropdown-main" childClassName="mx-dropdown-children">
                {/* <Link to="/EditProfile" className="d-block pt-2 pb-2">Edit Profile</Link> */}
                <Link to="/logout" className="d-block pt-2 pb-2">Logout</Link>
            </Dropdown>

        </div>
    )
}

const mapStateToProps = (state)=>{
    return state.activeLinkReducer;
}
export default withRouter(connect(mapStateToProps, null)(TopBar));