import React, {useState, useRef} from "react";

function Dropdown(props){
    const{ notClosesOnOutsideClick, defaultActive, hasArrow } = props;
    const [ isActive, setIsActive ] = useState(defaultActive);
    const button = useRef();



    function handleClick(e){
        e.stopPropagation();
        if(notClosesOnOutsideClick){
            if(e.target === button.current){
                setIsActive(!isActive);
            }
        }
        else{
            setIsActive(true);
            document.addEventListener("click",documentClick);
        }
    }
    function documentClick(){
        setIsActive(false);
        document.removeEventListener("click",documentClick)
    }
    return(
        <div className={`${props.className ? props.className :""}`}>
            <div ref={button} onClick={handleClick} style={{cursor:"pointer",position:"relative"}}>
                {
                    hasArrow ?
                    <i style={{position:"absolute", bottom:"7px", right:"15px", fontSize:"30px", zIndex:9}} className={`zmdi ${isActive ? "zmdi-caret-up" : "zmdi-caret-down"}`}></i>
                    :""
                }
                <div style={{pointerEvents:"none"}}>
                    {props.parent}
                </div>
            </div>
            {
                isActive ? 
                <div className={`${props.childClassName ? props.childClassName :""}`}>{props.children}</div>
                : ""
            }
        </div>
    )
}
export default Dropdown;