import React from "react";
import cookie from "react-cookies";
import Service from '../../../components/Service/Api/Api'
import Swal from "sweetalert2";
import CustomLoader from "../../Loader/Loader";
import './editProfile.css';
import BreadCrumb from "../../../copmonents/BreadCrumb";
import profileImage from '../../../img/user-placeholder.png';
import { validatePassword } from "../../../common/validatePassword.utils";

export default class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Oldpassword: "",
            Newpassword: "",
            customLoader: false,

        };
        this.changePassword = this.changePassword.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    changePassword() {
        var data = {
            "userName": cookie.load('user_Id'),
            "oldPassword": this.state.Oldpassword,
            "newPassword": this.state.Newpassword
        }
        if(validatePassword(this.state.Newpassword)){
            this.setState({ customLoader: true });
            Service.ChangePassword(data).then(res => {
                this.setState({
                    customLoader: false,
                    Oldpassword: "",
                    Newpassword: ""
                });
    
                Swal.fire(
                    'Done!',
                    'Password changed successfully!',
                    'success'
                )
            })
            .catch(err => {
                this.setState({ customLoader: false });
                if (err.toString().includes(400)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Cannot change password',
                        text: 'The password you entered in incorrect.',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please enter valid credentials!',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            })
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Invalid new password',
                text: 'Password must be 8 digit long and should have at least one uppercase character, one lowercase character, one digit and one special character.',
                showConfirmButton: true,
            });
        }
    }
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    render() {
        return (
            <>
                {/* <div className="profile-header">
                    <div className="profile-header-inner">
                        <div className="profile-avatar">
                            <img className="avatar" src="https://www.pngkit.com/png/full/349-3499672_kevin-wooster-dummy-profile.png" alt="" data-demo-src="assets/img/avatars/helen.jpg" />
                            <h2 className="title is-4 is-narrow">{cookie.load('user_Name')}</h2>
                            
                        </div>
                    </div>
                </div>
                <div className="section is-app-grey">
                    <div className="container">
                        <div className="profile-wrapper">
                            <div className="account-form">
                                <form>
                                    <div className="form-inner">
                                        <div className="columns is-multiline">
                                            <div className="column is-12">
                                                <h4 className="form-section-title">Account Details</h4>
                                            </div>
                                            <div className="column is-12">
                                                <div className="field">
                                                    <div className="control has-icons-left">
                                                        <input className="input" type="text" placeholder="First Name" value={cookie.load('user_Name')} />
                                                        <span className="icon is-small is-left">
                                                            <i className="sl sl-icon-user"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column is-12">
                                                <div className="field">
                                                    <div className="control has-icons-left">
                                                        <input className="input" type="text" placeholder="Email address" value={cookie.load('user_Id')} />
                                                        <span className="icon is-small is-left">
                                                            <i className="sl sl-icon-envelope-open"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column is-12">
                                                <h4 className="form-section-title mt-4">Security</h4>
                                            </div>
                                            <div className="column is-12">
                                                <div className="field">
                                                    <div className="control has-icons-left">
                                                        <input className="input" type="password" name="Oldpassword" placeholder="Old Password" onChange={this.onChange} />
                                                        <span className="icon is-small is-left">
                                                            <i className="sl sl-icon-lock"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column is-12">
                                                <div className="field">
                                                    <div className="control has-icons-left">
                                                        <input className="input" type="password" name="Newpasseord" placeholder="New Password" onChange={this.onChange} />
                                                        <span className="icon is-small is-left">
                                                            <i className="sl sl-icon-lock"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column is-12">
                                                <div className="field">
                                                    <div className="buttons is-centered">
                                                        <button type="button" onClick={this.changePassword} className="button form-button primary-btn raised">
                                                            Save Changes
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}

                {this.state.customLoader ? <CustomLoader /> : null}
                <BreadCrumb />
                {/* <section className="content profile-page">
                    <div className="container-fluid">

                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12">
                                <div className="card">
                                    <div className="body profile-header">
                                        <div className="row">
                                            <div className="col-lg-10 col-md-12">
                                                <img src="assets/images/profile_av.jpg" className="user_pic rounded img-raised" alt="User" />
                                                <div className="detail">
                                                    <div className="u_name">
                                                        <h4><strong>{cookie.load('user_Name').toUpperCase()}</strong></h4>
                                                        <span>{cookie.load('user_Id')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="content profile-page">
                    <div className="container-fluid">

                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12">
                                <div className="card profile-top">
                                    <div className="body bg-dark profile-header">
                                        <div className="row">
                                            <div className="col-lg-10 col-md-12">
                                                <img src={profileImage} className="user_pic rounded img-raised" alt="User" />
                                                <div className="detail">
                                                    <div className="u_name">
                                                        <h4><strong>{cookie.load('user_Name').toUpperCase()}</strong></h4>
                                                        <span>{cookie.load('user_Id')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div role="tabpanel" className="tab-pane" id="usersettings">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 p-4">
                                <div className="edit-profile-wrap">
                                    <div className="header">
                                        <h2><strong>Account</strong> Details</h2>
                                    </div>
                                    <div className="body">
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    {/* <input type="text" className="form-control" placeholder="First Name" /> */}
                                                    <p className="form-control"> {cookie.load('user_Name').split(" ")[0]}</p>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    {/* <input type="text" className="form-control" placeholder="Last Name" /> */}
                                                    <p className="form-control"> {cookie.load('user_Name').split(" ")[cookie.load('user_Name').split(" ").length - 1]}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <p className="form-control"> {cookie.load('user_Id')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-4">
                                <div className="edit-profile-wrap">
                                    <div className="header">
                                        <h2><strong>Security</strong> Settings</h2>
                                    </div>
                                    <div className="body">

                                        <div className="form-group">
                                            <input type="password" className="form-control" value={this.state.Oldpassword} name="Oldpassword" onChange={this.onChange} placeholder="Current Password" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control" value={this.state.Newpassword} name="Newpassword" onChange={this.onChange} placeholder="New Password" />
                                        </div>
                                        <button onClick={this.changePassword} className="btn btn-info btn-round">Save Changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

