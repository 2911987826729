import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

export default class ManageSoc extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: cookie.load('user_Id'),
            userName: cookie.load('user_Name'),
            AssignedUserName: ""
        };
        this.getQuestionaire = this.getQuestionaire.bind(this);

    }
    componentDidMount() {

        Service.GetUserByServiceId(7).then(res => {
            //console.log(res);
            this.setState({ AssignedUserName: res.UserName })
        })
    }
    startCall(CallingTo) {
        if (cookie.load('user_Id') == undefined) {
            this.props.history.push('login')
        }
        else
            window.call(CallingTo, this.state.userId)
    }
    getQuestionaire() {
        this.props.history.push('Questionnaire/7');
    }
    render() {

        return (
            <>
                <div className="hero parallax"
                    style={{ backgroundSize: "100% 100%", "background-image": "url(https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/SecurityAssessment111.jpg" }}>
                    {/* <!-- Hero caption --> */}
                    <div id="main-hero" className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-5 signup-column has-text-left">
                                    <h1 className="title main-title text-bold is-2 text-center" style={{ marginTop: 140 }}>
                                        SECURITY ASSESSMENT
                                    </h1>
                                    <h2 className="subtitle is-5 no-margin-bottom is-light text-center">
                                        SECURITY, COMPLIANCE AND PERFORMANCE AT YOUR FINGERTIPS
                                    </h2>
                                    <br />

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <!-- Clients --> */}
                </div>
                {/* <!-- Benefits section --> */}
                {/* <!-- Contact Section --> */}

                <div className="container">
                    <div className="section-title-wrapper has-text-centered" style={{ marginTop: 100 }}>
                        <div className="bg-number">1</div>
                        <h2 className="section-title-landing">
                            WHY TO CHOOSE US
                        </h2>
                        <h4>Great tools that will make your life easier</h4>
                    </div>
                    <div className="content-wrapper">
                        <div className="columns is-vcentered pb-40 pt-40">
                            <div className="column is-4 is-offset-1">
                                <div className="icon-subtitle"></div>
                                <div className="title quick-feature has-numbers text-bold">
                                    <div>CERTIFIED TEAM
                                    </div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="body-text" style={{ textAlign: "justify" }}>
                                    <div className="Paragraph">
                                        <p>
                                            As the number of sophisticated cyber threats continues to grow, perimeter security is
                                            proving to be an insufficient defence mechanism. Across industries, businesses must
                                            ensure they have the capability to actively hunt for and eliminate threats before they
                                            cause serious financial and reputational damage. However, the cost of setting up the
                                            necessary technology and personnel to proactively monitor and investigate
                                            threats means that an in-house SOC is out of reach for all but the largest organizations.
                                        </p>
                                    </div>
                                </span>
                                <div className="pt-10 pb-10">
                                    <div className='row'>
                                        <button className="button btn-align btn btn-primary col-4" onClick={this.getQuestionaire}>Buy Now</button>
                                        {this.state.AssignedUserName != "" ?
                                            <div className='col-2'><button className="button btn-align btn btn-success" onClick={() => this.startCall(this.state.AssignedUserName)}>Talk to an expert</button></div>
                                            : ""}

                                    </div>
                                </div>
                            </div>
                            <div className="column is-6 is-offset-1">
                                <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/graphics/compositions/drawing-bw-2-core.svg"
                                    data-base-url="https://vvm-prod-cdn.azureedge.net/managex/assets/img/graphics/compositions/drawing-bw-2"
                                    data-extension=".svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--SECTION 2-->*/}
                <div style={{ display: 'none' }} className="section is-medium">
                    <div className="container">
                        <div className="section-title-wrapper">
                            <div className="bg-number">2</div>
                            <h2 className="title section-title has-text-centered dark-text text-bold">
                                Pricing
                            </h2>
                        </div>

                        <div className="content-wrapper">
                            <div className="header-pricing">
                                <div className="columns is-vcentered">
                                    <div className="column is-10 is-offset-1">
                                        <div className="columns is-vcentered">

                                            <div className="column is-4">
                                                <div className="flex-card header-pricing-card accent hover-inset">
                                                    <h3 className="plan-name">Silver</h3>
                                                    <div className="pricing-card-body">
                                                        <div className="plan-price per-month animated preFadeInUp fadeInUp">
                                                            <small>USD</small> 25 <small><b>/month</b></small>
                                                        </div>
                                                        <div className="plan-price per-year is-hidden animated preFadeInUp fadeInUp">
                                                            <small>USD</small> 300 <small><b>/year</b></small>
                                                        </div>
                                                        <ul className="plan-features" style={{ "height": "1885px !important" }}>
                                                            <li><span className="feature-count-text">24/7 Managed SOC</span></li>
                                                            <li><span className="feature-count-text">Automated Asset discovery</span></li>
                                                            <li><span className="feature-count-text">Vulnerability assessment</span></li>
                                                            <li><span className="feature-count-text">Intrusion detection</span></li>
                                                            <li><span className="feature-count-text">Vulnerability Monitoring & Management</span></li>
                                                            <li><span className="feature-count-text">SIEM event correlation</span></li>
                                                            <li><span className="feature-count-text">Incident response</span></li>
                                                            <li><span className="feature-count-text">Log management</span></li>
                                                            <li><span className="feature-count-text">Ticket Management</span></li>
                                                            <li><span className="feature-count-text">Email alerts</span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                        </ul>
                                                        <div className="pt-30">
                                                            <button className="button button-cta btn-align rounded raised accent-btn  mb-20" onClick={this.getQuestionaire} >Get started</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="column is-4">
                                                <div className="flex-card header-pricing-card primary hover-inset">
                                                    <h3 className="plan-name" style={{ backgroundColor: '#06d6a0' }}>Gold</h3>
                                                    <div className="pricing-card-body">
                                                        <div className="plan-price per-month animated preFadeInUp fadeInUp">
                                                            <small>USD</small> 40 <small><b>/month</b></small>
                                                        </div>
                                                        <div className="plan-price per-year is-hidden animated preFadeInUp fadeInUp">
                                                            <small>USD</small> 480 <small><b>/year</b></small>
                                                        </div>
                                                        <ul className="plan-features" style={{ "height": "1885px !important" }}>
                                                            <li><span className="feature-count-text">24/7 Managed SOC</span></li>
                                                            <li><span className="feature-count-text">Automated Asset discovery</span></li>
                                                            <li><span className="feature-count-text">Vulnerability Monitoring & Management (IT/OT)</span></li>
                                                            <li><span className="feature-count-text">Vulnerability assessment</span></li>
                                                            <li><span className="feature-count-text">Intrusion detection</span></li>
                                                            <li><span className="feature-count-text">Threat Hunting</span></li>
                                                            <li><span className="feature-count-text">Incident Response & Triage</span></li>
                                                            <li><span className="feature-count-text">Threat Advisory Services</span></li>
                                                            <li><span className="feature-count-text">Devices Health Test Monitoring</span></li>
                                                            <li><span className="feature-count-text">Security Devices Tuning </span></li>
                                                            <li><span className="feature-count-text">24/7 Managed SOC</span></li>
                                                            <li><span className="feature-count-text">Automated Asset discovery</span></li>
                                                            <li><span className="feature-count-text">Vulnerability Monitoring & Management (IT/OT)</span></li>
                                                            <li><span className="feature-count-text">Vulnerability assessment</span></li>
                                                            <li><span className="feature-count-text">Intrusion detection</span></li>
                                                            <li><span className="feature-count-text">Threat Hunting</span></li>
                                                            <li><span className="feature-count-text">Incident Response & Triage</span></li>
                                                            <li><span className="feature-count-text">Threat Advisory Services</span></li>
                                                            <li><span className="feature-count-text">Devices Health Test Monitoring</span></li>
                                                            <li><span className="feature-count-text">Security Devices Tuning </span></li>
                                                            <li><span className="feature-count-text">Run Book/Playbooks Development </span></li>
                                                            <li><span className="feature-count-text">SIEM event correlation </span></li>
                                                            <li><span className="feature-count-text">Incident response </span></li>
                                                            <li><span className="feature-count-text">Endpoint detection and response </span></li>
                                                            <li><span className="feature-count-text">Compliance based reporting </span></li>
                                                            <li><span className="feature-count-text">Ticket Management </span></li>
                                                            <li><span className="feature-count-text">Log management </span></li>
                                                            <li><span className="feature-count-text">Vulnerability Assessment & Penetration Testing (Bi-Annually ) </span></li>
                                                            <li><span className="feature-count-text">Digital Forensics Analysis </span></li>
                                                            <li><span className="feature-count-text">Email alerts </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                            <li><span className="feature-count-text">X </span></li>
                                                        </ul>
                                                        <div className="pt-30">
                                                            <button className="button button-cta btn-align rounded raised success-btn mb-20" onClick={this.getQuestionaire}>Get started</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="column is-4">
                                                <div className="flex-card header-pricing-card secondary hover-inset">
                                                    <h3 className="plan-name">Platinum</h3>
                                                    <div className="pricing-card-body">
                                                        <div className="plan-price per-month animated preFadeInUp fadeInUp">
                                                            <small>USD</small> 75 <small><b>/month</b></small>
                                                        </div>
                                                        <div className="plan-price per-year is-hidden animated preFadeInUp fadeInUp">
                                                            <small>USD</small> 825 <small><b>/year</b></small>
                                                        </div>
                                                        <ul className="plan-features" style={{ "height": "1885px !important" }}>
                                                            <li><span className="feature-count-text">24/7 Managed SOC</span></li>
                                                            <li><span className="feature-count-text">Automated Asset discovery</span></li>
                                                            <li><span className="feature-count-text">Vulnerability Monitoring & Management (IT/OT)</span></li>
                                                            <li><span className="feature-count-text">Vulnerability assessment</span></li>
                                                            <li><span className="feature-count-text">Intrusion detection</span></li>
                                                            <li><span className="feature-count-text">Threat Hunting</span></li>
                                                            <li><span className="feature-count-text">Incident Response & Triage</span></li>
                                                            <li><span className="feature-count-text">Threat Advisory Services</span></li>
                                                            <li><span className="feature-count-text">Devices Health Test Monitoring</span></li>
                                                            <li><span className="feature-count-text">Security Devices Tuning </span></li>
                                                            <li><span className="feature-count-text">24/7 Managed SOC</span></li>
                                                            <li><span className="feature-count-text">Automated Asset discovery</span></li>
                                                            <li><span className="feature-count-text">Vulnerability Monitoring & Management (IT/OT)</span></li>
                                                            <li><span className="feature-count-text">Vulnerability assessment</span></li>
                                                            <li><span className="feature-count-text">Intrusion detection</span></li>
                                                            <li><span className="feature-count-text">Threat Hunting</span></li>
                                                            <li><span className="feature-count-text">Incident Response & Triage</span></li>
                                                            <li><span className="feature-count-text">Threat Advisory Services</span></li>
                                                            <li><span className="feature-count-text">Devices Health Test Monitoring</span></li>
                                                            <li><span className="feature-count-text">Security Devices Tuning </span></li>
                                                            <li><span className="feature-count-text">Integrated ticketing & alerting </span></li>
                                                            <li><span className="feature-count-text">Incident response & forensics </span></li>
                                                            <li><span className="feature-count-text">Threat Intelligence and Hunting support </span></li>
                                                            <li><span className="feature-count-text">Dark web monitoring </span></li>
                                                            <li><span className="feature-count-text">Support for higher data volumes </span></li>
                                                            <li><span className="feature-count-text">Supports PCI log storage requirements </span></li>
                                                            <li><span className="feature-count-text">Devices Health Test Monitoring </span></li>
                                                            <li><span className="feature-count-text">Vulnerability Assessment & Penetration Testing (Bi-Annually ) </span></li>
                                                            <li><span className="feature-count-text">Gap Assessment (Periodic) </span></li>
                                                            <li><span className="feature-count-text">Security configuration review (network devices & firewalls) </span></li>
                                                            <li><span className="feature-count-text">Vulnerability Management </span></li>
                                                            <li><span className="feature-count-text">Patching support </span></li>
                                                            <li><span className="feature-count-text">Security Advisory services </span></li>
                                                            <li><span className="feature-count-text">Digital Forensics & Malware Analysis </span></li>
                                                        </ul>
                                                        <div className="pt-30">
                                                            <button className="button button-cta btn-align rounded raised secondary-btn  mb-20" onClick={this.getQuestionaire}>Get started</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section is-medium">

                    <div className="section-title-wrapper has-text-centered">
                        <div className="bg-number">2</div>
                        <h2 className="section-title-landing">
                            HOW WE SECURE YOU
                        </h2>
                        <h3 className="section-subtitle-alt">A wide range of consulting services to help you manage your company</h3>
                    </div>
                    <div className="boxed-features">
                        <div className="flex-card light-bordered hover-inset">
                            <div className="flex-cell is-bordered">
                                <i className="im im-icon-Support"></i>
                                <h3>24/7 Support</h3>
                                <p>Top recent and curated job offers from our company network</p>
                            </div>
                            <div className="flex-cell is-bordered">
                                <i className="im im-icon-Laptop-Tablet"></i>
                                <h3>Asset discovery and Inventory</h3>
                                <p>To manage your earnings and make withdrawals</p>
                            </div>
                            <div className="flex-cell is-bordered">
                                <i className="im im-icon-Magnifi-Glass2"></i>
                                <h3>Intrusion Detection</h3>
                                <p>You can talk freely with the customers </p>
                            </div>
                            <div className="flex-cell is-bordered no-border-edge">
                                <i className="im im-icon-Hour"></i>
                                <h3>Incident Response</h3>
                                <p>You don't have to worry about invoices and billing</p>
                            </div>
                            <div className="flex-cell">
                                <i className="im im-icon-Monitor-Analytics"></i>
                                <h3>Log Management</h3>
                                <p>Don't get lost in your tasks anymore aith our built in tracker</p>
                            </div>
                            <div className="flex-cell">
                                <i className="im im-icon-Computer-Secure"></i>
                                <h3>Compliance Based Reporting</h3>
                                <p>You don't have to do your time tracking anymore, use ours</p>
                            </div>
                            <div className="flex-cell">
                                <i className="im im-icon-New-Mail"></i>
                                <h3>Email Based Alerts</h3>
                                <p>Evaluate your work and perfs by using built in reports</p>
                            </div>
                            <div className="flex-cell no-border-edge">
                                <i className="im im-icon-Security-Settings"></i>
                                <h3>Security Device Tuning</h3>
                                <p>Showcase progress to customers using a set of design tools</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-title-wrapper has-text-centered text-center">
                    <div className="bg-number">3</div>
                    <h2 className="section-title-landing">
                        HOW WE SECURE YOU
                    </h2>
                    <h3 className="section-subtitle-alt">A wide range of consulting services to help you manage your company</h3>
                </div>
                <div className="columns is-vcentered pb-40 pt-40">
                    <div className="column is-6">
                        <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/graphics/compositions/drawing-bw-5-core.svg" data-base-url="assets/img/graphics/compositions/drawing-bw-5" data-extension=".svg" alt="" />
                    </div>
                    <div className="column is-4 is-offset-1 is-hidden-mobile">
                        {/* <div className="icon-subtitle"><i className="im im-icon-Video-2"></i></div> */}
                        <div className="title quick-feature has-numbers text-bold">
                            <h2>SECURITY OPERATIONS CENTER
                                24/7 SECURITY</h2>
                        </div>
                        <div className="title-divider is-small"></div>
                        <span className="body-text">
                            <div className="Paragraph">
                                <p>
                                    The beauty of ManageX Security Operations Service that it it relevant to businesses
                                    of all sizes. The service offers enterprise-level threat detection
                                    and incident response capabilities at a fraction of the cost of an in-house SOC.
                                    Acting as an extension of your IT team, ManageX professionally trained security
                                    analysts and engineers utilize cutting-edge detection technology and cyber intelligence to offer an outsourced SOC service for complete managed detection and response operations.

                                </p>
                            </div>
                        </span>
                        <div className="pt-10 pb-10">
                            <Link className="button btn-align btn btn-primary"
                                onClick={this.getQuestionaire}>
                                Buy Now
                            </Link>
                        </div>
                    </div>
                </div>
            </>


        )
    }
}

