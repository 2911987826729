const initialState = {
    mainSessionObj:{
        apiKey:"apiKey",
        sessionId:"sessionid",
        token:"token"
    },
    mainSessionSignalObj:null,
    mainSessionRef:{},
    connections:[]
}
const mainSessionReducer = (state = initialState, action)=>{
    switch(action.type){
        case "SET_MAIN_SESSION" : return {...state, mainSessionObj:action.payload}
        case "SET_MAIN_SIGNAL_OBJ" : return {...state, mainSessionSignalObj:action.payload}
        case "SET_MAIN_SESSION_REF" : return {...state, mainSessionRef:action.payload}
        case "SET_CONNECTIONS" : return {...state, connections:action.payload}
        default: return state
    }
}
export default mainSessionReducer;