import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
class CodeReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: cookie.load('user_Id'),
            userName: cookie.load('user_Name'),
            AssignedUserName: ""
        };
        this.getQuestionaire = this.getQuestionaire.bind(this);

    }
    componentDidMount() {

        Service.GetUserByServiceId(9).then(res => {
            //console.log(res);
            this.setState({ AssignedUserName: res.UserName })
        })
    }
    startCall(CallingTo) {
        if (cookie.load('user_Id') == undefined) {
            this.props.history.push('login')
        }
        else
            window.call(CallingTo, this.state.userId)
    }
    getQuestionaire() {
        this.props.history.push('Questionnaire/8');
    }
    render() {
        return (
            <>
                <div className="hero parallax"
                    style={{ backgroundSize: "100% 100%", "background-image": "url(https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/code_review.jpg" }}
                >

                    {/* <!-- Hero caption --> */}
                    <div id="main-hero" className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-5 signup-column has-text-left">
                                    <h1 className="title main-title text-bold is-2" style={{ marginTop: 140, 'textAlign': "center" }}>
                                        CODE REVIEW
                                    </h1>
                                    <h2 className="subtitle is-5 no-margin-bottom is-light" style={{ 'textAlign': "center" }}>
                                        SECURITY, COMPLIANCE AND PERFORMANCE AT YOUR FINGERTIPS
                                    </h2>
                                    <br />

                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                {/* <!-- Clients --> */}
                <div className="container">
                    <div className="section-title-wrapper has-text-centered" style={{ marginTop: 100 }}>
                        <div className="bg-number">1</div>
                        <h2 className="section-title-landing">
                            Managed services with a difference
                        </h2>
                        <h4 style={{ "font-family": "sans-serif" }}>
                            ManageX Code Review services are a secure way of getting certified experts to audit your code and ensure its safety.
                            Our code auditors use proprietary tools to dig out vulnerabilities within the source code of your applications and recommend the right course of action for your particular use case.
                            In this day and age, when cyber threats are on the rise, hackers are using sophisticated methodologies to manipulate code vulnerabilities and holding reputable companies for ransom.
                            Simple code reviews allows companies to ensure that their precious customer data as well as their reputation remains safe.
                        </h4>

                    </div>
                    <div className="container">
                        <div className="content-wrapper">
                            <div className="columns is-vcentered pb-40 pt-40">
                                <div className="column is-4 is-offset-1">
                                    <div className="icon-subtitle"></div>
                                    <div className="title quick-feature has-numbers text-bold">
                                        <div>
                                            ManageX Code Reivew Services
                                        </div>
                                    </div>
                                    <div className="title-divider is-small"></div>
                                    <span className="body-text" style={{ textAlign: "justify" }}>
                                        <div className="Paragraph">
                                            <p style={{ color: "#999" }}>
                                                Agilant leverages it's state of the art
                                                Network-Operations-Center (NOC) and strategic
                                                partnerships with key technology vendors and
                                                to provide our customers with seamless IT operations.
                                            </p>
                                        </div>
                                    </span>
                                    <div className="pt-10 pb-10">
                                        <div className='row'>
                                            <button className="button btn-align btn btn-primary col-4" onClick={this.getQuestionaire}>Buy Now</button>
                                            {this.state.AssignedUserName != "" ?
                                                <div className='col-2'><button className="button btn-align btn btn-success" onClick={() => this.startCall(this.state.AssignedUserName)}>Talk to an expert</button></div>
                                                : ""}

                                        </div>


                                    </div>
                                </div>
                                <div className="column is-6 is-offset-1">
                                    <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/CodeSlide.jpg"
                                        data-base-url="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/CodeSlide.jpg"
                                        data-extension=".svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Benefits section --> */}

                <div id="about" className="section is-app-grey is-medium">
                    <div className="container">
                        {/* <!-- Title --> */}
                        <div className="section-title-wrapper has-text-centered">
                            <div className="bg-number">2</div>
                            <h2 className="section-title-landing">
                                Our Process
                            </h2>
                            {/* <h3 className="section-subtitle-alt">Before creation, there's thinking. Our process is sharp and let us craft
                    the best quality.</h3> */}
                        </div>

                        {/* <!-- Process steps --> */}
                        <div className="content-wrapper">
                            <div className="columns is-vcentered">
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block has-line">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Over"></i>
                                                <div className="process-number">1</div>
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">1</div>
                                            <div className="details">
                                                <div className="motto">Register</div>
                                                <span className="section-feature-description">Register using your company ID and get access to our CIO's dashboard.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block has-line">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Around"></i>
                                                <div className="process-number">2</div>
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">2</div>
                                            <div className="details">
                                                <div className="motto">Choose Service</div>
                                                <span className="section-feature-description">Pick a service from our vast digital transformation portfolio</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Refresh"></i>
                                                <div className="process-number">3</div>
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">3</div>
                                            <div className="details">
                                                <div className="motto">Launch</div>
                                                <span className="section-feature-description">Use our secure channel to pay online or via a pay order and a subject matter expert is assigned to your project within 5 minutes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* <!-- Contact Section --> */}




            </>


        )
    }
}

export default CodeReview;