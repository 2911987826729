import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import Swal from "sweetalert2";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
export default class Report extends React.Component {

    //#region Constructor & states declaration

    constructor(props) {
        super(props);
        this.state = {
            //customerid : cookie.load('user_Id'),
            projectid: cookie.load('admin_email'),
            ProjectList: [],
            isDownloadable: false
        };
    }

    componentDidMount() {
        this.FetchProject();
    }

    //#endregion

    //#region  Custom events & functions

    handleChange = (event) => {
        this.setState({ projectid: event.target.value });
    }

    ShowMessage = (MsgPayload) => {
        try {
            Swal.fire({
                icon: MsgPayload.icon,
                title: MsgPayload.title,
                text: MsgPayload.text,
                showConfirmButton: false,
                timer: 1500
            });
        }
        catch (e) {
            console.log(e + " : in ShowMessage function")
        }
    }

    FetchProject = () => {
        try {
            Service.GetProjectByUserId(cookie.load("user_Id")).then(res => {
                if (res) {
                    this.setState({ ProjectList: res });
                }
            })
        }
        catch (er) {
            console.log(er);
        }
    };

    CheckFileProject = (e) => {
        this.setState({ projectid: e.target.value });
        var payload = {
            customerid: cookie.load("user_Id"),
            projectid: e.target.value //this.state.projectid
        };
        try {
            Service.FileCheck(payload).then(res => {
                if (res.status == "Success") {
                    this.setState({ isDownloadable: true });
                }
                else {
                    this.setState({ isDownloadable: false });
                }
            })
        }
        catch (er) {
            console.log(er);
        }
    };

    OnSubmitRequest = e => {
        e.preventDefault();
        var payload = {
            customerid: cookie.load("user_Id"),
            projectid: this.state.projectid
        };
        try {
            Service.DownloadUserReport(payload);
        }
        catch (er) {
            console.log(er);
        }
    };

    //#endregion

    render() {

        var ProjectListOption =
            this.state.ProjectList == null
                ? ""
                : this.state.ProjectList.map((item) => {
                    return (
                        <option value={item.JiraProjectKey}>{item.ProjectName}</option>
                    );
                });

        return (
            <>
                <div className="hero-body ">
                    <div className="container has-text-centered">
                        <div className="columns is-vcentered">
                            <div className="col-12">
                                <div className="row">
                                    <div className='col-8'>
                                        <div className="card">
                                            <div className="card-header"><h3>Reports</h3></div>
                                            <div className="card-body">
                                                <form >
                                                    <div id="signup-form" className="login-form animated preFadeInLeft fadeInLeft">
                                                        <div className="field pb-10">
                                                            <div className="control has-icons-right">
                                                                <select name="Role" onChange={this.CheckFileProject} className="input is-medium has-shadow">
                                                                    <option value="">
                                                                        --Select--
                                                                    </option>
                                                                    {ProjectListOption}
                                                                </select>
                                                                <span className="icon is-medium is-right">
                                                                    <i className="sl sl-icon-book-open"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p className="control login">
                                                            <button disabled={!this.state.isDownloadable} className="button button-cta primary-btn btn-align-lg is-bold is-fullwidth rounded raised no-lh"
                                                                onClick={this.OnSubmitRequest} type="submit" value="Submit">Download
                                                            </button>
                                                        </p>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

