import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import Swal from "sweetalert2"; 
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  export default class RoleAssignment extends React.Component{
    
    //#region Constructor & State region
    
    constructor(props) {
        
        super(props);
        this.state = { 
            UserID : '',
            Role: '',
            Company : cookie.load('Orgnization'),
            UserList : [],
            RequestedRole : '' 
        };
    }

    //#endregion

    componentDidMount(){
        this.FetchUserList();
    }

    //#region  Custom events & function

    ShowMessage = (MsgPayload) => {
        try{
            Swal.fire({  
                icon:  MsgPayload.icon, 
                title: MsgPayload.title,  
                text:  MsgPayload.text,
                showConfirmButton: false,  
                timer: 1500  
              });
        }
        catch(e){
            console.log(e + " : in ShowMessage function")
        }
    } 

    handleChange = (event) => {
        this.setState({[event.target.name]:  event.target.value});
    }

    DropdownChange = (e) => {
       this.setState({UserID : e.target.value});
       this.setState({RequestedRole : e.target[e.target.selectedIndex].getAttribute("requestedRoleValue")});
    }

    FetchUserList = () =>{
        try{
            
            var RequestPayload = {
                userid : '',
                requestedrole : '',
                companyname : this.state.Company
            };

            Service.FetchRoleRequest(RequestPayload).then(res =>{
                if (res.status === "Success" ) {
                    if(res.userList != null){
                        this.setState({ UserList : res.userList  });
                    }
                }
                else{
                    var payload = {
                        icon  : 'error',
                        title : 'Oops...',
                        text  : 'Error occured while processing your request.'
                    };
                    this.ShowMessage(payload);
                }
            }).catch(err => {
                var payload = {
                    icon  : 'error',
                    title : 'Oops...',
                    text  : 'Something went wrong.'
                };
                this.ShowMessage(payload);
            });
        }
        catch(e){
            console.log(e + " in FetchUserList");
        }
    }

    ChangeAdminrole = () =>{
        try{
            var payload = {
                userid : cookie.load('Id'),
                requestedrole : '',
                companyname : '',
                username : ''
            };
            Service.ChangeAdminRole(payload).then(res =>{
                if (res.status === "Success" ) {
                }
                else{
                   
                }
            }).catch(err => {
                
            });
        }
        catch(e){

        }
    }

    OnSubmitRequest = e => {
        e.preventDefault();
        if(this.state.UserID == ""){
            var payload = {
                icon  : 'error',
                title : 'Error',
                text  : 'Please select user before proceding.'
            };
            this.ShowMessage(payload);
        }
        else if (this.state.Role === "") {
            var payload = {
                icon  : 'error',
                title : 'Error',
                text  : 'Please select role before proceding.'
            };
            this.ShowMessage(payload);
        }
        else{
            try{
                var payload = {
                    userid : this.state.UserID,
                    requestedrole : this.state.Role,
                    companyname : '',
                    username : ''
                };
                
                Service.ApproveRoleRequest(payload).then(res =>{
                    if (res.status === "Success" ) {
                        if(this.state.Role === "Company Admin"){
                            // write function here 
                            this.ChangeAdminrole();
                            this.props.history.push("/logout")
                        }
                        this.FetchUserList();
                        var payload = {
                            icon  : 'success',
                            title : 'Request sent',
                            text  : 'User sucessfully updated.'
                        };
                        this.ShowMessage(payload);
                    }
                    else{
                        var payload = {
                            icon  : 'error',
                            title : 'Oops...',
                            text  : 'Error occured while processing your request.'
                        };
                        this.ShowMessage(payload);
                    }
                }).catch(err => {
                    var payload = {
                        icon  : 'error',
                        title : 'Oops...',
                        text  : 'Something went wrong.'
                    };
                    this.ShowMessage(payload);
                });
            }
            catch(e){
                alert(e);
            }
        } 
    };

    //#endregion

    render() {
        var RequestedUserList = 
            this.state.UserList.map((item) => {
                for (var x = 0; x < this.state.UserList.length; x++) {
                    if(x===0){
                        return (
                            <>                            
                            <option
                              requestedRoleValue = {item.requestRole}
                              value={item.userID}
                            >
                              {item.userName}
                            </option>
                            </>
                        );    
                    }    
                    return (
                            <option
                              value={item.UserID}
                            >
                              {item.UserName}
                            </option>
                        );
                    
                }
          })
        

        return (
            <>
              <div className="hero-body ">
                  <div className="container has-text-centered">
                     <div className="columns is-vcentered">
                    <div className="col-12">
                        <div className="row">
                            <div className='col-8'>
                                <div className="card">
                                    <div className="card-header"><h3>Role Assignment</h3></div>
                                    <div className="card-body">
                                    <form >
                                    <div id="signup-form" className="login-form animated preFadeInLeft fadeInLeft">
                                        <div className="field pb-10">
                                            <div className="control has-icons-right">
                                            <select name="UserDD" onChange={this.DropdownChange} className = "input is-medium has-shadow">
                                                {this.state.UserList != null?<>
                                                    <option>Select User ....</option>
                                                    {RequestedUserList}
                                                </>:
                                                <option>Select User ....</option>
                                                }
                                                
                                            </select>                                              
                                            <span className="icon is-medium is-right">
                                                 <i className="sl sl-icon-user"></i>
                                            </span>
                                            </div>
                                        </div>
                                        <div className="field pb-10">
                                            <div className="control has-icons-right">
                                            <select name="Role" onChange={this.handleChange} className = "input is-medium has-shadow">
                                                 <option>Select Role ....</option>
                                                 <option>Company Admin</option>
                                                 <option>Company Finance</option>
                                                 <option>Company Manager</option>
                                            </select>                                              
                                            <span className="icon is-medium is-right">
                                                 <i className="sl sl-icon-shuffle"></i>
                                            </span>
                                            </div>
                                        </div>
                                        <p className="control login">
                                            <button className="button button-cta primary-btn btn-align-lg is-bold is-fullwidth rounded raised no-lh"
                                              onClick={this.OnSubmitRequest} type="submit" value="Submit">Approve
                                            </button>
                                        </p>
                                    </div>
                                </form>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                            <div className="card">
                                    <div className="card-header">Requested Role </div>
                                    <div className="card-body">
                                        <b>{this.state.RequestedRole}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  </div>
              </div>
            </>


        )
    };

}