import React from "react";
import {Link} from "react-router-dom"

const Error404 = () => (
    <div className="authentication d-flex justify-content-center" style={{minHeight:"100vh", color:"var(--white)"}}>
        <div className="container d-flex justify-content-center">
            <div>
                <h1 className="mt-5 mb-0" style={{fontSize:"80px", fontWeight:"lighter"}}>SORRY</h1>
                <h2 className="my-0">we couldn't find that page.</h2>
                <h4 className="my-4">Please check if the URL is correct or go to our <Link className="clr-sc" to="/">homepage</Link>.</h4>
                <div class="footer mt-5">
                    <ul class="social_link list-unstyled">
                        <li><a href="https://twitter.com/managexae" title="LinkedIn"><i class="zmdi zmdi-linkedin"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/managexae/" title="Facebook"><i class="zmdi zmdi-facebook"></i></a></li>
                        <li><a href="https://www.facebook.com/managex.ae" title="Twitter"><i class="zmdi zmdi-twitter"></i></a></li>
                    </ul>
                    <hr />
                    <ul>
                        <li><a href="https://www.managex.ae/contact-us/" target="_blank">Contact Us</a></li>
                        <li><a href="https://www.managex.ae/about-us/" target="_blank">About Us</a></li>
                        <li><a href="https://www.managex.ae/privacy-policy/" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://www.managex.ae/terms-of-services/">Terms Of Services</a></li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
)

export default Error404;