import React, { Component } from 'react';
import "../publicQuestionnaire/Package.css";
import { AddToCart, RemoveFromCart, CheckCartItem } from '../Common/Cart';
import Card from './Card';



class PackageCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SinglePrice: '',
            PriceValue: '',
            TotalPrice: 0,
            AssetsCount: 0,
            isExpanded: false,
            isSubserviceSelected: false,
            isListCollapsed:-1
        }
    }
    setIsListCollapsed = (data) =>{
        this.setState({isListCollapsed: data});
    }
    setIsSubServiceSelected = (value) => {
        this.setState({ isSubserviceSelected: value });
    }
    componentDidMount() {
        this.setState({ SinglePrice: this.props.Price });
        var isSubserviceSelected = CheckCartItem("cart_sub_services", this.props.PackagesObj.lstPackages[0].subServiceID);
        this.setState({ isSubserviceSelected: isSubserviceSelected });
        this.props.checkPackageInCart();
    }

    onchange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            AssetsCount: e.target.value
        });
        var _total = e.target.value * this.state.SinglePrice;
        this.setState({ TotalPrice: _total });
    }

    setExpanded = (value) => {
        this.setState({ isExpanded: !value });
        // for smooth scrolling at the bottom
        // setTimeout(() => {
        //     window.scrollTo({
        //         top: document.body.scrollHeight,
        //         left: 0,
        //         behavior: 'smooth'
        //     });
        // }, 0)
        if (!this.state.isExpanded) {
        }
    }

    AddPackage = (e) => {
        try {
            //alert(process.env.NODE_ENV);
            // e.target.style.backgroundColor = "red"
            AddToCart("cart_items", e.target.getAttribute("btnPackageID"));
        }
        catch (ex) {
            console.log(ex);
        }
    }
    render() {
        let subServiceName = this.props.PackagesObj.subServiceName;
        var _self = this;
        var _packagesSection = this.props.PackagesObj.lstPackages.length > 0 ? this.props.PackagesObj.lstPackages.map(function (item, i) {
            return (
                <>
                    <Card
                        item={item}
                        setIsSubServiceSelected={_self.setIsSubServiceSelected}
                        isSubserviceSelected={_self.state.isSubserviceSelected}
                        checkPackageInCart={_self.props.checkPackageInCart}
                        isQtyEnable={item.isQtyEnable}
                        minQty={item.minQty > 0 ? item.minQty : 1}
                        maxQty={item.maxQty}
                        isListCollapsed={_self.state.isListCollapsed}
                        setIsListCollapsed={_self.setIsListCollapsed}
                    />
                </>
            );
        }) : "<b>No Data found !</b>"



        return (
            <>
                <div className=''>
                    <article className="message mx-packages" style={{ width: '100%' }}>
                        <div className="message-header mx-package-header" onClick={() => this.setExpanded(this.state.isExpanded)}>
                            <h4 className='message-title'><b>{subServiceName}</b></h4>
                            <button className='btn' onClick={() => this.setExpanded(this.state.isExpanded)}>
                                {this.state.isExpanded ? '-' : '+'}
                            </button>



                        </div>
                        {this.state.isExpanded &&

                            <div className="row">
                                {_packagesSection}
                            </div>

                        }
                    </article>

                </div>
            </>
        );
    }
}

export default PackageCard;