import {useEffect, useState, useRef} from 'react';
import './Chat.css';
import chatBg from '../../img/chat-bg.png'

function Chat (props){
    const currentUser = props.currentUser;
    const [messageText, setMessageText] = useState("");
    const [file, setFile] = useState();
    const inputNode = useRef();
    const inputAttachmentNode = useRef();
    const { isChatPanelActive } = props;



    // handler functions START
    const fileSelectHandler = ()=>{
        inputAttachmentNode.current.click();
      }
      const fileChangeHandler = (e)=>{
        setFile(e.target.files[0]);
        console.log(e.target,file)
      }
      const messageTextHandler = e =>{
        setMessageText(e.target.value);
      }
    
      // function for sending message
      const sendTextMessage = (e) =>{
        const data = JSON.stringify({sentBy:currentUser,messageText:messageText});
        if(e.key === 'Enter' || e._reactName === "onClick"){
          if(messageText){
            props.SessionObj.signal({
              type:"text-message",
              data:data
            },(e)=>{
              if(!e){
                setMessageText("");
                inputNode.current.value="";
              }
            });
          }
          }
      }
    // handler functions END
    return(
        <div className={`chat-holder test ${isChatPanelActive ? "is-active" : ""}`} style={{backgroundImage:`url("${chatBg}")`}}>
          <div id="chat-panel" className="chat-messages-wrap">
            {
              props.messages.map((msg, i)=>{
                return(
                  <div key={i} className={`chat-message-wrap ${msg.sentBy === currentUser ? 'is-own-message' : ''}`}>
                    <p className="chat-message-text">{msg.messageText}</p>
                    <span className="chat-message-sender-name">{msg.sentBy}</span>
                  </div>
                )
              })
            }
          </div>

          <div className="chat-message-typing-wrap">
            <div className="chat-input-wrap">
              {/* <input type="file" style={{display:"none"}} ref={inputAttachmentNode} onChange={fileChangeHandler}></input>
              <i className="fa fa-paperclip chat-attach-file-button" onClick={fileSelectHandler}></i> */}
              <input
                className="chat-input"
                type="text"
                placeholder="Enter message"
                onChange={messageTextHandler}
                onKeyDown={sendTextMessage}
                ref={inputNode}
              />
              <button className="chat-send-button" onClick={sendTextMessage}>Send</button>
            </div>
          </div>
        </div>
    )
}

export default Chat;