import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";

export default class DevOps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: cookie.load('user_Id'),
            userName: cookie.load('user_Name'),
            AssignedUserName: ""
        };
        this.getQuestionaire = this.getQuestionaire.bind(this);

    }
    componentDidMount() {

        Service.GetUserByServiceId(9).then(res => {
            //console.log(res);
            this.setState({ AssignedUserName: res.UserName })
        })
    }
    startCall(CallingTo) {
        if (cookie.load('user_Id') == undefined) {
            this.props.history.push('login')
        }
        else
            window.call(CallingTo, this.state.userId)
    }
    getQuestionaire() {
        this.props.history.push('Questionnaire/9');
    }
    render() {
        return (
            <>
                <div className="hero parallax"
                    data-background="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/DevOps.png"
                    style={{ backgroundSize: "100% 100%", "background-image": "url(https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/DevOps.png" }}>
                    {/* <!-- Hero caption --> */}
                    <div id="main-hero" className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-5 signup-column has-text-left">
                                    <h1 className="title main-title text-bold is-2 text-center" style={{ marginTop: 140 }}>
                                        DEVOPS
                                    </h1>
                                    <h2 className="subtitle is-5 no-margin-bottom is-light text-center">
                                        SECURITY, COMPLIANCE AND PERFORMANCE AT YOUR FINGERTIPS
                                    </h2>
                                    <br />

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <!-- Clients --> */}
                </div>

                {/* <!-- Intro section --> */}
                <div className="section-title-wrapper has-text-centered" style={{ marginTop: 80 }}>
                    <div className="bg-number">1</div>
                    <h2 className="section-title-landing">
                        DevOps Made Easy
                    </h2>
                </div>
                <div className="content-wrapper" style={{ marginTop: 80 }}>
                    <div className="columns is-vcentered pb-40 pt-40">
                        <div className="column is-4 is-offset-1">
                            <div className="icon-subtitle"></div>
                            <div className="title quick-feature has-numbers text-bold">
                                <div>
                                    Automate Your Cloud
                                </div>
                            </div>
                            <div className="title-divider is-small"></div>
                            <span className="body-text" style={{ textAlign: "justify" }}>
                                <div className="Peragraph">
                                    <p>
                                        ManageX DevOps team helps define and identify a pragmatic approach to
                                        DevOps adoption across the business and technology layers.
                                        It focuses equally on bottomline and topline growth along
                                        with automation and cost optimization.
                                    </p>
                                </div>
                            </span>
                            <div className="pt-10 pb-10">

                                <div classNameName='row'>
                                    <button className="button btn-align btn btn-primary col-4" onClick={this.getQuestionaire}>Buy Now</button>
                                    {this.state.AssignedUserName != "" ?
                                        <div className='col-2'><button className="button btn-align btn btn-success" onClick={() => this.startCall(this.state.AssignedUserName)}>Talk to an expert</button></div>
                                        : ""}

                                </div>

                            </div>
                        </div>
                        <div className="column is-6 is-offset-1">
                            <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/services.png"
                                data-base-url="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/services.png" data-extension=".svg" alt="" />
                        </div>
                    </div>
                </div>

                <div id="services" className="section is-medium">
                    <div className="container">
                        {/*<!-- Title -->*/}
                        <div className="section-title-wrapper has-text-centered">
                            <div className="bg-number">2</div>
                            <h2 className="section-title-landing">
                                ManageX DevOps Features
                            </h2>
                            {/* <h4>Learn a solid coding basis in less than 6 weeks</h4> */}
                        </div>

                        <div className="content-wrapper">

                            <div className="columns is-vcentered is-multiline has-text-centered">
                                <div className="column is-3">
                                    <div className="wavy-icon-box">
                                        <div className="is-icon-reveal">
                                            <i className="im im-icon-Check"></i>
                                        </div>
                                        <div className="box-title">Assessment</div>
                                        {/* <p className="box-content is-tablet-padded">
                                   Assess an organisation’s existing environment
                                       </p> */}
                                    </div>
                                </div>
                                <div className="column is-3">
                                    <div className="wavy-icon-box">
                                        <div className="is-icon-reveal">
                                            <i className="im im-icon-Cloud-Settings"></i>
                                        </div>
                                        <div className="box-title">Optimization</div>
                                        {/* <p className="box-content is-tablet-padded">
                                   Optimise the existing solution and technical architecture.</p> */}
                                    </div>
                                </div>
                                <div className="column is-3">
                                    <div className="wavy-icon-box">
                                        <div className="is-icon-reveal">
                                            <i className="im im-icon-Code-Window"></i>
                                        </div>
                                        <div className="box-title">Code Security</div>
                                        {/* <p className="box-content is-tablet-padded">
                                   Code quality assessment and security coverage
                                   </p> */}
                                    </div>
                                </div>

                                <div className="column is-3">
                                    <div className="wavy-icon-box">
                                        <div className="is-icon-reveal">
                                            <i className="im im-icon-Loop"></i>
                                        </div>
                                        <div className="box-title">CI/CD = Configuration of complete CI/CD with DevSecOps </div>
                                        {/* <p className="box-content is-tablet-padded">Code quality assessment and security coverage</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Process section --> */}
                <div className="section is-medium section-feature-grey">
                    <div className="container">
                        <div className="section-title-wrapper">
                            <div className="bg-number">3</div>
                            <h2 className="title section-title has-text-centered dark-text text-bold">
                                Our DevOps Capabilities
                            </h2>
                        </div>

                        <div className="content-wrapper">
                            <div className="grid-clients three-grid">
                                <div className="columns is-vcentered">
                                    <div className="column"></div>
                                    <div className="column">
                                        <a><img className="client" src="https://vvm-prod-cdn.azureedge.net/managex/assets/DevOpsIcon/AWSLambda.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="https://vvm-prod-cdn.azureedge.net/managex/assets/DevOpsIcon/Picture1.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="https://vvm-prod-cdn.azureedge.net/managex/assets/DevOpsIcon/Picture2.png" alt="" /></a>
                                    </div>
                                    <div className="column"></div>
                                </div>
                                <div className="columns is-vcentered is-separator">
                                    <div className="column"></div>
                                    <div className="column">
                                        <a><img className="client" src="https://vvm-prod-cdn.azureedge.net/managex/assets/DevOpsIcon/Picture3.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="https://vvm-prod-cdn.azureedge.net/managex/assets/DevOpsIcon/Picture4.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="https://vvm-prod-cdn.azureedge.net/managex/assets/DevOpsIcon/Picture5.png" alt="" /></a>
                                    </div>
                                    <div className="column"></div>
                                </div>
                                <div className="columns is-vcentered is-separator">
                                    <div className="column"></div>
                                    <div className="column">
                                        <a><img className="client" src="https://vvm-prod-cdn.azureedge.net/managex/assets/DevOpsIcon/Picture6.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="https://vvm-prod-cdn.azureedge.net/managex/assets/DevOpsIcon/Picture7.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="https://vvm-prod-cdn.azureedge.net/managex/assets/DevOpsIcon/Picture8.png" alt="" /></a>
                                    </div>
                                    <div className="column"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>


        )
    }
}