
// dev new
// export const BASE_URl_Admin_Azure = "https://mx-auth-api-dev.azurewebsites.net/api/";
// export const BASE_URl_Admin_Azure_Payments = "https://mx-payments-api-dev.azurewebsites.net/api/";
// export const BASE_URl_Admin_Azure_JiraProjects = "https://mx-jira-api-dev.azurewebsites.net/api/";
// // export const BASE_URl_Admin_Azure_Resources = "https://mx-resources-api-dev.azurewebsites.net/api/";
// export const BASE_URl_Admin_Azure_Email = "https://mx-email-api-dev.azurewebsites.net/api/";
// export const BASE_URl_Admin_Azure_Reports = "https://mx-reports-api-dev.azurewebsites.net/api/";
// export const COMMUNICATION_API = "https://mx-communication-api-dev.azurewebsites.net/api/";
// export const BASE_URl_Admin_Azure_Services = "https://mx-services-api-dev.azurewebsites.net/api/";
// export const ADMIN_EMAIl = "managex@vaporvm.com";
// export const BASE_URl_Admin_Azure_Chat = "https://mx-chat-api.azurewebsites.net/chat/"
// export const BASE_URl_Pdf_API = "https://mx-prod-pdf-service.azurewebsites.net/"


//prod
export const BASE_URl_Admin_Azure = "https://mx-auth-api.azurewebsites.net/api/";
export const BASE_URl_Admin_Azure_Payments = "https://mx-payments-api.azurewebsites.net/api/";
export const BASE_URl_Admin_Azure_JiraProjects = "https://mx-jira-api.azurewebsites.net/api/";
// export const BASE_URl_Admin_Azure_Resources = "https://mx-resources-api.azurewebsites.net/api/";
export const BASE_URl_Admin_Azure_Email = "https://mx-email-api.azurewebsites.net/api/";
export const BASE_URl_Admin_Azure_Reports = "https://mx-reports-api.azurewebsites.net/api/";
export const COMMUNICATION_API = "https://mx-communication-api.azurewebsites.net/api/";
export const BASE_URl_Admin_Azure_Services = "https://mx-services-api.azurewebsites.net/api/";
export const ADMIN_EMAIl = "managex@vaporvm.com";
export const BASE_URl_Admin_Azure_Chat = "https://mx-chat-api.azurewebsites.net/chat/"
export const BASE_URl_Pdf_API = "https://mx-prod-pdf-service.azurewebsites.net/"
