import {useState, useEffect, useRef} from "react";
import './videoComponent.css';
import { connect } from "react-redux";
import cookie from 'react-cookies';
import Service from "../../components/Service/Api/Api";
import { setMainSession } from "../../store/actions/mainSessionActions";
import Swal from "sweetalert2";


const VideoComponent = (props) =>{
    //  Use Effect hook on mount to get the support status
    const { supportStatsReducer } = props;
    const { mainSessionReducer } = props;
        const { mainSessionSignalObj, mainSessionObj } = mainSessionReducer;

        function getSupportStatus(){
            if(mainSessionObj){
                const { apiKey, sessionId, token } = mainSessionObj;
                const {mainSessionSignalObj} = mainSessionReducer;
                if(apiKey !=="apiKey" && sessionId !== "sessionId" && token !== "token"){
                    mainSessionSignalObj({
                        data: JSON.stringify({sendername:cookie.load("user_Name")}),
                        type:"SupportAcknowledgement"
                    })
                }
                else{
                    // alert("Main session is not connected")
                }
            }
        }
        function callSupport(){
            Swal.fire({
                title: 'Call ManageX support?',
                text: "You are about to call the ManageX support team.",
                iconHtml: '<img src="https://uxwing.com/wp-content/themes/uxwing/download/communication-chat-call/phone-call-icon.png"/>',
                customClass: {
                    icon: 'custom-swal-icon'
                },
                showCancelButton: true,
                confirmButtonColor: 'var(--bg-color-pr)',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Call now'
              }).then((result) => {
                if (result.isConfirmed) {
                    if(mainSessionObj){
                        const { apiKey, sessionId, token } = mainSessionObj;
                        const {mainSessionSignalObj} = mainSessionReducer;
                        if(apiKey !=="apiKey" && sessionId !== "sessionId" && token !== "token"){
                            cookie.save("guestName",cookie.load("user_Name"));
                            cookie.save("guestEmail",cookie.load("user_Id"));
                            cookie.save("guestPhone","unavailable");
                            Service.getSupportAdmins().then(res=>{
                                const signalData = {
                                    type: "supportCallGuest",
                                    data: JSON.stringify({
                                        userName: cookie.load("user_Name"),
                                        senderEmail: cookie.load("user_Id"),
                                        senderPhone:"unavailable",
                                        receivers:res
                                    })
                                }
                                mainSessionSignalObj(signalData)
                            });
                        }
                        else{
                            // alert("Main session is not connected")
                        }
                    }
                }
              })
        }
    useEffect(()=>{
    })

    return(
        <>
        <div tabIndex={0} title="Call ManageX support" class={`body call-support ${props.className ? props.className : ""}`} onClick={callSupport}>
            <p class="m-b-20">
                <i class={"zmdi zmdi-hc-3x col-amber zmdi-headset-mic clr-green"} ></i>
            </p>
            <span>Click to call</span>
            <h3 class="m-b-10">Support</h3>
        </div>
        </>
    )
}
const mapStateToProps = (state=>{
    return state;
})
export default connect(mapStateToProps)(VideoComponent);