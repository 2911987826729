import React from 'react'
import TextLoader from '../CustomerSupport/TextLoader'

export default function Card(props) {
    return (
        <>
            <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">

                <div className="card text-center">
                    <div className={`body ${props.className ? props.className : ""}`}>
                        <p class="m-b-20">
                            <i class={"zmdi zmdi-hc-3x col-amber " + props.img} ></i>
                        </p>
                        <span>{props.heading}</span>
                        {
                            props.qty === -1 ? <h3 className='dot-loader'>
                                <div className='dot-loader-dot'></div>
                                <div className='dot-loader-dot'></div>
                                <div className='dot-loader-dot'></div>
                            </h3>
                            :
                            <h3>{props.qty}</h3>
                        }
                        {/* {
                            props.qty === -1 ? <h5 className='mt-2 mb-2'>Calculating...</h5>
                            :
                            <h3 class="m-b-10">{props.qty === -1 ? <TextLoader small loaderTitle="calculating"/>  : props.qty}</h3>
                        } */}
                    </div>
                </div>
            </div>
        </>
    )
}
