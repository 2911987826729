
import React from "react";
import cookie from "react-cookies";
import Service from "../../../components/Service/Api/Api";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import CustomLoader from "../../Loader/Loader";
import { connect } from "react-redux";
import { setMainSession } from "../../../store/actions/mainSessionActions";
import InputComponent from "../../inputComponents/Input";
// import image from '../../img/sign-up-graphic.png';
import Logo from '../../../img/logo.png';

import Swal from "sweetalert2";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
// import "../Login/Login.css";



// import loader from '../Loader/index'
const initialState = {
  email: "",
  password: "",
  loading: false,
  token: "", //cookie.load("bareer_Token"),
  emailError: "",
  passwordError: "",
  customLoader: false,
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }


  componentDidMount() {
    // alert("Login component")
    // this.clearCookiesAndSession();

    let _tokenValue = cookie.load("bareer_Token");
    if (_tokenValue !== undefined && _tokenValue !== "") {
      this.props.history.push("/dashboard");
    }
    // const { token } = this.state;
    // alert(token)
    // if (token !== undefined && token !== "") {
    //   var strURL = this.props.history.location.pathname;
    //   // if (/\d/.test(strURL)) {
    //   //   var last = strURL.substring(strURL.lastIndexOf("/") + 1, strURL.length);
    //   //   this.props.history.push("/Questionnaire/" + last);
    //   // } else {
    //   //   // this.props.history.push("/dashboard");
    //   // }

    //   this.props.history.push("/dashboard");
    // }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  OnValidate = (e) => {
    let emailError = "";
    let passwordError = "";

    if (!this.state.email.includes("@") && !this.state.email.includes(".")) {
      emailError = "Plesae enter your valid email";
    }
    if (
      !this.state.password.match(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
      )
    ) {
      passwordError = "Please enter your valid password";
    }
    if (emailError || passwordError) {
      this.setState({ emailError, passwordError, loading: true });

      return false;
    } else {
      return true;
    }
  };

  validataEmail = () => {
    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (this.state.email.match(mailformat)) {
      return true;
    }
    return false;
  }


  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.email && this.state.password !== '') {
      if (this.validataEmail()) {

        try {
          this.setState({ customLoader: true });
          const { email, password } = this.state;
          if (email !== "" && password !== "") {
            const isValidated = this.OnValidate();
            if (isValidated) {
              var data = {
                username: this.state.email,
                password: this.state.password,
              };
              Service.login(data)
                .then((res) => {
                  let allowedRoles = ["CompanyUser", "CompanyAdmin", "User"];
                  if (res && res.api_key) {
                    Service.GetSession(res.user.userName).then((responce) => {
                      if (responce) {
                        // New code START
                        // Saving the main session details in the main session Reducer
                        this.props.dispatch(setMainSession({ apiKey: responce.apiKey, token: responce.token, sessionId: responce.sessionId }));

                        // New code END
                        cookie.save("apiKey", responce.apiKey, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });
                        cookie.save("sessionId", responce.sessionId, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });
                        cookie.save("token", responce.token, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                      }
                      if (allowedRoles.includes(res.role[0]) && res.user.emailConfirmed) {
                        cookie.save("bareer_Token", res.api_key, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save("user_Id", res.user.userName, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });
                        cookie.save("company_Id", res.companyid, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save("Id", res.user.id, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save(
                          "user_Name",
                          res.user.firstName + " " + res.user.lastName,
                          {
                            path: "/",
                            maxAge: 5 * 3600,
                          }
                        );

                        cookie.save("Orgnization", res.user.comapnyName, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save("User_Role", res.user.userRole, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save("admin_email", res.adminemail, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });
                        // ======================================================================================================================
                        if (res.istwofactorenable) {
                          this.setState({ customLoader: false });
                          cookie.save("isTwoFactorEnable", true, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          cookie.save("isVerified", false, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          cookie.save("User_Email", res.user.email, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          cookie.save("TFCode", res.authtoken, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          this.props.history.push("/Otp");
                        } else {
                          this.setState({ customLoader: false });
                          var queryString = this.props.location.pathname.split("/");
                          var RedirectUrlId = queryString[queryString.length - 1];
                          var returnUrl = localStorage.getItem("RedirectUrl");
                          // if (
                          //     RedirectUrlId &&
                          //     RedirectUrlId.toLowerCase() != "login"
                          // ) {
                          //     this.props.history.push(
                          //         "/Questionnaire/" + RedirectUrlId
                          //     );
                          //     // further if else for navigation required discussion required with usman
                          // } else if (returnUrl) {
                          //     window.location.href = returnUrl;
                          // } else {
                          //     this.props.history.push("/dashboard");
                          // }
                          this.props.history.push("/dashboard");
                        }
                      } else if (allowedRoles.includes(res.role[0]) && !res.user.emailconfirmed) {
                        this.setState({ customLoader: false });
                        Swal.fire({
                          icon: "error",
                          title: "Email Address Verification",
                          text: "Please activate your account first.",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        this.setState({ customLoader: false });
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Please enter valid credentials to login!",
                          showConfirmButton: false,

                          timer: 1500,
                        });
                      }
                    });
                  }
                })
                .catch((err) => {
                  this.setState({ customLoader: false });
                  if (err.toString().includes(401)) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Authentication error. Please contact to administrator",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong. Please try again later.",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                });
            } else {
              this.setState({ customLoader: false });
            }
          } else {
            this.setState({ customLoader: false });
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Please enter Email / Password first.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (e) {
          this.setState({ customLoader: false });
          console.log(e);
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Enter Valid Email.',
          showConfirmButton: false,
          timer: 1500
        });
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid email or password.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  render() {
    return (
      <>
        {this.state.customLoader ? <CustomLoader /> : null}
        <div class="authentication">
          <div class="container">
            <div class="col-md-12 content-center">
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="company_detail">
                    <h4 class="logo"><img src={Logo} alt="" /></h4>
                    <h3>Transforming your <strong>Business</strong></h3>
                    <p>ManageX is a top-notch platform for solving mission-critical roadblocks and problems from Medium to Large Enterprises to Fortune 500 companies.</p>
                    <div class="footer">
                      <ul class="social_link list-unstyled">
                        <li><a href="https://twitter.com/managexae" title="LinkedIn"><i class="zmdi zmdi-linkedin"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/managexae/" title="Facebook"><i class="zmdi zmdi-facebook"></i></a></li>
                        <li><a href="https://www.facebook.com/managex.ae" title="Twitter"><i class="zmdi zmdi-twitter"></i></a></li>
                      </ul>
                      <hr />
                      <ul>
                        <li><a href="https://www.managex.ae/contact-us/" target="_blank">Contact Us</a></li>
                        <li><a href="https://www.managex.ae/about-us/" target="_blank">About Us</a></li>
                        <li><a href="https://www.managex.ae/privacy-policy/" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://www.managex.ae/terms-of-services/">Terms Of Services</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-12 offset-lg-1">
                  <div class="card-plain text-center">
                    <div class="header">
                      <h5>Log in</h5>
                    </div>
                    <form class="form" onSubmit={this.onSubmit}>
                      <div class="input-group">
                        {/* <input type="text" class="form-control" placeholder="User Name" /> */}
                        <InputComponent
                          inputType="inputField"
                          type="email"
                          name="email"
                          label="Email"
                          error={this.state.emailError}
                          floatingLabel
                          variant="primary"
                          onChange={this.onChange}
                          classes="form-control"
                        />
                        <span class="input-group-addon"><i class="zmdi zmdi-account-circle"></i></span>
                      </div>
                      <div class="input-group">
                        <InputComponent
                          inputType="inputField"
                          type="password"
                          name="password"
                          label="Password"
                          error={this.state.passwordError}
                          floatingLabel
                          variant="primary"
                          onChange={this.onChange}
                          classes="form-control"
                        />
                      </div>
                      <div class="input-group">
                        <InputComponent
                          inputType="button"
                          fullWidth
                          type="submit"
                          name="SIGN IN"
                          variant="secondary"
                          onClick={this.onSubmit}
                          onSubmit={this.onSubmit}
                          classes="mb-2 mt-4"
                        />
                      </div>
                      <div class="input-group">
                        <InputComponent
                          inputType="button"
                          fullWidth
                          type="submit"
                          name="SIGN UP"
                          variant="primary"
                          onClick={() => this.props.history.push("/SignUp")}
                          onSubmit={this.onSubmit}
                          classes="mt-1 mb-4"
                        />
                      </div>
                    </form>
                    {/* <div class="footer">
                      <a onClick={(e) => { e.preventDefault(); this.props.history.push("/SignUp") }} class="btn btn-primary btn-simple btn-round btn-block">SIGN UP</a>
                    </div> */}
                    {/* <a href="forgot-password.html" class="link">Forgot Password?</a> */}
                    {/* <Link className="clr-sc hover:clr-sc" to="/ForgetPassword">
                      Forget Password ?
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
}

export default withRouter(connect(mapStateToProps, null)(Login));
