import React from 'react';
import './statsContainer.css'
import TotalProject from './totalProjectComponent';
import InProgressComponent from './inProgressComponent';
import CompletedProjectComponent from './completedProjectComponent';
import { connect } from "react-redux";
import { loadstats } from "../../store/actions/statsAction";
import Counter from './counter';
import VideoComponent from './videoComponent';
import ChartComponent from './chartComponent';




import MiniLoader from "./miniLoader";


import Card from '../Billing/Card';

class StatsContainer extends React.Component {

    componentDidMount() {
        this.props.LoadStats();
    }
    render() {
        const newDesign =
            <div className='container-fluid p-0'>
                <div className='row mb-4 m-0 text-center justify-content-center'>
                    <Card img="zmdi-assignment clr-yellow" heading={"Total Projects"} qty={this.props.redux.TotalProgress} />
                    <Card img="zmdi-time clr-green" heading={"Projects in progress"} qty={this.props.redux.Inprogress} />
                    <Card img="zmdi-check-all clr-blue" heading={"Completed projects"} qty={this.props.redux.CompletedProgress} />

                    <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
                        <div className='card text-center'>
                            <VideoComponent status={"online"} />
                        </div>
                    </div>
                </div>
            </div>

        return (
            <>
                {newDesign}
            </>
        );

    }

}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        LoadStats: () => dispatch(loadstats(ownProps.userid))
    }

};
const mapStateToProps = (state) => {
    return {
        redux: state.RefreshStatReducer
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(StatsContainer);