import React from "react";
export default class AboutUs extends React.Component {
    render() {
        return (
            <>
                <div className="container-fluaid">
                    <div id="main-hero" className="hero-body" style={{ backgroundColor: "white" }}>
                        <div className="container has-text-centered">
                            <div className="columns is-vcentered">
                                <div className="column is-6 is-offset-3 has-text-centered is-subheader-caption">
                                    <h1 className="title is-2">
                                        About Us
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="container-fluaid">
                            <div className="columns is-vcentered is-multiline">
                                <div className="column is-8 is-offset-2 is-relative is-centered-portrait">
                                    <div className="" id="Palyer">
                                        <video id="hero-player" id="video2" className="bulkit-player"
                                            data-poster=""
                                            data-demo-poster="https://vvm-prod-cdn.azureedge.net/managex/assets/img/demo/video/poster-3.jpg" controls autoplay>
                                            <source src="https://vvm-prod-cdn.azureedge.net/managex/assets/video/Intro.mp4" type="video/mp4" />
                                            <source src="https://vvm-prod-cdn.azureedge.net/managex/assets/video/Intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                </div>

                                <div className="column is-4 is-offset-2">
                                    <div className="side-title mb-10">
                                        <h3 className="title is-5">Our Story</h3>
                                    </div>
                                    <p className="side-paragraph is-size-6 " style={{ textAlign: "justify" }}>
                                        ManageX was born out of the need to simplify the process of
                                        onboarding engineering resources for organizational needs. With
                                        countless hours of development going into designing a swift user journey, ManageX allows our customers
                                        to quickly get access to the best IT resources in the region at the click of  a button.
                                    </p>
                                </div>

                                <div className="column is-4">
                                    <div className="side-title mb-10">
                                        <h3 className="title is-5">How We Work</h3>
                                    </div>
                                    <p className="side-paragraph is-size-6" style={{ textAlign: "justify" }}>
                                        ManageX used its years of experience in providing digital transformation services to standardize scope of works into simple templates.
                                        Simply select the service that suits you best and get the pricing for your needs on the spot.
                                    </p>
                                </div>

                                <div className="column is-4 is-offset-2">
                                    <div className="side-title mb-10">
                                        <h3 className="title is-5">Our Process</h3>
                                    </div>
                                    <p className="side-paragraph is-size-6 " style={{ textAlign: "justify" }}>
                                        Leaning highly on service automation, ManageX assigns the right technical resources based on your requirements and allows you to directly call and speak with them from within the application. Our CIO's dashboard
                                        allows you to monitor all your projects from a single pane of glass and track their progress.
                                    </p>
                                </div>

                                <div className="column is-4">
                                    <div className="side-title mb-10">
                                        <h3 className="title is-5">Our Values</h3>
                                    </div>
                                    <p className="side-paragraph is-size-6" style={{ textAlign: "justify" }}>
                                        At the core of ManageX, we believe in
                                        providing transparency, swiftness and cost efficiency to our customers.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/*<!------Section Three----->*/}
                <div id="about" className="section is-app-grey is-medium">
                    <div className="container">
                        {/* <!-- Title --> */}
                        <div className="section-title-wrapper has-text-centered">
                            <div className="bg-number">3</div>
                            <h2 className="section-title-landing">
                                Our Process
                            </h2>
                            <h3 className="section-subtitle-alt">Before creation, there's thinking. Our process is sharp and let us craft
                                the best quality.</h3>
                        </div>

                        {/* <!-- Process steps --> */}
                        <div className="content-wrapper">
                            <div className="columns is-vcentered">
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block has-line">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Over"></i>
                                                {/* <div className="process-number">1</div> */}
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">1</div>
                                            <div className="details">
                                                <div className="motto">Sign up</div>
                                                <p className="description">Register using your company ID and get access to our CIO's dashboard.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block has-line">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Around"></i>
                                                {/* <div className="process-number">2</div> */}
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">2</div>
                                            <div className="details">
                                                <div className="motto">Select Service</div>
                                                <p className="description">Pick a service from our vast digital transformation portfolio</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Refresh"></i>
                                                {/* <div className="process-number">3</div> */}
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">3</div>
                                            <div className="details">
                                                <div className="motto">Problem Solved</div>
                                                <p className="description">Use our secure channel to pay online or via a pay order and a subject matter expert is assigned to your project within 5 minutes</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!---SECTION 3------>*/}
                <div className="section is-medium parallax is-cover is-relative"
                    data-background="/assets/img/photos/CustomImages/O365M.jpg"
                    data-color="#000"
                    data-color-opacity="0.7" data-demo-background="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/code_review.jpg">

                    <div className="parallax-overlay"></div>
                    <div className="container">
                        <div className="section-title-wrapper">
                            <div className="bg-number">3</div>
                            <h2 className="title section-title has-text-centered light-text text-bold">
                                Why ManageX?
                            </h2>
                        </div>

                        <div className="content-wrapper">
                            <div className="columns is-vcentered has-text-centered">
                                <div className="column is-4">
                                    <div className="parallax-counter">
                                        <div className="counter-icon">
                                            <i className="im im-icon-Hotel"></i>
                                        </div>
                                        <div className="counter counter-number text-bold">100</div>
                                        <div className="counter-text">Customers</div>
                                    </div>
                                </div>
                                <div className="column is-4">
                                    <div className="parallax-counter">
                                        <div className="counter-icon">
                                            <i className="im im-icon-Cloud-Email"></i>
                                        </div>
                                        <div className="counter counter-number text-bold">4300</div>
                                        <div className="counter-text">Projects</div>
                                    </div>
                                </div>
                                <div className="column is-4">
                                    <div className="parallax-counter">
                                        <div className="counter-icon">
                                            <i className="im im-icon-Big-Data"></i>
                                        </div>
                                        <div className="counter counter-number text-bold">10000</div>
                                        <div className="counter-text">Monitored assets</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!------section 4------> */}
                <div className="section is-medium section-feature-grey">
                    <div className="container">
                        <div className="section-title-wrapper">
                            <div className="bg-number"></div>
                            <h2 className="title section-title has-text-centered dark-text text-bold">
                                Clients grid
                            </h2>
                        </div>
                        {/** End of Section */}
                        <div className="content-wrapper">
                            <div className="grid-clients three-grid">
                                <div className="columns is-vcentered">
                                    <div className="column"></div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/accenture.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/alain.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/damac.png" alt="" /></a>
                                    </div>
                                    <div className="column"></div>
                                </div>
                                <div className="columns is-vcentered is-separator">
                                    <div className="column"></div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/dell.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/du.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/edcon.png" alt="" /></a>
                                    </div>
                                    <div className="column"></div>
                                </div>
                                <div className="columns is-vcentered is-separator">
                                    <div className="column"></div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/gbm.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/im.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/ms.png" alt="" /></a>
                                    </div>
                                    <div className="column"></div>
                                </div>
                                <div className="columns is-vcentered is-separator">
                                    <div className="column"></div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/nokia.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/p_g.png" alt="" /></a>
                                    </div>
                                    <div className="column">
                                        <a><img className="client" src="assets/Small/pwc.png" alt="" /></a>
                                    </div>
                                    <div className="column"></div>
                                </div>
                            </div>
                        </div>
                        {/** End of Section */}
                    </div>
                </div>
            </>
        )
    }
}

