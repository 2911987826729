import React from "react";
class PayOrder extends React.Component {
    render() {
        return(
            <>
            <div className="hero is-fullheight is-feature-grey">                    
                    {/* <!-- Hero caption --> */}
                    <div className="hero-body ">
                        <div className="container has-text-centered">
                            <div className="columns is-vcentered">
                                <div className="column is-8 is-offset-2 has-text-centered">
                                    <h1 className="parallax-hero-title">
                                        Pay Order
                                            </h1>
                                    <h2 className="subtitle is-5">
                                        Hover the navbar item to make the menu appear.
                                            </h2>
                                    <br />
                                    <p className="has-text-centered">
                                        <a href="#" className="button button-cta btn-align primary-btn rounded z-index-2">
                                            Get Started
                                                    </a>
                                    </p>
                                    {this.props.page}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default PayOrder;