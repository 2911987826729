import React from "react";
import Service from '../../Service/Api/Api'
import cookie from "react-cookies";
import queryString from 'query-string'
import Swal from "sweetalert2";
import { passwordChangeTemplate } from "../../EmailTemplates/passwordChangeTemplate";
import InputComponent from "../../inputComponents/Input";
import Logo from '../../../img/logo.png';
import { validatePassword } from "../../../common/validatePassword.utils";
import Loader from "../../Loader/Loader";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

export default class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userid: '',
            token: '',
            password: '',
            newpassword: '', 
            isLoader:false
        }
    }

    componentDidMount() {
        this.ValidateRequest();
    }

    ValidateRequest() {
        try {
            const queryParam = queryString.parse(this.props.location.search);
            this.setState({ userid: queryParam.userId });
            this.setState({ token: queryParam.Token });
        }
        catch (e) {
            alert(e);
            //this.setState({ isEmailVerified : false});
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    ShowMessage = (MsgPayload) => {
        try {
            Swal.fire({
                icon: MsgPayload.icon,
                title: MsgPayload.title,
                text: MsgPayload.text,
                showConfirmButton: false,
                timer: 1500
            });
        }
        catch (e) {
            console.log(e + " : in ShowMessage function")
        }
    }

    OnSubmitRequest = e => {
        e.preventDefault();
        if(validatePassword(this.state.password)){
            if (this.state.password === "") {
                var payload = {
                    icon: 'error',
                    title: 'Error',
                    text: 'Please enter password first'
                };
                this.ShowMessage(payload);
            }
            else if (this.state.newpassword === "") {
                var payload = {
                    icon: 'error',
                    title: 'Error',
                    text: 'Please enter confirm password.'
                };
                this.ShowMessage(payload);
            }
            else if (this.state.password != this.state.newpassword) {
                var payload = {
                    icon: 'error',
                    title: 'Error',
                    text: 'Password and confirm password must be same.'
                };
                this.ShowMessage(payload);
            }
            else {
                try {
                    this.setState({ isLoader: true });
                    var payload = {
                        userid: this.state.userid,
                        token: this.state.token,
                        newpassword: this.state.newpassword
                    };
                    Service.ResetPassword(payload).then(res => {
                        if (res.status === "Success") {
                            this.setState({ isLoader: false });
                            var payload = {
                                icon: 'success',
                                title: 'Password changed successfully',
                                text: 'Please wait for the admin approval. you will get confirmation email shortly.'
                            };
                            var EamilData = new FormData();
                            EamilData.append('ToEmail', this.state.email);
                            EamilData.append('Subject', 'Password change');
                            var _bodyTemplate = passwordChangeTemplate;
                            var Replaceversion = _bodyTemplate.replace("{User}", res.name).
                                replace("{EmailAddress}", res.email).replace("{ResetLink}", '<a href="http://' + window.location.host + '/#/login">click here</a>');
                            EamilData.append('Body', Replaceversion);
                            Service.SendEmail(EamilData);
                            Swal.fire({
                                title: 'Password changed successfully',
                                text: 'You can login now with new password.',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Ok',
                                //cancelButtonText: 'No, keep it'
                            }).then((result) => {
                                this.props.history.push("/login");
                            });
                        }
                        else {
                            var payload = {
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Error occured while processing your request.'
                            };
                            this.ShowMessage(payload);
                            this.setState({ isLoader: false });
                        }
                    }).catch(err => {
                        this.setState({ isLoader: false });
                        console.log(err)
                        var payload = {
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Expired Link.'
                        };
                        this.ShowMessage(payload);
                    });
                }
                catch (e) {
                    alert(e);
                }
            }
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Invalid new password',
                text: 'Password must be 8 digit long and should have at least one uppercase character, one lowercase character, one digit and one special character.',
                showConfirmButton: true,
            });
        }
    };

    render() {
        return (
            <>
                {
                    this.state.isLoader ?
                    <Loader/>
                    : null
                }
                {/* <div className="hero-body ">
                    <div className="container has-text-centered">
                        <div className="columns is-vcentered">
                            <div className="col-12">
                                <div className="row">
                                    <div className='col-8'>
                                        <div className="card">
                                            <div className="card-header"><h3>Reset Password</h3></div>
                                            <div className="card-body">
                                                <form >
                                                    <div id="signup-form" className="login-form animated preFadeInLeft fadeInLeft">
                                                        <div className="field pb-10">
                                                            <div className="control has-icons-right">
                                                                <input
                                                                    type="password"
                                                                    className="input is-medium has-shadow"
                                                                    placeholder="Enter password"
                                                                    name="password"
                                                                    required
                                                                    onChange={this.handleChange}
                                                                    value={this.state.password}
                                                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$"
                                                                />
                                                                <span className="icon is-medium is-right">
                                                                    <i className="sl sl-icon-lock"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="field pb-10">
                                                            <div className="control has-icons-right">
                                                                <input
                                                                    type="password"
                                                                    className="input is-medium has-shadow"
                                                                    placeholder="Confirm password"
                                                                    name="newpassword"
                                                                    required
                                                                    onChange={this.handleChange}
                                                                    value={this.state.newpassword}
                                                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$"
                                                                />
                                                                <span className="icon is-medium is-right">
                                                                    <i className="sl sl-icon-lock"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p className="control login">
                                                            <button className="button button-cta primary-btn btn-align-lg is-bold is-fullwidth rounded raised no-lh"
                                                                onClick={this.OnSubmitRequest} type="submit" value="Reset Password">Request
                                                            </button>
                                                        </p>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="authentication">
                    <div class="container">
                        <div class="col-md-12 content-center">
                            <div className="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="company_detail">
                                        <h4 class="logo"><img src={Logo} alt="" /></h4>
                                        <h3>Reset password via email</h3>
                                        <div class="footer">
                                        <ul class="social_link list-unstyled">
                                            <li><a href="https://twitter.com/managexae" title="LinkedIn"><i class="zmdi zmdi-linkedin"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/managexae/" title="Facebook"><i class="zmdi zmdi-facebook"></i></a></li>
                                            <li><a href="https://www.facebook.com/managex.ae" title="Twitter"><i class="zmdi zmdi-twitter"></i></a></li>
                                        </ul>
                                        <hr />
                                        <ul>
                                            <li><a href="https://www.managex.ae/contact-us/" target="_blank">Contact Us</a></li>
                                            <li><a href="https://www.managex.ae/about-us/" target="_blank">About Us</a></li>
                                            <li><a href="https://www.managex.ae/privacy-policy/" target="_blank">Privacy Policy</a></li>
                                            <li><a href="https://www.managex.ae/terms-of-services/">Terms Of Services</a></li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-12 offset-lg-1">
                                    <div class="card-plain ">
                                        <div class="header">
                                            <h5><strong>Reset Password</strong></h5>
                                            <p className="my-3">Enter a new password.</p>
                                        </div>
                                        <div class="body">
                                            <form>
                                                <div class="input-group">
                                                    {/* <input type="text" class="form-control" placeholder="Enter Email" /> */}
                                                    <InputComponent
                                                        inputType="inputField"
                                                        type="password"
                                                        name="password"
                                                        label="Enter password"
                                                        error={this.state.emailError}
                                                        floatingLabel
                                                        variant="primary"
                                                        onChange={this.handleChange}
                                                        classes="form-control"
                                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$"
                                                    />
                                                </div>
                                                <div class="input-group">
                                                    {/* <input type="text" class="form-control" placeholder="Enter Email" /> */}
                                                    <InputComponent
                                                        inputType="inputField"
                                                        type="password"
                                                        name="newpassword"
                                                        label="Confirm password"
                                                        error={this.state.emailError}
                                                        floatingLabel
                                                        variant="primary"
                                                        onChange={this.handleChange}
                                                        classes="form-control"
                                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$"
                                                    />
                                                </div>
                                                <div className="input-group">
                                                    <InputComponent
                                                        inputType="button"
                                                        fullWidth
                                                        type="submit"
                                                        name="RESET"
                                                        variant="secondary"
                                                        classes="mb-1"
                                                        onClick={this.OnSubmitRequest}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}