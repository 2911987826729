import React from 'react';
import { connect } from "react-redux";
import CustomLoader from "../Loader/section/SectionLoader";

class TotalProjectComponent extends React.Component{

    render(){

        return(
            
            <div className="column is-4">
                <div className="card-counter">
                    <div className="flex-card light-bordered hover-inset">
                        <div className="round-icon">
                            <i className="material-icons">folder</i>
                        </div>
                        {
                            this.props.totalCount > -1 || this.props.totalProjectCount > -1 ? 
                            <div className="counter counter-number text-bold">{this.props.totalCount > 0 ? this.props.totalCount : this.props.totalProjectCount}</div>
                            : 
                            <CustomLoader />
                        }
                        {/* <div className="counter counter-number text-bold">{this.props.totalCount > 0 ? this.props.totalCount : this.props.totalProjectCount}</div> */}
                        <div className="counter-text">Total Project</div>
                    </div>
                </div>
            </div>

        );

    }

}

const mapStateToProps = state =>{
    return{
        totalCount : state.TotalProgress
    }
};

export default connect(mapStateToProps)(TotalProjectComponent);