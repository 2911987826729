import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { connect } from "react-redux";
import { Doughnut } from 'react-chartjs-2';
import MiniLoader from './miniLoader';
import './chartComponent.css'

ChartJS.register(ArcElement, Tooltip);

class ChartComponent extends React.Component{
    render(){
        const data={
            labels: [ 'Completed Projects', 'In Progress Projects'],
            datasets: [
              {
                label: 'All Projects',
                data: [this.props.completedCount, this.props.inProgressCount],
                backgroundColor: [
                  '#80c44d',
                  '#010138'
                ],
                borderColor: [
                  'rgba(255, 255, 255, 1)',
                  'rgba(255, 255, 255, 1)',
                  'rgba(255, 255, 255, 1)'
                ],
                borderWidth: 1,
              },
            ],
          }
          const billingData={
            labels: [ 'Quoted Projects', 'Paid Projects'],
            datasets: [
              {
                label: 'All Projects',
                data: [ this.props.quotedQty,this.props.paidQty],
                backgroundColor: [
                  '#010138',
                  '#80c44d',
                  '#b5b5b5'
                ],
                borderColor: [
                  'rgba(255, 255, 255, 1)',
                  'rgba(255, 255, 255, 1)',
                  'rgba(255, 255, 255, 1)'
                ],
                borderWidth: 1,
              },
            ],
          }
          return (
            this.props.isBilling ?
            <>
            <div className="chart-size">
              <Doughnut data={billingData} />
            </div>
            <div className='d-flex justify-content-center flex-wrap' style={{gap:"10px"}}>
                <div className='d-flex align-items-center justify-content-center' style={{gap:"2px",fontSize:"10px"}}><div class="counter-color secondary" ></div>Paid</div>
                {/* <div className='d-flex align-items-center justify-content-center' style={{gap:"2px",fontSize:"10px"}}><div class="counter-color" ></div>Quoted</div> */}
                <div className='d-flex align-items-center justify-content-center' style={{gap:"2px",fontSize:"10px"}}><div class="counter-color primary" ></div>Quoted</div>
            </div>
            </>
            :
            <>
            <div className="chart-size">
                {
                   this.props.completedCount >= 0 && this.props.inProgressCount >= 0 ? <Doughnut data={data} />
                   :
                   <p class="m-b-20">
                      <i style={{fontSize:"88px"}} class={"zmdi zmdi-hc-3x col-amber zmdi-spinner spinning"} ></i>
                  </p>
                }
            </div>
            <div className='d-flex justify-content-center flex-wrap' style={{gap:"10px"}}>
                <div className='d-flex align-items-center justify-content-center' style={{gap:"2px",fontSize:"10px"}}><div class="counter-color secondary" ></div>Completed</div>
                <div className='d-flex align-items-center justify-content-center' style={{gap:"2px",fontSize:"10px"}}><div class="counter-color primary" ></div>In progress</div>
            </div>
            </>
            )
    }
}
const mapStateToProps = state =>{
    return{
        completedCount : state.RefreshStatReducer.CompletedProgress,
        inProgressCount : state.RefreshStatReducer.Inprogress
    }
};
export default connect(mapStateToProps)(ChartComponent);