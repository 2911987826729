import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import Swal from "sweetalert2"; 
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
export default class AddNewOrganization extends React.Component {
    
    //#region Constructor & states declaration

    constructor(props) {
        super(props);
        this.state = { 
            CompanyName : cookie.load('Orgnization'),
            AdminEmail :  cookie.load('admin_email'), //'hchaudhary@vaporvm.com',
            Role: '' 
        };
    }

    //#endregion
    
    //#region  Custom events & functions

    handleChange = (event) => {
        this.setState({[event.target.name]:  event.target.value});
    }

    SendEmailAdmin = () => {
        try{
            var EamilData = new FormData();
            EamilData.append('ToEmail', this.state.AdminEmail);
            EamilData.append('Subject', 'Role Change Request');
            var _emailBody = '<p>User ' + cookie.load('user_Name')  + ' want new role of ' + this.state.Role  + '.</p>';
            EamilData.append('Body', _emailBody);
            Service.SendEmail(EamilData);
        }
        catch(e){
           console.log(e);
        }
    }

    ShowMessage = (MsgPayload) => {
        try{
            Swal.fire({  
                icon:  MsgPayload.icon, 
                title: MsgPayload.title,  
                text:  MsgPayload.text,
                showConfirmButton: false,  
                timer: 1500  
              });
        }
        catch(e){
            console.log(e + " : in ShowMessage function")
        }
    }

    OnSubmitRequest = e => {
        e.preventDefault();
        if (this.state.Role === "") {
            var payload = {
                icon  : 'error',
                title : 'Error',
                text  : 'Please select role before requesting.'
            };
            this.ShowMessage(payload);
        }
        else{
            try{
                var payload = {
                    userid : cookie.load('Id'),
                    requestedrole : this.state.Role,
                    companyname : this.state.CompanyName,
                    username : cookie.load('user_Name')
                };
                
                Service.RoleRequest(payload).then(res =>{
                    if (res.status === "Success" ) {
                        this.SendEmailAdmin();
                        var payload = {
                            icon  : 'success',
                            title : 'Request sent',
                            text  : 'Please wait for the admin approval. you will get confirmation email shortly.'
                        };
                        this.ShowMessage(payload);
                        this.props.history.push("/logout");
                    }
                    else if (res.status === "InProgress") {
                        var payload = {
                            icon  : 'error',
                            title : 'Role assignment',
                            text  : 'Please wait for admin approval.'
                        };
                        this.ShowMessage(payload);
                    }
                    else{
                        var payload = {
                            icon  : 'error',
                            title : 'Oops...',
                            text  : 'Error occured while processing your request.'
                        };
                        this.ShowMessage(payload);
                    }
                }).catch(err => {
                    var payload = {
                        icon  : 'error',
                        title : 'Oops...',
                        text  : 'Something went wrong.'
                    };
                    this.ShowMessage(payload);
                });
            }
            catch(e){
                alert(e);
            }
        } 
    };
    
    //#endregion

    render() {
        return (
            <>
            <div className="hero-body ">
            <div className="container has-text-centered">
                <div className="columns is-vcentered">
                    <div className="col-12">
                        <div className="row">
                            <div className='col-8'>
                                <div className="card">
                                    <div className="card-header"><h3>User rights</h3></div>
                                    <div className="card-body">
                                    <form >
                                    <div id="signup-form" className="login-form animated preFadeInLeft fadeInLeft">
                                        <div className="field pb-10">
                                            <div className="control has-icons-right">
                                            <input 
                                            className="input is-medium has-shadow" 
                                            value={this.state.CompanyName}
                                            required 
                                            placeholder="Enter Company Name"
                                             type="text" 
                                             disabled = {true}
                                            //  value={this.state.value}
                                            //  onChange={this.handleChange} 
                                             />                                               
                                            <span className="icon is-medium is-right">
                                                <i className="sl sl-icon-home"></i>
                                            </span>
                                            </div>
                                        </div>
                                        <div className="field pb-10">
                                            <div className="control has-icons-right">
                                             <select name="Role" onChange={this.handleChange} className = "input is-medium has-shadow">
                                                 <option>Select Role ....</option>
                                                 <option>Company Admin</option>
                                                 <option>Company Finance</option>
                                                 <option>Company Manager</option>
                                                 </select>                                              
                                              <span className="icon is-medium is-right">
                                                        <i className="sl sl-icon-user"></i>
                                              </span>
                                            </div>
                                        </div>
                                        <p className="control login">
                                            <button className="button button-cta primary-btn btn-align-lg is-bold is-fullwidth rounded raised no-lh"
                                              onClick={this.OnSubmitRequest} type="submit" value="Submit">Request
                                            </button>
                                        </p>
                                    </div>
                                </form>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                            {/* <div className="card">
                                    <div className="card-header">Roles Instructions</div>
                                    <div className="card-body">
                                    <ul className="list-group">
                                        <li className="list-group-item"><b>Admin</b>
                                        <div className="col-10">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mollis semper purus, ut maximus urna pretium luctus. Mauris suscipit ullamcorper facilisis. Sed ut elit orci. 
                                        </div>
                                        </li>
                                        <li className="list-group-item"><b>Finance</b>
                                        <div className="col-10">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mollis semper purus, ut maximus urna pretium luctus. Mauris suscipit ullamcorper facilisis. Sed ut elit orci. 
                                        </div>
                                        </li>
                                        <li className="list-group-item"><b>Manager</b>
                                        <div className="col-10">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mollis semper purus, ut maximus urna pretium luctus. Mauris suscipit ullamcorper facilisis. Sed ut elit orci. 
                                        </div>
                                        </li>
                                    </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

                 

            </>


        )
    }
}

