import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

export const Encrypt = (param) => {
   try{
    // Note : need to pass string value in param    
    //alert(process.env.REACT_APP_SECRET_KEY);
    return CryptoAES.encrypt(param,"V@p%^8@Fdh&#1v~*9_$)"); //process.env.REACT_APP_SECRET_KEY); 
   }
   catch(e){
       alert(e);
       console.log(e);
   }
}

export const Decrypt = (param) => {
    try{
        // Note : need to pass string value in param
        var _resultString = CryptoAES.decrypt(param,"V@p%^8@Fdh&#1v~*9_$)");
        return _resultString.toString(CryptoENC); 
    }
    catch(e){
        console.log(e);
    }
}