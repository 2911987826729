import React from "react";
import cookie from "react-cookies";
//import {message} from "antd";
import Service from '../../components/Service/Api/Api'
import { connect } from "react-redux";
import {ClearStas} from "../../store/actions/statsAction";
import { setMainSession } from '../../store/actions/mainSessionActions'

class Logout extends React.Component {
    constructor(props) {
        super(props);
          this.state = {
            email: "",
            password: "",
            loading: false
            //token:cookie.load('bareer_Token')
        };
        cookie.remove('bareer_Token');
        cookie.remove('apiKey');
        cookie.remove('Id');
        cookie.remove('sessionId');
        cookie.remove('token');
        cookie.remove('User_Role');
        cookie.remove('user_Id');
        cookie.remove('company_Id');
        cookie.remove('user_Name');
        cookie.remove('isTwoFactorEnable');
        cookie.remove('isVerified');
        cookie.remove('User_Email');
        cookie.remove('TFCode');
        cookie.remove('GoogleToken');
        localStorage.clear();
        this.props.ClearStats();
    }
    componentDidMount() {
        //alert("Pushing to login")
        this.props.history.push("/login?");
    }   
    
    render() {            
        return(
            ""
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return {
        ClearStats : () => {
            dispatch(ClearStas());
            dispatch(setMainSession({apiKey:"apiKey",sessionId:"sessionId",token:"token"}));
        }
    }
    
};
export default connect(null,mapDispatchToProps)(Logout);
//export default Logout;