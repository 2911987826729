import React from "react";
import './Team.css'

function Team(props) {
    const { teamData } = props;
    return (
        <div className="mx-team-main">
            {
                teamData.length > 0 ?
                    teamData.map(data => {
                        return (

                            <div className="mx-team-image">
                                <div className="mx-team-tooltip">{data.name}</div>
                                {
                                    data.profilePhoto ?
                                        <img src={data.profilePhoto} alt="User" />
                                        : <div className="mx-team-noimage">{data.name.trim().split(" ").map((word, i) => i < 2 ? word[0].toUpperCase() : null)}</div>
                                }
                            </div>
                        )
                    })
                    : "No resource assigned"
            }
        </div>
    )
}
export default Team;