import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart({ title, data, ...otherProps }) {
    const options = {
        plugins: {
            legend: {
                position:"right",
            }
        }
    }
    return (
        <>
            <p className='mx-details-title'>{title}</p>
            <div style={{width:"70%"}}>
                <Doughnut data={data} options={options} {...otherProps} />
            </div>
        </>
    );
}

export default DoughnutChart;