import React from "react";
import './SideBar.css';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { render } from "@testing-library/react";
import logo from '../../img/managex-icon.png';
import siteLogo from '../../img/logo.png';
import ExpandOutlined from '@ant-design/icons/ExpandOutlined';
import CompressOutlined from '@ant-design/icons/CompressOutlined';
import { activeLinkAction } from "../../store/actions/activeLinkAction";
import dashboardIcon from "../../img/newicons/dashboard.svg";
import billsAndPaymentsIcon from "../../img/newicons/billsandpayments.svg"
import servicesColored from "../../img/newicons/servicescolored.svg"
import services from "../../img/newicons/services.svg";
import userPlaceholder from '../../img/user-placeholder.png';
import Swal from "sweetalert2";
import cookie from 'react-cookies';

class SideBar extends React.Component {
  constructor() {
    super();
    this.state = {
      sidebarExpanded: true,
      sidebarActive: false,
      isFullscreen: false,
      isNotificationPanelActive: false,
      isAppPanelActive: false,
      userName: cookie.load("user_Name"),
      userEmail: cookie.load("user_Id"),
    }
  }
  toggleSidebarExpand = () => {
    this.setState(prevState => {
      return { ...prevState, sidebarExpanded: !prevState.sidebarExpanded }
    })
  }
  toggleSidebarActive = () => {
    this.setState(prevState => {
      return { ...prevState, sidebarActive: !prevState.sidebarActive }
    })
  }
  toggleNotificationPanelActive = () => {
    this.setState(prevState => {
      return { ...prevState, isNotificationPanelActive: !prevState.isNotificationPanelActive }
    })
  }
  toggleAppPanelActive = () => {
    this.setState(prevState => {
      return { ...prevState, isAppPanelActive: !prevState.isAppPanelActive }
    })
  }
  toggleFullscreen = () => {
    this.setState(prevState => {
      if (prevState.isFullscreen) {
        window.document.exitFullscreen();
        return { ...prevState, isFullscreen: false }
      }
      else {
        window.document.body.requestFullscreen().catch((err) => {
          console.log(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
        });;
        return { ...prevState, isFullscreen: true }
      }
    });
  }
  closeAllPanels = () => {
    this.setState({
      isNotificationPanelActive: false,
      isAppPanelActive: false,
      sidebarActive: false
    })
  }
  logoutConfirmation = (e) => {
    e.preventDefault();
    console.log("Logout button clicked!");
    Swal.fire({
      title: 'Do you want to logout?',
      text: "You can login anytime using your email and password!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--bg-color-pr)',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Navigating to logout");
        this.props.history.push("/logout");
      }
    })
  }
  componentDidMount() {
    console.log(this.props.location.pathname.toLowerCase())
    if (this.props.location.pathname.toLowerCase() === "/serviceview" || this.props.location.pathname.toLowerCase() === "/steps") {
      this.props.dispatch(activeLinkAction("services"))
    }
    else if (this.props.location.pathname.toLowerCase() === "/dashboard" || this.props.location.pathname.toLowerCase() === "/" || this.props.location.pathname.toLowerCase() === "") {
      this.props.dispatch(activeLinkAction("dashboard"))
    }
    else if (this.props.location.pathname.toLowerCase() === "/billing") {
      this.props.dispatch(activeLinkAction("billandpayments"))
    }
  }

  changeHandler = (e) => {
    this.closeAllPanels();
    this.props.dispatch(activeLinkAction(e.currentTarget.dataset.name));
  }
  render() {
    const { activeLink } = this.props;
    // console.log("active link",activeLink);
    return (
      <div className="mx-sidebar-outer">
        {
          this.state.sidebarActive || this.state.isNotificationPanelActive || this.state.isAppPanelActive ?
            <div className="mx-sidebar-overlay" onClick={this.closeAllPanels}></div>
            : ""
        }
        <div className="mx-sidebar-fixed">
          <ul className="mx-sidebar-fixed__icons">
            <li className="mobile-icon">
              <a className="mx-sidebar-fixed__icon" onClick={this.toggleSidebarActive}>
                <i class="zmdi zmdi-menu"></i>
              </a>
            </li>
            <li>
              <a href="#" className="mx-sidebar-fixed__icon">
                <img className="mx-sidebar-fixed__brand" src={logo} />
              </a>
            </li>
            <li>
              <a className="mx-sidebar-fixed__icon" onClick={this.toggleSidebarExpand}>
                <i class="zmdi zmdi-swap"></i>
              </a>
            </li>
            <li>
              <a onClick={this.toggleAppPanelActive} className="mx-sidebar-fixed__icon">
                <i className="zmdi zmdi-apps"></i>
              </a>
            </li>
            <li>
              <a onClick={this.toggleNotificationPanelActive} className="mx-sidebar-fixed__icon">
                <i className="zmdi zmdi-notifications">
                  <div className="notify">
                    <span className="heartbit"></span>
                    <span className="point"></span>
                  </div>
                </i>
              </a>
            </li>
            <li>
              <a className="mx-sidebar-fixed__icon">
                <i className="zmdi zmdi-flag">
                  <div className="notify">
                    <span className="heartbit"></span>
                    <span className="point"></span>
                  </div>
                </i>
              </a>
            </li>
            <li>
              <a className="mx-sidebar-fixed__icon" onClick={this.toggleFullscreen}>
                {
                  this.state.isFullscreen ?
                    <i className="zmdi zmdi-fullscreen-exit"></i>
                    :
                    <i className="zmdi zmdi-fullscreen"></i>
                }
              </a>
            </li>
          </ul>
          <ul className="mx-sidebar-fixed__icons">
            <Link onClick={() => this.props.dispatch(activeLinkAction("editprofile"))} className="mx-sidebar-fixed__icon" to="/EditProfile">
              <i class="zmdi zmdi-settings spinning slow"></i>
            </Link>
            <li>
              <Link className="mx-sidebar-fixed__icon" onClick={this.logoutConfirmation}>
                <i class="zmdi zmdi-power"></i>
              </Link>
              {/* <a className="mx-sidebar-fixed__icon" onClick={this.toggleSidebarExpand}></a> */}
            </li>
          </ul>
        </div>
        <div className={`mx-sidebar-main ${this.state.sidebarActive ? "sidebar-active" : ""}`} style={{ width: this.state.sidebarExpanded ? null : "auto" }}>
          {
            this.state.sidebarExpanded ?
              <div className="mx-sidebar-main__profile-wrap">
                {
                  this.state.sidebarExpanded ?
                    <>
                      <img src={userPlaceholder} className="mx-sidebar-main__profile-image" />
                      <h6 className="mx-sidebar-main__profile-name">{this.state.userName}</h6>
                      <p className="mx-sidebar-main__profile-email">{this.state.userEmail}</p>
                    </>
                    :
                    <img src={userPlaceholder} className="mx-sidebar-main__profile-image smaller" />
                }
              </div>
              :
              <img src={userPlaceholder} className="mx-sidebar-main__profile-image smaller" />
          }
          <div className="mx-sidebar-navigation-wrap">
            <Link
              data-name={"dashboard"}
              className={`mx-sidebar-navigation ${this.props.activeLink === "dashboard" || this.props.activeLink === "" ? "active" : ""}`}
              to="/dashboard"
              onClick={this.changeHandler}
            >
              <i class={`zmdi zmdi-home mx-sidebar-navigation-icon ${this.state.sidebarExpanded ? "" : "icon-big"}`}></i>
              {
                this.state.sidebarExpanded ?
                  <span className="mx-sidebar-navigation-text">Dashboard</span>
                  : ""
              }
            </Link>
            <Link
              data-name={"services"}
              className={`mx-sidebar-navigation ${this.props.activeLink === "services" ? "active" : ""}`}
              to="/ServiceView"
              onClick={this.changeHandler}
            >
              <i class={`zmdi zmdi-case mx-sidebar-navigation-icon ${this.state.sidebarExpanded ? "" : "icon-big"}`}></i>
              {
                this.state.sidebarExpanded ?
                  <span className="mx-sidebar-navigation-text">Services</span>
                  : ""
              }
            </Link>
            <Link
              data-name={"billandpayments"}
              className={`mx-sidebar-navigation ${this.props.activeLink === "billandpayments" ? "active" : ""}`}
              to="/billing"
              onClick={this.changeHandler}
            >
              <i class={`zmdi zmdi-receipt mx-sidebar-navigation-icon ${this.state.sidebarExpanded ? "" : "icon-big"}`}></i>
              {
                this.state.sidebarExpanded ?
                  <span className="mx-sidebar-navigation-text">Bills & Payments</span>
                  : ""
              }
            </Link>
          </div>
        </div>
        {/* Notificaton panel */}
        <div className={`mx-sidebar-slideable ${this.state.isNotificationPanelActive ? "sidebar-active" : ""}`}>
          <div class="mx-sidebar-card">
            <div class="mx-sidebar-card-header">
              <h2><strong>Messages</strong></h2>
            </div>
            <div class="mx-sidebar-card-body">
              <ul class="list-unstyled messages">
                <li>
                  <a href="events.html">
                    <div class="media">
                        <img class="media-object rounded-circle" src="assets/images/xs/avatar1.jpg" alt="50x50" />
                        <div class="media-body">
                            <span class="name">Alexander <small class="time">35min ago</small></span>
                            <p class="message">New tasks needs to be done</p>                                        
                        </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="events.html">
                    <div class="media">
                        <img class="media-object rounded-circle" src="assets/images/xs/avatar1.jpg" alt="50x50" />
                        <div class="media-body">
                            <span class="name">Grayson <small class="time">1hr ago</small></span>
                            <p class="message">New tasks needs to be done</p>                                        
                        </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="events.html">
                    <div class="media">
                        <img class="media-object rounded-circle" src="assets/images/xs/avatar1.jpg" alt="50x50" />
                        <div class="media-body">
                            <span class="name">Sophia <small class="time">31min ago</small></span>
                            <p class="message">New tasks needs to be done</p>                                        
                        </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* App panel */}
        <div className={`mx-sidebar-slideable ${this.state.isAppPanelActive ? "sidebar-active" : ""}`}>
          <div class="mx-sidebar-card">
            <div class="mx-sidebar-card-header">
              <h2><strong>App</strong> Menu</h2>
            </div>
            <div class="mx-sidebar-card-body">
              <ul class="list-unstyled menu">
                <li><a href="events.html"><i class="zmdi zmdi-calendar-note"></i><span>Calendar</span></a></li>
                <li><a href="file-dashboard.html"><i class="zmdi zmdi-file-text"></i><span>File Manager</span></a></li>
                <li><a href="blog-dashboard.html"><i class="zmdi zmdi-blogger"></i><span>Blog</span></a></li>
                <li><a href="javascript:void(0)"><i class="zmdi zmdi-arrows"></i><span>Notes</span></a></li>
                <li><a href="javascript:void(0)"><i class="zmdi zmdi-view-column"></i><span>Taskboard</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state.activeLinkReducer;
}
export default withRouter(connect(mapStateToProps, null)(SideBar));