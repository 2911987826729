import React from "react";
import { connect } from "react-redux";
import MiniLoader from "./miniLoader";
import './counter.css'
import completedProjectsIcon from "../../img/CustomImages/icons/completedprojects.webp";
import totalProjectsIcon from "../../img/CustomImages/icons/totalprojects.png";

class Counter extends React.Component{
    render(){
        const newDesign = 
        <>
        <div className='col-sm-4 mb-4 mb-sm-0'>
            <div className='mx-stats-card'>
                <div className='mx-stats-card__icon'>
                    <img src={totalProjectsIcon}/>
                </div>
                <h2 className='mx-stats-card__title'>Total projects</h2>
                <p className='mx-stats-card__count'>{this.props.totalProjectCount}</p>
            </div>
        </div>
        <div className='col-sm-4 mb-4 mb-sm-0'>
            <div className='mx-stats-card'>
                <div className='mx-stats-card__icon'>
                    <img src='https://img.icons8.com/color/344/submit-progress--v1.png'/>
                </div>
                <h2 className='mx-stats-card__title'>In progress projects</h2>
                <p className='mx-stats-card__count'>{this.props.inProgressCount >= 0 ? this.props.inProgressCount : <MiniLoader/>}</p>
                {/* <p className='mx-stats-card__desc'>The projects that are currently in progress</p> */}
            </div>
        </div>
        <div className='col-sm-4 mb-4 mb-sm-0'>
            <div className='mx-stats-card'>
                <div className='mx-stats-card__icon'>
                    <img src={completedProjectsIcon}/>
                </div>
                <h2 className='mx-stats-card__title'>Completed projects</h2>
                <p className='mx-stats-card__count'>{this.props.completedCount >= 0 ? this.props.completedCount : <MiniLoader/>}</p>
                {/* <p className='mx-stats-card__desc'>The total number projects that are completed</p> */}
            </div>
        </div>
        
        </>
        return(
            <>
                {newDesign}
            </>
        )
    }
}
const mapStateToProps = state =>{
    return{
        completedCount : state.RefreshStatReducer.CompletedProgress,
        inProgressCount : state.RefreshStatReducer.Inprogress
    }
};
export default connect(mapStateToProps)(Counter);