import React, { Component } from 'react';
import CheckBox from './CheckBox';
//import TextBox from '../components/TextBox';
//import UserInfo from '../components/models/UserInfo';
import { Redirect } from 'react-router-dom';
//import OrderDetail from '../components/models/OrderDetail';
//import Office365 from "../assets/images/Office365-bg.png";
//import * as Constants from '../constants/AppConstants';
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import queryString from 'query-string'
import Swal from "sweetalert2";  
import CustomLoader from "../Loader/Loader";

import { UncontrolledTooltip, Tooltip  } from 'reactstrap';

export class Questionnaire extends React.Component {
    //userInfo = new UserInfo();
    constructor(props) {
        
        super(props);
        var queryString =   this.props.location.pathname.split('/');
        var serviceId =   queryString[queryString.length - 1]
        this.state = {
            flowId: serviceId,
            result: [],
            orderDetails: [],
            loading: false,
            message: "Please Wait..",
            user: 0,
            rangeValue : 0,
            userName: cookie.load('user_Id'),
            serviceName:"",
            customLoader : false
        };
        this.setState({flowId:serviceId})
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleusernumber = this.handleusernumber.bind(this);
    }

    componentDidMount() {
        
        //console.log(this.props.location.pathname)
        this.setState({customLoader : true});
        this.getQuestions(this.state.flowId);        
    }
    /**
     * Handle the form submission
     * @param {any} event
     */
    handleSubmit(event) {
        if(this.state.user>2000){
            Swal.fire({  
                icon: 'error',  
                title: 'Oops...',  
                text: 'Number of It resources must be less then 2000.',
                showConfirmButton: false,  
                timer: 1500  
              });

        }
        else{
console.log(this.state.orderDetails)
        var length = 0;
        if (this.state.result.length==0){
            length=this.state.orderDetails.length
        }
        else{
            //alert(this.state.result.Question.length+'=========='+this.state.orderDetails.length)
            length=this.state.result.Question.length
        }
        //alert(length)
        

        if (this.state.orderDetails !== null && this.state.orderDetails != undefined) {
            if (this.state.result.length){

            }

            if(this.state.user!=0 && this.state.orderDetails.length>=length){
                var payload = {
                    "serviceId": parseInt(this.state.flowId),
                    "userId": this.state.userName,
                    "numberofUsers": parseInt(this.state.user),
                    "questions": this.state.orderDetails
                }
                console.log(payload)
                Service.PostQuestionnaire(payload).then(res => {
                    if (res) {
                        console.log(res);
                        this.props.history.push("/Qoutation/"+res);                   
                    }
                });
            }else{
                //alert(length+'=========='+this.state.orderDetails.length)
                Swal.fire({  
                    icon: 'error',  
                    title: 'Oops...',  
                    text: 'Selection of all Option is required',
                    showConfirmButton: false,  
                    timer: 1500  
                  });
            }
            

        }
        else {
            console.error("this.state.orderDetails is null or undefined");
        }
        }
        
    }




    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        let c_attr = event.target.getAttribute('questionid');
        let c_type = event.target.getAttribute('controltype');
        let answerasoptionid = -1;
        let answerastext = null;       
        if (this.state.orderDetails.filter(o => o.questionId === c_attr).length > 0) {
            /* orderDetails contains the element we're looking for */
            const index = this.state.orderDetails.findIndex(o => o.questionId === c_attr);
            this.state.orderDetails.splice(index, 1);
        }
        let array = this.state.orderDetails;
        array.push({"questionId":parseInt(c_attr) , "selectedAnswerId" : parseInt(val) ,"selectedAnswerValue":parseInt(0)});
        this.setState({orderDetails :array});
        console.log(this.state.orderDetails);
    }
    
    getQuestions(flowId) {
        Service.getQuestionnaire(flowId).then(res => {
            if (res) {
                console.log(res);
                if(res.Question.length==1 &&res.Question[0].Answers.length==1){
                    let array = this.state.orderDetails;
                    array.push({"questionId":parseInt(res.Question[0].Id) , "selectedAnswerId" : parseInt(res.Question[0].Answers[0].Id) ,"selectedAnswerValue":parseInt(0)});
                    this.setState({orderDetails :array});
                    console.log(this.state.orderDetails);
                }
                else{
                this.setState({
                    result: res
                });
                }
                this.setState({
                    serviceName: res.ServiceName
                });
                this.setState({customLoader : false});
                
            }
        });

    }
    handleusernumber(event){
        // let val = event.target.value;
        if(event.target.value.length == 0){
            if(event.target.type == "number"){
                this.setState({user :event.target.value});
                this.setState({rangeValue : 0});
            }
        }
        else {
            this.setState({user :event.target.value});
            this.setState({rangeValue : event.target.value});
        }
        
    }
    render() {

        let data;
        if (false) {
            data = ""
        } else {
            data =
                <div>
                    <div className='row' style={{ backgroundColor: `#0A0044`, height: '60px',justifyContent:"center" }}>
                                
                        <div className='col-12' style={{marginTop:'1%', color:'white',textAlign:"center"}}>
                            <h2>{this.state.serviceName}</h2>
                        </div>
                    </div>
                    <hr />
                    {console.log(this.state.result)}
                    <div id="accordion" className='col-10 offset-1 mt-5'>
                    {this.state.result.Question?
                    <>
                    {                           
                        this.state.result.Question.map((question, index) =>
                        (
                            <div className="card" key={index}>
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn" data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="false" aria-controls="collapseOne">
                                            {question.QuestionText}
                                        </button>
                                    </h5>
                                </div>

                                <div id={"collapse" + index} className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="card-body">



                                        {question.Answers.map((a, i) =>
                                        (
                                            <div key={i}>
                                                {/* {question.controlType == 'radiobuttonlist' ? <CheckBox questionid={question.questionId} controlName={question.controlName} controlType={question.controlType} answerId={a.answerId} title={a.title} change={this.handleInputChange} /> : "Type may be checkbox so render chechkbox"} */}
                                                {true ? <CheckBox questionid={question.Id} controlName={question.Id} controlType={'radiobuttonlist'} answerId={a.Id} title={a.AnswerText} change={this.handleInputChange} /> : "Type may be checkbox so render chechkbox"}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )

                        )}
                        
                </> :""
                    }

                    <div className='card'>
                            <div className='card-header'>
                                <div className='col-10'>Number of IT Assets</div> 
                                <div className='col-2'><input type='number' min="0" id='uservalue' value ={this.state.user} onChange={this.handleusernumber}></input></div> 
                            </div>
                            <div className='card-body'>
                            <input id='userslider' type='range' min='0' max='2000' value={this.state.rangeValue}  className='form-control' onChange={this.handleusernumber}  style={{padding:"0"}}></input>
                            </div>
                            {this.state.rangeValue>300?
                                    <li style={{listStyle:"none",width:"100%",marginBottom:"1%"}}>
                                        <i style={{marginLeft:'1%'}} id="TooltipInfo" className="sl sl-icon-info"></i>
                                        <UncontrolledTooltip placement="right"  target="TooltipInfo">
                                        For more then 300 IT Assets kindly contact our support
                                        </UncontrolledTooltip>
                                    </li>
                                    :
                                    ""
                                }
                    </div>
                    <div className="col-md-12">
                    <button type="button" style={{marginLeft:'93%'}} className='btn btn-success col-1 offset-10 mt-5 mb-5' onClick={this.handleSubmit}>Submit</button>
                    </div>    
                    </div>
                    
                    
                    
                </div>
        }

        return (
            <div>
                {data}
                {this.state.customLoader ? <CustomLoader /> : null}
            </div>);
    }

}
export default Questionnaire;