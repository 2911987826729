// import React from "react";
// import cookie from "react-cookies";
// //import {message} from "antd";
// import Service from "../../components/Service/Api/Api";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";
// import CustomLoader from "../Loader/Loader";
// import Slider from "react-slick";
// import { connect } from "react-redux";
// import { setMainSession } from "../../store/actions/mainSessionActions";
// import InputComponent from "../inputComponents/Input";
// import image from '../../img/sign-up-graphic.png';

// import Swal from "sweetalert2";
// import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
// import "../Login/Login.css";


// import { GoogleLogin } from "react-google-login";

// // import loader from '../Loader/index'
// const initialState = {
//   email: "",
//   password: "",
//   loading: "",
//   token: cookie.load("bareer_Token"),
//   emailError: "",
//   passwordError: "",
// };
// const ClientID = "416413332650-vc8r12v7ro73sp2stpv7a5bstuma04mm.apps.googleusercontent.com";
// //"416413332650-vc8r12v7ro73sp2stpv7a5bstuma04mm.apps.googleusercontent.com";

// class Login extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       email: "",
//       password: "",
//       loading: false,
//       token: cookie.load("bareer_Token"),
//       emailError: "",
//       passwordError: "",
//       customLoader: false,
//       isGoogleSignedin: cookie.load("GoogleToken"),
//       //isGoogleSignInSucess : false
//     };
//   }
//   componentDidMount() {
//     //cookie.remove('bareer_Token');
//     //this.setState({ customLoader : false});
//     if (
//       this.state.isGoogleSignedin !== undefined &&
//       this.state.isGoogleSignedin !== ""
//     ) {
//       var strURL = this.props.history.location.pathname;
//       if (/\d/.test(strURL)) {
//         var last = strURL.substring(strURL.lastIndexOf("/") + 1, strURL.length);
//         this.props.history.push("/Questionnaire/" + last);
//       } else {
//         this.props.history.push("/dashboard");
//       }
//     }
//     const { token } = this.state;
//     if (token !== undefined && token !== "") {
//       var strURL = this.props.history.location.pathname;
//       if (/\d/.test(strURL)) {
//         var last = strURL.substring(strURL.lastIndexOf("/") + 1, strURL.length);
//         this.props.history.push("/Questionnaire/" + last);
//       } else {
//         this.props.history.push("/dashboard");
//       }
//       //console.log(this.props.history.location.pathname, "path");
//       // if(!(this.props.history.location.pathname.includes('Login')))
//       //     //window.location.reload()
//       // else

//       //this.props.setToken(token)
//     }
//   }

//   onSuccess = (res) => {
//     //console.log('Login Success: currentUser:', res.profileObj);
//     try {
//       this.setState({ customLoader: true });
//       //debugger;
//       cookie.save("GoogleToken", res.tokenObj.access_token, {
//         path: "/",
//         maxAge: 5 * 3600,
//       });
//       var PayloadObj = {
//         email: res.profileObj.email,
//         firstname: res.profileObj.givenName,
//         lastname: res.profileObj.familyName,
//       };
//       Service.LoginWithGoogle(PayloadObj)
//         .then((res) => {
//           if (res.status === "success") {
//             cookie.save("bareer_Token", res.api_key, {
//               path: "/",
//               maxAge: 5 * 3600,
//             });

//             cookie.save("user_Id", res.user.userName, {
//               path: "/",
//               maxAge: 5 * 3600,
//             });

//             cookie.save("Id", res.user.id, {
//               path: "/",
//               maxAge: 5 * 3600,
//             });

//             cookie.save(
//               "user_Name",
//               res.user.firstName + " " + res.user.lastName,
//               {
//                 path: "/",
//                 maxAge: 5 * 3600,
//               }
//             );

//             cookie.save("Orgnization", res.user.comapnyName, {
//               path: "/",
//               maxAge: 5 * 3600,
//             });

//             cookie.save("User_Role", res.user.userRole, {
//               path: "/",
//               maxAge: 5 * 3600,
//             });

//             cookie.save("admin_email", res.adminemail, {
//               path: "/",
//               maxAge: 5 * 3600,
//             });

//             Service.GetSession(res.user.userName).then((responce) => {
//               if (responce) {
//                 // New code START
//                 // Saving the main session details in the main session Reducer
//                 this.props.dispatch(setMainSession({ apiKey: responce.apiKey, token: responce.token, sessionId: responce.sessionId }));

//                 // New code END
//                 cookie.save("apiKey", responce.apiKey, {
//                   path: "/",
//                   maxAge: 5 * 3600,
//                 });
//                 cookie.save("sessionId", responce.sessionId, {
//                   path: "/",
//                   maxAge: 5 * 3600,
//                 });
//                 cookie.save("token", responce.token, {
//                   path: "/",
//                   maxAge: 5 * 3600,
//                 });
//                 // window.connect(
//                 //   responce.apiKey,
//                 //   responce.sessionId,
//                 //   responce.token,
//                 //   res.user.userName
//                 // );
//               }
//             });

//             var queryString = this.props.location.pathname.split("/");
//             var RedirectUrlId = queryString[queryString.length - 1];
//             var returnUrl = localStorage.getItem("RedirectUrl");
//             //alert(returnUrl)
//             this.setState({ customLoader: false });
//             if (RedirectUrlId && RedirectUrlId != "login") {
//               this.props.history.push("/Questionnaire/" + RedirectUrlId);
//               // further if else for navigation required discussion required with usman
//             } else if (returnUrl) {
//               window.location.href = returnUrl;
//             } else {
//               this.props.history.push("/dashboard");
//             }
//           } else {
//             // show error msg of api call
//             Swal.fire({
//               icon: "error",
//               title: "Error",
//               text: "Error occured while processing...",
//               showConfirmButton: false,
//               timer: 2000,
//             });
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//           this.setState({ customLoader: false });
//         });
//     } catch (ex) {
//       console.log(ex);
//       this.setState({ customLoader: false });
//     }
//   };

//   onFailure = (res) => { };

//   onChange = (e) => {
//     this.setState({
//       [e.target.name]: e.target.value,
//     });
//   };
//   onInput = (e) => {
//     if (e.target.type === "email") {
//       // if(!e.target.value.toLowerCase().includes('vaporvm')){
//       //     message.error("Please enter valid credentials to login");
//       // }
//     }
//   };
//   OnValidate = (e) => {
//     let emailError = "";
//     let passwordError = "";

//     if (!this.state.email.includes("@") && !this.state.email.includes(".")) {
//       emailError = "Plesae enter your valid email";
//     }
//     if (
//       !this.state.password.match(
//         "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
//       )
//     ) {
//       passwordError = "Please enter your valid password";
//     }
//     if (emailError || passwordError) {
//       this.setState({ emailError, passwordError, loading: true });

//       return false;
//     } else {
//       return true;
//     }
//   };

//   validataEmail = () => {
//     const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
//     if (this.state.email.match(mailformat)) {
//       return true;
//     }
//     return false;
//   }


//   onSubmit = (e) => {
//     e.preventDefault();

//     if (this.state.email && this.state.password !== '') {
//       if (this.validataEmail()) {

//         try {
//           this.setState({ customLoader: true });
//           const { email, password } = this.state;
//           if (email !== "" && password !== "") {
//             const isValidated = this.OnValidate();
//             if (isValidated) {
//               var data = {
//                 username: this.state.email,
//                 password: this.state.password,
//               };
//               Service.login(data)
//                 .then((res) => {
//                   let allowedRoles = ["CompanyUser", "CompanyAdmin", "User"];
//                   if (res && res.api_key) {
//                     // debugger;
//                     Service.GetSession(res.user.userName).then((responce) => {
//                       if (responce) {
//                         // New code START
//                         // Saving the main session details in the main session Reducer
//                         this.props.dispatch(setMainSession({ apiKey: responce.apiKey, token: responce.token, sessionId: responce.sessionId }));

//                         // New code END
//                         cookie.save("apiKey", responce.apiKey, {
//                           path: "/",
//                           maxAge: 5 * 3600,
//                         });
//                         cookie.save("sessionId", responce.sessionId, {
//                           path: "/",
//                           maxAge: 5 * 3600,
//                         });
//                         cookie.save("token", responce.token, {
//                           path: "/",
//                           maxAge: 5 * 3600,
//                         });
//                         // window.connect(
//                         //   responce.apiKey,
//                         //   responce.sessionId,
//                         //   responce.token,
//                         //   res.user.userName
//                         // );
//                       }
//                       if (allowedRoles.includes(res.role[0]) && res.user.emailConfirmed) {
//                         cookie.save("bareer_Token", res.api_key, {
//                           path: "/",
//                           maxAge: 5 * 3600,
//                         });

//                         cookie.save("user_Id", res.user.userName, {
//                           path: "/",
//                           maxAge: 5 * 3600,
//                         });

//                         cookie.save("Id", res.user.id, {
//                           path: "/",
//                           maxAge: 5 * 3600,
//                         });

//                         cookie.save(
//                           "user_Name",
//                           res.user.firstName + " " + res.user.lastName,
//                           {
//                             path: "/",
//                             maxAge: 5 * 3600,
//                           }
//                         );

//                         cookie.save("Orgnization", res.user.comapnyName, {
//                           path: "/",
//                           maxAge: 5 * 3600,
//                         });

//                         cookie.save("User_Role", res.user.userRole, {
//                           path: "/",
//                           maxAge: 5 * 3600,
//                         });

//                         cookie.save("admin_email", res.adminemail, {
//                           path: "/",
//                           maxAge: 5 * 3600,
//                         });
//   // ======================================================================================================================

//                         if (res.istwofactorenable) {
//                           this.setState({ customLoader: false });
//                           cookie.save("isTwoFactorEnable", true, {
//                             path: "/",
//                             maxAge: 5 * 3600,
//                           });
//                           cookie.save("isVerified", false, {
//                             path: "/",
//                             maxAge: 5 * 3600,
//                           });
//                           cookie.save("User_Email", res.user.email, {
//                             path: "/",
//                             maxAge: 5 * 3600,
//                           });
//                           cookie.save("TFCode", res.authtoken, {
//                             path: "/",
//                             maxAge: 5 * 3600,
//                           });
//                           this.props.history.push("/Otp");
//                         } else {
//                           this.setState({ customLoader: false });
//                           var queryString = this.props.location.pathname.split("/");
//                           //debugger;
//                           var RedirectUrlId = queryString[queryString.length - 1];
//                           var returnUrl = localStorage.getItem("RedirectUrl");
//                           //alert(returnUrl)
//                           if (
//                             RedirectUrlId &&
//                             RedirectUrlId.toLowerCase() != "login"
//                           ) {
//                             this.props.history.push(
//                               "/Questionnaire/" + RedirectUrlId
//                             );
//                             // further if else for navigation required discussion required with usman
//                           } else if (returnUrl) {
//                             window.location.href = returnUrl;
//                           } else {
//                             this.props.history.push("/dashboard");
//                           }
//                         }
//                       } else if (allowedRoles.includes(res.role[0]) && !res.user.emailconfirmed) {
//                         this.setState({ customLoader: false });
//                         Swal.fire({
//                           icon: "error",
//                           title: "Email Address Verification",
//                           text: "Please activate your account first.",
//                           showConfirmButton: false,
//                           timer: 1500,
//                         });
//                       } else {
//                         this.setState({ customLoader: false });
//                         Swal.fire({
//                           icon: "error",
//                           title: "Oops...",
//                           text: "Please enter valid credentials to login!",
//                           showConfirmButton: false,
//                           timer: 1500,
//                         });
//                       }
//                     });
//                   }
//                 })
//                 .catch((err) => {
//                   //debugger;
//                   this.setState({ customLoader: false });
//                   if (err.toString().includes(401)) {
//                     Swal.fire({
//                       icon: "error",
//                       title: "Oops...",
//                       text: "Authentication error. Please contact to administrator",
//                       showConfirmButton: false,
//                       timer: 1500,
//                     });
//                   } else {
//                     Swal.fire({
//                       icon: "error",
//                       title: "Oops...",
//                       text: "Something went wrong. Please try again later.",
//                       showConfirmButton: false,
//                       timer: 1500,
//                     });
//                   }
//                 });
//             } else {
//               this.setState({ customLoader: false });
//             }
//           } else {
//             this.setState({ customLoader: false });
//             Swal.fire({
//               icon: "error",
//               title: "Error",
//               text: "Please enter Email / Password first.",
//               showConfirmButton: false,
//               timer: 1500,
//             });
//           }
//         } catch (e) {
//           this.setState({ customLoader: false });
//           console.log(e);
//         }
//       }
//       else {
//         Swal.fire({
//           icon: 'error',
//           title: 'Oops...',
//           text: 'Enter Valid Email.',
//           showConfirmButton: false,
//           timer: 1500
//         });
//       }
//     }
//     else {
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: 'Invalid email or password.',
//         showConfirmButton: false,
//         timer: 1500
//       });
//     }
//   };

//   render() {
//     // const settings = {
//     //   infinite: true,
//     //   speed: 500,
//     //   slidesToShow: 1,
//     //   slidesToScroll: 1,
//     //   autoplay: true,
//     //   autoplaySpeed: 3000,
//     // };
//     const PreviousFormBackup = () => {
//       return (
//         <div className="login-wrapper columns is-gapless">
//           <div className="column is-12">
//             <div className="hero ">
//               <div className="hero-body">
//                 <div className="container">
//                   <div
//                     style={{
//                       width: "70%",
//                       marginLeft: "15%",
//                       marginTop: "10%",
//                     }}
//                   >
//                     <div className="columns">
//                       <div className="column is-8 is-offset-2">
//                         <div className="auth-content">
//                           <h2 style={{ marginLeft: "36%" }}>Sign in</h2>
//                           {/* <p>Don't have a account ?</p>
//                           <Link to="/SignUp">Sign Up</Link> */}
//                         </div>

//                         <form>
//                           <div
//                             id="signup-form"
//                             className="login-form animated preFadeInLeft fadeInLeft"
//                           >
//                             <div className="field pb-10">
//                               <div className="floating-label">
//                                 {/* <input className="floating-input" type="text" placeholder=" " /> */}
//                                 <input
//                                   type="email"
//                                   className="floating-input"
//                                   placeholder=" "
//                                   name="email"
//                                   required
//                                   onChange={this.onChange}
//                                   value={this.state.email}
//                                   pattern="^[a-zA-Z]+.*@[A-Za-z]+([.][a-zA-Z]+)+$"
//                                 />
//                                 <label>Type your email address</label>
//                               </div>
//                               {this.state.emailError ? (
//                                 <div
//                                   className="error"
//                                   style={{ fontSize: 12, color: "red" }}
//                                 >
//                                   {this.state.emailError}
//                                 </div>
//                               ) : null}
//                             </div>
//                             {/* <div className="field pb-10">
//                               <div className="control has-icons-right">
//                                 <input
//                                   type="email"
//                                   classNameName="textField"
//                                   placeholder="Type your email address"
//                                   name="email"
//                                   required
//                                   onChange={this.onChange}
//                                   value={this.state.email}
//                                   pattern="^[a-zA-Z]+.*@[A-Za-z]+([.][a-zA-Z]+)+$"
//                                 />
//                                 <span className="icon is-medium is-right">
//                                   <i className="sl sl-icon-envelope-open"></i>
//                                 </span>
//                               </div>
//                               {this.state.emailError ? (
//                                 <div
//                                   className="error"
//                                   style={{ fontSize: 12, color: "red" }}
//                                 >
//                                   {this.state.emailError}
//                                 </div>
//                               ) : null}
//                             </div> */}

//                             <div className="field">
//                               <div className="floating-label">
//                                 <input
//                                   type="password"
//                                   className="floating-input"
//                                   placeholder=" "
//                                   name="password"
//                                   required
//                                   onChange={this.onChange}
//                                   value={this.state.password}
//                                   pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$"
//                                 />
//                                 <label>Type your password</label>
//                               </div>
//                               {this.state.passwordError ? (
//                                 <div
//                                   className="error"
//                                   style={{ fontSize: 12, color: "red" }}
//                                 >
//                                   {this.state.passwordError}
//                                 </div>
//                               ) : null}
//                             </div>
//                             <div
//                               id="contactedForgetPassword"
//                               className="pt-0 pb-0 animated preFadeInLeft fadeInLeft"
//                             >
//                               <p className="has-text-right">
//                                 <Link style={{ color: '#95A5A6' }} to="/ForgetPassword">
//                                   Forget Password ?
//                                 </Link>
//                                 {/* <a href="/ForgetPassword">Forget Password</a> */}
//                               </p>
//                             </div>
//                             <p className="control login">
//                               <div className="row" style={{ justifyContent: "center", alignItems: "center" }}>
//                                 <button
//                                   className="button btn-LoginScreen rounded raised"
//                                   onClick={this.onSubmit}
//                                 >
//                                   Sign In
//                                 </button>
//                                 <span className="buttonStyling">
//                                   <Link
//                                     className="button btn-Link rounded raised"
//                                     to="/SignUp"
//                                   >
//                                     Sign up
//                                   </Link>
//                                 </span>
//                               </div>
//                             </p>
//                             {/* <div className="row" style={{ justifyContent: "center" }}>
//                                 <span>OR</span>
//                               </div> */}
//                             {/* <div className="row" style={{ justifyContent: "center" }}>
//                                 <p className="control login CenterAlignButton">
//                                   <Link className="button btn-Link btn-primary rounded raised" to="/SignUp">Sign up</Link>
//                                   <span style={{ marginLeft: "5%" }}>
//                                     <GoogleLogin
//                                       clientId={ClientID}
//                                       buttonText="Login with Google"
//                                       onSuccess={this.onSuccess}
//                                       onFailure={this.onFailure}
//                                       cookiePolicy={"single_host_origin"}
//                                       style={{ marginTop: "100px" }}
//                                       isSignedIn={false}
//                                     />
//                                   </span>
//                                 </p>
//                               </div> */}
//                           </div>
//                         </form>

//                         <form>
//                           <div
//                             id="contacted-form"
//                             className="login-form animated preFadeInLeft fadeInLeft is-hidden"
//                           >
//                             <div className="field pb-10">
//                               <div className="control">
//                                 <input
//                                   className="input is-large"
//                                   type="text"
//                                   placeholder="Full name *"
//                                 />
//                               </div>
//                             </div>
//                             <div className="field pb-10">
//                               <div className="control">
//                                 <input
//                                   className="text-field"
//                                   name="email"
//                                   placeholder="Email"
//                                   type="email"
//                                   required=""
//                                   value=""
//                                 />
//                               </div>
//                             </div>
//                             <div className="field pb-10">
//                               <div className="control">
//                                 <input
//                                   className="input is-large"
//                                   type="text"
//                                   placeholder="Phone"
//                                 />
//                               </div>
//                             </div>
//                             <p className="control login">
//                               <button className="button button-cta secondary-btn btn-align-lg is-bold is-fullwidth rounded raised no-lh">
//                                 Send info
//                               </button>
//                             </p>
//                           </div>
//                         </form>

//                         {this.state.customLoader ? <CustomLoader /> : null}
//                         <div
//                           id="back-to-signup"
//                           className="pt-10 pb-10 forgot-password animated preFadeInLeft fadeInLeft is-hidden"
//                         >
//                           <p className="has-text-centered">
//                             <a href="#">Back to Sign In</a>
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )
//     }
//     return (
//       <>
//         {/* {PreviousFormBackup()} */}
//         {this.state.customLoader ? <CustomLoader /> : null}
//         <div className="mx-form-bg">
//         <div className="row justify-content-center align-items-center login-form animated preFadeInLeft fadeInLeft mx-form-card">
//         <div className="col-6 d-lg-block d-none">
//             <img src={image} />
//         </div>
//         <div className="col-12 col-lg-6 mx-form-wrap">
//           <h2 className="text-center">Sign in</h2>
//           <form onSubmit={this.onSubmit}>
//           <InputComponent
//               inputType="inputField"
//               type="email"
//               name="email"
//               label="Email"
//               error={this.state.emailError}
//               floatingLabel
//               variant="primary"
//               onChange={this.onChange}
//               classes="input-thin"
//             />
//             <InputComponent
//               inputType="inputField"
//               type="password"
//               name="password"
//               label="Password"
//               classes="mb-1 input-thin"
//               error={this.state.passwordError}
//               floatingLabel
//               variant="primary"
//               onChange={this.onChange}
//             />
//             <div className="text-right">
//               <Link style={{ color: '#757575', margin: "0.5em" }} to="/ForgetPassword">
//                 Forget Password ?
//               </Link>
//             </div>
//             <InputComponent
//               inputType="button"
//               fullWidth
//               type="submit"
//               name="Sign in"
//               variant="secondary"
//               onClick={this.onSubmit}
//               onSubmit={this.onSubmit}
//               classes="mb-2 input-thin"
//             />
//           </form>
//           <div className="text-center mt-3">
//               <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push("/SignUp") }}>Don't have an account? Sign up</a>
//           </div>
//         </div>
//         <div className="col-12 text-center">
//             <a style={{ color: '#757575', margin: "0.5em" }} href="https://www.managex.ae/contact-us/" rel="noreferrer" target="_blank">Help</a>
//             <a style={{ color: '#757575', margin: "0.5em" }} href="https://www.managex.ae/privacy-policy/" rel="noreferrer" target="_blank">Privacy policy</a>
//             <a style={{ color: '#757575', margin: "0.5em" }} href="https://www.managex.ae/terms-of-services/" rel="noreferrer" target="_blank">Terms and conditions</a>
//         </div>
//         </div>
//         </div>
//         {/* <div className="row justify-content-center align-items-center login-form animated preFadeInLeft fadeInLeft" style={{ padding: "50px 30px" }}>
//           <div className="col-md-3 d-md-block d-none">
//             <img src={image} />
//           </div>
//           <form className="col-md-5 card p-5">
//             <h2 className="text-center">Sign in</h2>
//             <InputComponent
//               inputType="inputField"
//               type="email"
//               name="email"
//               label="Email"
//               error={this.state.emailError}
//               floatingLabel
//               variant="primary"
//               onChange={this.onChange}
//             />
//             <InputComponent
//               inputType="inputField"
//               type="password"
//               name="password"
//               label="Password"
//               classes="mb-1"
//               error={this.state.passwordError}
//               floatingLabel
//               variant="primary"
//               onChange={this.onChange}
//             />
//             <div className="text-right">
//               <Link style={{ color: '#757575', margin: "0.5em" }} to="/ForgetPassword">
//                 Forget Password ?
//               </Link>
//             </div>
//             <InputComponent
//               inputType="button"
//               fullWidth
//               type="submit"
//               name="Sign in"
//               variant="secondary"
//               onClick={this.onSubmit}
//               onSubmit={this.onSubmit}
//               classes="mb-2"
//             />
//             <div className="d-flex align-items-center" style={{ gap: "15px" }}>
//               <div style={{ width: "100%", borderBottom: "1px solid #d5d5d5" }}></div>
//               <div>or</div>
//               <div style={{ width: "100%", borderBottom: "1px solid #d5d5d5" }}></div>
//             </div>

//             <InputComponent
//               inputType="button"
//               fullWidth
//               type="submit"
//               name="Sign up"
//               variant="primary"
//               onClick={(e) => { e.preventDefault(); this.props.history.push("/SignUp") }}
//               classes="mt-2 mb-0"
//             />
//           </form>
//           <div className="col-md-3"></div>
//           <div className="col-md-3"></div>
//           <div className="col-md-5 text-right mt-3">
//             <a style={{ color: '#757575', margin: "0.5em" }} href="https://www.managex.ae/contact-us/" rel="noreferrer" target="_blank">Help</a>
//             <a style={{ color: '#757575', margin: "0.5em" }} href="https://www.managex.ae/privacy-policy/" rel="noreferrer" target="_blank">Privacy policy</a>
//             <a style={{ color: '#757575', margin: "0.5em" }} href="https://www.managex.ae/terms-of-services/" rel="noreferrer" target="_blank">Terms and conditions</a>
//           </div>
//           <div className="col-md-3"></div>
//         </div> */}
//       </>
//     );
//   }
// }
// const mapStateToProps = (state) => {
//   return state;
// }
// export default withRouter(connect(mapStateToProps, null)(Login));













import React from "react";
import cookie from "react-cookies";
//import {message} from "antd";
import Service from "../../components/Service/Api/Api";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import CustomLoader from "../Loader/Loader";
import { connect } from "react-redux";
import { setMainSession } from "../../store/actions/mainSessionActions";
import InputComponent from "../inputComponents/Input";
import image from '../../img/sign-up-graphic.png';

import Swal from "sweetalert2";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import "../Login/Login.css";


// import loader from '../Loader/index'
const initialState = {
  email: "",
  password: "",
  loading: "",
  token: cookie.load("bareer_Token"),
  emailError: "",
  passwordError: "",
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      token: cookie.load("bareer_Token"),
      emailError: "",
      passwordError: "",
      customLoader: false,
    };
  }
  componentDidMount() {

    const { token } = this.state;
    if (token !== undefined && token !== "") {
      var strURL = this.props.history.location.pathname;
      if (/\d/.test(strURL)) {
        var last = strURL.substring(strURL.lastIndexOf("/") + 1, strURL.length);
        this.props.history.push("/Questionnaire/" + last);
      } else {
        this.props.history.push("/dashboard");
      }
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  OnValidate = (e) => {
    let emailError = "";
    let passwordError = "";

    if (!this.state.email.includes("@") && !this.state.email.includes(".")) {
      emailError = "Plesae enter your valid email";
    }
    if (
      !this.state.password.match(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
      )
    ) {
      passwordError = "Please enter your valid password";
    }
    if (emailError || passwordError) {
      this.setState({ emailError, passwordError, loading: true });

      return false;
    } else {
      return true;
    }
  };

  validataEmail = () => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.state.email.match(mailformat)) {
      return true;
    }
    return false;
  }


  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.email && this.state.password !== '') {
      if (this.validataEmail()) {

        try {
          this.setState({ customLoader: true });
          const { email, password } = this.state;
          if (email !== "" && password !== "") {
            const isValidated = this.OnValidate();
            if (isValidated) {
              var data = {
                username: this.state.email,
                password: this.state.password,
              };
              Service.login(data)
                .then((res) => {
                  let allowedRoles = ["CompanyUser", "CompanyAdmin", "User"];
                  if (res && res.api_key) {
                    Service.GetSession(res.user.userName).then((responce) => {
                      if (responce) {
                        // New code START
                        // Saving the main session details in the main session Reducer
                        this.props.dispatch(setMainSession({ apiKey: responce.apiKey, token: responce.token, sessionId: responce.sessionId }));

                        // New code END
                        cookie.save("apiKey", responce.apiKey, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });
                        cookie.save("sessionId", responce.sessionId, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });
                        cookie.save("token", responce.token, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                      }
                      if (allowedRoles.includes(res.role[0]) && res.user.emailConfirmed) {
                        cookie.save("bareer_Token", res.api_key, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save("user_Id", res.user.userName, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save("Id", res.user.id, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save(
                          "user_Name",
                          res.user.firstName + " " + res.user.lastName,
                          {
                            path: "/",
                            maxAge: 5 * 3600,
                          }
                        );

                        cookie.save("Orgnization", res.user.comapnyName, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save("User_Role", res.user.userRole, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });

                        cookie.save("admin_email", res.adminemail, {
                          path: "/",
                          maxAge: 5 * 3600,
                        });
                        // ======================================================================================================================
                        if (res.istwofactorenable) {
                          this.setState({ customLoader: false });
                          cookie.save("isTwoFactorEnable", true, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          cookie.save("isVerified", false, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          cookie.save("User_Email", res.user.email, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          cookie.save("TFCode", res.authtoken, {
                            path: "/",
                            maxAge: 5 * 3600,
                          });
                          this.props.history.push("/Otp");
                        } else {
                          this.setState({ customLoader: false });
                          var queryString = this.props.location.pathname.split("/");
                          var RedirectUrlId = queryString[queryString.length - 1];
                          var returnUrl = localStorage.getItem("RedirectUrl");
                          // if (
                          //     RedirectUrlId &&
                          //     RedirectUrlId.toLowerCase() != "login"
                          // ) {
                          //     this.props.history.push(
                          //         "/Questionnaire/" + RedirectUrlId
                          //     );
                          //     // further if else for navigation required discussion required with usman
                          // } else if (returnUrl) {
                          //     window.location.href = returnUrl;
                          // } else {
                          //     this.props.history.push("/dashboard");
                          // }
                          this.props.history.push("/dashboard");
                        }
                      } else if (allowedRoles.includes(res.role[0]) && !res.user.emailconfirmed) {
                        this.setState({ customLoader: false });
                        Swal.fire({
                          icon: "error",
                          title: "Email Address Verification",
                          text: "Please activate your account first.",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        this.setState({ customLoader: false });
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Please enter valid credentials to login!",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    });
                  }
                })
                .catch((err) => {
                  this.setState({ customLoader: false });
                  if (err.toString().includes(401)) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Authentication error. Please contact to administrator",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong. Please try again later.",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                });
            } else {
              this.setState({ customLoader: false });
            }
          } else {
            this.setState({ customLoader: false });
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Please enter Email / Password first.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (e) {
          this.setState({ customLoader: false });
          console.log(e);
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Enter Valid Email.',
          showConfirmButton: false,
          timer: 1500
        });
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid email or password.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  render() {
    return (
      <>
        {this.state.customLoader ? <CustomLoader /> : null}

        <div class="authentication">
          <div class="container">
            <div class="col-md-12 content-center">
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="company_detail">
                    <h4 class="logo"><img src="assets/images/logo.svg" alt="" /> ManageX</h4>
                    <h3>Transforming your <strong>Business</strong></h3>
                    <p>ManageX, backed by VaporVM, is a top-notch platform for solving mission-critical roadblocks and problems from Medium to Large Enterprises to Fortune 500 companies.</p>
                    <div class="footer">
                      <ul class="social_link list-unstyled">
                        <li><a href="https://twitter.com/managexae" title="LinkedIn"><i class="zmdi zmdi-linkedin"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/managexae/" title="Facebook"><i class="zmdi zmdi-facebook"></i></a></li>
                        <li><a href="https://www.facebook.com/managex.ae" title="Twitter"><i class="zmdi zmdi-twitter"></i></a></li>
                      </ul>
                      <hr />
                      <ul>
                        <li><a href="http://thememakker.com/contact/" target="_blank">Contact Us</a></li>
                        <li><a href="http://thememakker.com/about/" target="_blank">About Us</a></li>
                        <li><a href="http://thememakker.com/services/" target="_blank">Services</a></li>
                        <li><a href="javascript:void(0);">FAQ</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-12 offset-lg-1">
                  <div class="card-plain">
                    <div class="header">
                      <h5>Log in</h5>
                    </div>
                    <form class="form" onSubmit={this.onSubmit}>
                      <div class="input-group">
                        {/* <input type="text" class="form-control" placeholder="User Name" /> */}
                        <InputComponent
                          inputType="inputField"
                          type="email"
                          name="email"
                          label="Email"
                          error={this.state.emailError}
                          floatingLabel
                          variant="primary"
                          onChange={this.onChange}
                          classes="form-control"
                        />
                        <span class="input-group-addon"><i class="zmdi zmdi-account-circle"></i></span>
                      </div>
                      <div class="input-group">
                        <InputComponent
                          inputType="inputField"
                          type="password"
                          name="password"
                          label="Password"
                          error={this.state.passwordError}
                          floatingLabel
                          variant="primary"
                          onChange={this.onChange}
                          classes="form-control"
                        />
                      </div>
                      <div class="input-group">
                        <InputComponent
                          inputType="button"
                          fullWidth
                          type="submit"
                          name="SIGN IN"
                          variant="secondary"
                          onClick={this.onSubmit}
                          onSubmit={this.onSubmit}
                          classes=""
                        />
                      </div>
                    </form>
                    <div class="footer">
                      <a onClick={(e) => { e.preventDefault(); this.props.history.push("/SignUp") }} class="btn btn-primary btn-simple btn-round btn-block">SIGN UP</a>
                    </div>
                    {/* <a href="forgot-password.html" class="link">Forgot Password?</a> */}
                    <Link style={{ color: '#757575', margin: "0.5em" }} to="/ForgetPassword">
                      Forget Password ?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
}
export default withRouter(connect(mapStateToProps, null)(Login));
