import React, { useState, useEffect, useRef } from "react";
import "./AssignedResources.css";

import { connect } from "react-redux";


const AssignedResources = ({ userList, callEngineer, connections }) => {
    return(
        <div className="mt-3">
        {
            userList.length > 0 ?
            userList.map(user=>{
                let isOnline = connections.find(connection=>connection.data.includes(user.userEmail));
                return(
                    <div className={`mx-engineer ${isOnline ? "online" : ""}`}>
                        <div className="mx-engineer-status"></div>
                        <p className="mx-engineer-name">{user.userName}</p>
                        <i className="zmdi zmdi-info-outline mx-engineer-info">
                            <div className="mx-engineer-info-main">
                                <div className="mx-engineer-info-row">Name: {user.userName}</div>
                                <div className="mx-engineer-info-row">Status: {isOnline ? "Available" : "Offline"}</div>
                            </div>
                        </i>
                        <button title="Call engineer" className="mx-engineer-call" onClick={()=>callEngineer({user, isOnline})}><i className="zmdi zmdi-phone"></i></button>
                    </div>
                );
            })
            :<p>No resource allocated.</p>
        }
    </div>
    )
}
const mapStateToProps = state => ({
    connections:state.mainSessionReducer.connections
});
export default connect(mapStateToProps, null)(AssignedResources);