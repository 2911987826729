import React from "react";
import Service from '../../components/Service/Api/Api'
import '../Communication/Call.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { OTSession, OTPublisher, OTStreams, OTSubscriber, } from 'opentok-react';
export default class Call extends React.Component {
  constructor(props) {
    super(props);
    var queryString = this.props.location.pathname.split('/');
    this.Reciver = queryString[queryString.length - 1];
    this.myEamil = queryString[queryString.length - 2];
    this.state = {
      error: null,
      connection: '',
      publishVideo: true,
      publishAudio: true,
      // videoSource: 'screen',
      type: 'DEFAULT',
      token: "",
      sessionId: "",
      ArchiveId: "",
      apiKey: "",
      signal: {
        type: '',
        data: ''
      },
      text: 'abc',
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      isResponceloded: false,
      isCallRecording: false,
      width: 500,
      height: 500
    };




    this.handleChange = this.handleChange.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.onSignalSend = this.onSignalSend.bind(this);
    this.onSignalRecieve = this.onSignalRecieve(this);
    this.otSession = React.createRef();

    this.sessionEventHandlers = {
      sessionConnected: () => {
        this.setState({ connection: 'Connected' });
      },
      sessionDisconnected: () => {
        this.setState({ connection: 'Disconnected' });
      },
      sessionReconnected: () => {
        this.setState({ connection: 'Reconnected' });
      },
      sessionReconnecting: () => {
        this.setState({ connection: 'Reconnecting' });
      }

    };
    this.publisherEventHandlers = {
      accessDenied: () => {
        console.log('User denied access to media source');
      },
      streamCreated: () => {
        console.log('Publisher stream created');
      },
      streamDestroyed: ({ reason }) => {
        console.log(`Publisher stream destroyed because: ${reason}`);
      }
    };
    this.subscriberEventHandlers = {
      videoEnabled: () => {
        console.log('Subscriber video enabled');
      },
      videoDisabled: () => {
        console.log('Subscriber video disabled');
      },
      videoDimensionsChanged: (event) => {
        //console.log('videoDimensionsChanged', event);
      }
    };
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    const element = document.getElementsByClassName('OTSubscriberContainer');
    if (element.length > 0) {
      window.updateElementHeight('OTSubscriberContainer', this.state.height, this.state.width);
    }
  };
  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
    this.setState({ windowHeight: window.innerHeight });
  }; onSessionError = error => {
    this.setState({ error });
  };

  onPublish = () => {
    console.log('Publish Success');
  };
  onPublishError = error => {
    this.setState({ error });
  };

  onSubscribe = () => {
    console.log('Subscribe Success');
  };

  onSubscribeError = error => {
    this.setState({ error });
  };
  toggleVideo = () => {
    this.setState(state => ({
      publishVideo: !state.publishVideo,
    }));
  };
  endCall = () => {
    if (this.state.ArchiveId != "") {
      this.onStopRecording();
    }
    window.endCall(this.myEamil, this.Reciver)
    window.close();

  };
  toggleAudio = () => {
    this.setState(state => ({
      publishAudio: !state.publishAudio,
    }));
  };
  toggleToken = () => {
    var userType = this.state.type === 'DEFAULT' ? 'PROVIDER' : 'DEFAULT';
    var token = this.state.type === 'DEFAULT' ? this.props.credentials.providerToken : this.props.credentials.token;
    this.setState({ type: userType });
    this.setState({ token: token });
  }

  handleChange(event) {
    this.setState({ text: event.target.value });
  }

  onSignalSend = () => {
    var value = this.state.text;
    this.otSession.current.sessionHelper.session.signal({
      type: 'msg',
      data: value
    }, function signalCallback(error) {
      if (error) {
        console.error('Error sending signal:', error.name, error.message);
      } else {
        console.log(value);
      }
    });
  }

  onSignalRecieve = (data) => {
    // debugger;
    // console.log('onSignalRecieve');
    // console.log(data);
  }
  onStartRecording = (data) => {
    this.setState({ isCallRecording: true })
    console.log(this.state.sessionId)
    Service.StartArchive(this.state.sessionId)
      .then(res => {
        this.setState({ ArchiveId: res })
        alert("start recording");
      })
      .catch(err => console.log(err));
  }
  onStopRecording = (data) => {
    this.setState({ isCallRecording: false })
    //alert("Recording stop");
    Service.StopArchive(this.state.ArchiveId)
      .then(res => {
        alert("Url : " + res);
      })
      .catch(err => console.log(err));
  }
  componentDidMount() {
    this.updateDimensions();

    window.addEventListener('resize', this.updateDimensions);
    console.log(this.Reciver)
    var data = {
      "email": this.myEamil,
      "otherEmail": this.Reciver,
      "IsCaller": false
    }
    Service.GetVideoSession(data).then(res => {
      if (res) {
        this.setState({ apiKey: res.apiKey, sessionId: res.sessionId, token: res.token, isResponceloded: true });
        console.log(this.state)
        window.addEventListener('resize', this.updateDimensions);
      }

    })
    // window.addEventListener("resize", this.handleResize);
  }
  render() {
    const { apiKey, sessionId } = this.state;
    const { error, connection, publishVideo, publishAudio } = this.state;
    const { windowWidth, windowHeight } = this.state;
    return (
      <>
        <div id='callScreen'></div>
        {
          this.state.isResponceloded ? <OTSession
            ref={this.otSession}
            apiKey={apiKey}
            sessionId={sessionId}
            token={this.state.token}
            onError={this.onSessionError}
            eventHandlers={this.sessionEventHandlers}
            onSignalRecieve={this.onSignalRecieve}

          >
            <div className='app-publisher'>
              <OTPublisher
                properties={{ publishVideo, publishAudio, width: 200, height: 200 }}
                onPublish={this.onPublish}
                onError={this.onPublishError}
                eventHandlers={this.publisherEventHandlers}
              />
            </div>
            <div className='app-subscriber' style={{ width: this.state.width, height: this.state.height }}>
              <OTStreams>
                <OTSubscriber
                  properties={{ width: this.state.width, height: this.state.height }}
                  onSubscribe={this.onSubscribe}
                  onError={this.onSubscribeError}
                  eventHandlers={this.subscriberEventHandlers}
                />
              </OTStreams>
              <div className="callcontroldiv row ml-2">
                {/* <div className="col-4"></div>                  */}
                <div>
                  {this.state.publishVideo ?
                    <button className="btn btn-primary" title="Camera Off" onClick={this.toggleVideo}><i className="fa fa-video-camera"></i></button>
                    :
                    <button className="btn btn-danger" title="Camera On" onClick={this.toggleVideo}><i className="fa fa-camera" aria-hidden="true"></i></button>
                  }
                  {this.state.publishAudio ?
                    <button className="btn btn-primary" title="Mic Off" onClick={this.toggleAudio}><i className="fa fa-microphone" aria-hidden="true"></i></button>
                    :
                    <button className="btn btn-danger" title="Mic On" onClick={this.toggleAudio}> <i className="fa fa-microphone-slash" aria-hidden="true"></i> </button>
                  }
                  <button className="btn btn-danger" title="End Call" onClick={this.endCall}><i className="fa fa-phone" aria-hidden="true"></i></button>
                </div>
                {/* <div className="col-4"></div>                  */}


              </div>



            </div>
          </OTSession> : <h1>Loader</h1>
        }

      </>


    )
  }
}