import React from "react";
import Service from '../../components/Service/Api/Api'
import queryString from 'query-string'
import Swal from "sweetalert2";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    ElementsConsumer,
    CardElement,
  } from "@stripe/react-stripe-js";


//const stripePromise = loadStripe("pk_test_51Iuz0GGMmvyl2mlxa0ci4gXhpw9JOACRC6V2yJyLU6vgJImVZSUvXRg4TWykmdGKWEaeDLE8CWlvmOA1P4AQOTE200hO7M5fjB");

const appearance = {
    theme: 'stripe',
  
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      // See all possible variables below
    }
  };

export default class StripePaymentForm extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            CustomerOrderInfo : ''
        }
    }

    componentDidMount(){
        try{
            // if(this.props.QuotationNumber != null && this.props.QuotationNumber != undefined && this.props.QuotationNumber != ""){
            //     this.getCustomerOrder(this.props.QuotationNumber);
            // }
        }
        catch(e){
            console.log(e);
        }
    }

    
    getCustomerOrder = (customerOrderId) => {
        try{
            Service.getQuotation(customerOrderId).then(res => {
                if (res) {
                    this.setState({
                        CustomerOrderInfo : res
                    });
                }
            });
        }
        catch(e){
            console.log(e);
        }
    }
    
    render(){
        return(
            <>
            <div className="container">
                <div className="row">
                    <h1>Stripe Form</h1>
                    <div style={{height:'20%', width:'100%'}}>
                    <CardElement options={appearance} />
                    </div>
                    
                </div>
            </div> 
            </>
        )
    }
}

