import { Encrypt , Decrypt } from "../Utility/Encryption";

export const AddToCart = (key,value) => {
    try{
        if(localStorage.getItem(key) === null || localStorage.getItem(key) === undefined){
            // Logic  : encrypt value first and convert it into string and then store 
            // Need array for storing package id only
            let dataArr = [];
            dataArr.push(value);
            let _encryptedText = Encrypt(JSON.stringify(dataArr));
            localStorage.setItem(key,_encryptedText);
        }
        else{
            // Logic : get valye from local storage first and add value in it then add again in local storage 
            let _value = localStorage.getItem(key);
            let decryptString =  Decrypt(_value);
            let _arr = JSON.parse(decryptString);
            // need validation ( if item is already added or not)
            if(_arr.indexOf(value) === -1){
                _arr.push(value);
                let _encryptedText = Encrypt(JSON.stringify(_arr));
                localStorage.setItem(key,_encryptedText);
            } 
        }
    }
    catch(e){
        console.log("error in AddToCart : " + e);
    }
}

export const RemoveFromCart = (key,value) => {
    try{
        let strCartItem = localStorage.getItem(key);
        if(strCartItem){
            let decryptString =  Decrypt(strCartItem);
            let _arr = JSON.parse(decryptString);
            const index = _arr.indexOf(value);
            if(index > -1){
                _arr.splice(index,1);
                let _encryptedText = Encrypt(JSON.stringify(_arr));
                localStorage.setItem(key,_encryptedText);
            }
        }
    }
    catch(e){
        console.log("error in RemoveFromCart : " + e);
    }
}

export const CheckCartItem = (key,value) => {
    try{
        let strCartItem = localStorage.getItem(key);
        if(strCartItem){
            let decryptString =  Decrypt(strCartItem.toString());
            let _arr = JSON.parse(decryptString);
            const index = _arr.indexOf(value.toString());
            if(index > -1){
               return true;
            }
            return false;
        }
    }
    catch(e){
        console.log("Error in CheckCartItem : " + e);
        return false;
    }
}
// zargham START
export const checkCartPackage = (key,value) => {
    try{
        let strCartItem = localStorage.getItem(key);
        if(strCartItem){
            let decryptString =  Decrypt(strCartItem.toString());
            let _arr = JSON.parse(decryptString);
            let result = false;
            _arr.forEach(index=>{
                if(Number(value.id) === Number(index.id)){
                    result={
                        id:index.id,
                        qty:index.qty
                    };
                }
            });
            return result;
        }
    }
    catch(e){
        console.error("Error checking cart for " + key + "having value " + value ,e)
        return false
    }
}
export const RemoveCartPackage = (key,value) => {
    try{
        let strCartItem = localStorage.getItem(key);
        if(strCartItem){
            let decryptString =  Decrypt(strCartItem);
            let _arr = JSON.parse(decryptString);
            const filteredArray =[];
            _arr.forEach(index=>{
                if(Number(value.id) !== Number(index.id)){
                    filteredArray.push(index);
                }
            });
            let _encryptedText = Encrypt(JSON.stringify(filteredArray));
            localStorage.setItem(key,_encryptedText);
        }
    }
    catch(e){
        console.log("error in RemoveFromCart : " + e);
    }
}
// zargham END

export const GetCartItems = (key) => {
    try{
        let strCartItem = localStorage.getItem(key);
        if(strCartItem){
            let decryptString =  Decrypt(strCartItem.toString());
            let _arr = JSON.parse(decryptString);
            return _arr;
        }
        else{
            return null;
        }
    }
    catch(ex){
        console.log(ex);
        return null;
    }
}