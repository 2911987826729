import React from "react";
import CyberSecurity from "./CyberSecurityListItems";


export default class ParentService extends React.Component {

    constructor(props) {
        super(props);
    }

    // componentDidMount() {
    //     try {

    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }
    isStepsScreen = this.props.isStepsScreen;

    RenderSelectedService() {
        if (this.props.SelectedServiceName === "1") {
            return (
                <div className="card">
                    <h5 className="card-header">Cyber Security Services</h5>
                    <div className="card-body">
                        <div style={{ minHeight: '5%', maxHeight: '5%', overflowY: 'auto' }}>
                            <CyberSecurity
                                SubServiceIDs={this.props.SubServiceIDs}
                                ListSubServices={this.props.SubServicesList}
                                EventSubServiceID={this.props.SelectSubServiceIdEvent}
                                SubServiceEvent={this.props.SelectedSubServiceEvent}
                                SubServiceValue={this.props.SelectedSubServiceName}
                            />
                        </div>
                    </div>
                </div>



            );
        }

        else {
            return (
                <div className="card">
                    <h5 className="card-header">Select Domain(s) to view Services</h5>
                    <div className="card-body">
                        <div style={{ minHeight: '5%', maxHeight: '5%', overflowY: 'auto' }}>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        //alert(this.props.ParentServicesList.length); 
        const _self = this;
        var _ParentList = this.props.ParentServicesList.length === 0 ? " Loading..." : this.props.ParentServicesList.map(function (item) {
            return (
                <div>
                    {/* checked={_self.props.SelectedServiceName !== "steps" ? item.id : null} Temporary comment that part*/} {/* This part is not needed anymore. The new logic is written below */}
                    <input
                        checked={_self.props.SelectedParentService ? _self.props.SelectedParentService : null}
                        onClick={_self.props.setParentService}
                        name="rdServices"
                        value={item.id}
                        type="radio"
                    />
                    <b> {item.name} </b>

                </div>);
        });

        return (
            <>

                <div className={`${this.isStepsScreen ? "container" :""}`}>
                    <div className="row">
                        <div className="col col-5">
                            <div>
                                <div className="row">
                                    <b>What service would you like to acquire ?</b>
                                </div>
                                <div className="row" onChange={this.props.SelectedServiceEvent} style={{ marginTop: '1%', alignItems: "center" }}>

                                    {_ParentList}
                                </div>
                            </div>
                        </div>
                        <div className="col col-7">
                            <div className="card">
                                {this.RenderSelectedService()}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="button is-large is-success is-inverted" style={this.props.buttonStyles} onClick={this.props.NextClickEvent}>Next &gt;</button>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}

