import React from "react";
//import {message} from "antd";
import Service from '../../Service/Api/Api'
import Swal from "sweetalert2";
import { signupTemplate } from "../../EmailTemplates/signupTemplate";
import "../Login/login.css";
import InputComponent from "../../inputComponents/Input";
// import image from '../../img/sign-up-graphic.png';
import CustomLoader from "../../Loader/Loader";
import Logo from '../../../img/logo.png'


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter
} from "react-router-dom";
class SignUp extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            email: '',
            username: '',
            Password: '',
            Repassword: '',
            FirstName: '',
            LastName: '',
            PhoneNumber: '',
            CompanyName: '',
            Role: 'User',
            TwoFactorEnable: true,
            customLoader: false,
        };
    }
    //handleChange = this.handleChange.bind(this)
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value.trim() });
    }

    // handleCheck = () => {
    //     this.setState({ TwoFactorEnable: !this.state.TwoFactorEnable });
    // }

    ValidateEmail = () => {
        try {
            if (this.state.email.indexOf('gmail') > -1) {
                return false;
            }
            else if (this.state.email.indexOf('yahoo') > -1) {
                return false;
            }
            else if (this.state.email.indexOf('outlook') > -1) {
                return false;
            }
            else if (this.state.email.indexOf('hotmail') > -1) {
                return false;
            }
            else {
                const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (this.state.email.match(mailformat)) {
                    return true;
                }
                return false;
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    validataName = (name) => {
        const reg = /^[A-Za-z]+$/
        if (name !== "" && (name.match(reg))) {
            return true
        }
        return false
    }

    //   validate the phone to only have numbers
    ValidatePhone = (text) => {
        const reg = /^\d+$/;
        if (text && `${text}`.match(reg, text)) {
            return true
        }
        else return false
    }

    handleSubmit = (event) => {
        event.preventDefault()
        var passw = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
        if ((this.state.FirstName && this.state.LastName && this.state.email && this.state.Password && this.state.PhoneNumber) !== "") {
            if (this.validataName(this.state.FirstName) && this.validataName(this.state.LastName)) {
                if (this.ValidateEmail()) {
                    if (this.state.PhoneNumber.length <= 7 || this.state.PhoneNumber.length > 15) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Phone number should greater than 7 and less than 15 digits.',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                    else if (this.state.Password !== this.state.Repassword) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Password and retype pasword must be same',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                    else if (!this.ValidatePhone(this.state.PhoneNumber)) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Phone Number can only have numbers',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                    else if (this.state.Password.match(passw)) {
                        this.setState({ customLoader: true });
                        Service.createCustomer(this.state).then(res => {
                            if (res.status == "Success") {
                                var EamilData = new FormData();
                                EamilData.append('ToEmail', this.state.email);
                                EamilData.append('Subject', 'Welcome to ManageX, Lets solve problems');
                                var _bodyTemplate = signupTemplate;
                                var Replaceversion = _bodyTemplate.replace("{UserName}", this.state.FirstName + " " + this.state.LastName).replace("{url}", res.url);
                                //var _emailBody = '<p>Your Account has been successfully created. Click ' + '<a href="'+ res.url +'">link</a>' + ' to activate your account.</p>';
                                EamilData.append('Body', Replaceversion);
                                this.setState({ customLoader: false });
                                Service.SendEmail(EamilData);
                                Swal.fire({
                                    title: 'User has been successfully Created',
                                    text: 'Kindly verify your account by the link in your Email',
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonText: 'Ok',
                                    cancelButtonText: 'No, keep it'
                                }).then((result) => {
                                    if (result.value) {
                                        this.props.history.push("/login")
                                    }
                                })
                            }
                        }).catch(err => {
                            this.setState({ customLoader: false });
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'user already Exist!',
                                showConfirmButton: false,
                                timer: 1500
                            });
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Password must contain uppercase ,lowercase, number, special character!',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                }

                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Enter Valid Email.',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'First Name and Last Name must be characters only.',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter all fields.',
                showConfirmButton: false,
                timer: 1500
            });
        }
        event.preventDefault();
    }
    render() {
        return (
            <>
                {this.state.customLoader ? <CustomLoader /> : null}


                <div className="authentication">
                    <div className="container">
                        <div className="col-md-12 content-center">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="company_detail">
                                        <h4 class="logo"><img src={Logo} alt="" /></h4>
                                        <h3>Transforming your <strong>Business</strong></h3>
                                        <p>ManageX is a top-notch platform for solving mission-critical roadblocks and problems from Medium to Large Enterprises to Fortune 500 companies.</p>
                                        <div className="footer">
                                            <ul class="social_link list-unstyled">
                                                <li><a href="https://twitter.com/managexae" title="LinkedIn"><i class="zmdi zmdi-linkedin"></i></a></li>
                                                <li><a href="https://www.linkedin.com/company/managexae/" title="Facebook"><i class="zmdi zmdi-facebook"></i></a></li>
                                                <li><a href="https://www.facebook.com/managex.ae" title="Twitter"><i class="zmdi zmdi-twitter"></i></a></li>
                                            </ul>
                                            <hr />
                                            <ul>
                                                <li><a href="https://www.managex.ae/contact-us/" target="_blank">Contact Us</a></li>
                                                <li><a href="https://www.managex.ae/about-us/" target="_blank">About Us</a></li>
                                                <li><a href="https://www.managex.ae/privacy-policy/" target="_blank">Privacy Policy</a></li>
                                                <li><a href="https://www.managex.ae/terms-of-services/">Terms Of Services</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 offset-lg-1">
                                    <div className="card-plain text-center">
                                        <div className="header">
                                            <h5>Sign Up</h5>
                                            <span>Register a new membership</span>
                                        </div>
                                        <form className="form" onSubmit={this.handleSubmit}>
                                            <div className="input-group">
                                                <InputComponent
                                                    inputType="inputField"
                                                    type="text"
                                                    name="FirstName"
                                                    label="First Name"
                                                    error={this.state.emailError}
                                                    floatingLabel
                                                    variant="primary"
                                                    onChange={this.handleChange}
                                                    classes="form-control"
                                                />
                                                <span className="input-group-addon"><i className="zmdi zmdi-account-circle"></i></span>
                                            </div>
                                            <div className="input-group">
                                                <InputComponent
                                                    inputType="inputField"
                                                    type="text"
                                                    name="LastName"
                                                    label="Last Name"
                                                    error={this.state.emailError}
                                                    floatingLabel
                                                    variant="primary"
                                                    onChange={this.handleChange}
                                                    classes="form-control"
                                                />
                                                <span className="input-group-addon"><i className="zmdi zmdi-email"></i></span>
                                            </div>
                                            <div className="input-group">
                                                <InputComponent
                                                    inputType="inputField"
                                                    type="email"
                                                    name="email"
                                                    label="Email"
                                                    error={this.state.emailError}
                                                    floatingLabel
                                                    variant="primary"
                                                    onChange={this.handleChange}
                                                    classes="form-control"
                                                />
                                                <span className="input-group-addon"><i className="zmdi zmdi-email"></i></span>
                                            </div>

                                            <div className="input-group">
                                                <InputComponent
                                                    inputType="inputField"
                                                    type="tel"
                                                    name="PhoneNumber"
                                                    label="Phone no"
                                                    error={this.state.emailError}
                                                    floatingLabel
                                                    variant="primary"
                                                    onChange={this.handleChange}
                                                    classes="form-control"
                                                />
                                                <span className="input-group-addon"><i className="zmdi zmdi-email"></i></span>
                                            </div>
                                            <div className="input-group">
                                                <InputComponent
                                                    inputType="inputField"
                                                    type="password"
                                                    name="Password"
                                                    label="Password"
                                                    error={this.state.passwordError}
                                                    info="Password must contain uppercase, lowercase, number, special character"
                                                    floatingLabel
                                                    variant="primary"
                                                    onChange={this.handleChange}
                                                    classes="form-control"
                                                />
                                            </div>
                                            <div className="input-group">
                                                <InputComponent
                                                    inputType="inputField"
                                                    type="password"
                                                    name="Repassword"
                                                    label="Confirm password"
                                                    error={this.state.passwordError}
                                                    floatingLabel
                                                    variant="primary"
                                                    onChange={this.handleChange}
                                                    classes="form-control"
                                                />
                                            </div>
                                            {/* <div className="control has-icons-right my-2 d-flex align-items-center" style={{ gap: "5px" }}>
                                                <input
                                                    type="Checkbox"
                                                    name="TwoFactorEnable"
                                                    onChange={this.handleCheck}
                                                    style={{ height: "15px", width: "15px" }}
                                                />
                                                <b> Enable two factor authentication</b>
                                            </div> */}
                                            <div className="input-group">
                                                <InputComponent
                                                    inputType="button"
                                                    fullWidth
                                                    type="submit"
                                                    name="SIGN UP"
                                                    variant="secondary"
                                                    classes="mb-1"
                                                />
                                            </div>
                                        </form>

                                        {/* <a className="link" onClick={(e) => { e.preventDefault(); this.props.history.push("/login") }}>You already have a membership?</a> */}

                                        <Link className="clr-sc hover:clr-sc" to="/login">
                                            Already have an account?
                                        </Link>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(SignUp);