import React, { Component, createRef } from 'react';
import Swal from 'sweetalert2';
import { AddToCart, RemoveFromCart , CheckCartItem, checkCartPackage,RemoveCartPackage, GetCartItems } from '../Common/Cart';
import './Card.css';
import exclamationIcon from '../../img/exclamation.png'

export default class Card extends Component {

    constructor(props) {
        super(props);
        this.listWrap = createRef();
        this.firstListItem = createRef();
        this.state = {
            isCartAdded: false, // use for checking whether package is added in cart or not...
            packageQuantity:null, // the actual package quantity that will be used for price calculations
            inputQuantity:null, // the quantity only related to input field. It is just for the view purpose. This can get higher or lower than min and max value so it will not be used for price calculation
            customAlert:false,
            listHeight:"",
            listOverflow:"hidden",
            lastClicked:false
        }
    }
    toggleListCollapse = (e)=>{
        const setIsListCollapsed = this.props.setIsListCollapsed;
        const isListCollapsed = this.props.isListCollapsed;
        setIsListCollapsed(this.props.item.packageId);
        if(this.state.lastClicked){
            setIsListCollapsed(-1);
        }
    }
    setInitialHeight = () =>{
        const initialHeight = `${this.firstListItem.current.scrollHeight}px`;
        this.setState({
            listHeight:initialHeight,
            listOverflow:"hidden"
        })
    }
    componentDidUpdate(prevProps, prevState) {
        const height = `${this.listWrap.current.scrollHeight}px`;
        const initialHeight = `${this.firstListItem.current.scrollHeight}px`;
        if (prevProps.isListCollapsed !== this.props.isListCollapsed) {
            if(this.props.isListCollapsed === this.props.item.packageId){
                this.setState({
                    listHeight:height,
                    listOverflow:"visible",
                    lastClicked:true
                });
            }
            else{
                this.setState({
                    listHeight:initialHeight,
                    listOverflow:"hidden",
                    lastClicked:false
                });
            }
        }
    }
    componentDidMount() {
        this.setInitialHeight();
        // var isExist = CheckCartItem("cart_items", this.props.item.packageId); // commented by Zargham
        const packageToCheck = {
            id:this.props.item.packageId,
            qty:this.state.packageQuantity
        }
        var isExist = checkCartPackage("cart_items", packageToCheck);
        this.setState({ isCartAdded: isExist });
        if(isExist){
            if(isExist.qty !== undefined && isExist.qty !== null && isExist.qty !== false){
                this.setState({
                    packageQuantity:isExist.qty,
                    inputQuantity:isExist.qty
                })
            }
        }
        else{
            this.setState({
                packageQuantity:this.props.minQty,
                inputQuantity:this.props.minQty
            });
        }
        this.props.checkPackageInCart();
    }
    showCustomAlert = (value) => {
        this.setState({
            customAlert:value
        });
    }
    changeQuantity = (e) => {
        e.preventDefault();
        const {minQty, maxQty} = this.props;
        const { buttonType } = e.currentTarget.dataset; // will be either 'increment' or 'decrement'
        this.setState({
            inputQuantity:this.state.packageQuantity
        },()=>{
            if(buttonType === 'increment' && this.state.packageQuantity < maxQty){
                this.setState({
                    packageQuantity:Number(this.state.packageQuantity) + 1,
                    inputQuantity:Number(this.state.inputQuantity) + 1
                });
            }
            else if(buttonType === 'decrement' && this.state.packageQuantity > minQty){
                this.setState({
                    packageQuantity:Number(this.state.packageQuantity) - 1,
                    inputQuantity:Number(this.state.inputQuantity) - 1
                });
            }
            this.showCustomAlert(false);
        })
    }
    quantityInputHandler = (e) =>{
        // e.preventDefault();
        const key = e.key;
        // we only want the numeric values to be entered as our quantity
        const allowedInput = ['0','1','2','3','4','5','6','7','8','9'];
        if(allowedInput.indexOf(key) !== -1){
            const newValue = Number(`${this.state.inputQuantity}${e.key}`)
            this.setState({
                inputQuantity:newValue
            },updateQuantity);
        }
        else if(e.key === "Backspace" || e.key === "Delete"){
            this.setState({
                inputQuantity:0
            },updateQuantity);
        }
        function updateQuantity (){
            const {minQty, maxQty} = this.props;
            if(this.state.inputQuantity >= minQty && this.state.inputQuantity <= maxQty){
                this.setState({
                    packageQuantity:this.state.inputQuantity
                });
                this.showCustomAlert(false);
            }
            else{
                this.showCustomAlert(true);
            }
        }
    }
    AddPackage = (e) => {
        try {
            if (!this.state.isCartAdded && !this.state.customAlert) {
                //alert(process.env.NODE_ENV);
                // e.target.style.backgroundColor = "red";
                this.setState({
                    isCartAdded: true
                });
                const packageToAdd = {
                    id:e.target.getAttribute("btnPackageID"),
                    qty:this.state.packageQuantity,
                    price:this.props.item.price
                }
                AddToCart("cart_items", packageToAdd);
                AddToCart("cart_sub_services", e.target.getAttribute("subServiceID"));
                this.props.setIsSubServiceSelected(true);
            }
            else {
                this.setState({
                    isCartAdded: false
                });
                // RemoveFromCart("cart_items", e.target.getAttribute("btnPackageID")); // commented by Zargham
                const packageToRemove={
                    id:e.target.getAttribute("btnPackageID"),
                    qty:this.state.packageQuantity
                }
                RemoveCartPackage("cart_items", packageToRemove);
                RemoveFromCart("cart_sub_services", e.target.getAttribute("subServiceID"));
                this.props.setIsSubServiceSelected(false);
            }
            this.props.checkPackageInCart();
        }
        catch (ex) {
            console.log(ex);
        }
    }


    render() {
        var _self = this;
        const isListCollapsed = this.props.isListCollapsed;
        return (
            <>
                <div className='col-xl-3 col-lg-4 col-md-6 p-2 custom-card'>
                    <div className={`custom-card__inner ${this.state.customAlert ? 'has-error' : ""}`}>
                        {
                            this.state.customAlert ?
                            <div className='custom-alert-message'>
                                <img src={exclamationIcon} style={{display:"block",margin:"auto"}}/>
                                Value should be greater than {this.props.minQty - 1} and upto {this.props.maxQty}
                            </div>
                            :""
                        }
                        <div className="text-center custom-card__content">
                            <div className="custom-card__quantity-tooltip-wrap">
                                <p className="custom-card__title">
                                    {this.props.item.packageName.split("").map(value=>value === "(" ? `\n${value}` : value)}
                                    { this.state.isCartAdded ? <i class="zmdi zmdi-check added-to-cart-icon"></i> : "" }
                                    {
                                        this.props.isQtyEnable ?
                                        <span>{`\n(${this.props.minQty} - ${this.props.maxQty})`}</span>
                                        :""
                                    }
                                </p>
                                <div className="custom-card__quantity-tooltip" style={{width:"20vw"}}>{this.props.item.packageName}</div>
                            </div>
                            <h5><span className='custom-card__quantity-price'>${(Number(this.props.item.price) * Number(this.state.packageQuantity)).toFixed(2)}</span>{this.props.isQtyEnable ? `/ ${this.state.packageQuantity} items` : ""}</h5>
                            <div className='custom-card__quantity-divider'>
                                <div className='custom-card__quantity-wrap'>
                                    <button className='custom-card__quantity-button' data-button-type="decrement" disabled={!this.state.isCartAdded && this.props.isSubserviceSelected ? true : null || this.state.isCartAdded ? true : !this.props.isQtyEnable ? true : null} onClick={this.props.isQtyEnable ? this.changeQuantity : ""}>-</button>
                                    <input className='custom-card__quantity-input' data-button-type="input" value={this.state.inputQuantity} disabled={!this.state.isCartAdded && this.props.isSubserviceSelected ? true : null || this.state.isCartAdded ? true : !this.props.isQtyEnable ? true : null} onKeyDown={this.props.isQtyEnable ? this.quantityInputHandler : ""}/>
                                    <button className='custom-card__quantity-button' data-button-type="increment" disabled={!this.state.isCartAdded && this.props.isSubserviceSelected ? true : null || this.state.isCartAdded ? true : !this.props.isQtyEnable ? true : null} onClick={this.props.isQtyEnable ? this.changeQuantity : ""}>+</button>
                                </div>
                                {
                                    this.props.isQtyEnable ?
                                    null
                                    :
                                    <i className="zmdi zmdi-info custom-card__quantity-tooltip-wrap" style={{fontSize:"22px", color:"var(--bg-color-pr)"}}>
                                        <div className='custom-card__quantity-tooltip' style={{whiteSpace:"nowrap"}}>Custom quantity not allowed</div>
                                    </i>
                                }
                            </div>
                        </div>
                        <ul ref={this.listWrap} className='custom-card__list' style={{maxHeight:this.state.listHeight, overflow: this.state.listOverflow }}>
                        <button title={isListCollapsed ? "View items" : "Hide items"} className={`custom-card__list-dropdown ${this.props.isListCollapsed === this.props.item.packageId ? "rotate" : ""}`} onClick={this.toggleListCollapse}>
                            <i className='zmdi zmdi-chevron-down'></i>
                        </button>
                        {
                            this.props.item.lstDetails.length > 0 ? this.props.item.lstDetails.map((pkgdetail,i)=>{
                            return(
                                <div ref={i===0 ? this.firstListItem : ""} className='custom-card__list-item-wrap'>
                                    <i className='zmdi zmdi-check custom-card__list-icon'></i>
                                    <li title={pkgdetail.label + " : "  + pkgdetail.text} className='custom-card__list-item'>{pkgdetail.label + " : "  + pkgdetail.text} </li>
                                </div>
                            )
                            }) : <b>Description unavailable ! </b>
                        }
                        </ul>
                        <div className="text-center custom-card__add-to-cart-wrap">
                            <button
                                // style={{width:"100%"}}
                                disabled={!this.state.isCartAdded && this.props.isSubserviceSelected || this.state.customAlert ? true : null}
                                className={`custom-card__button ${!this.state.isCartAdded && this.props.isSubserviceSelected || this.state.customAlert ? 'button-disabled' : '' }`} 
                                onClick={_self.AddPackage} btnPackageID={this.props.item.packageId} subServiceID={this.props.item.subServiceID}>
                                {this.state.isCartAdded && this.props.isSubserviceSelected ? "Remove from cart" : "Add to cart"}
                            </button>
                            {/* {_Descriptions} */}
                        </div>
                        {
                            this.state.isCartAdded ? <div className='custom-card__cart-indication'>Item added to cart</div>
                            :""
                        }
                        
                    </div>
                </div>
            </>
        );
    }

}

// push test