import React from "react";
import Service from "../../components/Service/Api/Api";
import queryString from "query-string";
import Swal from "sweetalert2";
import cookie from "react-cookies";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../Loader/Loader";
import { withRouter } from "react-router-dom";


import uploadIcon from '../../img/CustomImages/icons/upload.png'
import removeIcon from '../../img/CustomImages/icons/remove.png'

// import {
//     Elements,
//     ElementsConsumer,
//     CardElement,
//     CheckoutForm,
//     PaymentElement
//   } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// import {Tabs , Tab} from 'react-bootstrap';
import StripeCheckout from "react-stripe-checkout";
import StripePaymentForm from "./StripePaymentForm";
import CheckoutForm from "./CheckoutForm";
import "../Login/Login.css";
import { GoogleLogin } from "react-google-login";
import CustomLoader from "../Loader/Loader";

const stripePromise = loadStripe(
  "pk_test_51Iuz0GGMmvyl2mlxa0ci4gXhpw9JOACRC6V2yJyLU6vgJImVZSUvXRg4TWykmdGKWEaeDLE8CWlvmOA1P4AQOTE200hO7M5fjB"
);

const ClientID = "416413332650-vc8r12v7ro73sp2stpv7a5bstuma04mm.apps.googleusercontent.com"; //"416413332650-69r7a1ec8jv620hpa5386kud68o2hhag.apps.googleusercontent.com";
//"416413332650-vc8r12v7ro73sp2stpv7a5bstuma04mm.apps.googleusercontent.com";

var redirectmethod = async (res) => {
  try {
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
      sessionId: res,
    });
  } catch (e) {
    alert(e);
  }
};

class StripePaymentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.purchaseOrder = React.createRef();
    this.email = cookie.load("user_Id");
    // this.domain = this.email.toLowerCase().split("@")[1].split(".")[0];
    // this.emailUsername = this.email.toLowerCase().split("@")[0];
    // console.log(`username: ${this.emailUsername}, domain: ${this.domain}`);
    this.isAudi = this.email.toLowerCase().includes("audie");
    this.isIngramMicro = this.email.toLowerCase().includes("@ingrammicro");
    this.state = {
      CustomerOrderInfo: "",
      customLoader: false,
      selectedFile: '',
      isAlert: false,
      isLoader: false
    };
  }

  componentDidMount() {
  }

  hidemodal = () => {
    this.setState({ ismodalshow: false });
  };

  // this will call when user sign in or signup sucessfully
  // Client secret key method
  PrePayment = () => {
    try {
      this.setState({ customLoader: true });
      let inputModel = {
        userId: cookie.load('user_Id'),
        ivp_method: "create",
        ivp_store: "",
        ivp_authkey: "",
        ivp_amount: parseFloat(this.props.TotalAmount),
        ivp_currency: "",
        ivp_test: 1,
        ivp_cart: this.props.QuotationNumber, //this.state.customerOrder.Quotation.Id,
        ivp_desc: "No Description",
        return_auth: window.location.origin + "/#/steps/success", //"/#/paymentSuccess",
        return_decl: window.location.origin + "/#/steps/reject",
        return_can: window.location.origin + "/#/steps/reject",
        ServiceName: "ManageX Packages", // Currently i intentially passing hardcoded later we will change this 
        //this.props.QuotationNumber, //this.props.ServiceName,
      };
      Service.CreateTransaction(inputModel).then((res) => {
        if (res) {
          redirectmethod(res);
        }
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  getCustomerOrder = (customerOrderId) => {
    try {
      Service.getQuotation(customerOrderId).then((res) => {
        if (res) {
          this.setState({
            CustomerOrderInfo: res,
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  SavePaymentInfo = () => { };

  UploadButtonHandler = (e) => {
    e.preventDefault();
    if (this.purchaseOrder.current) {
      this.purchaseOrder.current.click();
    }
  }
  PurchaseOrderHandler = (e) => {
    // e.preventDefault();
  }
  // Backup===============================================================================================================================================
  // SubmitPurchaseOrder = (e) =>{
  //   if(e.target.files[0]){
  //     console.log(e.target.files[0]);
  //     const filename = e.target.files[0].name;
  //     const fileSize = e.target.files[0].size;
  //     if(filename.includes(".pdf") || filename.includes(".docx")){
  //       if( ((fileSize/1024)/1024).toFixed(4) < 5){
  //         this.setState({
  //           selectedFile:e.target.files[0]
  //           // selectedFile: {
  //           //   ...e.target.files[0],
  //           //   name:`PO_${cookie.load("user_Name")}_${new Date().toDateString()}_${new Date().toTimeString()}`
  //           // }
  //         },()=>{
  //           // Update the formData object 
  //           const formData = new FormData();
  //           formData.append("myFile", this.state.selectedFile);

  //           for(var key of formData.entries()) {
  //             console.log(key[0] + ', ' + key[1]);
  //           }
  //           Swal.fire({
  //             title: 'Do you want to upload the selected document?',
  //             text: `Filename: ${this.state.selectedFile.name}`,
  //             showCancelButton: true,
  //             confirmButtonText: 'Upload',
  //             confirmButtonColor:"#93d85e",
  //             cancelButtonColor:"#dd3333"
  //           }).then((result) => {
  //             if (result.isConfirmed) {
  //               this.setState({isLoader:true});
  //               Service.uploadPO().then(res=>{
  //                 if(res.status === 200){
  //                   this.setState({isLoader:false});
  //                   Swal.fire({
  //                     icon: 'success',
  //                     title: 'Upload complete!',
  //                     text: 'Our team will create your project upon verification of your purchase order.',
  //                     confirmButtonText: 'Go to dashboard'
  //                   }).then((result) => {
  //                     if (result.isConfirmed) {
  //                       Swal.fire('Redirect to dashboard', '', 'success');
  //                     }
  //                   })
  //                 }
  //                 else{
  //                   this.setState({isLoader:false});
  //                   Swal.fire({
  //                     icon: 'error',
  //                     title: 'Upload failed!',
  //                     text: 'Please ensure that your internet connection is stable.',
  //                   });
  //                 }
  //               })
  //             } else if (result.isDenied) {
  //               Swal.fire('Changes are not saved', '', 'info');
  //             }
  //           })
  //         });
  //       }
  //       else{
  //         this.setState({selectedFile:''});
  //         Swal.fire({
  //           title: "<h3 style='color:ff4040'>File size too large.</h3>",
  //           text: 'Your file size is exceeding 5 MB size, kindly use a file size under 5 MB.',
  //           confirmButtonColor:"#93d85e",
  //           timer: 5000,
  //         }).then((result) => {
  //           /* Read more about handling dismissals below */
  //           if (result.dismiss === Swal.DismissReason.timer) {
  //             // console.log('I was closed by the timer')
  //           }
  //         });
  //       }
  //     }
  //     else{
  //       this.setState({selectedFile:''});
  //       Swal.fire({
  //         title: "<h3 style='color:ff4040'>File not supported.</h3>",
  //         text: 'Kindly upload supported file format, .pdf and .docx',
  //         confirmButtonColor:"#93d85e",
  //         timer: 5000,
  //       }).then((result) => {
  //         /* Read more about handling dismissals below */
  //         if (result.dismiss === Swal.DismissReason.timer) {
  //           // console.log('I was closed by the timer')
  //         }
  //       });
  //     }
  //   }
  // }

  // New=============================================================================================================================================
  SubmitPurchaseOrder = (e) => {
    if (e.target.files[0]) {
      console.log(e.target.files[0]);
      const filename = e.target.files[0].name;
      const fileSize = e.target.files[0].size;
      if (filename.includes(".pdf") || filename.includes(".docx")) {
        if (((fileSize / 1024) / 1024).toFixed(4) < 5) {
          this.setState({
            selectedFile: e.target.files[0]
          }, () => {
            // Update the formData object 
            const formData = new FormData();
            formData.append("myFile", this.state.selectedFile);
            for (var key of formData.entries()) {
              console.log(key[0] + ', ' + key[1]);
            }
          });
        }
        else {
          this.setState({ selectedFile: '' });
          Swal.fire({
            title: "<h3 style='color:ff4040'>File size too large.</h3>",
            text: 'Your file size is exceeding 5 MB size, kindly use a file size under 5 MB.',
            confirmButtonColor: "#93d85e",
            timer: 5000,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              // console.log('I was closed by the timer')
            }
          });
        }
      }
      else {
        this.setState({ selectedFile: '' });
        Swal.fire({
          title: "<h3 style='color:ff4040'>File not supported.</h3>",
          text: 'Kindly upload supported file format, .pdf and .docx',
          confirmButtonColor: "#93d85e",
          timer: 5000,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            // console.log('I was closed by the timer')
          }
        });
      }
    }
  }
  SendPurchaseOrder = (e) => {
    if (this.state.selectedFile !== "" && this.state.selectedFile !== undefined && this.state.selectedFile !== null) {
      this.setState({ isLoader: true });
      const formData = new FormData();
      formData.append("file", this.state.selectedFile);
      formData.append("userid", cookie.load("Id"));
      formData.append("username", cookie.load("user_Name"));
      formData.append("quotationrefnum", this.props.QuotationNumber);
      const payload = formData;
      Service.uploadPO(payload).then(res => {
        if (res.statusCode === 200) {
          Service.getFinanceAdmins().then(admins => {
            if (admins.length > 0) {
              const messageToSend = `<p>Hi Admin,</p><p>A new Purchase Order having reference number <b>${this.props.QuotationNumber}</b> has been Uploaded by <b>${cookie.load("user_Name")}</b>. Please review it now.</p>`;
              admins.forEach(admin => {
                const EmailData = new FormData();
                EmailData.append('ToEmail', admin.email);
                EmailData.append('Subject', cookie.load("user_Name") + " has uploaded a new Purchase Order.");
                EmailData.append('Body', messageToSend);
                Service.SendEmail(EmailData);
              });
              this.setState({ isLoader: false });
              try {
                cookie.remove("TempUserInfo", { path: '/' });
                cookie.remove("QuoteReference", { path: '/' });
                cookie.remove('QuoteItems', { path: '/' });
              }
              catch (ex) {
                console.log(ex);
              }
              localStorage.removeItem("cart_items");
              localStorage.removeItem("cart_sub_services");
              // if(!this.props.isBilling){
              //   this.props.history.push("/");
              // }
              // else{
                
              // }
              this.props.history.push("/");
              Swal.fire({
                icon: 'success',
                title: 'Upload complete!',
                text: 'Our team will create your project upon verification of your purchase order.',
                confirmButtonText: 'Ok'
              });
            }
          });
        }
        else {
          this.setState({ isLoader: false });
          Swal.fire({
            icon: 'error',
            title: 'Upload failed!',
            text: 'Please ensure that your internet connection is stable.',
          });
        }
      });
    }
  }
  RemovePurchaseOrder = () => {
    this.setState({
      selectedFile: ""
    })
  }
  render() {
    return (
      <>
        {this.state.isLoader ? <Loader /> : ""}
        {this.state.customLoader ? <CustomLoader /> : null}
        <div className="container text-center">
          {
            this.state.selectedFile ?
              <div> <h2>Purchase Order</h2> <br></br> </div > :
              <h2 className="mt-5 mb-5">Total Amount : {"USD " + this.props.TotalAmount}</h2>
          }
          <div className="row">
            {this.state.isUserLogin == false ? (
              <div style={{ width: '100%' }}>
                <div
                  className="alert alert-success"
                  style={{ width: "87%", marginLeft: '6%', textAlign: "justify" }}
                >
                  <b>Create credentials/login and proceed to checkout</b>
                </div>
              </div>
            ) : ""}
          </div>
          {/* <div className="row"  style={{display:"flex",justifyContent:"left",marginTop:"20px"}}>
            <button className="button btnPayNow" style={{marginLeft:'7%'}} onClick={this.props.BackClickEvent} > &lt; Previous</button>
          </div> */}
          {
            this.state.selectedFile ?
              <div className="container text-left mb-5">
                <div className="row border ">
                  <div className="col-12 font-weight-bold">File name</div>
                </div>
                <div className="row border align-items-center">
                  <div className="col-8">{this.state.selectedFile.name}</div>
                  <div className="col-4 buttons-po">
                    <button title="Remove" onClick={this.RemovePurchaseOrder} className="button-po">
                      <img src={removeIcon}/>
                    </button>
                    <button title="Upload" onClick={this.SendPurchaseOrder} className="button-po">
                      <img src={uploadIcon}/>
                    </button>
                    
                  </div>
                </div>
                <h2 className="mt-5 mb-5">Total Amount : {"USD " + this.props.TotalAmount}</h2>
              </div>

              : this.isAudi && this.isIngramMicro ?
                <div className="row mb-5 justify-content-center align-items-center" style={{ gap: "150px" }}>
                  {/* <button className="custom-button" onClick={this.PrePayment}>Pay with <br /> Credit/Debit Card</button> */}
                  <button className="custom-button" onClick={this.UploadButtonHandler}>Upload<br /> Purchase Order</button>
                  <input onClick={this.PurchaseOrderHandler} onChange={this.SubmitPurchaseOrder} ref={this.purchaseOrder} type="file" className="hidden-input" />
                </div>
                : !this.isAudi && this.isIngramMicro ?
                  <div className="row mb-5 justify-content-center align-items-center" style={{ gap: "150px" }}>
                    {/* <button disabled className="custom-button">Pay with <br /> Credit/Debit Card</button> */}
                    <button disabled className="custom-button">Upload<br /> Purchase Order</button>
                    <input onClick={this.PurchaseOrderHandler} onChange={this.SubmitPurchaseOrder} ref={this.purchaseOrder} type="file" className="hidden-input" />
                  </div> :
                  <div className="row mb-5 justify-content-center align-items-center" style={{ gap: "150px" }}>
                    {/* <button className="custom-button" onClick={this.PrePayment}>Pay with <br /> Credit/Debit Card</button> */}
                    <button className="custom-button" onClick={this.UploadButtonHandler}>Upload<br /> Purchase Order</button>
                    <input onClick={this.PurchaseOrderHandler} onChange={this.SubmitPurchaseOrder} ref={this.purchaseOrder} type="file" className="hidden-input" />
                  </div>
          }
          {
            this.props.isBackButtonDisabled ? ""
            :
            <div className="row">
              <div className="col-md-12 text-right">
                <button className="button is-large is-success is-inverted" style={this.props.buttonStyles} onClick={this.props.BackClickEvent} > &lt; Back</button>
              </div>
            </div>
          }
          <div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(StripePaymentContainer)
