export const setMainSession = data => {
    return{
        type:"SET_MAIN_SESSION",
        payload:data
    }
}
export const setMainSignalObj = data =>{
    return{
        type:"SET_MAIN_SIGNAL_OBJ",
        payload:data
    }
}

export const setMainSessionRef = data =>{
    return{
        type:"SET_MAIN_SESSION_REF",
        payload:data
    }
}

export const setConnections = data =>{
    return{
        type:"SET_CONNECTIONS",
        payload:data
    }
}