import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";

class SecurityAssessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: cookie.load('user_Id'),
            userName: cookie.load('user_Name'),
            AssignedUserName: ""
        };
        this.getGapAssessment = this.getGapAssessment.bind(this);
        this.getValunerablityAssessment = this.getValunerablityAssessment.bind(this);
        this.getSimplePenTesting = this.getSimplePenTesting.bind(this);
        this.getBlackBoxTesting = this.getBlackBoxTesting.bind(this);
    }
    componentDidMount() {

        Service.GetUserByServiceId(2).then(res => {
            //console.log(res);
            if (res) {
                this.setState({ AssignedUserName: res.UserName })
            }
        })
    }
    getGapAssessment() {
        this.props.history.push('/Questionnaire/5');
    }
    getValunerablityAssessment() {
        this.props.history.push('/Questionnaire/2');
    }
    getSimplePenTesting() {
        this.props.history.push('/Questionnaire/3');
    }
    getBlackBoxTesting() {
        this.props.history.push('/Questionnaire/4');
    }
    startCall(CallingTo) {
        if (cookie.load('user_Id') == undefined) {
            this.props.history.push('login')
        }
        else
            window.call(CallingTo, this.state.userId)
    }

    render() {
        return (
            <>
                <div classNameName="hero parallax"
                    style={{ backgroundSize: "100% 100%", backgroundImage: "url(https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/SecurityAssessment111.jpg" }}>
                    {/* <!-- Hero caption --> */}
                    <div id="main-hero" classNameName="hero-body">
                        <div classNameName="container">
                            <div classNameName="columns is-vcentered">
                                <div classNameName="column is-5 signup-column has-text-left">
                                    <h1 classNameName="title main-title text-bold is-2" style={{ marginTop: 140, textAlign: "center" }}>
                                        SECURITY ASSESSMENT
                                    </h1>
                                    <h2 classNameName="subtitle is-5 no-margin-bottom is-light" style={{ textAlign: "center" }}>
                                        SECURITY, COMPLIANCE AND PERFORMANCE AT YOUR FINGERTIPS
                                    </h2>
                                    <br />

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <!-- Clients --> */}
                </div>
                {/* <!-- Benefits section --> */}
                {/* <!-- Contact Section --> */}

                <div className="container">
                    <div className="section-title-wrapper has-text-centered" style={{ marginTop: 100 }}>
                        <div className="bg-number">1</div>
                        <h2 className="section-title-landing">
                            WHY TO CHOOSE US
                        </h2>
                    </div>
                    <div className="content-wrapper">
                        <div className="columns is-vcentered pb-40 pt-40">
                            <div className="column is-4 is-offset-1">
                                <div className="title quick-feature has-numbers text-bold">
                                    <div>CERTIFIED TEAM
                                    </div>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="body-text" style={{ textAlign: "justify" }}>
                                    <div classNameName="Paragraph">
                                        <p>
                                            The most efficient way to approach information security tasks is, to begin with, an enterprise-wide IT security assessment.  The results of such assessments enable our customers to quickly locate and correct any existing vulnerabilities identify shortcomings in security policies. ManageX team has often faced scenarios where policies are there however, they have not been correctly enforced.

                                            ManageX teams performs a thorough technical analysis of the network, reviews potential network design issues with routers, firewalls, and intrusion detection systems which are often uncovered.

                                            ManageX's security assessment portfolio consists of comprehensive services that allow our customers to use us as a one-stop-shop for all their security needs.
                                        </p>
                                    </div>
                                </span>
                                <div className="pt-10 pb-10">
                                </div>
                            </div>
                            <div className="column is-6 is-offset-1">
                                <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/graphics/compositions/drawing-bw-2-core.svg" data-base-url="assets/img/graphics/compositions/drawing-bw-2" data-extension=".svg" alt="" />
                            </div>
                        </div>

                        <div className="columns is-vcentered pb-40 pt-40">
                            <div className="column is-4 is-offset-1 is-hidden-tablet is-hidden-desktop">
                                <div className="icon-subtitle"></div>
                                <div className="title quick-feature has-numbers text-bold">
                                    <h2>SECURITY OPERATIONS CENTER
                                        24/7 SECURITY</h2>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="body-text">
                                    <div classNameName="Peragraph">
                                        <p>
                                            ManageX SOC-as-a-Service is designed to enable our analysts to keep an eye out for vulnerabilities in our customer's environment at all time. Our team works with the customers to ensure compliance standards
                                            are met by implementing thresholds and resolving incidents prior to any compliance/ SLA breaches.
                                        </p>
                                    </div>

                                </span>
                                <div className="pt-10 pb-10">
                                    <button className="button btn-align btn btn-primary" onClick={this.getQuestionaire}>TRY OUR SECURITY ASSESSMENT </button>

                                </div>
                            </div>
                            <div className="column is-6">
                                <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/graphics/compositions/drawing-bw-5-core.svg"
                                    data-base-url="https://vvm-prod-cdn.azureedge.net/managex/assets/img/graphics/compositions/drawing-bw-5" data-extension=".svg" alt="" />
                            </div>
                            <div className="column is-4 is-offset-1 is-hidden-mobile">
                                {/* <div className="icon-subtitle"><i className="im im-icon-Video-2"></i></div> */}
                                <div className="title quick-feature has-numbers text-bold">
                                    <h2>SECURITY OPERATIONS CENTER
                                        24/7 SECURITY</h2>
                                </div>
                                <div className="title-divider is-small"></div>
                                <span className="body-text">
                                    <div classNameName="Peragraph">
                                        <p>
                                            ManageX SOC-as-a-Service is designed to enable our analysts to keep an eye out for vulnerabilities in our customer's environment at all time. Our team works with the customers to ensure compliance standards
                                            are met by implementing thresholds and resolving incidents prior to any compliance/ SLA breaches.
                                        </p>
                                    </div>
                                </span>
                                <div className="pt-10 pb-10">
                                    {/**
                           <Link className="button btn-align btn btn-primary" 
                        onClick={this.getQuestionaire}>TRY OUR SECURITY ASSESSMENT 
                        </Link>
                        
                        */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--SECTION 2-->*/}
                <div classNameName="section is-medium">

                    <div classNameName="section-title-wrapper has-text-centered">
                        <div classNameName="bg-number">2</div>
                        <h2 classNameName="section-title-landing">
                            HOW WE ASSESS YOUR ENVIRONMENT
                        </h2>
                        <h3 classNameName="section-subtitle-alt">A wide range of consulting services to help you manage your company</h3>
                    </div>
                    <div id="start" classNameName="container">
                        {/* <!-- Title --> */}
                        <div classNameName="content-wrapper">
                            <div classNameName="columns" style={{ marginLeft: "-3.75rem" }}>
                                {/* <!-- Benefit box --> */}
                                <div className="column is-3 mr-25">
                                    <div id="CardHeight" className="feature-card card-lg light-bordered hover-inset mb-20">
                                        <div className="card-img">
                                            <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/GapAssessmentSmall.jpg"
                                                alt="" data-demo-src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/GapAssessmentSmall.jpg" />
                                        </div>
                                        <div className="card-title pb-10 pt-10">
                                            <h4>
                                                Gap Assessment
                                            </h4>
                                        </div>
                                        <div className="card-img-description has-text-left ellips">
                                            <span className="">
                                                Security Gap assessment identifies, assesses, and implements key security controls in applications. It also focuses on preventing application security defects and vulnerabilities. Carrying out a risk assessment allows an organization to view the application portfolio holistically—from an attacker’s perspective.
                                                While Gap analysis can frequently identify capabilities that already exist within an organization, offering the ability to promote these capabilities rather than adopt new ones. Gap analysis can diagnose problems and provide recommendations on how to solve these problems.

                                            </span>
                                        </div>
                                        <div className="pt-10 has-text-left row ml-3">
                                            <button className="button btn-align col-4 btn btn-primary" onClick={this.getGapAssessment}>Buy Now</button>
                                            {this.state.AssignedUserName != "" ?
                                                <div classNameName='col-2'><button className="btn btn-success rounded" onClick={() => this.startCall(this.state.AssignedUserName)}>Talk to an expert</button></div>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-3 mr-25" style={{ marginLeft: 20 }}>
                                    <div className="feature-card card-lg light-bordered hover-inset mb-20">
                                        <div className="card-img">
                                            <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/VulnerabilityAssessmentmall.jpg"
                                                alt="" data-demo-src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/VulnerabilityAssessmentmall.jpg" />
                                        </div>
                                        <div className="card-title pb-10 pt-10">
                                            <h4>
                                                Vulnerability Assessment
                                            </h4>
                                        </div>
                                        <div className="card-img-description has-text-left ellips">
                                            <span className="">
                                                Vulnerability Assessment (VA) is the process of identifying, quantifying, and prioritizing the weakness in the system and evaluating the current security state of an IT Assets to find vulnerabilities that an attacker could exploit to gain unauthorized access to systems and information. This process involves the identification of security weaknesses that may result due to the improper security configuration of systems or applications and known or unknown vulnerabilities in the assets in scope.
                                            </span>
                                        </div>
                                        <div className="pt-10 row has-text-left ml-3">
                                            <button className="button btn-align col-4 btn btn-primary" onClick={this.getValunerablityAssessment}>Buy Now</button>
                                            {this.state.AssignedUserName != "" ?
                                                <div classNameName='col-2'><button className="btn btn-success rounded" onClick={() => this.startCall(this.state.AssignedUserName)}>Talk to an expert</button></div>
                                                : ""}

                                        </div>

                                    </div>
                                </div>
                                <div className="column is-3 mr-25" style={{ marginLeft: 20 }}>
                                    <div className="feature-card card-lg light-bordered hover-inset mb-20">
                                        <div className="card-img">
                                            <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/PenTesting.jpg" alt=""
                                                data-demo-src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/PenTesting.jpg" />
                                        </div>
                                        <div className="card-title pb-10 pt-10">
                                            <h4>Basic Penetration Testing</h4>
                                        </div>
                                        <div className="card-img-description has-text-left ellips">
                                            <span className="">
                                                Penetration testing is the process of evaluating the security profile of IT products (software and hardware-based), the outcome of which is the identification of security misconfigurations and vulnerabilities exploitation of which may lead to unauthorized access of organizational assets, disclosure of sensitive information, and reputational damage. A penetration testing program is a security evaluation process based on a proactive approach, the objective of which is the timely identification of security vulnerabilities before they are being disclosed and exploited by hackers.
                                            </span>
                                        </div>
                                        <div className="pt-10 has-text-left row ml-3">
                                            <button className="button btn-align col-4 btn btn-primary" onClick={this.getSimplePenTesting}>Buy Now</button>
                                            {this.state.AssignedUserName != "" ?
                                                <div classNameName='col-2'><button className="btn btn-success rounded" onClick={() => this.startCall(this.state.AssignedUserName)}>Talk to an expert</button></div>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-3 mr-25" style={{ marginLeft: 20 }}>
                                    <div className="feature-card card-lg light-bordered hover-inset mb-20">
                                        <div className="card-img">
                                            <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/BlackboxPT.jpg"
                                                alt="" data-demo-src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/BlackboxPT.jpg" />
                                        </div>
                                        <div className="card-title pb-10 pt-10">
                                            <h4>Blackbox Penetration Testing</h4>
                                        </div>
                                        <div className="card-img-description has-text-left ellips">
                                            <span className="">
                                                Black box penetration testing is designed to determine the strength of Organization’s systems and their ability to withstand attack from external sources. An external source is one with limited knowledge of your network architecture. A competitor, for example, may attempt to hack your system and access proprietary information that would help improve its competitive advantage over you. Black box penetration testing would simulate this type of attack and provide insight into the exploitable vulnerabilities that threaten your company.
                                            </span>
                                        </div>
                                        <div className="pt-10 has-text-left row ml-3">
                                            <button className="button btn-align col-4 btn btn-primary" onClick={this.getBlackBoxTesting}>Buy Now</button>
                                            {this.state.AssignedUserName != "" ?
                                                <div classNameName='col-2'><button classNameName="btn btn-success rounded" onClick={() => this.startCall(this.state.AssignedUserName)}>Talk to an expert</button></div>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </>


        )
    }
}

export default SecurityAssessment;