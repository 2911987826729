import React from "react";

class CyberSecurityServicesListItems extends React.Component {
    componentDidMount() {
    }
    render() {

        //console.log(this.props.SubServiceIDs)
        var _self = this.props.SubServiceIDs;


        var _CallbackEvent = this.props.EventSubServiceID;
        var _SubServicesList = this.props.ListSubServices.length === 0 ? " Loading..." : this.props.ListSubServices.map(function (item) {
            // console.log(_self.indexOf(item.id.toString()));
            return (
                <div key={item.id}>
                    <li className="list-group-item">
                        <input id="abc" name="rdSubServices" onChange={_CallbackEvent} value={item.id} type="checkbox"
                            // checked={ this && this.props.subServicesIDs.indexOf(item.value) !== -1 ? true : null }
                            checked={_self.indexOf(item.id.toString()) !== -1 ? true : null}
                        />
                        {/* {this && this.props.subServicesIDs.indexOf(item.value) !== -1 ? console.log(this.props.subServicesIDs) : ""} */}
                        <span style={{ marginLeft: '1%' }}> {item.name} </span>
                    </li>
                </div>);
        });

        return (
            <div>
                <ul className="list-group">
                    {_SubServicesList}
                </ul>
            </div>

        );
    }
}

export default CyberSecurityServicesListItems;
