import React, { useState, useEffect, useRef } from 'react';
import { connect, Connect } from 'react-redux';
import Swal from 'sweetalert2';
import cookie from 'react-cookies'
import './ProjectDetailView.css';
import ProgressBar from '../ProgressBar';
import BreadCrumb from "../BreadCrumb";
import TextLoader from '../CustomerSupport/TextLoader';
import SupportCall from '../CustomerSupport/SupportCall';
import Dropdown from "../Dropdown";
import { Encrypt, Decrypt } from '../Utility/Encryption';
import { setCallSession, setIsCallJoined, setIsCallDeclined, setIsCalling } from '../../store/actions/callSessionActions'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { clearMessages } from '../../store/actions/groupChatAction';

import DoughnutChart from '../../components/DoughnutChart/DoughnutChart';
import BarChart from '../../components/BarChart/BarChart';
import Service from '../../components/Service/Api/Api';
import Loader from '../../components/Loader/Loader';

// importing card components
import GroupChat from '../../components/GroupChat/GroupChat';
import AssignedRersources from '../../components/AssignedResources/AssignedRersources';
import Reports from '../../components/Reports/Reports';


function ProjectDetailView(props){
    const { callSessionObj, isCalling, isCallJoined, isCallDeclined, isCallPicked } = props.callSessionReducer;
    const { mainSessionReducer } = props;

    // State variables START
    const [ messages, setMessages ] = useState(null);
    const [ assignedUsers, setAssignedUsers ] = useState([]);
    const [ signalList, setSignalList ] = useState([]);
    const [ reports, setReports ] = useState([
        {
          Id: 94,
          JiraProjectKey: "MXCROQUY",
          FileUrl: "https://mxprodstorage.blob.core.windows.net/managex/reports-upload/SOC_Daily_Report7th_September_2022-d1_%281%29_11-11-2022%207%3A14%3A20%20AM.pdf",
          CreatedOn: "2022-11-11T07:14:20.8604126",
          IsDeleted: false,
          FilePassword: "secret@123",
          FileName: "SOC Daily Report7th September 2022-d1 (1).pdf"
        },
        {
          Id: 95,
          JiraProjectKey: "MXCROQUY",
          FileUrl: "https://mxprodstorage.blob.core.windows.net/managex/reports-upload/PO_usman_sheikh-08-10-2022-08-05-25_11-11-2022%207%3A14%3A44%20AM.pdf",
          CreatedOn: "2022-11-11T07:14:44.6360142",
          IsDeleted: false,
          FilePassword: "pass@123",
          FileName: "PO_usman sheikh-08-10-2022-08-05-25.pdf"
        }
    ]);
    const [ isLoader, setIsLoader ] = useState(true);

    const [ projectDetails, setProjectDetails ] = useState({});
    // State variables END
    let timeoutForOTP = null;


    function generateOTP () {
        // Declare a digits variable 
        // which stores all digits
        var digits = '0123456789';
        let OTP = '';
        for (let i = 0; i < 6; i++) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        return OTP;
    }

    function DownloadReport (Url) {
        const OTP = generateOTP();
        const expiryTimer = 2 * 1000 * 60;

        let tempOTP = "";
        timeoutForOTP = setTimeout(() => {
            tempOTP="";
            console.log("OTP EXPIRED")
        }, expiryTimer);

        const EmailData = new FormData();
        EmailData.append('ToEmail', cookie.load("user_Id"));
        EmailData.append('Subject', "OTP for downloading file");
        EmailData.append('Body', `Hello ${cookie.load("user_Id")}, your OTP for downloading file is <b>${OTP}</b><p>Note: The OTP will only be valid for next 2 minutes.</p>`);
        Service.SendEmail(EmailData);

        const _encryptedOTP = Encrypt(JSON.stringify(OTP)).toString();
        tempOTP = _encryptedOTP;
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: 'Enter OTP to download',
            icon: "info",
            text: `An OTP is sent to ${cookie.load("user_Id")}. Please enter it below.`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Download',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                return login
            },
            timer: expiryTimer
        }).then((result) => {
            if (result.isConfirmed) {
                if (tempOTP !== "") {
                    const _decryptedOTP = JSON.parse(Decrypt(tempOTP));
                    if (result.value === _decryptedOTP) {
                        clearTimeout(timeoutForOTP);
                        tempOTP="";
                        window.open(Url, '_blank').focus();
                    }
                    else {
                        Swal.fire(
                            'Invalid OTP?',
                            'Please enter valid OTP',
                            'error'
                        )
                    }
                }
                else {
                    Swal.fire(
                        'Invalid OTP?',
                        'OTP might be expired',
                        'error'
                    )
                }
            }
        })
    }

    function SendPasswordToUser (pwd) {
        if (pwd) {
            const EmailData = new FormData();
            EmailData.append('ToEmail', cookie.load("user_Id"));
            EmailData.append('Subject', "Password for your report");
            EmailData.append('Body', `
            <p>Greetings ${cookie.load("user_Name")},<br/>Please find report password below.</p>
            <p>Password: <b>${pwd}</b></p>
            `);
            Service.SendEmail(EmailData);
            Swal.fire(
                'Password sent',
                "This report's password has been sent to your email.",
                'success'
            )
        }
        else if (pwd === null || pwd === undefined) {
            Swal.fire(
                'No password set',
                'There is no password set for this file.',
                'info'
            )
        }
    }




    const currentUser = cookie.load("user_Id");
    const chartData = [
        {
            labels: ['data 1', 'data 2', 'data 3'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [12, 19, 3],
                    backgroundColor: [
                        '#f96332',
                        '#414751',
                        '#59cbff',
                    ],
                    borderWidth:5,
                    borderColor: [
                        '#ffffff',
                        '#ffffff',
                        '#ffffff',
                    ],
                },
            ],
        },
        {
            labels: ['data 1', 'data 2', 'data 3'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [10, 8, 6],
                    backgroundColor: [
                        '#ffaa00',
                        '#59cbff',
                        '#cc0000',
                    ],
                    borderWidth:5,
                    borderColor: [
                        '#ffffff',
                        '#ffffff',
                        '#ffffff',
                    ],
                },
            ],
        },
        {
            labels: ['data 1', 'data 2', 'data 3'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [20, 12, 8 ],
                    backgroundColor: [
                        '#80c44d',
                        '#414751',
                        '#59cbff',
                    ],
                    borderWidth:5,
                    borderColor: [
                        '#ffffff',
                        '#ffffff',
                        '#ffffff',
                    ],
                },
            ],
        }
    ]
    function callEngineer(details){
        const { user, isOnline } = details;
        if(isOnline){
            Swal.fire({
                title: `Call ${user.userName}?`,
                text: `You are about to place a video call with ${user.userName}`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: 'var(--bg-color-pr)',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText:"No",
                allowOutsideClick:false,
              }).then((result) => {
                if (result.isConfirmed) {
                    const { mainSessionReducer } = props;
                    if(mainSessionReducer){
                        const { mainSessionSignalObj } = mainSessionReducer;
                        if(mainSessionSignalObj){
                            let admins;
                            Service.getSupportAdmins().then(data=> admins = data)
                            const signalData = {
                                type: "supportCall",
                                data: JSON.stringify({
                                    senderEmail: cookie.load("user_Id"),
                                    userName: cookie.load("user_Name"),
                                    customerId: "this.state.userId",
                                    supportPersonId: user.userEmail,
                                    projectId: "ProjectId",
                                    serviceName: "ServiceName",
                                    receivers: admins
                                })
                            }
                            mainSessionSignalObj(signalData);
                        }
                    }
                }
              })



        }
        else{
            Swal.fire({
                    icon: 'error',
                    title: `${user.userName} is offline`,
                    html: 'Please try again within a few minutes.<br/> If the problem persists, try reloading your page.',
                    allowOutsideClick:false,
                    allowEnterKey:false,
                    confirmButtonText:"Ok",
                    confirmButtonColor:"var(--bg-color-pr)"
                });
        }
    }
    function endCall(){
        props.dispatch(setIsCallJoined(false));
        props.dispatch(setIsCallDeclined(false));
        props.dispatch(setIsCalling(false));
        // console.log("ENDING SESSION")
        cookie.remove("callApiKey");
        cookie.remove("callSessionId");
        cookie.remove("callToken");
    }


    let prevId = "";
    useEffect(()=>{
        const projectId = props.match.params.projectid;
        getDetailsByProjectId(projectId);
        // const assignedUsers = [
        //     {
        //         name:"Hamza Chaudhary",
        //         email:"hchaudhary@vaoprvm.com",
        //     },
        //     {
        //         name:"Huzaifa Ameer",
        //         email:"hameer@vaoprvm.com",
        //     },
        //     {
        //         name:"Zargham Aijaz",
        //         email:"zaijaz@vaoprvm.com",
        //     },
        //     {
        //         name:"Zarish Yousaf",
        //         email:"zyousaf@vaoprvm.com",
        //     },
        //     {
        //         name:"Admin",
        //         email:"admin@managex.com",
        //     },
        //     {
        //         name:"Irum Saleem",
        //         email:"isaleem@vaporvm.com",
        //     },
        // ];
        const assignedUsers = [
            {
                name:"Admin",
                email:"admin@managex.com",
            },
            {
                name:"Olive Yew",
                email:"hchaudhary@vaoprvm.com",
            },
            {
                name:"Allie Grater",
                email:"hameer@vaoprvm.com",
            },
            {
                name:"Jack Aranda",
                email:"zaijaz@vaoprvm.com",
            },
        ];
        setAssignedUsers(assignedUsers)
    },[]);


    function getDetailsByProjectId(projectId){
        const payload = {
            projectId: Number(projectId)
        }
        Service.GetProjectDetailsById(payload).then(res=>{
            if(res.status === "success"){
                setProjectDetails(res.result);
                setIsLoader(false);
            }
        }).catch(err=>{
            Swal.fire(
                "Error getting details",
                "An unknown error occured while fetching the project details. Please try again within a few minutes.",
                "error"
            );
            setProjectDetails({lstProjectDetails:null});
            setIsLoader(false)
        })
    }
    function removeInterval(signalInterval){
        console.log("Support status signaling stopped.");
        clearInterval(signalInterval);
        props.dispatch(clearMessages());
        console.log("Message list cleared")

    }
    if (isCallDeclined) {
        Swal.fire(
            'Call not picked',
            'The resources are busy at the moment. Please try again within a few minutes.',
            'error'
        );
        props.dispatch(setIsCallDeclined(false))
    }
    // const data = <>
    //     <BreadCrumb/>
    //         <div className="container-fluid">
    //             <Dropdown
    //                 parent={
    //                     <div className="mx-details-head card"><div>Cyber Security - Web Application Security Assessment (Service Description (10-20) IPs)</div></div>
    //                 }
    //                 defaultActive={true}
    //                 notClosesOnOutsideClick={true}
    //                 hasArrow
    //             >
    //                 <div className="mx-details-body">
    //                     <div className="mx-details-inner card">
    //                         <p className="mx-details-title">Realtime Progress<i className="zmdi zmdi-info-outline mx-details-info"><span>Overall progress</span></i></p>
    //                         <ProgressBar progress={50} displayNumber numberPosition="left"/>
    //                         <div className="mx-steps-main">
    //                             <div className="mx-step completed">
    //                                 <div className="mx-step-status">
    //                                     <i className="zmdi zmdi-check"></i>
    //                                 </div>
    //                                 <div>
    //                                     <p className="mx-step-desc">Phase 1 Discovery and Initial Assessment</p>
    //                                     <p className="mx-step-detail">Completed</p>
    //                                 </div>
    //                             </div>
    //                             <div className="mx-step in-progress">
    //                                 <div className="mx-step-status">
    //                                     <i className="zmdi zmdi-spinner"></i>
    //                                 </div>
    //                                 <div>
    //                                     <p className="mx-step-desc">Phase 2 Detailed Assessment</p>
    //                                     <p className="mx-step-detail">In progress</p>
    //                                 </div>
    //                             </div>
    //                             <div className="mx-step">
    //                                 <div className="mx-step-status">
    //                                     <i className="zmdi zmdi-hourglass"></i>
    //                                 </div>
    //                                 <div>
    //                                     <p className="mx-step-desc">Phase 3 State Analysis</p>
    //                                     <p className="mx-step-detail">In queue</p>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="mx-details-inner card">
    //                         <p className="mx-details-title">Download Reports<i className="zmdi zmdi-info-outline mx-details-info"><span>Download the reports uploaded by the engineers</span></i></p>
    //                         <div className="mx-report-dropdown-child">
    //                             {
    //                                 reports.map(report=>{
    //                                     return(
    //                                         <div className="mx-report">
    //                                             <span>{report.FileName}</span>
    //                                             <div>
    //                                                 <button className="btn btn-success rounded mx-1" onClick={() => DownloadReport(report.FileUrl)}><i className="zmdi zmdi-download"></i></button>
    //                                                 <button title="Protected file password" className="btn btn-primary rounded mx-1" onClick={()=>SendPasswordToUser(report.FilePassword)}>File password</button>
    //                                             </div>
    //                                         </div>
    //                                     )
    //                                 })
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="mx-details-inner card ">
    //                         <p className="mx-details-title">Assigned resources<i className="zmdi zmdi-info-outline mx-details-info"><span>Engineers allocated to this project</span></i></p>
    //                         <div className="mt-3">
    //                             {
    //                                 assignedUsers.map(user=>{
    //                                     let isOnline = false;
    //                                     signalList.forEach(list=>{
    //                                         if(list.email === user.email){
    //                                             var _oldDate = list.ackTime;
    //                                             var NewDate = new Date();
    //                                             var difference = (NewDate - _oldDate) / 1000;
    //                                             if(difference < 5){
    //                                                 isOnline = true;
    //                                             }
    //                                             else{
    //                                                 isOnline = false;
    //                                             }
    //                                         }
    //                                     })
                                        
    //                                     return(
    //                                         <div className={`mx-engineer ${isOnline ? "online" : ""}`}>
    //                                             <div className="mx-engineer-status"></div>
    //                                             <p className="mx-engineer-name">{user.name}</p>
    //                                             <i className="zmdi zmdi-info-outline mx-engineer-info">
    //                                                 <div className="mx-engineer-info-main">
    //                                                     <div className="mx-engineer-info-row">Name: {user.name}</div>
    //                                                     <div className="mx-engineer-info-row">Status: {isOnline ? "Available" : "Offline"}</div>
    //                                                 </div>
    //                                             </i>
    //                                             <button title="Call engineer" className="mx-engineer-call" onClick={()=>callEngineer({user, isOnline})}><i className="zmdi zmdi-phone"></i></button>
    //                                         </div>
    //                                     );
    //                                 })
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="mx-details-inner card align-self-start">
    //                         <p className="mx-details-title">Group chat<i className="zmdi zmdi-info-outline mx-details-info"><span>Group chat with engineers</span></i></p>
    //                         <div className="mx-chat-widget">
    //                             {
    //                                 messages ?
    //                                 messages.map((data,i,arr)=>{
    //                                     if(i===0){
    //                                         prevId = "";
    //                                     }
    //                                     else{
    //                                         prevId = arr[i-1].id;
    //                                     }
    //                                     console.log(prevId)
    //                                     return(
    //                                         <div className={`mx-chat-widget-message ${currentUser === data.id ? "is-own" : ""}`}>
    //                                             {
    //                                                 prevId !== data.id?
    //                                                 <p className="mx-chat-widget-message-name">{data.name}</p>
    //                                                 :""
    //                                             }
    //                                             <p className="mx-chat-widget-message-text">{data.message}</p>
    //                                         </div>
    //                                     );
    //                                 })
    //                                 :""
    //                             }
    //                             <div className="mx-chat-widget-send">
    //                                 <input type="text"/>
    //                                 <button><i className="zmdi zmdi-mail-send"></i></button>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="mx-details-inner card ">
    //                         <DoughnutChart data={chartData[0]} title="Incidents created by severity" />
    //                     </div>
    //                     <div className="mx-details-inner card ">
    //                         <DoughnutChart data={chartData[1]} title="Incidents created by owner" />
    //                     </div>
    //                     <div className="mx-details-inner card ">
    //                         <DoughnutChart data={chartData[2]} title="Incidents created by status" />
    //                     </div>
    //                     <div className="mx-details-inner card ">
    //                         <BarChart data={chartData[0]} title="Incidents created by status" />
    //                     </div>
    //                 </div>
    //             </Dropdown>
    //         </div>
    // </>






















    const _data = <>
        <BreadCrumb/>
        <div className="container-fluid">
            {
                projectDetails.lstProjectDetails ?
                projectDetails.lstProjectDetails.map((project, i)=>{
                    return(
                        <Dropdown
                            parent={
                                    <div className="mx-details-head card">
                                        <div>{project.jiraKey} - {project.projectName}</div>
                                    </div>
                            }
                            defaultActive={i===0 ? true : false}
                            notClosesOnOutsideClick={true}
                            hasArrow
                        >
                            <div className="mx-details-body">
                                {
                                    project.projectNotes ?
                                    <div className="mx-details-notes">
                                        <i className='zmdi zmdi-info-outline'></i>
                                        Note: {project.projectNotes}
                                    </div>
                                    :""
                                }
                                <div className="mx-details-inner card">
                                    <p className="mx-details-title">Realtime Progress<i className="zmdi zmdi-info-outline mx-details-info"><span>Overall progress</span></i></p>
                                    <div className="mx-steps-main mx-chat-widget">

                                        {
                                            project.projectDetails.issues?
                                            <>
                                            <div style={{marginTop:"-8px"}}>
                                                <ProgressBar progress={project.progress} displayNumber numberPosition="left"/>
                                            </div>
                                            {
                                                project.projectDetails.issues.map(issue=>{
                                                    const status = issue.fields.status.name;
                                                    let subtaskStatus = "";
                                                    if(status === "To Do"){
                                                        subtaskStatus=""
                                                    }
                                                    else if(status === "Done"){
                                                        subtaskStatus="completed"
                                                    }
                                                    else{
                                                        subtaskStatus="in-progress"
                                                    }
                                                    return(
                                                        <div className={`mx-step ${subtaskStatus}`}>
                                                            <div className="mx-step-status-line"></div>
                                                            <div className="mx-step-status">
                                                                {
                                                                    subtaskStatus === "" ?
                                                                    <i className="zmdi zmdi-hourglass"></i>
                                                                    :""
                                                                }
                                                                {
                                                                    subtaskStatus === "in-progress" ?
                                                                    <i className="zmdi zmdi-spinner"></i>
                                                                    :""
                                                                }
                                                                {
                                                                    subtaskStatus === "completed" ?
                                                                    <i className="zmdi zmdi-check"></i>
                                                                    :""
                                                                }
                                                            </div>
                                                            <div>
                                                                <p className="mx-step-desc">{issue.fields.summary}</p>
                                                                {
                                                                    issue.fields.subtasks.length > 0 ?
                                                                    <ul style={{paddingLeft:"1em"}}>
                                                                        {
                                                                            issue.fields.subtasks.map(subtask=>(
                                                                                <li className="mx-step-detail">{subtask.fields.summary}</li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                    :""
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </>
                                            :<p>No data to show.</p>
                                        }
                                    </div>
                                </div>
                                <div className="mx-details-inner card">
                                    <p className="mx-details-title">Download Reports<i className="zmdi zmdi-info-outline mx-details-info"><span>Download the reports uploaded by the engineers</span></i></p>
                                    <div className="mx-details-scrollable">
                                        <Reports reportList={project.reportList} projectName={project.projectName} jiraKey={project.jiraKey} />

                                        {/* <div className="mx-report-dropdown-child">
                                            <div className="mx-report-download-all">
                                                <button className="mx-report-download-all-button">
                                                    <i className="zmdi zmdi-download mr-2"></i>
                                                    Download all as zip file
                                                </button>
                                            </div>
                                            {
                                                project.reportList.length > 0 ?
                                                project.reportList.map((report,i)=>{
                                                    return(
                                                        <div className="mx-report">
                                                            <span title={report.fileName ? report.fileName : `Report-${i+1}`}>{report.fileName ? report.fileName : `Report-${i+1}`}</span>
                                                            <div>
                                                                <button className="btn btn-success rounded mx-1" onClick={() => DownloadReport(report.fileURL)}><i className="zmdi zmdi-download"></i></button>
                                                                <button title="Protected file password" className="btn btn-primary rounded mx-1" onClick={()=>SendPasswordToUser(report.filePassword)}><i className="zmdi zmdi-key"></i></button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : <p>No reports found.</p>
                                            }
                                        </div> */}
                                    </div>
                                </div>
                                <div className="mx-details-inner card ">
                                    <p className="mx-details-title">Assigned resources<i className="zmdi zmdi-info-outline mx-details-info"><span>Engineers allocated to this project</span></i></p>
                                    {/* <div className="mt-3">
                                        {
                                            project.userList.length > 0 ?
                                            project.userList.map(user=>{
                                                let isOnline = false;
                                                signalList.forEach(list=>{
                                                    // console.log(list.email, user.userEmail);
                                                    if(list.email === user.userEmail){
                                                        var _oldDate = list.ackTime;
                                                        var NewDate = new Date();
                                                        var difference = (NewDate - _oldDate) / 1000;
                                                        if(difference < 5){
                                                            isOnline = true;
                                                        }
                                                        else{
                                                            isOnline = false;
                                                        }
                                                    }
                                                })
                                                
                                                return(
                                                    <div className={`mx-engineer ${isOnline ? "online" : ""}`}>
                                                        <div className="mx-engineer-status"></div>
                                                        <p className="mx-engineer-name">{user.userName}</p>
                                                        <i className="zmdi zmdi-info-outline mx-engineer-info">
                                                            <div className="mx-engineer-info-main">
                                                                <div className="mx-engineer-info-row">Name: {user.userName}</div>
                                                                <div className="mx-engineer-info-row">Status: {isOnline ? "Available" : "Offline"}</div>
                                                            </div>
                                                        </i>
                                                        <button title="Call engineer" className="mx-engineer-call" onClick={()=>callEngineer({user, isOnline})}><i className="zmdi zmdi-phone"></i></button>
                                                    </div>
                                                );
                                            })
                                            :<p>No resource allocated.</p>
                                        }
                                    </div> */}
                                    <div className="mx-details-scrollable">
                                        <AssignedRersources
                                            userList={project.userList}
                                            callEngineer={callEngineer}
                                        />
                                    </div>
                                </div>
                                <div className="mx-details-inner card">
                                    <p className="mx-details-title">Group chat<i className="zmdi zmdi-info-outline mx-details-info"><span>Group chat with engineers</span></i></p>
                                    <GroupChat assignedUsers={project.userList} jiraKey={project.jiraKey} detailId={project.detailId} />
                                </div>
                                {/* <div className="mx-details-inner card ">
                                    <DoughnutChart data={chartData[0]} title="Incidents created by severity" />
                                </div>
                                <div className="mx-details-inner card ">
                                    <DoughnutChart data={chartData[1]} title="Incidents created by owner" />
                                </div>
                                <div className="mx-details-inner card ">
                                    <DoughnutChart data={chartData[2]} title="Incidents created by status" />
                                </div>
                                <div className="mx-details-inner card ">
                                    <BarChart data={chartData[0]} title="Incidents created by status" />
                                </div> */}
                            </div>
                        </Dropdown>
                    )
                })
                : projectDetails.lstProjectDetails !== null ?
                <TextLoader loaderTitle='Getting details' />
                : <h3>Error occured</h3>
            }
        </div>
    </>
    return(
        <>
        {
            isLoader ?
                <Loader/>
            :""
        }
        {
            isCalling ? 
                <div style={{ minHeight: "calc(100vh - 70px)" }} className="d-flex justify-content-center align-items-center">
                    <TextLoader loaderTitle='Connecting you to the ManageX team' />
                </div> :
                isCallPicked ?
                    <div style={{ minHeight: "calc(100vh - 70px)" }} className="d-flex justify-content-center align-items-center">
                        <TextLoader loaderTitle='Call is picked. Please wait a few seconds.' />
                    </div> :
                    isCallJoined ?
                        <SupportCall endCall={endCall} apiKey={callSessionObj.apiKey} sessionId={callSessionObj.sessionId} token={callSessionObj.token} /> :
                        isCallDeclined ?
                        _data : _data
        }
        </>
    )
}

const mapStateToProps = state => {
    return state;
}


export default connect(mapStateToProps, null)(ProjectDetailView);