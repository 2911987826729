import React, { Component } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { OTSession } from 'opentok-react';
import SupportCall from './SupportCall';
import ContactForm from './ContactForm';
import TextLoader from './TextLoader';
import Service from '../../components/Service/Api/Api';
import cookie from "react-cookies";
import { connect } from 'react-redux';
import { setMainSession } from '../../store/actions/mainSessionActions';
import { setCallSession, setIsCallJoined, setIsCallDeclined, setIsCalling } from '../../store/actions/callSessionActions';
import {withRouter} from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from '../Loader/Loader';
import Logo from "../../img/logo.png";

class CustomerSupport extends Component {
    constructor(props) {
        super(props);
        this.notificationRef = React.createRef();
        this.timeout = null;
        this.state = {
            captchaStatus:false,
            ccs: 0,
            name: '',
            email: '',
            phone: '',
            companyName:'',
            message: '',
            apiKey: '',
            token: '',
            callApiKey: "",
            callToken: "",
            callSessionId: "",
            sessionId: '',
            admins:[],
            gettingSessionLoader:false
            // myEmail: "zaijaz@vaporvm.com"
        }
    }
    endCall = () => {
        this.props.dispatch(setCallSession({apiKey:'apiKey', sessionId:'sessionId',token:'token'}));
        // cookie.remove("callApiKey");
        // cookie.remove("callSessionId");
        // cookie.remove("callToken");
        sessionStorage.removeItem("callApiKey");
        sessionStorage.removeItem("callSessionId");
        sessionStorage.removeItem("callToken");

        this.props.dispatch(setIsCallJoined(false));
        this.props.dispatch(setIsCallDeclined(false));
        this.props.dispatch(setIsCalling(false));
        this.setState({ccs:0,captchaStatus:false});
        // cookie.save("apiKey", "apiKey", { 
        //     path: "/",
        //     maxAge: 1 * 3600,
        // });
        // cookie.save("sessionId", "sessionId", {
        //     path: "/",
        //     maxAge: 1 * 3600,
        // });
        // cookie.save("token", "token", {
        //     path: "/",
        //     maxAge: 1 * 3600,
        // });
        // this.props.history.push('/');
    }


    // First time sending signal to admins


    checkAck = () => {
        console.log("Check ACK Running in customerSupport")
        const { mainSessionSignalObj } = this.props.mainSessionReducer;
        const signalData = {
            type: "supportCallGuest",
            data: JSON.stringify({
                senderEmail: this.state.email,
                senderPhone:this.state.phone,
                userName: this.state.name,
                receivers:this.state.admins
                
            })
        }
        cookie.save("guestName",this.state.name);
        cookie.save("guestEmail",this.state.email);
        cookie.save("guestPhone",this.state.phone);
        mainSessionSignalObj(signalData) //Sending first signal to the admins and expecting an acknowledge signal
    }
    handleForm = () => {
        if (this.state.name && this.state.email && this.state.phone && this.state.companyName && this.state.captchaStatus) {
            try {
                const data = {
                    "name": this.state.name,
                    "email": this.state.email,
                    "contactNumber": this.state.phone,
                };
                this.setState({gettingSessionLoader:true});
                Service.GetGuestSession(data).then((res) => {
                    if (res) {
                        this.setState({gettingSessionLoader:false});
                        const { mainSessionObj } = this.props.mainSessionReducer;
                        console.log(mainSessionObj)
                        if(mainSessionObj.apiKey==="apiKey" && mainSessionObj.sessionId==="sessionid" && mainSessionObj.token==="token"){
                            this.props.dispatch(setMainSession({apiKey:res.apiKey, token:res.token, sessionId:res.sessionId}));
                        }
                        this.setState({ccs:2});
                        // apiKey , sessionId , token
                        cookie.save("apiKey", res.apiKey, { 
                            path: "/",
                            maxAge: 1 * 3600,
                        });
                        cookie.save("sessionId", res.sessionId, {
                            path: "/",
                            maxAge: 1 * 3600,
                        });
                        cookie.save("token", res.token, {
                            path: "/",
                            maxAge: 1 * 3600,
                        });
                        this.checkAck();
                    }
                });
                // this.setState({ captchaStatus: true , ccs : 1 });
            }
            catch (ex) {
                console.log(ex);
            }
        }
        else{
            Swal.fire(
                'Fields missing',
                'Please fill in all the necessary fields',
                'error'
            );
        }
    }
    handleReachOutForm = () => {
        if (this.state.name && this.state.email && this.state.phone && this.state.companyName) {
            const { name, email, phone, message, companyName } = this.state;
            const admins = this.state.admins;
            if(!cookie.load("hasContacted")){
                admins.forEach(admin=>{
                        const EmailData = new FormData();
                        EmailData.append('ToEmail', admin.email);
                        EmailData.append('Subject', name + " is trying to contact you on ManageX!");
                        EmailData.append('Body',`<p>A user wants to connect with you on a call. Please get in touch with them by using their information below.</p> <p>Name: ${name}</p> <p>Email: ${email}</p> <p>Phone: ${phone}</p> <p>Company Name: ${companyName}</p> <p>Message: ${message}</p>`);
                        Service.SendEmail(EmailData);
                    });
                    this.props.dispatch(setIsCallDeclined(false));
                    Swal.fire(
                        'Thank you for contacting ManageX',
                        'Our team will contact you shortly',
                        'success'
                    );
                    cookie.save("hasContacted",true, {maxAge: 3600});
                }
                else{
                    Swal.fire(
                        'You have already submitted a request',
                        'Please wait for our team to get back to you',
                        'info'
                    );
                }
        }
        else{
            Swal.fire(
                'Fields missing',
                'Please fill in all the necessary fields',
                'error'
            );
        }
    }
    setFormValues = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleVerify = () => {
        this.setState({ captchaStatus: true });
    }
    expireCaptcha = () =>{
        this.setState({ captchaStatus: false });
    }
    componentDidMount() {
        const apiKey = cookie.load("callApiKey");
        const sessionId = cookie.load("callSessionId");
        const token = cookie.load("callToken");
        if(apiKey && sessionId && token){
            this.props.dispatch(setCallSession({
                apiKey:`${apiKey}`,
                token:`${token}`,
                sessionId:`${sessionId}`
            }));
            this.props.dispatch(setIsCallJoined(true));
        }
        Service.getSupportAdmins().then(res=>{
            this.setState({admins:res});
        })
    }
    render() {
        const { callSessionObj, isCalling, isCallJoined, isCallDeclined, isCallPicked } = this.props.callSessionReducer;
        // console.log(callSessionObj);
        return (
            <>
                {
                    this.state.gettingSessionLoader ?
                    <Loader/>
                    :""
                }
                {
                    isCalling ?
                    <div style={{ minHeight: "calc(100vh - 70px)" }} className="d-flex justify-content-center align-items-center">
                        <TextLoader loaderTitle='Connecting you to the ManageX team' />
                    </div> :
                    isCallPicked ?
                    <div style={{ minHeight: "calc(100vh - 70px)" }} className="d-flex justify-content-center align-items-center">
                        <TextLoader loaderTitle='Getting ready to join. Please wait' />
                    </div> :
                    isCallJoined ?
                    <SupportCall endCall={this.endCall} apiKey={callSessionObj.apiKey} sessionId={callSessionObj.sessionId} token={callSessionObj.token} /> :
                    isCallDeclined ?
                    <div class="authentication">
                        <div class="container">
                            <div class="col-md-12 content-center">
                                <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="company_detail">
                                        <h4 class="logo"><img src={Logo} alt="" /></h4>
                                        <h3>Dear User, All of our Engineers are busy at the moment.</h3>
                                        <p>Please leave your message by using this from and we will get back to you in a jiffy!</p>
                                        <div class="footer">
                                            <ul class="social_link list-unstyled">
                                            <li><a href="https://twitter.com/managexae" title="LinkedIn"><i class="zmdi zmdi-linkedin"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/managexae/" title="Facebook"><i class="zmdi zmdi-facebook"></i></a></li>
                                            <li><a href="https://www.facebook.com/managex.ae" title="Twitter"><i class="zmdi zmdi-twitter"></i></a></li>
                                            </ul>
                                            <hr />
                                            <ul>
                                                <li><a href="https://www.managex.ae/contact-us/" target="_blank">Contact Us</a></li>
                                                <li><a href="https://www.managex.ae/about-us/" target="_blank">About Us</a></li>
                                                <li><a href="https://www.managex.ae/privacy-policy/" target="_blank">Privacy Policy</a></li>
                                                <li><a href="https://www.managex.ae/terms-of-services/">Terms Of Services</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-12 offset-lg-1">
                                    <ContactForm
                                        formTitle="Please fill in the fields below"
                                        componentTitle=""
                                        onSubmit={this.handleReachOutForm}
                                        setFormValues={this.setFormValues}
                                        data={{ name: this.state.name, email: this.state.email, phone: this.state.phone, companyName:this.state.companyName, message: this.state.message }}
                                        hasMessage={true}
                                    />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div class="authentication">
                    <div class="container">
                      <div class="col-md-12 content-center">
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <div class="company_detail">
                              <h4 class="logo"><img src={Logo} alt="" /></h4>
                              <h3>To begin, we will need some of your information</h3>
                              <div class="footer">
                                <ul class="social_link list-unstyled">
                                  <li><a href="https://twitter.com/managexae" title="LinkedIn"><i class="zmdi zmdi-linkedin"></i></a></li>
                                  <li><a href="https://www.linkedin.com/company/managexae/" title="Facebook"><i class="zmdi zmdi-facebook"></i></a></li>
                                  <li><a href="https://www.facebook.com/managex.ae" title="Twitter"><i class="zmdi zmdi-twitter"></i></a></li>
                                </ul>
                                <hr />
                                <ul>
                                    <li><a href="https://www.managex.ae/contact-us/" target="_blank">Contact Us</a></li>
                                    <li><a href="https://www.managex.ae/about-us/" target="_blank">About Us</a></li>
                                    <li><a href="https://www.managex.ae/privacy-policy/" target="_blank">Privacy Policy</a></li>
                                    <li><a href="https://www.managex.ae/terms-of-services/">Terms Of Services</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-5 col-md-12 offset-lg-1">
                              <ContactForm
                                    componentTitle=""
                                    formTitle="Please fill the fields below"
                                    onSubmit={this.handleForm}
                                    submitButtonText="Call as a guest"
                                    setFormValues={this.setFormValues}
                                    data={{ name: this.state.name, email: this.state.email, phone: this.state.phone,companyName:this.state.companyName }}
                                    hasCaptcha={true}
                                    handleVerify={this.handleVerify}
                                    expireCaptcha={this.expireCaptcha}
                                />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
            </>

        )
    }
}
const mapStateToProps = (state)=>{
    return state;
}
export default connect(mapStateToProps,null)(withRouter(CustomerSupport));