import React from "react";
import Service from "./components/Service/Api/Api";
import { OTSession } from 'opentok-react';
import { connect } from 'react-redux';
import { setMainSignalObj, setMainSessionRef } from './store/actions/mainSessionActions';
import { setCallSession, setIsCalling, setIsCallJoined, setIsCallDeclined, setIsCallPicked } from './store/actions/callSessionActions';
import { setSupportStatus } from './store/actions/supportStatusAction';
import { setGroupChat, setMessages } from "./store/actions/groupChatAction";
import { setMainSession, setConnections } from "./store/actions/mainSessionActions";

import cookie from 'react-cookies';
import Swal from "sweetalert2";

class NotificationSession extends React.Component {
    constructor(props) {
        super(props);
        this.notificationRef = React.createRef();
        this.timeout = null;

        this.state = {
            testInput: "",
            connections: []
        }
    }

    sendGroupMessage = (emailList, projectId, message) => {
        const senderEmail = cookie.load("user_Id");
        const senderName = cookie.load("user_Name");
        if (this.props.mainSessionReducer.connections.length) {
            const messageObj = {
                // projectId:"63903761122b9f8b5fe31fd2",
                projectId,
                senderEmail,
                message,
                senderName
            }
            Service.storeMessageToDb(messageObj).then(res => {
                const messageObjFromApi = JSON.stringify(res);
                emailList.forEach(email => {
                    const receivers = this.props.mainSessionReducer.connections.filter(connection => connection.data.includes(email));
                    // console.log(emailList, receivers, this.props.mainSessionReducer.connections)
                    if (receivers.length) {
                        receivers.forEach(receiver => {
                            this.notificationRef.current.sessionHelper.session.signal(
                                {
                                    to: receiver,
                                    type: "group-message",
                                    data: messageObjFromApi
                                },
                                function (error) {
                                    if (error) {
                                        console.log("signal error ("
                                            + error.name
                                            + "): " + error.message);
                                    } else {
                                        console.log("signal sent.");
                                    }
                                });
                        });
                    }
                });
                // Storing the group message in the redux state so we can view it on our client
                this.props.dispatch(setMessages(JSON.parse(messageObjFromApi)))
            });

        }
        else {
            Swal.fire(
                "Cannot send message",
                "The message could not be sent because we have encountered a network issue. Please reload your page. If the problem persists, try logging out and log in again.",
                "error"
            )
        }
    }
    sendSignal = (signalData) => {
        this.notificationRef.current.sessionHelper.session.signal(signalData);
        // console.log("SENDING SIGNAL", this.notificationRef.current.sessionHelper.session)
    }
    componentDidMount() {
        this.reloadOnConnectionOnline();
        this.notifyOnConnectionOffline();
        this.props.dispatch(setMainSignalObj(this.sendSignal));
        this.props.dispatch(setMainSessionRef(this.notificationRef));
        this.props.dispatch(setGroupChat(this.sendGroupMessage));
        const apiKey = cookie.load("apiKey");
        const sessionId = cookie.load("sessionId");
        const token = cookie.load("token");
        if (apiKey === undefined && sessionId === undefined && token === undefined) {
            console.log("Main session aborted ");
            return null
        }
        else if (apiKey !== "apiKey" && sessionId !== "sessionId" && token !== "token") {
            this.props.dispatch(setMainSession({ apiKey: apiKey, token: token, sessionId: sessionId }));
            console.log("Main session initialized ====> ", { apiKey, sessionId, token });
        }
        // console.log("THE REDUX STATE IS", this.props);
    }
    sessionEvents = {
        sessionConnected: event => {
            // const interval = setInterval(()=>{
            //     this.sendSignal({
            //         data: JSON.stringify({sendername:cookie.load("user_Name")}),
            //         type:"Acknowledgement"
            //     })
            // },5000)
            console.log("SESSION CONNECTED", event.target)
        },
        sessionDisconnected: event => {
            console.log("Client disConnect to a session")
        },
        streamCreated: event => {
            console.log('Publisher stream created!');
        },

        // this event fires whenever a new client connects to the session
        // we will use it to get connection object of the clients so that we can signal them individualy instead of broadcasting :D
        connectionCreated: event => {
            this.props.dispatch(setConnections([...this.props.mainSessionReducer.connections, event.connection]))
        },

        // This event fires when a client disconnects from the session.
        // We will use it to clean our state and remove the client who disconnects from the session
        connectionDestroyed: event => {
            this.props.mainSessionReducer.connections.forEach((connection,i,arr)=>{
                if(connection.id === event.connection.id){
                    arr.splice(i, 1);
                    this.props.dispatch(setConnections(arr))
                }
            });
        },
        streamDestroyed: event => {
            console.log('Publisher stream destroyed!');
        },
        signal: event => {
            // console.log("Signal received on main session", event);
            const resObj = event.data;
            const { supportUserEmail } = resObj;
            const data = event.data;
            const guestEmail = cookie.load("guestEmail");
            const guestName = cookie.load("guestName");
            const guestPhone = cookie.load("guestPhone");
            if (event.type === "signal:supportCall") {
                const senderEmail = cookie.load("user_Id");
                const senderUserName = cookie.load("user_Name");
                const signalData = JSON.parse(data);
                if (senderEmail === signalData.senderEmail && senderUserName === signalData.userName) {
                    this.props.dispatch(setIsCalling(true));
                    this.timeout = setTimeout(() => {
                        // console.log("Call rejected");
                        this.props.dispatch(setIsCallDeclined(true));
                        this.props.dispatch(setIsCalling(false));


                        Swal.fire(
                            'Support Busy',
                            'Sorry the support is busy at the moment. An email also has been sent!',
                            'error'
                        );
                        // Email code shifted to admin panel
                        const EmailData = new FormData();
                        EmailData.append('ToEmail', signalData.supportPersonId);
                        EmailData.append('Subject', cookie.load("user_Name") + " is trying to contact you on ManageX!");
                        EmailData.append('Body', `<p>${cookie.load("user_Name")} wants to connect with you on a call. Please get in touch with ${cookie.load("user_Id")}`);
                        Service.SendEmail(EmailData);


                    }, 30000);
                }
            }
            else if (event.type === "signal:supportCallGuest") {
                const signalData = JSON.parse(data);
                if (guestEmail === signalData.senderEmail && guestName === signalData.userName && guestPhone === signalData.senderPhone) {
                    this.props.dispatch(setIsCalling(true));
                    this.timeout = setTimeout(() => {
                        // console.log("Call rejected");
                        this.props.dispatch(setIsCallDeclined(true));
                        this.props.dispatch(setIsCalling(false));
                        // sending emails to all admins

                        Swal.fire(
                            'Support Busy',
                            'Sorry the support is busy at the moment. An email also has been sent!',
                            'error'
                        );
                        // Email code shifted to admin panel
                        Service.getSupportAdmins().then(admins => {
                            admins.forEach(admin => {
                                const EmailData = new FormData();
                                EmailData.append('ToEmail', admin.email);
                                EmailData.append('Subject', cookie.load("user_Name") + " is trying to contact you on ManageX!");
                                EmailData.append('Body', `<p>${cookie.load("user_Name")} wants to connect with you on a call. Please get in touch with ${cookie.load("user_Id")}`);
                                Service.SendEmail(EmailData);
                            });
                        });

                    }, 30000);
                }
            }
            else if (event.type === "signal:SupportAcknowledgementConfirmed" && !this.props.supportStatusReducer.supportStatus) {
                // change redux state for "supportStatus" here
                // console.log("Ack confirmed by ", event.data.UserEmail);
                // console.log("The id of admin is ", event.form)
                this.props.dispatch(setSupportStatus(true));
                setTimeout(() => {
                    this.props.dispatch(setSupportStatus(false));
                    // console.log("Ack cleared");
                }, 10000)
            }
            else if (event.type === "signal:supportCallAck" && this.props.callSessionReducer.isCalling) {
                // console.log(`${supportUserEmail} has accepted the call.`);
                this.props.dispatch(setIsCalling(false));
                this.props.dispatch(setIsCallPicked(true));
                clearTimeout(this.timeout);
                // Will get below data object from the redux state
                const data = {
                    name: cookie.load("guestName") || cookie.load("user_Name"),
                    email: cookie.load("guestEmail") || "Test@email.com",
                    contactNumber: cookie.load("guestPhone") || "1234567890",
                };
                Service.GetGuestVideoSession(data).then(res => {
                    if (res) {
                        this.props.dispatch(setCallSession({
                            apiKey: `${res.apiKey}`,
                            token: `${res.token}`,
                            sessionId: `${res.sessionId}`
                        }));
                        sessionStorage.setItem("callApiKey", `${res.apiKey}`);
                        sessionStorage.setItem("callSessionId", `${res.sessionId}`);
                        sessionStorage.setItem("callToken", `${res.token}`);
                        // cookies disabled
                        // cookie.save("callApiKey", `${res.apiKey}`);
                        // cookie.save("callSessionId", `${res.sessionId}`);
                        // cookie.save("callToken", `${res.token}`);
                        this.props.dispatch(setIsCallPicked(false));
                        this.props.dispatch(setIsCallJoined(true));

                        // console.log("Your data for joining the session is ",res);
                        // Sending new signal to join newly created session
                        this.sendSignal({
                            type: "joinCall",
                            to: event.from,
                            data: JSON.stringify({
                                senderEmail: data.email,
                                userName: data.name,
                                sessionId: res.callSessionId,
                                dbSessionId: res.id,
                                targetEmail: supportUserEmail
                            })
                        });
                    }
                })
            }
            else if (event.type === "signal:supportCallAck" && !this.props.callSessionReducer.isCalling) {
                this.sendSignal({
                    type: "callAlreadyJoined",
                    to: event.from,
                    data: JSON.stringify({
                        senderEmail: data.email,
                        userName: data.name,
                        targetEmail: supportUserEmail
                    })
                });
            }
            else if (event.type === "signal:supportCallRej" && this.props.callSessionReducer.isCalling) {
                // console.log(`${supportUserEmail} has rejected the call.`);
                clearTimeout(this.timeout);
                this.props.dispatch(setIsCallDeclined(true));
                this.props.dispatch(setIsCalling(false));
                Swal.fire(
                    'Call Denied',
                    'Sorry the engineer is busy at the moment. They will contact you on your email address.',
                    'error'
                );
                const EmailData = new FormData();
                EmailData.append('ToEmail', event.data.supportUserEmail);
                EmailData.append('Subject', cookie.load("user_Name") + " is trying to contact you on ManageX!");
                EmailData.append('Body', `<p>${cookie.load("user_Name")} wants to connect with you on a call. Please get in touch with ${cookie.load("user_Id")}`);
                Service.SendEmail(EmailData);

            }
            else if (event.type == 'signal:AcknowledgementConfirmed') {
                //console.log("Hamza " + event.from.id);
                var SignalData = JSON.parse(event.data);
                const myEmail = cookie.load("user_Id");
                if (SignalData != null && SignalData != undefined && SignalData.UserEmail != myEmail) {
                    if (window._SignalList.length == 0) {
                        var _tempDate = new Date();
                        var _tempObj = {
                            Name: SignalData.UserEmail,
                            AckTime: _tempDate,
                            Status: SignalData.Status
                        }
                        //console.log("1 : Adding First Time");
                        window._SignalList.push(_tempObj);
                        //console.log(window._SignalList);
                    }
                    else {
                        var TempSignalObj = window._SignalList.find(x => x.Name == SignalData.UserEmail)
                        if (TempSignalObj != null && TempSignalObj != undefined) {
                            TempSignalObj.AckTime = new Date();
                            TempSignalObj.Status = SignalData.Status;
                            // console.log("2 : updating");
                            // console.log("Updated Object");
                            // console.log(TempSignalObj);
                            // console.log("Updated List print");
                            // console.log(window._SignalList);
                            //debugger;
                        }
                        else {
                            var _tempObj = {
                                Name: SignalData.UserEmail,
                                AckTime: new Date(),
                                Status: SignalData.Status
                            };
                            //console.log("3 another new entry");
                            window._SignalList.push(_tempObj);
                            //console.log("Updated List New object");
                            //console.log(window._SignalList);
                            //debugger;
                        }
                    }
                }
                //console.log("Array check below :");
                //console.log(window._SignalList);
            }
            else if(event.type==="signal:group-message"){
                // console.log("Received text message ", event);
                this.props.dispatch(setMessages( JSON.parse(event.data) ));
            }
        }
    }
    reloadOnConnectionOnline = () => {
        window.addEventListener("online", e => {
            window.location.reload();
        });
    }
    notifyOnConnectionOffline = () => {
        window.addEventListener("offline", e => {
            Swal.fire("Connection lost", "Your connection seems to have an issue. Please ensure that your connection is stable.", "error");
        });
    }
    render() {
        const { mainSessionReducer } = this.props;
        const { mainSessionObj } = mainSessionReducer;
        return (
            <OTSession
                ref={this.notificationRef}
                eventHandlers={this.sessionEvents}
                onError={() => { }}
                apiKey={`${mainSessionObj.apiKey}`}
                sessionId={`${mainSessionObj.sessionId}`}
                token={`${mainSessionObj.token}`}
            >
                {this.props.children}
            </OTSession>

        )
    }

}
const mapStateToProps = (state) => {
    return state;
}
export default connect(mapStateToProps, null)(NotificationSession);