import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Row } from "react-bootstrap";
export default class MicrosoftOffice365 extends React.Component {
    constructor(props)
    {
      super(props);
      this.state = { 
        userId :cookie.load('user_Id'),
        userName : cookie.load('user_Name'),
        AssignedUserName :""        
     };
      this.getMSOffice365 = this.getMSOffice365.bind(this);
    }
    getMSOffice365()
    {
        this.props.history.push('/Questionnaire/1');
    }
    componentDidMount(){
        
        Service.GetUserByServiceId(1).then(res =>{
            //console.log(res);
            this.setState({AssignedUserName:res.UserName})
        })
    }
    startCall(CallingTo){
        if (cookie.load('user_Id')==undefined) {
            this.props.history.push('login')
        }
        else
        window.call(CallingTo,  this.state.userId)
    }
    render() {
        return (
            <>
            <div className="hero parallax" 
            style={{height:400,"background-image": "linear-gradient(to bottom, rgba(245, 246, 252, 0.1), rgba(11, 32, 35, 1)), url(https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/O365M.jpg)"}}>
              {/* <!-- Hero caption --> */}
              <div id="main-hero" className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-5 signup-column has-text-left">
                            <h1 className="title main-title text-bold is-2 text-center" style={{marginTop:140}}>
                            MICROSOFT OFFICE 365
                           </h1>
                            <h2 className="subtitle is-5 no-margin-bottom is-light text-center">
                            SECURITY, COMPLIANCE AND PERFORMANCE AT YOUR FINGERTIPS
                           </h2>
                            <br />
            
                        </div>
                    
                    </div>
                </div>
              </div>
              {/* <!-- Clients --> */}
            </div>                                          
                {/* <!-- Process section --> */}

                <div className="container">
                   <div className="content-wrapper">
                     <div className="columns is-vcentered pb-40 pt-40">
                        <div className="column is-4 is-offset-1">
                        <div className="icon-subtitle"></div>
                          <div className="title quick-feature has-numbers text-bold">
                            <div>MANAGEX YOUR MIGRATION
                            </div>
                         </div>
                         <div className="title-divider is-small"></div>
                         <span className="body-text" style={{textAlign:"justify"}}>
                           <div className="Paragraph">
                             <p>
                             ManageX helps organizations smoothly transit to Office 365 
                             from on-premises, cloud or hybrid solutions. 
                             Relying on our Office 365 consulting practice, we guide our 
                             customers through the entire migration path and ensure that 
                             the migrated solution operates appropriately and meets the 
                             collaboration expectations of users.
                             </p>
                           </div>
                         </span>
                         <div className="pt-10 pb-10">
                             <div className='row'>
                             <button className="button btn-align btn btn-primary col-4" onClick={this.getMSOffice365}>Buy Now</button>
                             {this.state.AssignedUserName!=""?
                             <div className='col-2'><button className="button btn-align btn btn-success"  onClick={() => this.startCall(this.state.AssignedUserName)}>Talk to an expert</button></div>
                             :""}
                                
                             </div>
                         
                        </div>
                       </div>
                        <div className="column is-6 is-offset-1">
                        <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/office365.jpg" 
                        data-base-url="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/office365.jpg" data-extension=".jpeg" alt=""/>
                       </div>
                 </div>
                 <div id="services" className="section is-medium"><div className="container">
                 <div className="section-title-wrapper has-text-centered"><div className="bg-number"></div>
                 <h2 className="section-title-landing">What We Do Best</h2>
                 <h4>Learn a solid coding basis in less than 6 weeks</h4>
                 </div><div className="content-wrapper">
                 <div className="columns is-vcentered is-multiline has-text-centered">
                 <div className="column is-3"><div className="wavy-icon-box"><div className="is-icon-reveal">
                 <i className="im im-icon-Share-onCloud"></i></div><div className="box-title">Solve Connectivity Issues</div>
                 <p className="box-content is-tablet-padded">Assess an organisation’s existing environment</p>
                 </div></div><div className="column is-3"><div className="wavy-icon-box">
                 <div className="is-icon-reveal">
                 <i className="im im-icon-Block-Cloud">
                 </i></div><div className="box-title">Failed Migrations</div>
                 <p className="box-content is-tablet-padded">Optimise the existing solution and technical architecture.</p>
                 </div></div><div className="column is-3"><div className="wavy-icon-box">
                 <div className="is-icon-reveal"><i className="im im-icon-Cloud-Secure"></i></div>
                 <div className="box-title">Learn Javascript</div>
                 <p className="box-content is-tablet-padded">Code quality assessment and security coverage</p>
                 </div></div><div className="column is-3"><div className="wavy-icon-box">
                 <div className="is-icon-reveal"><i className="im im-icon-People-onCloud"></i></div>
                 <div className="box-title">Office365 Adoption</div>
                 <p className="box-content is-tablet-padded">Code quality assessment and security coverage</p>
                 </div>
                 </div></div></div>
                 </div>
                 </div>
                 <div className="columns is-vcentered pb-40 pt-40">
                 </div>
                 </div>
                 </div>
                  <div id="about" className="section is-app-grey is-medium">
                    <div className="container">
                        {/* <!-- Title --> */}
                        <div className="section-title-wrapper has-text-centered">
                            <div className="bg-number"></div>
                            <h2 className="section-title-landing">
                            What Differentiates ManageX
                        </h2>
                            <h3 className="section-subtitle-alt">Before creation, there's thinking. Our process is sharp and let us craft
                      the best quality.</h3>
                        </div>
                        {/* <!-- Process steps --> */}
                        <div className="content-wrapper">
                            <div className="columns is-vcentered">
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block has-line">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Over"></i>
                                                <div className="process-number">1</div>
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">1</div>
                                            <div className="details">
                                                <div className="motto" style={{fontSize:25}}>Pre-migration activities</div>
                                                <p className="description">Register using your company ID and get access to our CIO's dashboard.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block has-line">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Around"></i>
                                                <div className="process-number">2</div>
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">2</div>
                                            <div className="details">
                                                <div className="motto" style={{fontSize:25}}>Complete Project Visibility</div>
                                                <p className="description">Pick a service from our vast digital transformation portfolio</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Refresh"></i>
                                                <div className="process-number">3</div>
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">3</div>
                                            <div className="details">
                                                <div className="motto" style={{fontSize:25}}>Post Migration Support</div>
                                                <p className="description">Use our secure channel to pay online or via a pay order and a subject matter expert is assigned to your project within 5 minutes</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/**/}
                 <div className="section is-medium parallax is-cover is-relative" 
                  data-background="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/O365M.jpg"
                  data-color="#000" 
                  data-color-opacity="0.7" data-demo-background="/assets/img/photos/CustomImages/O365M.jpg">

                   <div className="parallax-overlay"></div>
                 <div className="container">
                     <div className="section-title-wrapper">
                         <div className="bg-number">3</div>
                         <h2 className="title section-title has-text-centered light-text text-bold">
                             Why ManageX Migration?
                         </h2>
                     </div>
         
                     <div className="content-wrapper">
                         <div className="columns is-vcentered has-text-centered">
                             <div className="column is-4">
                                 <div className="parallax-counter">
                                     <div className="counter-icon">
                                         <i className="im im-icon-Hotel"></i>
                                     </div>
                                     <div className="counter counter-number text-bold">126</div>
                                     <div className="counter-text">Customers</div>
                                 </div>
                             </div>
                             <div className="column is-4">
                                 <div className="parallax-counter">
                                     <div className="counter-icon">
                                         <i className="im im-icon-Cloud-Email"></i>
                                     </div>
                                     <div className="counter counter-number text-bold">10480</div>
                                     <div className="counter-text">Mailboxes Migrated</div>
                                 </div>
                             </div>
                             <div className="column is-4">
                                 <div className="parallax-counter">
                                     <div className="counter-icon">
                                         <i className="im im-icon-Big-Data"></i>
                                     </div>
                                     <div className="counter counter-number text-bold">157200</div>
                                     <div className="counter-text">GB's of Data to the Cloud</div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>

                 

            </>


        )
    }
}

