import React, { useState, useEffect } from "react";
import "./Reports.css";
import cookie from 'react-cookies';
import Swal from 'sweetalert2';
import Service from "../Service/Api/Api";
import { Encrypt, Decrypt } from "../../copmonents/Utility/Encryption";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "save-as";
import Loader from "../Loader/Loader";

let timeoutForOTP = null;



const Reports = ({ reportList, projectName, jiraKey }) => {
    const [ isLoader, setIsLoader ] = useState(false);


    function CompressReportsAndDownload(reportList,projectName, jiraKey){
        var zip = new JSZip();
        var count = 0;
        var zipFilename = `${projectName}-${jiraKey}-reports.zip`;
        setIsLoader(true);
        let folder = zip.folder("reports");
    
        reportList.forEach((report,i)=>{
            const filename = `${i+1}-${report.fileName}`;
            JSZipUtils.getBinaryContent(report.fileURL, function(err, data) {
                if (err) {
                  throw err; // or handle the error
                }
                folder.file(filename, data, { binary: true });
                count++;
                if (count === reportList.length) {
                  zip.generateAsync({ type: "blob" }).then(function(content) {
                    setIsLoader(false);
                    saveAs(content, zipFilename);
                  });
                }
              });
        })
    }
    function generateOTP () {
        // Declare a digits variable 
        // which stores all digits
        var digits = '0123456789';
        let OTP = '';
        for (let i = 0; i < 6; i++) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        return OTP;
    }
    function DownloadAllReports (reportList, projectName, jiraKey){
        const OTP = generateOTP();
        const expiryTimer = 2 * 1000 * 60;
        let tempOTP = "";
        timeoutForOTP = setTimeout(() => {
            tempOTP="";
            console.log("OTP EXPIRED")
        }, expiryTimer);
    
        // sending OTP to user via email
        const EmailData = new FormData();
        EmailData.append('ToEmail', cookie.load("user_Id"));
        EmailData.append('Subject', "OTP for downloading reports.");
        EmailData.append('Body', `Hello ${cookie.load("user_Id")}, your OTP for downloading the reports is <b>${OTP}</b><p>Note: The OTP will only be valid for next 2 minutes.</p>`);
        Service.SendEmail(EmailData);
    
    
        const _encryptedOTP = Encrypt(JSON.stringify(OTP)).toString();
        tempOTP = _encryptedOTP;
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: 'Enter OTP to download',
            icon: "info",
            text: `An OTP has been sent to ${cookie.load("user_Id")}. Please enter it below.`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Download',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                return login
            },
            timer: expiryTimer
        }).then((result) => {
            if (result.isConfirmed) {
                if (tempOTP !== "") {
                    const _decryptedOTP = JSON.parse(Decrypt(tempOTP));
                    if (result.value === _decryptedOTP) {
                        clearTimeout(timeoutForOTP);
                        tempOTP="";
                        CompressReportsAndDownload(reportList, projectName, jiraKey);
                    }
                    else {
                        Swal.fire(
                            'Invalid OTP?',
                            'Please enter valid OTP',
                            'error'
                        )
                    }
                }
                else {
                    Swal.fire(
                        'Invalid OTP?',
                        'OTP might be expired',
                        'error'
                    )
                }
            }
        });
    }
    function DownloadReport (Url) {
        const OTP = generateOTP();
        const expiryTimer = 2 * 1000 * 60;
    
        let tempOTP = "";
        timeoutForOTP = setTimeout(() => {
            tempOTP="";
            console.log("OTP EXPIRED")
        }, expiryTimer);
    
        // sending OTP to user via email
        const EmailData = new FormData();
        EmailData.append('ToEmail', cookie.load("user_Id"));
        EmailData.append('Subject', "OTP for downloading report.");
        EmailData.append('Body', `Hello ${cookie.load("user_Id")}, your OTP for downloading the report is <b>${OTP}</b><p>Note: The OTP will only be valid for next 2 minutes.</p>`);
        Service.SendEmail(EmailData);
    
        const _encryptedOTP = Encrypt(JSON.stringify(OTP)).toString();
        tempOTP = _encryptedOTP;
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: 'Enter OTP to download',
            icon: "info",
            text: `An OTP has been sent to ${cookie.load("user_Id")}. Please enter it below.`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Download',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                return login
            },
            timer: expiryTimer
        }).then((result) => {
            if (result.isConfirmed) {
                if (tempOTP !== "") {
                    const _decryptedOTP = JSON.parse(Decrypt(tempOTP));
                    if (result.value === _decryptedOTP) {
                        clearTimeout(timeoutForOTP);
                        tempOTP="";
                        window.open(Url, '_blank').focus();
                    }
                    else {
                        Swal.fire(
                            'Invalid OTP?',
                            'Please enter valid OTP',
                            'error'
                        )
                    }
                }
                else {
                    Swal.fire(
                        'Invalid OTP?',
                        'OTP might be expired',
                        'error'
                    )
                }
            }
        });
    }
    function SendPasswordToUser (pwd) {
        if (pwd) {
            const EmailData = new FormData();
            EmailData.append('ToEmail', cookie.load("user_Id"));
            EmailData.append('Subject', "Password for your report");
            EmailData.append('Body', `
            <p>Greetings ${cookie.load("user_Name")},<br/>Please find report password below.</p>
            <p>Password: <b>${pwd}</b></p>
            `);
            Service.SendEmail(EmailData);
            Swal.fire(
                'Password sent',
                "This report's password has been sent to your email.",
                'success'
            )
        }
        else if (pwd === null || pwd === undefined) {
            Swal.fire(
                'No password set',
                'There is no password set for this file.',
                'info'
            )
        }
    }
    return(
        <>
            {
                isLoader ?
                <Loader/>
                : null
            }
            <div className="mx-report-dropdown-child">
                {
                    reportList.length > 0 ?
                    <div className="mx-report-download-all">
                        <button className="mx-report-download-all-button" onClick={(e)=>{e.preventDefault();DownloadAllReports(reportList, projectName, jiraKey)}}>
                            <i className="zmdi zmdi-download mr-2"></i>
                            Download all as zip file
                        </button>
                    </div>
                    : null
                }
                {
                    reportList.length > 0 ?
                    reportList.map((report,i)=>{
                        return(
                                <div className="mx-report">
                                    <span title={report.fileName ? report.fileName : `Report-${i+1}`}>{report.fileName ? report.fileName : `Report-${i+1}`}</span>
                                    <div>
                                        <button className="btn btn-success rounded mx-1" onClick={() => DownloadReport(report.fileURL)}><i className="zmdi zmdi-download"></i></button>
                                        <button title="Protected file password" className="btn btn-primary rounded mx-1" onClick={()=>SendPasswordToUser(report.filePassword)}><i className="zmdi zmdi-key"></i></button>
                                    </div>
                                </div>
                        )
                    })
                    : <p>No reports found.</p>
                }
            </div>
        </>
    )
}

export default Reports;