import React from 'react';
import { connect } from 'react-redux';

class AssignedUserContainer extends React.Component {


    constructor(props) {
        super(props);
        this.state = { time: Date.now() };
    }

    componentDidMount() {
        //console.log("Logging in container");
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 3000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {

        const OnlineStyle = {
            "height": "10px",
            "width": "10px",
            "background-color": "#bbb",
            "border-radius": "50%",
            "display": "inline-block",
            "background-color": "green",
            "margin-right": "7px"
        };

        const BusyStyle = {
            "height": "10px",
            "width": "10px",
            "background-color": "#bbb",
            "border-radius": "50%",
            "display": "inline-block",
            "background-color": "red",
            "margin-right": "7px"
        };

        const OfflineStyle = {
            "height": "10px",
            "width": "10px",
            "background-color": "#bbb",
            "border-radius": "50%",
            "display": "inline-block",
            "background-color": "grey",
            "margin-right": "7px"
        };

        var _tempList = [];
        if (this.props.AssignedUser.length != 0) {

            this.props.AssignedUser.map(function (item, i) {
                var TempSignalObj = window._SignalList.find(x => x.Name == item.UserId);
                if (TempSignalObj != null && TempSignalObj != undefined) {
                    var _oldDate = TempSignalObj.AckTime;
                    var NewDate = new Date();
                    var difference = (NewDate - _oldDate) / 1000;
                    if (difference > 5) {
                        var userobj = {
                            UserId: item.UserId,
                            Status: "Offline"
                        };
                        _tempList.push(userobj);
                    }
                    else {
                        var userobj = {
                            UserId: item.UserId,
                            Status: "Available"
                        };
                        _tempList.push(userobj);
                        // if(TempSignalObj.Status == "Busy"){
                        //     console.log("Busy logic");
                        //     var userobj = {
                        //         UserId : item.UserId,
                        //         Status : "Busy"
                        //     };
                        //     _tempList.push(userobj);
                        // }
                        // else{
                        //     var userobj = {
                        //         UserId : item.UserId,
                        //         Status : "Available"
                        //     };
                        //     _tempList.push(userobj);
                        // }
                    }

                }
                else {
                    var userobj = {
                        UserId: item.UserId,
                        Status: "Offline"
                    };
                    _tempList.push(userobj);
                }
            });

            //this.props.AssignedUser.map((users,index) => 


        }

        return (

            <div className="card">
                <div style={{ display: 'none' }} >{this.state.time}</div>
                {_tempList.length != 0 ?
                    <>
                        {_tempList.map((users, index) =>
                        (
                            <div key={index} className="card-header">
                                <div className='col-10'>{users.UserId}</div>
                                <div className='col-2'>
                                    <button
                                        className="btn btn-success rounded"
                                        disabled={users.Status == "Available" ? false : users.Status == "Offline" ? true : false}
                                        style={{ backgroundColor: "transparent", border: "none", color: '#010138' }}
                                        onClick={() => this.props.startCall(users.UserId, this.props.Project.JiraProjectKey, this.props.Project.ProjectName)}>
                                        <span
                                            title={users.Status == "Available" ? "Online" : users.Status == "Offline" ? "Offline" : "Busy"}
                                            style={users.Status == "Available" ? OnlineStyle : users.Status == "Offline" ? OfflineStyle : BusyStyle}>
                                        </span>
                                        <i className="fa fa-phone"></i>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </>
                    :
                    <div className="card-header">{"We are allocating " + this.props.Project.ProjectName + " Engineer, This will take a few moments"}</div>}

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}
export default connect(mapStateToProps)(AssignedUserContainer);