import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from '../Login/index';
import Header from '../Header';
import SideBar from '../SideBar';
import TopBar from '../TopBar';
import cookie from "react-cookies";
export default class CustomRoutes extends Component{
    constructor(props) {
        super(props);
          this.state = {
            email: "",
            password: "",
            loading: false
            //token:cookie.load('bareer_Token')
        };
    }
    
    render(){
        var token  = cookie.load("bareer_Token");
        var Path = this.props.path;
        // Change for non OTP pages
        var _isVerified = true;
        if (window.location.href.indexOf("Otp") === -1) {
            var isTwoFactorEnable = cookie.load("isTwoFactorEnable");
            if(isTwoFactorEnable){
                var result = cookie.load("isVerified");
                if(result == "false"){
                    _isVerified = false;
                    cookie.remove('bareer_Token');  
                    cookie.remove('apiKey');  
                    cookie.remove('sessionId');  
                    cookie.remove('token'); 
                    cookie.remove('User_Role');
                    cookie.remove('isTwoFactorEnable'); 
                    cookie.remove('isVerified'); 
                    cookie.remove('User_Email');
                    cookie.remove('TFCode'); 
                    localStorage.clear();
                }
                else{
                    _isVerified = true;
                }
            }
        }
        
        if(!(window.location.href.includes("signUp") || window.location.href.includes("login"))){
            //alert(window.location.href)
            localStorage.setItem('RedirectUrl',window.location.href)
        }
        if(!token){
            localStorage.clear();
        }
        return(
            token && _isVerified === true ?
            <div className='d-flex'>
                {/* <Header></Header> */}
                {token ? <SideBar /> : ""}
                <main className='main-content'>
                    {/* {token ? <TopBar /> : ""} */}
                    <Route path={this.props.path}  component={this.props.component} />
                </main>
            </div>
            :
            window.location.href = "../#/login"            
        )
    }
}