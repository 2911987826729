import React from "react";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";
import { Switch, BrowserRouter, Route } from 'react-router-dom';
//import Route from './Route';
import { HashRouter } from 'react-router-dom'
import './App.css';
// import Header from "../src/copmonents/Header.js";
// import Footer from "../src/copmonents/Footer.js";
import Doenlaods from './copmonents/Qoutation/Downlaod'
import DownlaodSOW from './copmonents/Qoutation/DownlaodSOW'
//import MainLayout from "../src/copmonents/MainLayout.js";
// import Home from "./copmonents/Home";
import About from "./copmonents/AboutUs";
import Billing from "./copmonents/Billing";
import DashBoard from "./copmonents/DashBoard";
import ProjectDetailView from "./copmonents/ProjectDetailView";
// import Login from "./copmonents/Login";
import Payments from "./copmonents/Payments";
import PaymentSuccess from "./copmonents/PaymentSuccess";
import PayOrder from "./copmonents/PayOrder";
import Qoutation from "./copmonents/Qoutation";
import Questionnaire from "./copmonents/Questionnaire";
// import SignUp from "./copmonents/SignUp";
import CyberSecurity from "./copmonents/CyberSecurity";
import ManagedServices from "./copmonents/ManagedServices";
import DevOps from "./copmonents/DevOps";
import MicrosoftOffice365 from "./copmonents/MicrosoftOffice365";
import SecurityAssessment from "./copmonents/SecurityAssessment";
import CodeReview from "./copmonents/CodeReview";
import ManageSoc from "./copmonents/ManageSoc";
// import EditProfile from "./copmonents/EditProfile";
import PaymentReject from "./copmonents/PaymentReject";
import AboutUs from "./copmonents/AboutUs";
import ContactUs from "./copmonents/ContactUs";
import TermsofServices from "./copmonents/TermsofServices";
import PrivacyPolicy from "./copmonents/PrivacyPolicy";
import Logout from "../src/copmonents/Logout";
import CustomRoutes from "./copmonents/CustomRoute/index"
import PublicRoutes from "./copmonents/CustomRoute/PublicRoute"
import Call from './copmonents/Communication/Call'
import EmailVerification from './copmonents/EmailVerification/EmailVerification'
import AddNewOrganization from './copmonents/Organization/AddNewOrganization'
import RoleAssignment from './copmonents/Organization/RoleAssignment'
// import ForgetPassword from './copmonents/ForgetPassword/ForgetPassword'
// import ResetPassword from './copmonents/ResetPassword/ResetPassword'
// import Otp from './copmonents/Otp/Otp'
import Reports from './copmonents/Report/Report'
import cookie from "react-cookies";
import ScrollToTop from './copmonents/Common/ScrollToTop';
// import ServiceView from './copmonents/ServicesList/ServiceView';
import QuestionnaireContainer from "./copmonents/publicQuestionnaire/QuestionnaireContainer";
import SupportForm from "./copmonents/SupportForm/SupportForm";
import PackageSelection from "./copmonents/PackageDesign/PackageSelection";
import AdminContainer from "./copmonents/AdminPackageDesign/AdminContainer";
import QuotationForm from "./copmonents/publicQuestionnaire/QuotationForm";
import CustomerSupport from "./copmonents/CustomerSupport/CustomerSupport";

// New imports from components folder START

import NewLogin from "./components/Pages/Login/Login";
import NewSignUp from "./components/Pages/SignUp/SignUp";
import NewForgetPassword from './components/Pages/ForgetPassword/ForgetPassword';
import NewResetPassword from "./components/Pages/ResetPassword/ResetPassword";
import NewEditProfile from "./components/Pages/EditProfile/EditProfile";
import NewOtp from "./components/Pages/Otp/Otp";
import Error404 from "./components/Pages/Error404/Error404";
import ErrorBoundary from './components/ErrorBoundary/index'

import NotificationSession from "./NotificationSession";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: "dashoard"
    }
  }
  setActiveLink = (link) => {
    this.setState({ activeLink: link });
  }
  componentDidMount() {
    if (!window.session) {
      if (cookie.load('apiKey')) {
        var api_key = cookie.load('apiKey')
        var Session_Id = cookie.load('sessionId')
        var Token = cookie.load('token')
        var username = cookie.load('user_Id')
        window.connect(api_key, Session_Id, Token, username)
      }
    }
  }


  render() {
    return (
      <div id="app">
        <NotificationSession>
          <HashRouter>
            <ScrollToTop />
            <Switch>
              {/* <PublicRoutes path="/login" component={Login} /> */}

              {/* <PublicRoutes path="/signUp" component={SignUp} /> */}

              <PublicRoutes path="/signUp" component={NewSignUp} />

              {/* <CustomRoutes path="/editprofile" component={EditProfile} /> */}
              {/* <PublicRoutes path="/ForgetPassword" component={ForgetPassword} /> */}
              {/* <PublicRoutes path="/ResetPassword" component={ResetPassword} /> */}
              {/* <Route path="/Otp" component={Otp} /> */}
              <CustomRoutes path="/editprofile" component={NewEditProfile} />
              <Route path="/Otp" component={NewOtp} />


              {/* Routes commented */}
              {/* <PublicRoutes path="/ForgetPassword" component={NewForgetPassword} />
              <PublicRoutes path="/ResetPassword" component={NewResetPassword} /> */}

              <PublicRoutes exact path="/logout" component={Logout} />
              <CustomRoutes exact path="/" component={DashBoard} />
              <CustomRoutes path="/billing" component={Billing} />
              <CustomRoutes exact path="/dashBoard" component={DashBoard} />
              <PublicRoutes path='/Steps' component={QuestionnaireContainer} />


              <PublicRoutes path="/quoteform" component={QuotationForm} />
              <PublicRoutes path="/about" component={About} />
              <CustomRoutes path="/dashBoard/projectdetails/:projectid" component={ProjectDetailView} />
              <CustomRoutes path="/payments" component={Payments} />
              <CustomRoutes path="/paymentSuccess" component={PaymentSuccess} />
              <CustomRoutes path="/payOrder" component={PayOrder} />
              <CustomRoutes path="/qoutation" component={Qoutation} />
              <CustomRoutes path="/questionnaire/:id" component={Questionnaire} />
              {/* <CustomRoutes  path="/AddNewOrganization" component={AddNewOrganization} /> */}
              <PublicRoutes path="/CyberSecurity" component={CyberSecurity} />
              <PublicRoutes path="/ManagedServices" component={ManagedServices} />
              <PublicRoutes path="/DevOps" component={DevOps} />
              <PublicRoutes path="/MicrosoftOffice365" component={MicrosoftOffice365} />
              <PublicRoutes path="/SecurityAssessment" component={SecurityAssessment} />
              <PublicRoutes path="/CodeReview" component={CodeReview} />
              <PublicRoutes path="/ManageSoc" component={ManageSoc} />
              <CustomRoutes path="/PaymentReject" component={PaymentReject} />
              <PublicRoutes path="/AboutUs" component={AboutUs} />
              <PublicRoutes path="/ContactUs" component={ContactUs} />
              <PublicRoutes path="/TermsofServices" component={TermsofServices} />
              {/* <PublicRoutes  path="/PrivacyPolicy" component={PrivacyPolicy} /> */}
              <PublicRoutes path="/PrivacyPolicy" component={PrivacyPolicy} />
              <PublicRoutes path="/EmailVerification" component={EmailVerification} />
              <CustomRoutes path="/AddNewOrganization" component={AddNewOrganization} />
              <CustomRoutes path="/RoleAssignment" component={RoleAssignment} />
              <PublicRoutes path="/Reports" component={Reports} />
              <CustomRoutes path="/ServiceView" component={QuestionnaireContainer} />
              <Route path='/downlaod' component={Doenlaods} />
              <Route path='/DownlaodSOW' component={DownlaodSOW} />
              <Route path='/call' component={Call} />
              <Route path='/SupportForm' component={SupportForm} />
              <Route path='/Package' component={PackageSelection} />
              <Route path='/AdminPackage' component={AdminContainer} />
              <PublicRoutes path='/CustomerSupport' component={CustomerSupport} />


              {/* New routes */}

              <PublicRoutes path='/NewLogin' component={NewLogin} />
              <PublicRoutes path='/NewSignUp' component={NewSignUp} />
              <PublicRoutes path='/NewForgetPassword' component={NewForgetPassword} />
              <PublicRoutes path='/NewResetPassword' component={NewResetPassword} />
              <PublicRoutes path='/NewOtp' component={NewOtp} />
              <CustomRoutes path='/NewEditProfile' component={NewEditProfile} />

              {/* <ErrorBoundary> */}
              <PublicRoutes path="/login" component={NewLogin} />
              {/* </ErrorBoundary> */}
              <PublicRoutes path="*" component={Error404} />
            </Switch>
          </HashRouter>
        </NotificationSession>
      </div>
    );
  }
}
export default App;
