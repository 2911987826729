import React from "react";
import cookie from "react-cookies";
import Service from "../../components/Service/Api/Api";
import Swal from "sweetalert2";
import { setMainSession } from "../../store/actions/mainSessionActions";
import { connect } from "react-redux";
import { signupTemplate } from "../../components/EmailTemplates/signupTemplate";
import { authCodeTemplate } from "../../components/EmailTemplates/authcodeTemplate";


const initialState = {
    email: "",
    password: "",
    loading: false,
    token: cookie.load("bareer_Token"),
    emailError: "",
    passwordError: "",
    customLoader: false,
    currentScreen:"signin"
  };
class SignUpJourney extends React.Component {
    constructor(){
        super();
        this.state=initialState;
    }

    componentDidMount(){
        this.sendVerificationLink();
    }

    sendVerificationLink=()=>{
        var EamilData = new FormData();
        EamilData.append('ToEmail', this.props.parentState.UserEmailAddress);
        EamilData.append('Subject', 'Welcome to ManageX, Lets solve problems');
        var _bodyTemplate = signupTemplate;
        var Replaceversion = _bodyTemplate.replace("{UserName}", this.props.parentState.UserFirstName + " " + this.props.parentState.UserLastName).replace("{url}", this.props.parentState.verificationUrl);
        //var _emailBody = '<p>Your Account has been successfully created. Click ' + '<a href="'+ res.url +'">link</a>' + ' to activate your account.</p>';
        EamilData.append('Body', Replaceversion);
        this.setState({ customLoader: false });
        Service.SendEmail(EamilData);
        this.props.setCustomLoader(false);
    }

    sendOTP=()=>{
        var EamilData = new FormData();
        EamilData.append('ToEmail', this.props.parentState.UserEmailAddress);
        EamilData.append('Subject', 'Your 2-Factor Code is here!');
        var _bodyTemplate = authCodeTemplate;
        var Replaceversion = _bodyTemplate.replace("{UserName}", this.props.parentState.UserFirstName + " " + this.props.parentState.UserLastName).replace("{Code}", this.state.TFCode);
        //var _emailBody = '<p>Your verification code is :' + this.state.userTfCode  + '</p>';
        EamilData.append('Body', Replaceversion); //_emailBody);
        Service.SendEmail(EamilData);
        this.props.setCustomLoader(false);
    }
















    onChange = (e) => {
        this.setState( { [e.target.name]: e.target.value } );
    };
    OnValidate = (e) => {
        let emailError = "";
        let passwordError = "";
    
        if (!this.state.email.includes("@") && !this.state.email.includes(".")) {
          emailError = "Plesae enter your valid email";
        }
        if (
          !this.state.password.match(
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          )
        ) {
          passwordError = "Please enter your valid password";
        }
        if (emailError || passwordError) {
          this.setState({ emailError, passwordError, loading: true });
    
          return false;
        } else {
          return true;
        }
      };
      validataEmail = () => {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (this.state.email.match(mailformat)) {
          return true;
        }
        return false;
      }


      onSubmit = (e) => {
        e.preventDefault();
        this.props.setCustomLoader(true);

        if (this.state.email && this.state.password !== '') {
          if (this.validataEmail()) {
    
            try {
              this.setState({ customLoader: true });
              const { email, password } = this.state;
              if (email !== "" && password !== "") {
                const isValidated = this.OnValidate();
                if (isValidated) {
                  var data = {
                    username: this.state.email,
                    password: this.state.password,
                  };
                  Service.login(data)
                    .then((res) => {
                      let allowedRoles = ["CompanyUser", "CompanyAdmin", "User"];
                      if (res && res.api_key) {
                        Service.GetSession(res.user.userName).then((responce) => {
                          if (responce) {
                            // New code START
                            // Saving the main session details in the main session Reducer
                            this.props.dispatch(setMainSession({ apiKey: responce.apiKey, token: responce.token, sessionId: responce.sessionId }));
    
                            // New code END
                            cookie.save("apiKey", responce.apiKey, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
                            cookie.save("sessionId", responce.sessionId, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
                            cookie.save("token", responce.token, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
    
                          }
                          if (allowedRoles.includes(res.role[0]) && res.user.emailConfirmed) {
                            cookie.save("bareer_Token", res.api_key, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
    
                            cookie.save("user_Id", res.user.userName, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
                            cookie.save("company_Id", res.companyid, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
    
                            cookie.save("Id", res.user.id, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
    
                            cookie.save(
                              "user_Name",
                              res.user.firstName + " " + res.user.lastName,
                              {
                                path: "/",
                                maxAge: 5 * 3600,
                              }
                            );
    
                            cookie.save("Orgnization", res.user.comapnyName, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
    
                            cookie.save("User_Role", res.user.userRole, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
    
                            cookie.save("admin_email", res.adminemail, {
                              path: "/",
                              maxAge: 5 * 3600,
                            });
                            // ======================================================================================================================
                            if (res.istwofactorenable) {
                              this.setState({ customLoader: false });
                              this.props.setCustomLoader(false);
                              cookie.save("isTwoFactorEnable", true, {
                                path: "/",
                                maxAge: 5 * 3600,
                              });
                              cookie.save("isVerified", false, {
                                path: "/",
                                maxAge: 5 * 3600,
                              });
                              cookie.save("User_Email", res.user.email, {
                                path: "/",
                                maxAge: 5 * 3600,
                              });
                              cookie.save("TFCode", res.authtoken, {
                                path: "/",
                                maxAge: 5 * 3600,
                              });
                              this.setState({currentScreen:"otp", TFCode:res.authtoken},this.sendOTP);
                            }
                          } else if (allowedRoles.includes(res.role[0]) && !res.user.emailconfirmed) {

                            this.setState({ customLoader: false });
                            this.props.setCustomLoader(false);
                            
                            Swal.fire({
                              icon: "error",
                              title: "Email Address Verification",
                              text: "Please activate your account first.",
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          } else {
                            this.setState({ customLoader: false });
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Please enter valid credentials to login!",
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          }
                        });
                      }
                    })
                    .catch((err) => {
                      this.setState({ customLoader: false });
                      if (err.toString().includes(401)) {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Authentication error. Please contact to administrator",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong. Please try again later.",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    });
                } else {
                  this.setState({ customLoader: false });
                }
              } else {
                this.setState({ customLoader: false });
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Please enter Email / Password first.",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } catch (e) {
              this.setState({ customLoader: false });
              console.log(e);
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Enter Valid Email.',
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Invalid email or password.',
            showConfirmButton: false,
            timer: 1500
          });
        }
      };

      onOTPSubmit = e => {
        e.preventDefault();
        this.props.setCustomLoader(true);
        try {
            var payload = {
                TwoFactorCode: this.state.otp,
                email: cookie.load("User_Email")
            }
            Service.VerifyOTP(payload).then(response => {
                if (response.status === "Success") {
                    // set cookie to true
                    cookie.remove("isVerified");
                    cookie.save("isVerified", true);
                    this.props.setJourney(false);
                    this.props.setIsUserLoginMain(true)
                    this.props.setIsUserLogin(true);
                    this.props.setCustomLoader(false);
                }
                else if (response.status === "Failed") {
                  this.props.setCustomLoader(false);
                  Swal.fire({
                    icon: 'error',
                    title: 'Invalid code',
                    text: 'Please ensure that the OTP you entered is correct',
                    showConfirmButton: false,
                    timer: 1500
                });
                }
            }).catch(err => {
                alert(err);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
        catch (e) {
            console.log(e);
        }
    };






    render(){
        const {parentState} = this.props
        return(
            <div className="signup-journey card p-4">
                {
                    this.state.currentScreen === "signin" ?
                    <>
                        <h3 className="text-center">Account verification needed!</h3>
                        <div className="helper-text mb-5">
                            <p>Hi {parentState.UserFirstName} {parentState.UserLastName},<br/></p>
                            <p>
                                Now that you have your account, let's quickly get it verified. Check your email <span className="clr-blue">{parentState.email}</span> for a verification link.
                                <br/>
                                Once you have verified your email, you will be able to login below.
                            </p>
                        </div>
                        <form className="" onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-md-6 my-3">
                                    <input type="text" className="form-control" placeholder="Email" name="email" onChange={this.onChange}/>
                                </div>
                                <div className="col-md-6 my-3">
                                    <input type="password" className="form-control" placeholder="password" name="password" onChange={this.onChange}/>
                                </div>
                                <div className="col-12 text-center">
                                    <button className="custom-button mx-1 bg-sc">Login</button>
                                </div>
                            </div>
            
                        </form>
                    </>
                    : null
                }
                {
                    this.state.currentScreen === "otp" ?
                    <>
                        <h3 className="text-center">Final step</h3>
                        <div className="helper-text mb-5">
                            <p>Your account is protected by two factor authentication,<br/></p>
                            <p>We have sent an OTP to your email <span className="clr-blue">{parentState.email}</span>. Enter that below to login.</p>
                        </div>
                        <form className="" onSubmit={this.onOTPSubmit}>
                            <div className="row">
                                <div className="col-12 my-3">
                                    <input type="text" className="form-control" name="otp" placeholder="OTP code" onChange={this.onChange}/>
                                </div>
                                <div className="col-12 text-center">
                                    <button className="custom-button mx-1 bg-sc">Login</button>
                                </div>
                            </div>
            
                        </form>
                    </>
                    : null
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return state;
}
export default connect(mapStateToProps,null) (SignUpJourney);