import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
class CyberSecurity extends React.Component {
    render() {
        return (
            <>
            <div className="hero parallax" 
            style={{backgroundSize:"100% 100%" , "background-image": "url(https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/CyberSecurity.jpg"}}>
              {/* <!-- Hero caption --> */}
              <div id="main-hero" className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-5 signup-column has-text-left">
                            <h1 className="title main-title text-bold is-2 text-center" style={{marginTop:140}}>
                            CYBER SECURITY
                           </h1>
                            <h2 className="subtitle is-5 no-margin-bottom is-light text-center">
                            SECURITY, COMPLIANCE AND PERFORMANCE AT YOUR FINGERTIPS
                           </h2>
                            <br />
                            
                        </div>
                    
                    </div>
                </div>
              </div>
              {/* <!-- Clients --> */}
            </div>
                {/* <!-- Benefits section --> */}
                
                <div className="section is-medium">

                <div className="section-title-wrapper has-text-centered">
                            <div className="bg-number">1</div>
                            <h2 className="section-title-landing">
                                HOW WE SECURE YOU
                           </h2>
                            <h3 className="section-subtitle-alt">A wide range of consulting services to help you manage your company</h3>
                        </div>
                    <div id="start" className="container">
                        {/* <!-- Title --> */}
                        <div className="content-wrapper" id="OurServices">
                            <div className="columns" style={{ "margin-left": "-3.75rem" }}>
                                {/* <!-- Benefit box --> */}
                                <div className="column is-4 mr-25">
                                    <div id="CardHeight" className="feature-card card-lg light-bordered hover-inset mb-20">
                                        <div className="card-img">
                                            <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/captures/DevOps.jpg" 
                                            alt="" data-demo-src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/captures/DevOps.jpg" />
                                        </div>
                                        <div className="card-title pb-10 pt-10">
                                            <h4>
                                            SECURITY ASSESSMENTS
                                            </h4>
                                        </div>
                                        <div className="card-img-description has-text-left">
                                            <span className="">
                                            ManageX provides Vulnerability
                                             Assessment and Penetration Testing
                                              services to help you immediately 
                                              discover security lapses within 
                                              your infrastructure.
                                             </span>
                                        </div>
                                        <div className="pt-10 has-text-left">
                                        <Link className="button btn-align btn btn-success raised" to="/SecurityAssessment"  style={{marginLeft:20}}>Read More </Link>
                                        <Link className="button btn-align primary-btn raised" to="/SecurityAssessment"    style={{marginLeft:20}}>Buy Now</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-4 mr-25">
                                    <div className="feature-card card-lg light-bordered hover-inset mb-20">
                                        <div className="card-img">
                                            <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/captures/MsOffice.jpg" alt="" data-demo-src="/assets/img/photos/captures/MsOffice.jpg" />
                                        </div>
                                        <div className="card-title pb-10 pt-10">
                                            <h4>SECURITY OPERATIONS CENTER</h4>
                                        </div>
                                        <div className="card-img-description has-text-left">
                                            <span className="">
                                            ManageX SOC-as-a-Service allows our 
                                            customers immediately deploy resources
                                             who use industry leading SIEM solutions 
                                             to begin monitoring your devices.
                                            </span>
                                        </div>
                                        <div className="pt-10 has-text-left">

                                        <Link className="button btn-align btn btn-success raised" to="/ManageSoc"  style={{marginLeft:20}}>Read More </Link>
                                        <Link className="button btn-align primary-btn raised" to="/ManageSoc"    style={{marginLeft:20}}>Buy Now</Link>

                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="column is-4 mr-25">
                                    <div className="feature-card card-lg light-bordered hover-inset mb-20">
                                        <div className="card-img">
                                            <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/captures/ManageService.jpg" alt="" data-demo-src="/assets/img/photos/captures/ManageService.jpg" />
                                        </div>
                                        <div className="card-title pb-10 pt-10">
                                            <h4>CODE REVIEW</h4>
                                        </div>
                                        <div className="card-img-description has-text-left">
                                            <span className="">
                                             Get access to certified 
                                             cloud resources to immediately setup your
                                             CI/CD pipelines, deploy container 
                                             environments and optimize your infrastructure.
                                             </span>
                                        </div>
                                        <div className="pt-10 has-text-left">
                                        <Link className="button btn-align btn btn-success raised" to="/CodeReview"  style={{marginLeft:20}}>Read More </Link>
                                        <Link className="button btn-align primary-btn raised" to="/CodeReview"    style={{marginLeft:20}}>Buy Now</Link>

                                        </div>
                                    </div>
                                </div>

                                
                            
                                
                            </div>
                        </div>

                    </div>
                </div>                                               
                {/* <!-- Process section --> */}
                <div id="about" className="section is-app-grey is-medium">
                    <div className="container">
                        {/* <!-- Title --> */}
                        <div className="section-title-wrapper has-text-centered">
                            <div className="bg-number">2</div>
                            <h2 className="section-title-landing">
                            OUR PROCESS
                        </h2>
                            <h3 className="section-subtitle-alt">Before creation, there's thinking. Our process is sharp and let us craft
                    the best quality.</h3>
                        </div>

                        {/* <!-- Process steps --> */}
                        <div className="content-wrapper">
                            <div className="columns is-vcentered">
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block has-line">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Over"></i>
                                                <div className="process-number">1</div>
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">1</div>
                                            <div className="details">
                                                <div className="motto">Step 1</div>
                                                <p className="description">Register using your company ID and get access to our CIO's dashboard.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block has-line">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Around"></i>
                                                <div className="process-number">2</div>
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">2</div>
                                            <div className="details">
                                                <div className="motto">Step 2</div>
                                                <p className="description">Pick a service from our vast digital transformation portfolio</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Process step --> */}
                                <div className="column is-4">
                                    <div className="process-block">
                                        <div className="process-icon is-icon-reveal">
                                            <div className="icon-wrapper">
                                                <i className="im im-icon-Arrow-Refresh"></i>
                                                <div className="process-number">3</div>
                                            </div>
                                        </div>
                                        <div className="process-info">
                                            <div className="step-number">3</div>
                                            <div className="details">
                                                <div className="motto">Step 4</div>
                                                <p className="description">Use our secure channel to pay online or via a pay order and a subject matter expert is assigned to your project within 5 minutes</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            

                {/* <!-- Contact Section --> */}

                <div className="container">
                <div className="section-title-wrapper has-text-centered" style={{marginTop:100}}>
                <div className="bg-number">3</div>
                <h2 className="section-title-landing">
                   WHY TO CHOOSE US
                </h2>
                <h4>Great tools that will make your life easier</h4>
            </div>
                   <div className="content-wrapper">
                   <div className="columns is-vcentered pb-40 pt-40">
                    <div className="column is-4 is-offset-1">
                        <div className="icon-subtitle"></div>
                        <div className="title quick-feature has-numbers text-bold">
                            <div>CERTIFIED TEAM
                            </div>
                        </div>
                        <div className="title-divider is-small"></div>
                        <span className="body-text">
                           <div className="Paragraph">
                             <p>
                             Our team comprises of leading cyber security
                         experts from across the globe that come together to secure our customer's 
                         environment against any cyber attack. Our certified team sits in JLT,
                         Dubai and is readily availble to go to the customer premises for any onsite engagement.
                             </p>
                           </div>

                         </span>
                        <div className="pt-10 pb-10">
                        <Link className="button btn btn-primary" to="/CyberSecurity">Buy Now</Link>
                        </div>
                    </div>
                    <div className="column is-6 is-offset-1">
                    <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/GAO.jpg" 
                    alt="" data-demo-src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/GAO.jpg" />
                    </div>
                </div>

                <div className="columns is-vcentered pb-40 pt-40">
                    <div className="column is-4 is-offset-1 is-hidden-tablet is-hidden-desktop">
                        <div className="icon-subtitle"></div>
                        <div className="title quick-feature has-numbers text-bold">
                            <h2>SECURITY OPERATIONS CENTER
                            24/7 SECURITY</h2>
                        </div>
                        <div className="title-divider is-small"></div>
                        <span className="body-text">
                        <div className="Paragraph">
                             <p>
                        ManageX SOC-as-a-Service is designed to enable our analysts to keep an eye out for vulnerabilities in our customer's environment at all time. Our team works with the customers to ensure compliance standards 
                        are met by implementing thresholds and resolving incidents prior to any compliance/ SLA breaches.
                          </p>
                          </div>
                        </span>
                        <div className="pt-10 pb-10">
                        <Link className="button btn-align btn-more is-link color-secandary" href="#OurServices">Buy Now</Link>

                        </div>
                    </div>
                    <div className="column is-6">
                    <img src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/Security.png" 
                    alt="" data-demo-src="https://vvm-prod-cdn.azureedge.net/managex/assets/img/photos/CustomImages/Security.png" />
                    </div>
                    <div className="column is-4 is-offset-1 is-hidden-mobile">
                        {/* <div className="icon-subtitle"><i className="im im-icon-Video-2"></i></div> */}
                        <div className="title quick-feature has-numbers text-bold">
                            <h2>SECURITY OPERATIONS CENTER
                            24/7 SECURITY</h2>
                        </div>
                        <div className="title-divider is-small"></div>
                        <span className="body-text">ManageX SOC-as-a-Service is designed to enable our analysts to keep an eye out for vulnerabilities in our customer's environment at all time. Our team works with the customers to ensure compliance standards
                         are met by implementing thresholds and resolving incidents prior to any compliance/ SLA breaches.</span>
                        <div className="pt-10 pb-10">
                        <Link className="button btn btn-primary" to="/ManageSoc">Buy Now</Link>

                        </div>
                    </div>
                </div>

                  
                
                     </div>
                </div>
                
            </>


        )
    }
}

export default CyberSecurity;