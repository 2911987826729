import React from "react";
import Service from '../../components/Service/Api/Api';
import cookie from "react-cookies";
import Alert from 'react-bootstrap/lib/Alert';
import StatsContainer from './statsContainer';
import AssignedUserContainer from '../DashBoard/assignedUserContainer';
import { connect } from "react-redux";
import { setMainSession } from "../../store/actions/mainSessionActions";
import TextLoader from "../CustomerSupport/TextLoader";
import SupportCall from "../CustomerSupport/SupportCall";
import { setCallSession, setIsCallJoined, setIsCallDeclined, setIsCalling } from '../../store/actions/callSessionActions';
import { activeLinkAction } from "../../store/actions/activeLinkAction";

import Loader from "../../components/Loader/Loader";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import Swal from "sweetalert2";
import { Encrypt, Decrypt } from '../Utility/Encryption';
import GridWrapper from "../GridWrapper/GridWrapper";
import ProgressBar from "../../copmonents/ProgressBar";
import Team from "../../copmonents/Team";
import BreadCrumb from "../BreadCrumb";

class DashBoard extends React.Component {
    //customerOrderId = -1;
    constructor(props) {
        super(props);
        this.state = {
            userId: cookie.load('user_Id'),
            companyId: cookie.load('company_Id'),
            userName: cookie.load('user_Name'),
            admins: [],
            projects: [],


            companyProjects:[],



            parentProjects: [],
            ProjectTask: [],
            ProjectTaskWithProgress: "",
            AssignedUser: [],
            ProjectReport: [],
            AdminAlert: false,
            isDownloadable: false,
            Duedate: "",
            isAccordionOpen: false,
            lastClicked: "",
            tempOTP: "",
            GridData: [],
            isDetailView: false,
            currentlyViewing: {},
            loader:true
        };
        this.ColumnList = [
            {
                text: 'Services',
                key: 'ProjectName',
                width: '150',
                sortable: false,
            },
            {
                text: 'Assigned Resources',
                key: 'Team',
                width: '10',
                sortable: false,
                cell: (data,i) => 
                {
                if(i % 2 === 0){
                    return <Team
                        teamData={[
                            { name: "Irum Saleem", profilePhoto: "" },
                            { name: "Ahsan Khan", profilePhoto: "" },
                            { name: "John Doe", profilePhoto: "" },
                            { name: "James Smith", profilePhoto: "" },
                            { name: "Dexter Mathew", profilePhoto:"" }
    
                        ]}
                    />}
                    if(i === 3){
                        return <Team
                        teamData={[
                            { name: "Irum Saleem", profilePhoto: "" },
                            { name: "John Doe", profilePhoto: "" },
    
                        ]}
                    />
                    }
                    else {
                        return <Team
                        teamData={[
                            { name: "Irum Saleem", profilePhoto: "" },
                            { name: "John Doe", profilePhoto: "" },
                            { name: "Dexter Mathew", profilePhoto:"" }
    
                        ]}
                    />
                    }
                }
            },
            // {
            //     text: 'Reference ID',
            //     key: 'JiraProjectKey',
            //     width: '150',
            //     sortable: false
            // },
            // {
            //     text: 'Description',
            //     key: 'ProjectNotes',
            //     width: '150',
            //     sortable: false
            // },
            {
                text: 'Progress',
                key: 'Progress',
                width: '150',
                sortable: false,
                align:'center',
                cell: data => <ProgressBar progress={data.Progress} displayNumber={true} numberPosition="center" />
            },
            // {
            //     text: 'Start Date',
            //     key: 'ProjectCreatedDate',
            //     width: '150',
            //     sortable: false,
            //     // cell:data=>data.ProjectCreatedDate.split('T').join(' ').split(':').slice(0, -1).join(':')
            //     cell:data=>data.ProjectCreatedDate.split('T')[0]
            // },
            // {
            //     text: 'Estimated End Date',
            //     key: 'key5',
            //     width: '150',
            //     sortable: false
            // },
            {
                text: 'View Details',
                width: '150',
                sortable: false,
                align: 'center',
                cell: (record, i) => <div className="text-center"><button title="View details" className="mx-table-button" onClick={(e) => this.props.history.push(this.props.location.pathname === "/dashboard" ? this.props.location.pathname + "/projectdetails" : "/dashboard/projectdetails")}><i className="zmdi zmdi-eye"></i></button></div>
            },
        ]
        this.CompanyColumnList = [
            {
                text: 'Services',
                key: 'serviceName',
                width: '150',
                sortable: false,
            },
            {
                text: 'Assigned Resources',
                key: 'Team',
                width: '10',
                sortable: false,
                cell: (data,i) =>
                {
                    const { lstAssignedResources } = data;
                    // console.log(lstAssignedResources)
                    const teamdData = lstAssignedResources.map(item=>{
                        const { resourceFirstName, resourceLastName } = item;
                        let fullname = resourceFirstName;
                        if(resourceLastName){
                            fullname = `${fullname} ${resourceLastName}`
                        }
                        return{
                            name:fullname,
                            profilePhoto:item.resourceImage
                        }
                    })
                    return <Team
                        // teamData={[
                        //     { name: "Irum Saleem", profilePhoto: "" },
                        //     { name: "Ahsan Khan", profilePhoto: "" },
                        //     { name: "John Doe", profilePhoto: "" },
                        //     { name: "James Smith", profilePhoto: "" },
                        //     { name: "Dexter Mathew", profilePhoto:"" }
    
                        // ]}
                        teamData={teamdData}
                />}
            },
            // {
            //     text: 'Reference ID',
            //     key: 'JiraProjectKey',
            //     width: '150',
            //     sortable: false
            // },
            // {
            //     text: 'Description',
            //     key: 'ProjectNotes',
            //     width: '150',
            //     sortable: false
            // },
            {
                text: 'Progress',
                key: 'progress',
                width: '150',
                sortable: false,
                align:'center',
                cell: data => <ProgressBar progress={data.progress} displayNumber={true} numberPosition="center" />
            },
            // {
            //     text: 'Start Date',
            //     key: 'ProjectCreatedDate',
            //     width: '150',
            //     sortable: false,
            //     // cell:data=>data.ProjectCreatedDate.split('T').join(' ').split(':').slice(0, -1).join(':')
            //     cell:data=>data.ProjectCreatedDate.split('T')[0]
            // },
            // {
            //     text: 'Estimated End Date',
            //     key: 'key5',
            //     width: '150',
            //     sortable: false
            // },
            {
                text: 'View Details',
                width: '150',
                sortable: false,
                align: 'center',
                cell: (record, i) => <div className="text-center"><button title="View details" className="mx-table-button" onClick={(e) => this.props.history.push(this.props.location.pathname === "/dashboard" ? this.props.location.pathname + "/projectdetails/" + record.id : "/dashboard/projectdetails/" + record.id)}><i className="zmdi zmdi-eye"></i></button></div>
            },
        ]
        this.temp = "";
        this.getProjects = this.getProjects.bind(this);
        this.interval = null;
        this.intervalForOTP = null;
        // this.startCall = this.startCall.bind(this);
        // this.getQuotation = this.getQuotation.bind(this);
    }
    componentDidMount() {
        // fixing route isssue when user uploads PO then it still says services. Instead it should say dashboard
        if(this.props.activeLinkReducer.activeLink !== "dashboard"){
            this.props.dispatch(activeLinkAction("dashboard"));
        }

        this.getProjects(this.state.userId);
        this.getProjectsByCompanyId(this.state.companyId);
        // $('.collapse').on('show.bs.collapse', function (event) {
        //   })
        this.FetchUserList();

        // Getting the list of support persons/Admins from API 
        Service.getSupportAdmins().then(res => {
            this.setState({
                admins: res
            });
        });


        // we need to check local storage / cookies for main session data (sessionId, token, apiKey)
        // if these are available then we will initialize the session



// ================================================================================================================================COMMENTED
        
        // const apiKey = cookie.load("apiKey");
        // const sessionId = cookie.load("sessionId");
        // const token = cookie.load("token");
        // if (apiKey && sessionId && token) {
        //     this.props.dispatch(setMainSession({ apiKey: apiKey, token: token, sessionId: sessionId }));
        //     console.log("Main session initialized ====> ", { apiKey, sessionId, token } );
        // }
        // // New change start
        // else {
        //     const userId = cookie.load("user_Id");
        //     Service.GetSession(userId).then((responce) => {
        //         if (responce) {
        //             cookie.save("apiKey", responce.apiKey, {
        //                 path: "/",
        //                 maxAge: 5 * 3600,
        //             });
        //             cookie.save("sessionId", responce.sessionId, {
        //                 path: "/",
        //                 maxAge: 5 * 3600,
        //             });
        //             cookie.save("token", responce.token, {
        //                 path: "/",
        //                 maxAge: 5 * 3600,
        //             });
        //             this.props.dispatch(setMainSession({ apiKey: apiKey, token: token, sessionId: sessionId }));
        //         }
        //     });
        // }

// ============================================================================================================================================COMMENTED



        // checking if the call session is active
        // const callApiKey = cookie.load("callApiKey");
        // const callSessionId = cookie.load("callSessionId");
        // const callToken = cookie.load("callToken");
        // if (callApiKey && callSessionId && callToken) {
        //     this.props.dispatch(setCallSession({
        //         apiKey: `${callApiKey}`,
        //         token: `${callToken}`,
        //         sessionId: `${callSessionId}`
        //     }));
        //     this.props.dispatch(setIsCallJoined(true));
        // }


        const callApiKey = sessionStorage.getItem("callApiKey");
        const callSessionId = sessionStorage.getItem("callSessionId");
        const callToken = sessionStorage.getItem("callToken");
        if (callApiKey && callSessionId && callToken) {
            this.props.dispatch(setCallSession({
                apiKey: `${callApiKey}`,
                token: `${callToken}`,
                sessionId: `${callSessionId}`
            }));
            this.props.dispatch(setIsCallJoined(true));
        }
        // New change end
    }

    viewDetails = (record, i) => {
        this.setState({
            isDetailView: true,
            currentlyViewing: record
        })
        console.log(record, i);
    }
    generateOTP = () => {
        // Declare a digits variable 
        // which stores all digits
        var digits = '0123456789';
        let OTP = '';
        for (let i = 0; i < 6; i++) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        return OTP;
    }
    endCall = () => {
        // this.props.dispatch(setCallSession({apiKey:'apiKey', sessionId:'sessionId',token:'token'}));
        this.props.dispatch(setIsCallJoined(false));
        this.props.dispatch(setIsCallDeclined(false));
        this.props.dispatch(setIsCalling(false));
        // console.log("ENDING SESSION")
        // cookie.remove("callApiKey");
        // cookie.remove("callSessionId");
        // cookie.remove("callToken");
        sessionStorage.removeItem("callApiKey");
        sessionStorage.removeItem("callSessionId");
        sessionStorage.removeItem("callToken");
    }
    getProjects(Id) {
        Service.GetProjectByUserId(Id).then(res => {
            // console.log(res);
            const tempRes = [];
            res.forEach(data => {
                const mappedData = { ...data, Progress: Math.round(Math.random() * 100) };
                tempRes.push(mappedData);
            });
            this.setState({ projects: tempRes })
            // this.setState({ projects: res })
            const parentProjects = [];
            res.map((project, i) => {
                return (
                    isNaN(Number(project.JiraProjectKey[project.JiraProjectKey.length - 1])) ? parentProjects.push(project) : ""
                )
            });
            this.setState({
                parentProjects: parentProjects
            })
        })
    }
    getProjectsByCompanyId(companyId){
        const data = {companyid: Number(companyId)}
        Service.GetProjectByCompanyId(data).then(res=>{
            this.setState({
                companyProjects:res.result,
                loader:false
            });
        }).catch(err=>{
            this.setState({
                loader:false
            });
        });
    }
    getProjectTask(ProjectId) {
        // Adding new logic here because this function is already attached to the accordion UI

        // Service.getPDFDetail({
        //     isQuoteRefNum:false,
        //     quoteRefNum:"",
        //     JiraProjectKey:ProjectId
        // }).then(res=>{
        //     // console.log("Returned value from the API is ",res)
        // });




        clearInterval(this.interval);
        if (this.state.lastClicked === ProjectId) {
            this.setState({ lastClicked: "" });
            clearInterval(this.interval);
        }
        else {
            this.setState({ lastClicked: ProjectId });
            this.interval = setInterval(() => {
                this.props.mainSessionReducer.mainSessionSignalObj({
                    data: JSON.stringify({ sendername: cookie.load("user_Name") }),
                    type: "Acknowledgement"
                });
            }, 5000);
        }
        // sending signals to admin for availability

        this.setState({ ProjectTaskWithProgress: 0 })
        this.temp = ProjectId
        Service.GetProjectTask(ProjectId).then(res => {
            // console.log(res);
            if (res.projectDetails.issues) {
                this.setState({ ProjectTask: res.projectDetails.issues })
                this.setState({ ProjectTaskWithProgress: res.project.projectProgress })
                //alert(res.projectEndDate);

                //this.setState({Duedate:res.projectEndDate.split(" ")[0]})

                document.getElementById(ProjectId).innerHTML = this.state.AssignedUser.length > 0 ? res.projectEndDate.split(" ")[0] : "Project in Planning mode";
            }
        })
        Service.GetAssignResource(ProjectId).then(res => {
            // console.log(res);
            this.setState({ AssignedUser: res })
        })
        this.FetchProjectReports(ProjectId);
    }
    FetchProjectReports = (param) => {
        try {
            Service.GetProjectReports(param).then(res => {
                // console.log(res);
                this.setState({ ProjectReport: res })
            })
        }
        catch (er) {
            console.log(er);
        }
    };

    OnReportDownload = (Param) => {

        var payload = {
            customerid: cookie.load("user_Id"),
            projectid: Param
        };
        try {
            Service.DownloadUserReport(payload);
        }
        catch (er) {
            console.log(er);
        }
    };

    startCall = (CallingTo, ProjectId, ServiceName) => {
        const { mainSessionSignalObj } = this.props.mainSessionReducer;
        const signalData = {
            type: "supportCall",
            data: JSON.stringify({
                senderEmail: cookie.load("user_Id"),
                userName: cookie.load("user_Name"),
                customerId: this.state.userId,
                supportPersonId: CallingTo,
                projectId: ProjectId,
                serviceName: ServiceName,
                receivers: this.state.admins
            })
        }
        mainSessionSignalObj(signalData) //Sending first signal to the admins and expecting an acknowledge signal
    }
    DownloadReport = (Url) => {
        const OTP = this.generateOTP();
        this.timeoutForOTP = setTimeout(() => {
            this.setState({ tempOTP: "" });
        }, 2 * 1000 * 60);
        const EmailData = new FormData();
        EmailData.append('ToEmail', cookie.load("user_Id"));
        EmailData.append('Subject', "OTP for downloading file");
        EmailData.append('Body', `Hello ${cookie.load("user_Id")}, your OTP for downloading file is <b>${OTP}</b><p>Note: The OTP will only be valid for next 2 minutes.</p>`);
        Service.SendEmail(EmailData);

        const _encryptedOTP = Encrypt(JSON.stringify(OTP)).toString();
        this.setState({ tempOTP: _encryptedOTP });
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: 'Enter OTP to download',
            icon: "info",
            text: `An OTP is sent to ${cookie.load("user_Id")}. Please enter it below.`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Download',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                return login
            },
            timer: 2 * 1000 * 60
        }).then((result) => {
            if (result.isConfirmed) {
                if (this.state.tempOTP !== "") {
                    const _decryptedOTP = JSON.parse(Decrypt(this.state.tempOTP));
                    if (result.value === _decryptedOTP) {
                        clearTimeout(this.timeoutForOTP);
                        this.setState({
                            tempOTP: ""
                        });
                        window.open(Url, '_blank').focus();
                    }
                    else {
                        Swal.fire(
                            'Invalid OTP?',
                            'Please enter valid OTP',
                            'error'
                        )
                    }
                }
                else {
                    Swal.fire(
                        'Invalid OTP?',
                        'OTP might be expired',
                        'error'
                    )
                }
            }
        })
    }
    UpdateDueDate(date) {
        // if(this.state.Duedate!="")
        // {
        //     var d1 = new Date(date);
        //     var d2 = new Date(this.state.Duedate);
        //     alert(d1)
        //     alert(d2)
        //     // if(d1>d2){
        //     //     alert("hello");
        //     //     this.setState({duedate:date})
        //     // }
        // }
        // else{
        //     this.setState({duedate:date})
        // }

    }
    FetchUserList = () => {
        try {
            var UserRole = cookie.load("User_Role");
            if (UserRole == "Company Admin") {
                var RequestPayload = {
                    userid: '',
                    requestedrole: '',
                    companyname: cookie.load('Orgnization')
                };

                Service.FetchRoleRequest(RequestPayload).then(res => {
                    if (res.status === "Success") {
                        if (res.userList != undefined && res.userList.length > 0) {
                            this.setState({ AdminAlert: true });
                        }
                    }
                }).catch(err => {

                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    SendPasswordToUser = (pwd) => {
        if (pwd) {
            const EmailData = new FormData();
            EmailData.append('ToEmail', cookie.load("user_Id"));
            EmailData.append('Subject', "Password for your report");
            EmailData.append('Body', `
            <p>Greetings ${cookie.load("user_Name")},<br/>Please find report password below.</p>
            <p>Password: <b>${pwd}</b></p>
            `);
            Service.SendEmail(EmailData);
            Swal.fire(
                'Password sent',
                "This report's password has been sent to your email.",
                'success'
            )
        }
        else if (pwd === null || pwd === undefined) {
            Swal.fire(
                'No password set',
                'There is no password set for this file.',
                'info'
            )
        }
    }
    render() {
        const { callSessionObj, isCalling, isCallJoined, isCallDeclined, isCallPicked } = this.props.callSessionReducer;
        // console.log("call session data in Dashboard", callSessionObj, isCalling, isCallJoined, isCallDeclined)
        if (isCallDeclined) {
            Swal.fire(
                'Call not picked',
                'The resources are busy at the moment. Please try again within a few minutes.',
                'error'
            );
            this.props.dispatch(setIsCallDeclined(false))
        }
        let data = <>
            <div className="hero px-3" style={{ minHeight: '10% !important' }} >
                <BreadCrumb />
                {
                    this.state.AdminAlert ?
                        <div className="column is-12">
                            <Alert variant='warning'>
                                You have new request role assignment.
                            </Alert> </div> :
                        ""
                }

                {<StatsContainer
                    userid={cookie.load("company_Id")}//{this.state.userId}
                    projectCount={this.state.projects.length}
                />}
                <div className="container-fluid ">
                    <div className="row mb-4 m-0 text-center justify-content-center">

                        {/* Previous Code commented */}

                        {/* <div className="col-12 card text-left mx-scrollable-table-wrap">
                            <GridWrapper
                                config={{
                                    page_size: this.state.projects.length - 1,
                                    show_filter: true,
                                    show_pagination: false,
                                    show_length_menu: false
                                }}
                                Records={this.state.projects}
                                ColumnList={this.ColumnList}
                                className={"table"}
                                tHeadClassName={"mx-table-head"}
                            />
                        </div> */}





                        <div className="col-12 card text-left mx-scrollable-table-wrap">
                            {
                                this.state.companyProjects.length ?
                                <GridWrapper
                                    // config={{
                                    //     page_size: this.state.companyProjects.length - 1,
                                    //     show_filter: true,
                                    //     show_pagination: false,
                                    // }}
                                    config={{
                                        show_length_menu: false,
                                        show_filter: true,
                                        show_pagination: true,
                                    }}
                                    Records={this.state.companyProjects}
                                    ColumnList={this.CompanyColumnList}
                                    className={"table"}
                                    tHeadClassName={"mx-table-head"}
                                />
                                :<div className="py-2 px-1"><p>No projects added yet</p></div>
                            }
                        </div>
                        {/* <div className="col-12">
                                    <div className='card'>
                                        <div className='card-header pr-5 pl-5'> <h2>Projects</h2></div>
                                        <div className='card-body'>
                                            <div className="row">
                                                <div className="col-12">
                                                    {this.state.projects == 0 ?
                                                        <h4>No projects</h4> :
                                                        <Accordion allowZeroExpanded={true} >

                                                            {
                                                                this.state.projects.map((Project, index) =>
                                                                (
                                                                    <AccordionItem>
                                                                        <AccordionItemHeading>
                                                                            <AccordionItemButton>
                                                                                <div onClick={() => this.getProjectTask(Project.JiraProjectKey)} className="row" style={{ fontSize: '16px', textAlign: 'left', paddingBottom: '15px', borderBottom:"1px solid #a5a5a5" }}>
                                                                                    <div className="col-lg-8 row mb-3">
                                                                                        <div className="col-6" style={{ fontWeight: "600" }}>
                                                                                            <p style={{ fontSize: '12px', textDecoration: 'none' }}><b>Service Name</b></p>{Project.ProjectName}
                                                                                        </div>
                                                                                        <div className="col-lg-2 col-3">
                                                                                            <p style={{ fontSize: '12px', textDecoration: 'none' }}><b>Project Reference ID</b></p>{Project.JiraProjectKey}
                                                                                        </div>
                                                                                        <div className="col-lg-4 col-3">
                                                                                            <p style={{ fontSize: '12px', textDecoration: 'none' }}><b>Project Description</b></p>{Project.ProjectNotes ? Project.ProjectNotes : ""}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-2 col-6">
                                                                                        <p style={{ fontSize: '12px', textDecoration: 'none' }}><b>Project Start Date</b></p> {Project.CreatedOn.split('T')[0]}
                                                                                    </div>
                                                                                    <div className="col-lg-2 col-6 p-sm-0">
                                                                                        <p style={{ fontSize: '12px', textDecoration: 'none' }}><b>Project Estimate End Date</b></p> <span id={Project.JiraProjectKey}>click to load</span>
                                                                                    </div>
                                                                                </div>
                                                                            </AccordionItemButton>
                                                                        </AccordionItemHeading>
                                                                        <AccordionItemPanel>
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    {this.temp == Project.JiraProjectKey ?
                                                                                        <>
                                                                                            <Accordion allowZeroExpanded={true}>
                                                                                                {this.state.ProjectTask.map((Task, i) =>
                                                                                                (<>
                                                                                                    <AccordionItem>
                                                                                                        <AccordionItemHeading>
                                                                                                            <AccordionItemButton>
                                                                                                                <div className="row" style={{ fontSize: '14px', textAlign: 'left', paddingBottom: '15px' }}>
                                                                                                                    <div className="col-md-6 text-wrap">
                                                                                                                        {Task.fields.summary}
                                                                                                                    </div>
                                                                                                                    <div className="col-md-6 text-wrap text-right pr-5">
                                                                                                                        <span className={Task.fields.status.name == "Done" ? "badge badge-pill badge-success" : "badge badge-pill badge-warning"}>
                                                                                                                            <span style={{ fontSize: '16px', whiteSpace: "break-spaces" }}>
                                                                                                                                {Task.fields.status.name}
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </AccordionItemButton>
                                                                                                        </AccordionItemHeading>
                                                                                                        <AccordionItemPanel>
                                                                                                            {
                                                                                                                Task.fields.subtasks.length != 0 ?
                                                                                                                    <>
                                                                                                                        <table className="table">
                                                                                                                            {Task.fields.subtasks.map((SubTask, inde) => (
                                                                                                                                <tr className='row'>
                                                                                                                                    <td className='col-8'>{SubTask.fields.summary}</td>
                                                                                                                                    <td className='col-2'><span className={SubTask.fields.status.name == "Done" ? "badge badge-pill badge-success" : "badge badge-pill badge-warning"} style={{ fontSize: '12px' }}>{SubTask.fields.status.name}</span></td>
                                                                                                                                </tr>
                                                                                                                            ))}
                                                                                                                        </table>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <div> <span className="badge badge-primary col-12" style={{ fontSize: '14px', background: "#010138" }}>SubTask will be appear here</span></div>
                                                                                                            }
                                                                                                        </AccordionItemPanel>
                                                                                                    </AccordionItem>


                                                                                                </>
                                                                                                )
                                                                                                )}
                                                                                                <AccordionItem>
                                                                                                    <AccordionItemHeading>
                                                                                                        <AccordionItemButton>
                                                                                                            <div className="row" style={{ fontSize: '14px', textAlign: 'left', paddingBottom: '15px' }}>
                                                                                                                <div className="col-8">Reports</div>
                                                                                                                {this.state.ProjectReport.length != 0 ?
                                                                                                                    <div className="col-2 btn btn-link"><span className="badge badge-pill"><span style={{ fontSize: '16px' }}>View Report</span></span></div> : <></>}
                                                                                                            </div>
                                                                                                        </AccordionItemButton>
                                                                                                    </AccordionItemHeading>
                                                                                                    <AccordionItemPanel>
                                                                                                        {this.state.ProjectReport.length != 0 ?
                                                                                                            <>
                                                                                                                {this.state.ProjectReport.map((Report, index) =>
                                                                                                                (
                                                                                                                    <div key={index} className="card-header">
                                                                                                                        {
                                                                                                                            Report.FileName !== null ? 
                                                                                                                            <div className='col-6'>{Report.FileName}</div>
                                                                                                                            :
                                                                                                                            <div className='col-6'>{Report.FileUrl.split('/')[(Report.FileUrl.split('/').length) - 1].split('%20').join(' ')}</div>
                                                                                                                        }
                                                                                                                        <div className='col-6 d-flex justify-content-end align-items-start p-0'>
                                                                                                                            <button title="Download" className="btn btn-success rounded mx-1" onClick={() => this.DownloadReport(Report.FileUrl)}><i className="fa fa-download"></i></button>
                                                                                                                            <button title="Protected file password" className="btn btn-primary rounded mx-1" onClick={() => this.SendPasswordToUser(Report.FilePassword)}>File password</button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </>
                                                                                                            :
                                                                                                            <div className="card-header">No Reports Uploaded</div>}
                                                                                                    </AccordionItemPanel>
                                                                                                </AccordionItem>
                                                                                            </Accordion>
                                                                                        </> : ""}

                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="card">
                                                                                        <div className="card-header" style={{ fontSize: '18px' }}><b className="col-9">Project Progress : </b> <b className="col-3" >{this.state.ProjectTaskWithProgress} % </b></div>
                                                                                    </div>
                                                                                    <AssignedUserContainer
                                                                                        AssignedUser={this.state.AssignedUser}
                                                                                        Project={Project}
                                                                                        startCall={this.startCall}
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        </AccordionItemPanel>
                                                                    </AccordionItem>
                                                                )
                                                                )}
                                                        </Accordion>
                                                    }

                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div> */}
                    </div>

                </div>

            </div>

        </>
        let data2 =
            <div className="">
                <div className="columns pt-5 pl-3 pr-3 is-flex-wrap-wrap">
                    <div className="column is-12 pl-5 pr-5">
                        <div className="card-counter">
                            <div className="flex-card light-bordered hover-inset">
                                <div className="title section-title has-text-centered dark-text text-bold">My Services</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.AdminAlert ?
                            <div className="column is-12">
                                <Alert variant='warning'>
                                    You have new request role assignment.
                                </Alert> </div> :
                            ""
                    }
                    {<StatsContainer
                        userid={this.state.userId}
                        projectCount={this.state.projects.length}
                    />}
                </div>
                <div className='card'>
                    <div className='card-header pr-5 pl-5'> <h2>Projects</h2></div>
                    <div className='card-body pr-5 pl-5'>
                        {
                            this.state.projects.length === 0 ? <h4>No Projects</h4> :
                                <Accordion allowZeroExpanded={true}>
                                    {
                                        this.state.parentProjects.map((project, i) =>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row">
                                                            <div className="col-3">{project.JiraProjectKey}</div>
                                                            <div className="col-3">TEST</div>
                                                            <div className="col-3">TEST</div>
                                                            <div className="col-3">TEST</div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <h1>Test</h1>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        )
                                    }
                                </Accordion>
                        }
                    </div>
                </div>
            </div>
        return (
            <div>
                {
                    this.state.loader?
                    <Loader/>
                    :""
                }
                {
                    isCalling ?
                        <div style={{ minHeight: "calc(100vh - 70px)" }} className="is-flex is-justify-content-center is-align-items-center">
                            <TextLoader loaderTitle='Connecting you to the ManageX team' />
                        </div> :
                        isCallPicked ?
                            <div style={{ minHeight: "calc(100vh - 70px)" }} className="is-flex is-justify-content-center is-align-items-center">
                                <TextLoader loaderTitle='Call is picked. Please wait a few seconds.' />
                            </div> :
                            isCallJoined ?
                                <SupportCall endCall={this.endCall} apiKey={callSessionObj.apiKey} sessionId={callSessionObj.sessionId} token={callSessionObj.token} /> :
                                isCallDeclined ?
                                    data : data
                    // <>{data2}{data}</> : <>{data2}{data}</>
                }
            </div>);
    }
}
const mapStateToProps = (state) => {
    return state;
}
export default connect(mapStateToProps)(DashBoard);