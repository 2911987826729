import React from "react";
import Service from '../../../components/Service/Api/Api'
import cookie from "react-cookies";
import queryString from 'query-string'
import Swal from "sweetalert2";
import { authCodeTemplate } from "../../EmailTemplates/authcodeTemplate";
import Logo from "../../../img/logo.png";
import InputComponent from "../../inputComponents/Input";
import Loader from "../../Loader/Loader";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

export default class Otp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            TwoFactorCode: '',
            userid: cookie.load("Id"),
            useremail: cookie.load("User_Email"),
            userTfCode: cookie.load("TFCode"),
            userName: cookie.load("user_Name"),
            isLoader:false
        }
    }

    componentDidMount() {
        try {
            var EamilData = new FormData();
            EamilData.append('ToEmail', this.state.useremail);
            EamilData.append('Subject', 'Your 2-Factor Code is here!');
            var _bodyTemplate = authCodeTemplate;
            var Replaceversion = _bodyTemplate.replace("{UserName}", this.state.userName).replace("{Code}", this.state.userTfCode);
            //var _emailBody = '<p>Your verification code is :' + this.state.userTfCode  + '</p>';
            EamilData.append('Body', Replaceversion); //_emailBody);
            Service.SendEmail(EamilData);
        }
        catch (e) {
            console.log(e);
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onSubmit = e => {
        e.preventDefault();
        this.setState({isLoader:true});
        try {
            var payload = {
                TwoFactorCode: this.state.TwoFactorCode,
                email: cookie.load("User_Email")
            }
            Service.VerifyOTP(payload).then(response => {
                if (response.status === "Success") {
                    // set cookie to true
                    cookie.remove("isVerified");
                    cookie.save("isVerified", true);
                    this.props.history.push("/dashboard");
                    this.setState({isLoader:false});
                }
                else if(response.status === "Failed"){
                    this.setState({isLoader:false});
                    Swal.fire({
                        icon: 'error',
                        title: 'Invalid OTP',
                        text: 'The OTP is invalid or it may have been expired.',
                        showConfirmButton: false,
                        timer: 3000
                    });
                }
            }).catch(err => {
                alert(err);
                this.setState({isLoader:false});
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
        catch (e) {
            console.log(e);
        }
    };

    render() {

        return (
            <>
            {
                this.state.isLoader ?
                <Loader/>
                :null
            }
                {/* <div className="hero-body ">
                    <div className="container has-text-centered">
                        <div className="columns is-vcentered">
                            <div className="column is-8 is-offset-2 has-text-centered">
                                <h3 className="subtitle is-5">
                                    <b>Authentication Code Required</b>
                                </h3>
                                <h2 className="subtitle is-5">
                                    To continue, please enter the verification code we've just sent you.
                                </h2>
                                <br />
                                <div className="field pb-10">
                                    <div className="control has-icons-right">
                                        <input
                                            type="text"
                                            className="input is-medium has-shadow"
                                            placeholder="Enter OTP code"
                                            name="TwoFactorCode"
                                            required
                                            onChange={this.onChange}
                                            value={this.state.TwoFactorCode}
                                        />
                                        <span className="icon is-medium is-right">
                                            <i className="sl sl-icon-lock-open"></i>
                                        </span>
                                    </div>
                                </div>
                                <p className="control login">
                                    <button className="button button-cta primary-btn btn-align-lg  is-bold is-fullwidth rounded raised no-lh"
                                        onClick={this.onSubmit}>
                                        Verify </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="authentication">
                    <div className="container">
                        <div className="col-md-12 content-center">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div class="company_detail">
                                        <h4 class="logo"><img src={Logo} alt="" /></h4>
                                        <h3>Verify your email</h3>
                                        <p>Please check your email address for an OTP.</p>
                                        <div class="footer">
                                            <ul class="social_link list-unstyled">
                                                <li><a href="https://twitter.com/managexae" title="LinkedIn"><i class="zmdi zmdi-linkedin"></i></a></li>
                                                <li><a href="https://www.linkedin.com/company/managexae/" title="Facebook"><i class="zmdi zmdi-facebook"></i></a></li>
                                                <li><a href="https://www.facebook.com/managex.ae" title="Twitter"><i class="zmdi zmdi-twitter"></i></a></li>
                                            </ul>
                                            <hr />
                                            <ul>
                                                <li><a href="https://www.managex.ae/contact-us/" target="_blank">Contact Us</a></li>
                                                <li><a href="https://www.managex.ae/about-us/" target="_blank">About Us</a></li>
                                                <li><a href="https://www.managex.ae/privacy-policy/" target="_blank">Privacy Policy</a></li>
                                                <li><a href="https://www.managex.ae/terms-of-services/">Terms Of Services</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 offset-lg-1">
                                    <div class="card-plain text-center">
                                        <div class="header">
                                            <h5>OTP</h5>
                                            <p className="my-3">Please enter the code that was sent to your email.</p>
                                        </div>
                                        <form class="form" onSubmit={this.onSubmit}>
                                            <div class="input-group">
                                                {/* <input type="text" class="form-control" placeholder="User Name" /> */}
                                                <InputComponent
                                                    inputType="inputField"
                                                    type="text"
                                                    name="TwoFactorCode"
                                                    label="Enter OTP code"
                                                    error={this.state.emailError}
                                                    floatingLabel
                                                    variant="primary"
                                                    onChange={this.onChange}
                                                    classes="form-control"
                                                />
                                                <span class="input-group-addon"><i class="zmdi zmdi-lock"></i></span>
                                            </div>
                                            <div class="input-group">
                                                <InputComponent
                                                    inputType="button"
                                                    fullWidth
                                                    type="submit"
                                                    name="Confirm"
                                                    variant="secondary"
                                                    onSubmit={this.onSubmit}
                                                    classes="mt-4 mb-4"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* previous code commented */}
                            {/* <div className="row clearfix m-0">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="card p-4">
                                        <div className="header">
                                            <h2><strong>Verify your email!</strong></h2>
                                            <p>Please enter the code that was sent to your email.</p>
                                        </div>
                                        <div className="mt-4">
                                            <form>
                                                <label for="email_address">Verification code</label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter OTP code"
                                                        name="TwoFactorCode"
                                                        required
                                                        onChange={this.onChange}
                                                        value={this.state.TwoFactorCode}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <button type="button" onClick={this.onSubmit} className="btn btn-raised btn-primary btn-round waves-effect">Confirm</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

