import React from "react";
import './ProgressBar.css';

function ProgressBar(props){
    const progress = props.progress;
    let color = "rgb(223 12 12)";
    if(progress > 33 && progress <= 66){
        color="rgb(255 191 19)";
    }
    else if(progress > 66){
        color="rgb(128 196 77)";
    }
    return(
    <div className={`mx-progress-main ${props.numberPosition ? props.numberPosition : ""}`}>
        {
            props.displayNumber ?
            <span className="mx-progress-number">{progress}%</span>
            :""
        }
        <div className="mx-progress-wrap">
            <div className="mx-progress-bar" style={{width:`${progress}%`, backgroundColor:color}}>
            </div>
        </div>
    </div>
    )
}
export default ProgressBar;