import React from "react";
import { History } from "react-router-dom";
import cookie from "react-cookies";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {withRouter} from 'react-router-dom';

class Header extends React.Component {
  constructor(){
    super();
    this.state={
      activeLink:"dashboard",
      isNavActive:false,
      isHeaderSticky:false
    }
  }
  componentDidMount(){
    if(this.props.location.pathname.toLowerCase() === "/servicesiew" || this.props.location.pathname.toLowerCase() === "/steps"){
      this.setState({
        activeLink:"services"
      })
    }
    else if(this.props.location.pathname.toLowerCase() === "/dashboard" || this.props.location.pathname.toLowerCase() === "/"){
      this.setState({
        activeLink:"dashboard"
      })
    }
    else if(this.props.location.pathname.toLowerCase() === "/billing"){
      this.setState({
        activeLink:"billandpayments"
      })
    }
    this.stickyHeader();
  }
  stickyHeader =()=> {
    const makeHeaderSticky=()=>{
      const header = document.getElementById("mx-header");
      const sticky = header.scrollHeight;
      if(sticky !== undefined && sticky !== null){
        if (window.pageYOffset > sticky*2) {
          this.setState({isHeaderSticky:true});
        } else {
          this.setState({isHeaderSticky:false});
        }
      }
    }
    window.addEventListener("scroll",makeHeaderSticky);
  }
  changeHandler = (e) => {
    this.setState({
      activeLink:e.currentTarget.dataset.name,
      isNavActive:false
    })
  }
  toggleNav = e =>{
    e.preventDefault();
    this.setState(prevState=>{
      return{
        isNavActive:!prevState.isNavActive
      }
    })
  }
  render() {
    var token = cookie.load("bareer_Token");
    var UserRole = cookie.load("User_Role");
    return (
      <>
        <nav id="mx-header" className={`navbar navbar-wrapper p-0 pl-lg-3 pr-lg-3 is-solid is-static ${this.state.isHeaderSticky ? "sticky" : ""}`}>
          <div className="container-fluid align-items-start p-0 pl-lg-3 pr-lg-3">
            <div className="navbar w-100">
              <div className="navbar-brand">
                <a className="" href="https://managex.ae">
                  <img
                    src="https://mxprodstorage.blob.core.windows.net/managex/assets/EmailTemplate/Logo.png"
                    alt=""
                    style={{ marginTop: "10px" }}
                  />
                </a>
              </div>
              <div className={`navbar-menu ${this.state.isNavActive ? "is-active" : ""}`}>
                <div className="navbar-start">
                  {token ? (
                    <>
                      <Link
                        data-name={"services"}
                        style={{ textDecoration: "none" }}
                        className="navbar-item is-slide"
                        to="/ServiceView"
                        onClick={this.changeHandler}
                      >
                        {" "}
                        <h1  className={`title is-6 is-mega-menu-title ${this.state.activeLink === "services" ? "active" : ""}`}>Services</h1>
                      </Link>
                      <Link
                        data-name={"dashboard"}
                        style={{ textDecoration: "none" }}
                        className="navbar-item is-slide"
                        to="/dashboard"
                        onClick={this.changeHandler}
                      >
                        {" "}
                        <h1 className={`title is-6 is-mega-menu-title ${this.state.activeLink === "dashboard" ? "active" : ""}`}>Dashboard</h1>
                      </Link>
                      <Link
                        data-name={"billandpayments"}
                        style={{ textDecoration: "none" }}
                        className="navbar-item is-slide"
                        to="/billing"
                        onClick={this.changeHandler}
                      >
                        {" "}
                        <h1 className={`title is-6 is-mega-menu-title ${this.state.activeLink === "billandpayments" ? "active" : ""}`}>
                          Bill & payments
                        </h1>
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="navbar-end">
                  {token ? (
                    <div className="navbar-item">
                      <a
                        style={{ textDecoration: "none" }}
                        className="navbar-item is-slide dropdown-toggle"
                        href="#"
                        id="ProfileDD"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        <big className="title is-6 is-mega-menu-title color-primary">
                          {cookie.load("user_Name")}
                        </big>
                      </a>
                      {/* <!-- your mega menu starts here! --> */}
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="ProfileDD"
                        style={{
                          width: "61%",
                          // height: "74px",
                          borderRadius: "18px",
                        }}
                      >
                        {/* <!-- finally, using flex to create your layout --> */}
                        <div className="d-md-flex" style={{ fontSize: 'larger' }}>
                          <div className="col-12">
                            <Link
                              className="navbar-item is-flex hovergreencolor"
                              to="/EditProfile"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <div className="navbar-content">
                                <i className="fa fa-user"></i>&nbsp;&nbsp;
                                <small>Edit Profile</small>
                              </div>
                            </Link>
                            <Link
                              className="navbar-item is-flex hovergreencolor"
                              to="/logout"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <div className="navbar-content">
                                <i className="fa fa-lock"></i>&nbsp;&nbsp;
                                <small>Logout</small>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {!window.location.href.includes("Login") &&
                        !window.location.href.includes("login") &&
                        !window.location.href.includes("steps") &&
                        !window.location.href.includes("SignUp") ? (
                        <div className="navbar-item">
                          <Link
                            className="button button-cta  is-bold btn-align secondary-btn rounded raised"
                            style={{ marginTop: "-10px" }}
                            to="/login"
                          >
                            Log in
                          </Link>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
              {
                token ?
                <a role="button hamburger-icon" class={`navbar-burger ${this.state.isNavActive ? "is-active" : "" }`} aria-label="menu" aria-expanded="false" onClick={this.toggleNav}>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
                :""
              }
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default withRouter(Header);
