import React, { useState } from "react";
import './Input.css';

const InputComponent = (props) =>{
    const {
            name,
            value,
            type,
            variant,
            inputType,
            error,
            info,
            options,
            floatingLabel,
            classes,
            label,
            columns,
            rows,
            fullWidth,
            large,
            centered,
            left,
            right,
            // event functions START
            onChange,
            onClick,
            onSubmit
        } = props;
    const [showPassword, setShowPassword] = useState(true);
    return(
        inputType === "inputField" ? 
        <div class={`mx-input-wrap ${floatingLabel ? "animated-label" : "" } ${variant ? variant :""} ${classes ? classes : ""} `}>
            {
                floatingLabel ? "" : <label className="label" for="">{label}</label>
            }
            <input onChange={onChange} name={name} type={type === "password" && showPassword ? "password" : type === "password" && !showPassword ? "text": type} placeholder={label}/>
            {
                error ?
                <p className="mx-input-error">{error}</p>
                :""
            }
            {
                info ?
                <p className="mx-input-info">{info}</p>
                :""
            }
            {
                floatingLabel ? <label className="label" for="">{label}</label> : ""
            }
            {
                type === "password" ?
                    <i className={`zmdi ${showPassword ? "zmdi-eye" : "zmdi-eye-off"} input-right-icon`} onClick={()=>setShowPassword(!showPassword)}></i>
                : ""
            }
        </div>
        : inputType === "select" ?
        <div class={`mx-input-wrap ${floatingLabel ? "animated-label" : "" } ${variant ? variant :""} ${classes} `}>
            {
                floatingLabel ? "" : <label className="label" for="">{label}</label>
            }
            <select onChange={onChange} name={name}>
                {
                    options.map(option=>{
                        return <option value={option}>{option}</option>
                    })
                }
            </select>
            {
                error ?
                <p className="mx-input-error">{error}</p>
                :""
            }
            {
                info ?
                <p className="mx-input-info">{info}</p>
                :""
            }
            {
                floatingLabel ? <label className="label" for="">{label}</label> : ""
            }
        </div>
        : inputType === "textarea" ?
        <div class={`mx-input-wrap ${floatingLabel ? "animated-label" : "" } ${variant ? variant :""} ${classes ? classes : ""} `}>
            {
                floatingLabel ? "" : <label className="label" for="">{label}</label>
            }
            <textarea onChange={onChange} name={name} placeholder={label} rows={rows ? rows : "3"} columns={columns ? columns : "3"}></textarea>
            {
                error ?
                <p className="mx-input-error">{error}</p>
                :""
            }
            {
                info ?
                <p className="mx-input-info">{info}</p>
                :""
            }
            {
                floatingLabel ? <label className="label" for="">{label}</label> : ""
            }
            {
                type === "password" ?
                    <i className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"} input-right-icon`} onClick={()=>setShowPassword(!showPassword)}></i>
                : ""
            }
        </div>
        : inputType === "button" ?
        <div class={`mx-input-wrap ${variant ? variant :""} ${fullWidth ? "full-width" :""} ${large ? "mx-large" :""} ${centered ? "mx-centered" :""} ${left ? "mx-left" :""} ${right ? "mx-right" :""} ${classes ? classes : ""} `}>
            <button onClick={onClick} onSubmit={onSubmit} value={value}>{name}</button>
        </div>
        :""

    )
}
export default InputComponent;