import { BASE_URl_Admin_Azure_Chat, BASE_URl_Chat_Azure_Services } from '../../../copmonents/Service/Constent/Constent'
import { BASE_URl_Admin_Azure } from '../Constent/Constent'
import { BASE_URl_Admin_Azure_Services } from '../Constent/Constent'
import { BASE_URl_Admin_Azure_Payments } from '../Constent/Constent'
import { BASE_URl_Admin_Azure_JiraProjects } from '../Constent/Constent'
// import { BASE_URl_Admin_Azure_Resources } from '../Constent/Constent'
import { BASE_URl_Admin_Azure_Email } from '../Constent/Constent'
import { BASE_URl_Admin_Azure_Reports } from '../Constent/Constent'
import { COMMUNICATION_API } from '../Constent/Constent'
import request from './requests'

function createCustomer(data) {
    data.username = data.email;
    return request({
        url: `${BASE_URl_Admin_Azure}Authenticate/Register`,
        method: 'POST',
        data
    })
}
function login(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Authenticate/login`,
        method: 'POST',
        data
    })
}
function getQuestionnaire(id) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Questionnaire/GetQuestionnaire/` + id,
        method: 'GET',
        id
    })
}
function PostQuestionnaire(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Questionnaire/PostQuestionnaire`,
        method: 'POST',
        data
    })
}
function getQuotation(id) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Questionnaire/GetQuotationById?Id=` + id,
        method: 'GET',
        id
    })
}
function getQuotationbyUserId(id) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Questionnaire/GetQuestionsByUserId/` + id,
        method: 'GET',
        id
    })
}
function CreateTransaction(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Payments}TelrPayments/CreateTransaction`,
        method: 'POST',
        data
    })
}
function CreatePaymentIntent(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Payments}TelrPayments/CreateIntent`,
        method: 'POST',
        data
    })
}
function SuccessfullPayment(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Payments}TelrPayments/SuccessfullPayment?PaymentId=` + data,
        method: 'GET'
    })
}
function UpdateQoutationbyId(id) {
    console.log("Payement EndPoint")
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Questionnaire/UpdateQoutationbyQoutationId/` + id,
        method: 'PUT',
        id
    })
}
function CreateJiraProject(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_JiraProjects}JiraLocalProject/CreateJiraLocalProject`,
        method: 'POST',
        data
    })
}
function GetProjectByUserId(Id) {
    return request({
        url: `${BASE_URl_Admin_Azure_JiraProjects}JiraLocalProject/GetProjectByUserId/` + Id,
        method: 'GET'

    })
}
function GetProjectByCompanyId(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_JiraProjects}JiraLocalProject/GetJiraProjects/`,
        method: 'POST',
        data
    })
}
function GetProjectDetailsById(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_JiraProjects}JiraLocalProject/GetJiraProjectsDetails/`,
        method: 'POST',
        data
    });
}
function GetProjectTask(Id) {
    return request({
        url: `${BASE_URl_Admin_Azure_JiraProjects}JiraLocalProject/GetAllJiraProjectTaskandSubTask?JiraProjectKey=` + Id,
        method: 'GET'

    })
}
function GetAssignResource(Id) {
    console.log("hhehehe")
    return
    // return request({
    //     url: `${BASE_URl_Admin_Azure_Resources}AssignResourse/GetAssignResourceProjectName?ProjectName=` + Id,
    //     method: 'GET'

    // })
}
function SendEmail(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Email}Email/Send`,
        method: 'POST',
        data
    })
}
function CreatePaymentByPayOrder(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Payments}PayOrder/CreatePaymentByPayOrder`,
        method: 'POST',
        data
    })
}
function GetSession(id) {
    return request({
        url: `${COMMUNICATION_API}Session/GetSession?email=` + id,
        method: 'GET'
    })
}
function GetVideoSession(data) {
    return request({
        url: `${COMMUNICATION_API}Session/GetVideoSession`,
        method: 'POST',
        data
    })
}
function GetUserByServiceId(ServiceId) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}UserService/GetUserByServiceId/` + ServiceId,
        method: 'GET',
    })
}
function ChangePassword(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Authenticate/ChangePassword`,
        method: 'POST',
        data
    })
}
function ActivateUser(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Authenticate/ActivateUser`,
        method: 'POST',
        data
    })
}
function RoleRequest(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Users/RoleRequest`,
        method: 'POST',
        data
    })
}
function FetchRoleRequest(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Users/FetchRoleAssignmentRequest`,
        method: 'POST',
        data
    })
}
function ApproveRoleRequest(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Users/ApproveRoleAssignmentRequest`,
        method: 'POST',
        data
    })
}
function ChangeAdminRole(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Users/ChangeAdminRole`,
        method: 'POST',
        data
    })
}
function DownlaodSOW(data) {
    window.location.href = `${BASE_URl_Admin_Azure_Services}Questionnaire/DownlaodSOW?QoutationId=` + data.QoutationId + `&CompanyName=` + data.CompanyName
}
function ResetPassword(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Authenticate/ResetPassword`,
        method: 'POST',
        data
    })
}
function GetUserName(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Users/GetUserFirstLastName/` + data,
        method: 'GET'
    })
}
function ForgetPassword(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Authenticate/ForgetPassword`,
        method: 'POST',
        data
    })
}
function VerifyOTP(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Authenticate/VerifyAuthCode`,
        method: 'POST',
        data
    })
}
function FileCheck(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Questionnaire/ReportFileCheck`,
        method: 'POST',
        data
    })
}
function DownloadUserReport(data) {
    window.location.href = `${BASE_URl_Admin_Azure_Services}Questionnaire/DownloadUserReport?customerid=` + data.customerid + `&projectid=` + data.projectid
}
function GetProjectReports(JiraProjectId) {
    return request({
        url: `${BASE_URl_Admin_Azure_Reports}Reports/GetReportByJiraProjectKey?JiraProjectKey=` + JiraProjectId,
        method: 'GET'
    })
}

function GetProjectsStatsByUserId(Id) {
    return request({
        url: `${BASE_URl_Admin_Azure_JiraProjects}JiraLocalProject/GetStatsByCompanyID/` + Id,
        method: 'GET'

    })
}
function AddCallLog(data) {
    return request({
        url: `${COMMUNICATION_API}CallLogs/AddCallLog`,
        method: 'POST',
        data
    })
}
function GetSelectedQuestionsbyId(Id) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Questionnaire/GetSelectedQuestionsbyId?Id=` + Id,
        method: 'GET'

    })
}
function LoginWithGoogle(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Authenticate/SignInGoogle`,
        method: 'POST',
        data
    });
}
function createGuestCustomer(data) {
    return request({
        url: `${BASE_URl_Admin_Azure}Authenticate/GuestRegister`,
        method: 'POST',
        data
    })
}

function SuccessfullPaymentGuest(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Payments}TelrPayments/SuccessfullPaymentGuest`,
        method: 'POST',
        data
    })
}

function UpdateQoutationbyIdGuest(data) {
    //console.log("Payement EndPoint")
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Questionnaire/UpdateQoutationbyQoutationIdGuest`,
        method: 'POST',
        data
    })
}

function FetchParentServices() {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Packages/GetParentServices`,
        method: 'GET',
    })
}

function FetchSubServices(serviceId) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Packages/GetSubServices?serviceId=` + serviceId,
        method: 'GET'
    })
}

function FetchPackages(subServiceId) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Packages/GetPackagesDetail?subServiceIDs=` + subServiceId,
        method: 'GET'
    })
}

function FetchCartItems(refID) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Packages/GetCartDetail?refID=` + refID,
        method: 'GET'
    })
}

function GetGuestSession(data) {
    return request({
        url: `${COMMUNICATION_API}Session/GetGuestSession`,
        method: 'POST',
        data
    })
}
function GetGuestVideoSession(data) {
    return request({
        url: `${COMMUNICATION_API}Session/CreateGuestVideoSession`,
        method: 'POST',
        data
    })
}
function CreateQuotation(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Quotation/CreatePackageQuotation`,
        method: 'POST',
        data
    })
}
function CreateQuotationNew(data) {
    console.log("NEW API DATA ===================================> ", data)
}
function UpdateQuotationN(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Quotation/UpdatePackageQuotation`,
        method: 'POST',
        data
    })
}
function UpdateQuotationStatus(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Quotation/PackageQuotationStatus`,
        method: 'POST',
        data
    })
}
function CreateJiraPackages(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_JiraProjects}JiraLocalProject/CreateJiraLocalPackages`,
        method: 'POST',
        data
    })
}
function FetchPackageQuotationList(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Quotation/PackageQuotationsList`,
        method: 'POST',
        data
    })
}
function FetchPackageQuotationInfo(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Quotation/PackageQuotationsInfo`,
        method: 'POST',
        data
    })
}
// zargham's change START
function getPDFDetail(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Quotation/CreateSow`,
        method: 'POST',
        data: data
    });
}
function getFinanceAdmins() {
    const promise = new Promise((res, rej) => {
        // Support calls, Project related emails, payments etc will be notified on these emails
        res([
            { email: "amumtaz@vaporvm.com", userName: "Arifa Mumtaz" },
            { email: "habbas@vaporvm.com", userName: "Hussain Abbas" },
            { email: "hchaudhary@vaporvm.com", userName: "Hamza Chaudhary" },
            { email: "hameer@vaporvm.com", userName: "Huzaifa Ameer" },
            { email: "zyousaf@vaporvm.com", userName: "Zarish Yousaf" },
            { email: "zaijaz@vaporvm.com", userName: "zargham Aijaz" },
            { email: "hpasha@vaporvm.com", userName: "Hassan Pasha" },
            { email: "mhassan@vaporvm.com", userName: "Ali Hassan" },
            { email: "rehmana@vaporvm.com", userName: "Azeez Ur Rehman" },
        ]);
        // Below are testing Emails
        // disable the above and enable these for testing


        // for UNIT TESTING
        // res([
        //     { email: "zaijaz@vaporvm.com", userName: "Zargham" },
        //     { email: "zyousaf@vaporvm.com", userName: "Zarish Yousaf" }
        // ]);
        // res([
        //     { email: "admin@managex.com", userName: "Admin" },
        //     { email: "zaijaz@vaporvm.com", userName: "Zargham" },
        //     { email: "hameer@vaporvm.com", userName: "Huzaifa" },
        //     { email: "hchaudhary@vaporvm.com", userName: "Hamza" },
        //     { email: "zyousaf@vaporvm.com", userName: "Zarish" }
        // ]);
    });
    return promise;
}
function getSupportAdmins() {
    const promise = new Promise((res, rej) => {
        // Support calls, Project related emails, payments etc will be notified on these emails
        res([
            { email: "admin@managex.com", userName: "Admin" },
            { email: "ahsank@vaporvm.com", userName: "ahsank" },
            { email: "hkhan@vaporvm.com", userName: "hkhan" },
            { email: "isaleem@vaporvm.com", userName: "Isaleem" },
            { email: "hemeka@vaporvm.com", userName: "Hemeka" },
            // { email: "afarah@vaporvm.com", userName: "Angel Farah" },


            { email: "hchaudhary@vaporvm.com", userName: "Hamza Chaudhary" },
            { email: "hameer@vaporvm.com", userName: "Huzaifa Ameer" },
            { email: "zyousaf@vaporvm.com", userName: "Zarish Yousaf" },
            { email: "zaijaz@vaporvm.com", userName: "zargham Aijaz" },
            { email: "hpasha@vaporvm.com", userName: "Hassan Pasha" },
            { email: "mhassan@vaporvm.com", userName: "Ali Hassan" },
            { email: "rehmana@vaporvm.com", userName: "Azeez Ur Rehman" },
        ]);
        // Below are testing Emails
        // disable the above and enable these for testing


        // for UNIT TESTING
        // res([{ email: "zaijaz@vaporvm.com", userName: "Zargham" }]);
        // res([
        //     { email: "admin@managex.com", userName: "Admin" },
        //     { email: "zaijaz@vaporvm.com", userName: "Zargham" },
        //     { email: "ahsank@vaporvm.com", userName: "ahsank" },
        //     { email: "hameer@vaporvm.com", userName: "Huzaifa" },
        //     { email: "hchaudhary@vaporvm.com", userName: "Hamza" },
        //     { email: "zyousaf@vaporvm.com", userName: "Zarish" }
        // ]);
    });
    return promise;
}

function uploadPO(data) {
    console.log("Payload", data)
    return request({
        url: `${BASE_URl_Admin_Azure_Services}Quotation/PackageQuotationPayOrderStatus`,
        method: 'POST',
        data: data
    });
}
// zargham's change END

function storeMessageToDb(data) {
    return request({
        url: `${BASE_URl_Admin_Azure_Chat}newMessage`,
        method: 'PUT',
        data: data
    });
}

function getMessagesByJiraKey(jiraKey) {
    return request({
        url: `${BASE_URl_Admin_Azure_Chat}` + jiraKey,
        method: 'GET'
    });

}




const Service = {
    login,
    createCustomer,
    getQuestionnaire,
    PostQuestionnaire,
    getQuotation,
    CreateTransaction,
    SuccessfullPayment,
    UpdateQoutationbyId,
    CreateJiraProject,
    GetProjectByUserId,
    GetProjectByCompanyId,
    GetProjectDetailsById,
    GetProjectTask,
    GetAssignResource,
    getQuotationbyUserId,
    SendEmail,
    CreatePaymentByPayOrder,
    GetSession,
    GetVideoSession,
    GetUserByServiceId,
    ChangePassword,
    ActivateUser,
    RoleRequest,
    FetchRoleRequest,
    ApproveRoleRequest,
    ChangeAdminRole,
    DownlaodSOW,
    ResetPassword,
    ForgetPassword,
    VerifyOTP,
    FileCheck,
    DownloadUserReport,
    GetUserName,
    GetProjectReports,
    GetProjectsStatsByUserId,
    AddCallLog,
    GetSelectedQuestionsbyId,
    LoginWithGoogle,
    CreatePaymentIntent,
    createGuestCustomer,
    SuccessfullPaymentGuest,
    UpdateQoutationbyIdGuest,
    FetchParentServices,
    FetchSubServices,
    FetchPackages,
    FetchCartItems,
    GetGuestSession,
    GetGuestVideoSession,
    CreateQuotation,
    UpdateQuotationN,
    UpdateQuotationStatus,
    CreateJiraPackages,
    FetchPackageQuotationList,
    FetchPackageQuotationInfo,
    getPDFDetail,
    getSupportAdmins,
    getFinanceAdmins,
    CreateQuotationNew,
    uploadPO,
    storeMessageToDb,
    getMessagesByJiraKey
};
export default Service