import React, { Component } from 'react'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: null
        }
    }
    static getDerivedStateFromError() {
        return { hasError: true }
    }
    componentDidCatch() {
    }
    render() {
        if (this.state.hasError) return <h1>Something Went Wrong!</h1>
        return this.props.children
    }
}
