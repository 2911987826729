import React, { Fragment } from "react";
import 'jquery/dist/jquery.min.js';
import { Redirect } from 'react-router-dom'
import PresaleServices from '../../components/Service/Api/Api';
import cookie from "react-cookies";
//Datatable Modules
import $ from 'jquery';
import axios from "axios";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import CustomLoader from '../Loader/Loader';
import StatsContainer from "../DashBoard/statsContainer";

import Card from "./Card";
import GridWrapper from "../GridWrapper/GridWrapper";
import { Doughnut } from 'react-chartjs-2';
import MiniLoader from "../DashBoard/miniLoader";
import StripePaymentContainer from "../publicQuestionnaire/StripePaymentContainer";

import ChartComponent from "../DashBoard/chartComponent";
import BreadCrumb from "../BreadCrumb";
import { BASE_URl_Pdf_API } from "../../components/Service/Constent/Constent";


// import { CloudDownloadOutlined } from "@ant-design/icons";

export default class Billing extends React.Component {
    pdfServiceUrl = BASE_URl_Pdf_API

    constructor(props) {
        super(props);
        let loggedIn = null;
        var html = "";

        this.ColumnList = [
            {
                text: 'Customer Name',
                key: 'customerName',
                width: '150',
                sortable: false
            },
            {
                text: 'Service Name',
                key: 'serviceName',
                width: '40%',
                sortable: false,
            },
            {
                text: 'Quoted Date',
                key: 'quotedDate',
                sortable: true
            },
            {
                text: 'Amount',
                key: 'amount',
                sortable: false
            },
            {
                text: 'Status',
                key: 'status',
                sortable: false
            },
            {
                key: "quotationID",
                text: "Action",
                sortable: false,
                cell: (record, index) => {
                    return (
                        <>
                            <Fragment>
                                {/* <CloudDownloadOutlined /> */}

                                <img src="https://cdn4.iconfinder.com/data/icons/multimedia-75/512/multimedia-21-256.png" width="30px" heigth="30px"
                                    style={{ marginLeft: "15px", fontSize: "20px", display: "inline-block" }}
                                    onClick={(e) => { e.preventDefault(); this.downloadInvoice(record.status, record.quotationData.quotationID) }} alt="Download" title="Download" />
                                {record.status === "Quoted"
                                    ?
                                    <img src="https://cdn1.iconfinder.com/data/icons/shopping-346/24/hand-gesture-payment-card-credit-128.png" width="30px" heigth="30px"
                                        style={{ marginLeft: "15px", fontSize: "20px", display: "inline-block" }}
                                        onClick={(e) => { e.preventDefault(); this.payQuotation(record) }}
                                        alt="Pay now"
                                        title="Pay now"
                                    />
                                    : ""
                                }
                            </Fragment>
                        </>
                    );
                }
            }
        ];



        this.state = {
            loggedIn,
            data: [],
            filtered: [],
            searched: [],
            currentPage: 1,
            itemsPerPage: 5,
            searching: false,
            loaded: false,
            billsAndPayments: "",
            isLoader: false,
            isModalActive: false,
            GridData: [],
            quotedData: {}
        };
        // this.props.setLocation(this.props.history.location.pathname)
    }

    onChangeHandler = e => {
        e.preventDefault();
        const { data, searched, itemsPerPage } = this.state;
        const currentPage = 1;
        let value = e.target.value;
        if (e.target.type == "date") {
            let temp = value.split("-")
            temp = temp.reverse();
            value = temp.join('/')
        }
        let filtered = [...data];
        let key = e.target.name;
        searched[key] = value;
        for (key in searched) {
            value = searched[key];
            if (value !== "") {
                filtered = filtered.filter(el => {
                    const lc = el[key].toLowerCase();
                    const filter = value.toLowerCase();
                    return lc.includes(filter);
                });
            }
        }
        let searchingflag = false;
        for (key in searched) {

            value = searched[key];
            if (value !== "")
                searchingflag = true
        }
        if (!searchingflag) {
            const indexOfLastItem = currentPage * itemsPerPage;
            const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
            this.setState({
                filtered: filtered.slice(
                    indexOfFirstItem, indexOfLastItem
                ),
                searched: searched,
                searching: false
            });
        }
        else
            this.setState({
                filtered: filtered,
                searched: searched,
                searching: true
            });
    };
    totalProjects = 0
    paidQty = 0
    quotedQty = 0
    totalPaidAmount = 0
    totalQuotedAmount = 0
    componentDidMount() {

        var id = cookie.load('user_Id')
        //cookie name :  user_Name and Id 
        var payLoad = {
            userid: parseInt(cookie.load('Id')),
            username: cookie.load('user_Name')
        }
        let tempArr = []

        PresaleServices.FetchPackageQuotationList(payLoad)
            .then(res => {
                const responseData = res.data.map((item, index) => {
                    this.totalProjects += 1
                    tempArr.push({
                        customerName: item.userName,
                        amount: `USD ${item.totalPrice}`,
                        status: item.status,
                        serviceName: item.serviceName,
                        quotedDate: item.quotationdate.split("T")[0],
                        quotationData: {
                            quotationID: item.quotationID,
                            amount: item.totalPrice
                        }
                    })

                    var _totalPriceQuote = item.totalPrice + " $"
                    if (item.status === "Paid") {
                        this.paidQty += 1
                        this.totalPaidAmount += item.totalPrice
                    }

                    if (item.status === "Quoted") {
                        this.quotedQty += 1
                        this.totalQuotedAmount += item.totalPrice
                    }
                    return (
                        <tr key={index}>
                            <td>{item.userName}</td>
                            <td>{_totalPriceQuote}</td>
                            <td>{item.status}</td>
                            <td>{item.serviceName}</td>
                            <td>{item.quotationdate.split("T")[0]}</td>
                            <td>
                                <a href={item.quotationID} data-quotationID={item.quotationID} onClick={(e) => { e.preventDefault(); this.downloadInvoice(item.status, item.quotationID) }}>
                                    <i className="fa fa-download" style={{ marginLeft: "16%" }}></i>
                                </a>
                            </td>
                        </tr>
                    )
                });
                this.setState({ GridData: tempArr });

                this.setState({
                    billsAndPayments: responseData
                })
            })
            .catch(err => console.log(err));
    }
    downloadInvoice = (status, refNum) => {
        this.setState({ isLoader: true });
        const PayLoad = {
            refnumber: refNum
        }
        PresaleServices.FetchPackageQuotationInfo(PayLoad).then(res => {
            if (res) {
                this.setState({
                    userName: cookie.load("user_Name"),
                    userEmail: cookie.load("user_Id"),
                    customerOrder: res.data
                });
                const dataForPrintingQuotation = {
                    customerName: this.state.userName,
                    customerEmail: this.state.userEmail,
                    paymentMode: this.state.customerOrder[0].paymentMode || 0,
                    orderDate: this.state.customerOrder[0].quotationDate.split('T').join(' ').split(':').slice(0, -1).join(':'),
                    quotationRef: PayLoad.refnumber,
                    status: status,
                    termsAndConditions: [
                        "Valid for 15 business days from the date of generation",
                        "Quotation is without Tax and Discount",
                        "For further details, call our support"
                    ],
                    subTotal: `$ ${this.state.customerOrder[0].totalAmount}`,
                    discount: this.state.customerOrder[0].discount ? `$ ${this.state.customerOrder[0].discount}` : "$ 0",
                    vat: this.state.customerOrder[0].vat ? `$ ${this.state.customerOrder[0].vat}` : "$ 0",
                    totalPrice: `$ ${(Number(this.state.customerOrder[0].totalAmount) + Number(this.state.customerOrder[0].vat || 0)) - Number(this.state.customerOrder[0].discount || 0)}`,
                    services: this.state.customerOrder.map(item => {
                        return {
                            packageName: item.serviceName,
                            packageQuantity: item.packageQuantity || 1,
                            packagePrice: `$ ${item.unitPrice}`,
                            total: `$ ${Number(item.packageQuantity || 1) * Number(item.unitPrice)}`
                        }
                    })
                }
                axios.post(`${this.pdfServiceUrl}PrintInvoice`, dataForPrintingQuotation, {
                    responseType: "blob",
                }).then((response) => {
                    var resptime = Date.now();
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], { type: "application/pdf" });
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    const link = document.createElement("a");
                    link.href = fileURL;
                    link.setAttribute("download", "Invoice.pdf");
                    // document.body.appendChild(link);
                    link.click();
                    var end = Date.now();
                    // const pdfWindow = window.open();
                    // pdfWindow.location.href = fileURL;
                    this.setState({ isLoader: false });
                });
            }

        });
    }
    editQuotation = reference_no => {
        this.props.history.push(`/Edit/${reference_no}`)
    };
    showQuotation = ref_no => {
        alert(ref_no)
        // var url = '../downlaod/'+ref_no
        // var newwindow=window.open(url,'name','height=600,width=1000');
        //     if (window.focus) {newwindow.focus()}
        //     return false; 
    };

    payQuotation = data => {
        this.setState({
            isModalActive: true,
            quotationData: data
        })
    }

    render() {
        return (
            <div className="hero px-3">
                {
                    this.state.isLoader ?
                        <CustomLoader />
                        : ""
                }
                {
                    this.state.isModalActive ?
                        <div className="mx-modal-bg">
                            <div className="mx-modal-inner">
                                <div className="mx-modal-title">Do you want to pay for the following quotation?</div>
                                <i className="zmdi zmdi-close mx-modal-close-icon" onClick={() => { this.setState({ isModalActive: false }) }}></i>
                                <div className="mx-modal-items">
                                    <h5>Selected packages:</h5>
                                    <ul style={{ padding: "0px", listStylePosition: "inside" }}>
                                        {
                                            this.state.quotationData.serviceName ?
                                                this.state.quotationData.serviceName.split(",").map(item => {
                                                    return <li>{item}</li>
                                                })
                                                : ""
                                        }
                                    </ul>
                                </div>
                                <StripePaymentContainer
                                    isBackButtonDisabled={true}
                                    TotalAmount={this.state.quotationData.amount.split(" ")[this.state.quotationData.amount.split(" ").length - 1]}
                                    QuotationNumber={this.state.quotationData.quotationData.quotationID}
                                />
                            </div>
                        </div>
                        : ""
                }

                <BreadCrumb />


                <div className="container-fluid">

                    <div class="row mb-4 m-0 text-center justify-content-center">

                        <Card img="zmdi-assignment clr-yellow" heading={"Total Projects"} qty={this.totalProjects} />
                        <Card img="zmdi-case-check clr-green" heading={"Paid Projects"} qty={this.paidQty} />
                        <Card img="zmdi-assignment-alert" heading={"Unpaid"} qty={this.quotedQty} />
                        <Card img="zmdi-money clr-blue" heading={"Paid Amount"} qty={`$ ` + this.totalPaidAmount} />

                    </div>
                </div>


                <div className="container-fluid">
                    <div className="col-12">

                        <div className="content search-table-wrapper" id="main-content">
                            <div className="card mt-3">

                                <div className="mt-4 px-4">
                                    <GridWrapper
                                        PageSize={10}
                                        Menu={[10, 20, 50, 100]}
                                        ShowFilter={true}
                                        ShowPagination={true}
                                        PaginationOption={'advance'}
                                        Records={this.state.GridData}
                                        ColumnList={this.ColumnList}
                                        ExtraButton={[]}
                                    />
                                </div>

                            </div>
                            <br />

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


