import React from "react";
import Service from '../../components/Service/Api/Api'
import cookie from "react-cookies";
import Swal from "sweetalert2"; 
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { jsPDF } from "jspdf";
import {ADMIN_EMAIl} from "../Service/Constent/Constent"
import Loader from "react-loader-spinner";
import { loadStripe } from "@stripe/stripe-js";
import CustomLoader from "../Loader/Loader";
import SupportForm from "../SupportForm/SupportForm";
const stripePromise = loadStripe("pk_test_51Iuz0GGMmvyl2mlxa0ci4gXhpw9JOACRC6V2yJyLU6vgJImVZSUvXRg4TWykmdGKWEaeDLE8CWlvmOA1P4AQOTE200hO7M5fjB");
var redirectmethod = async (res) => {
    //alert("11");
    try{
        const stripe = await stripePromise;
        console.log(res);
        await stripe.redirectToCheckout({
            sessionId: res,
          });
    }
    catch(e){
        alert(e);
    }
    
}
class Qoutation extends React.Component {
    customerOrderId = -1;
    constructor(props)
    {
        super(props);
        var queryString =   this.props.location.pathname.split('/');
        this.customerOrderId = queryString[queryString.length - 1];
        this.state = { customerOrder: null , loading: false };
        this.doPayment = this.doPayment.bind(this);
        this.downlaodSOW = this.downlaodSOW.bind(this);
        this.downloadPayment = this.downloadPayment.bind(this);
        this.getQuotation = this.getQuotation.bind(this);
        this.sendPayorderEmail = this.sendPayorderEmail.bind(this);
    }

    componentDidMount()
    {
        if (this.customerOrderId != null)
        {
            console.info('step1: getting customer order');
            this.getCustomerOrder(this.customerOrderId);
        }
        else { console.error("this.CustomerOrderId is not greater then 0"); }
        
    }

    /**
     * Get the customer order info and update state variable customerOrder
     * @param {any} customerOrderId
     */
    getCustomerOrder(customerOrderId)
    {
        Service.getQuotation(customerOrderId).then(res => {
            if (res) {
                console.log(res);
                this.setState({
                    customerOrder: res
                });
            }
        });
    }


    /** Get Quotation of request */
    getQuotation()
    {
       
        console.log("Customer order id is =>" + this.customerOrderId);
        //call the api method to generate pdf
        //var myWindow = window.open(Constants.PDFControllerUrl+"/" + this.customerOrderId, "myWindow", "width=800,height=800");
        //myWindow.document.write("<p>downloading file..'</p>");
        //myWindow.close();
    }



    /** Function which redirect to telr payment page */
    doPayment()
    {
        this.setState({loading: true})
        let inputModel = 
            {
                "userId": cookie.load('user_Id'),
                "ivp_method": "create",
                "ivp_store": "",
                "ivp_authkey": "",
                "ivp_amount": parseFloat(this.state.customerOrder.Quotation.TotalPrice),
                "ivp_currency": "",
                "ivp_test": 1,
                "ivp_cart": this.state.customerOrder.Quotation.Id,
                "ivp_desc": 'No Description',
                "return_auth": window.location.origin+"/#/paymentSuccess",
                "return_decl": window.location.origin+"/#/PaymentReject",
                "return_can": window.location.origin+"/#/PaymentReject",
                "ServiceName":this.state.customerOrder.ServiceName
            };
        console.log(inputModel);
        Service.CreateTransaction(inputModel).then(res => {
            if (res) {
                redirectmethod (res)
                //window.location = res;
            }
        });  
    }
    
     
    
    downloadPayment()
    {  
        var url = '#/downlaod/'+this.customerOrderId
       var newwindow=window.open(url,'name','height=600,width=1000');
        if (window.focus) {newwindow.focus()}
        return false;    
        //this.props.history.push('/downlaod/'+this.customerOrderId) 
    }
    downlaodSOW()
    {
       // var serviceId = this.state.customerOrder.Quotation.ServiceId
        // var data = {
        //     QoutationId:this.customerOrderId,
        //     CompanyName:cookie.load('user_Name')
        // }
        // Service.DownlaodSOW(data);
        //alert("");
        //var url = '../DownlaodSOW/'+this.customerOrderId;
        var url = '#/DownlaodSOW/'+this.customerOrderId;
        //alert(url);
       var newwindow=window.open(url,'name','height=600,width=1000');
        if (window.focus) {newwindow.focus()}
        return false;
    }
    sendPayorderEmail(event)
    {
        this.setState({loading: true})
       var payorderfile = document.getElementById('payorder').files[0]
        console.log(payorderfile.name)
        var ext = payorderfile.name.split(".").pop().toLowerCase();
        if(ext !== 'doc' && ext !== 'docx'&& ext !=='pdf')
        {
            Swal.fire({  
                icon: 'error',  
                title: 'Invalid file type',  
                text: 'Please upload pdf or docx file only.',
                showConfirmButton: false,  
                timer: 2500  
              });  
              this.setState({
                loading: false
            })   
        }
        else  if(ext == 'doc' || ext == 'docx'|| ext =='pdf')
        {

            var EamilData = new FormData();
            EamilData.append('ToEmail', ADMIN_EMAIl);
            EamilData.append('Subject', 'Payorder Files');
            EamilData.append('Body', 'Payorder file Against the Quotation #: '+this.customerOrderId);
            EamilData.append('Attachments', payorderfile);
        

        Service.SendEmail(EamilData).then( res =>{
            let inputModel = 
            {
                "userId": cookie.load('user_Id'),
                "ivp_method": "create",
                "ivp_store": "",
                "ivp_authkey": "",
                "ivp_amount": parseFloat(this.state.customerOrder.Quotation.TotalPrice),
                "ivp_currency": "",
                "ivp_test": 1,
                "ivp_cart": this.state.customerOrder.Quotation.Id,
                "ivp_desc": 'No Description',
                "return_auth": window.location.origin+"/#/paymentSuccess",
                "return_decl": window.location.origin+"/#/PaymentReject",
                "return_can": window.location.origin+"/#/PaymentReject"
            };
        console.log(inputModel);
        Service.CreatePaymentByPayOrder(inputModel).then(res => {
            if (res) {
                //console.log(res);
                //window.location = res;
            }
        });
            Service.UpdateQoutationbyId(this.customerOrderId).then(res => {
                if (res) {
                    console.log(res);
                    var data = {
                        //"id": 0,
                        "qoutationId": this.customerOrderId,
                        "userId": cookie.load('user_Id'),
                        "jiraProjectKey": "string",
                        "projectName": "string",
                        "projectTemplateId": res.TemplateId                            
                      }
                    Service.CreateJiraProject(data).then(res =>{
                        if(res !="Already Created"){
                            var EamilData = new FormData();
                            EamilData.append('ToEmail', ADMIN_EMAIl);
                            EamilData.append('Subject', 'Project Creation');
                            EamilData.append('Body', 'A new project: '+res.ProjectName+' has been created by: '+this.state.userId+'');
                            Service.SendEmail(EamilData);

                            this.props.history.push('/dashboard')
                        }
                        else
                        {
                            alert("Project Already Created")
                            this.props.history.push('/dashboard')
                        }
                    })                       
                }
            });
        });
        }
    }
    render() {
        var data = (this.state.loading || this.state.customerOrder?.Quotation==null)?
        <div>
        
          <CustomLoader></CustomLoader>
        </div>
        
            :
        <>            
            {this.state.customerOrder?.Quotation?.TierBasePrice===0?<SupportForm/>:<div className="quotation-container col-11 m-5">
                
                <h1 className="quotation-bg-heading" style={{marginLeft:'41%', marginBottom:'-22px'}}>quotation</h1>

                <div className="quotaion-section" id='downloadpage'>
                    <div style={{width:"50%", marginLeft:"28%"}}>
                        <p className="mb-4">
                            Please find details of your quote listed below.
                        </p>
                        <table className="table-bordered center">
                        <thead></thead>
                        <tbody>
                            <tr className="quotation-table-row">
                                <td className="quotation-table-left-data" style={{borderBottom:'1px solid'}}>Reference Number:</td>
                                <td className="quotation-table-right-data" style={{borderLeft:'1px solid'}}>
                                    {this.state.customerOrder!=null?this.state.customerOrder.Quotation.Id:""}                                    
                                </td>
                            </tr>
                            <tr className="quotation-table-row">
                                <td className="quotation-table-left-data" style={{borderBottom:'1px solid'}}>Service:</td>
                                <td className="quotation-table-right-data" style={{borderLeft:'1px solid'}}>
                                {this.state.customerOrder!=null?this.state.customerOrder.ServiceName:""}
                                </td>
                            </tr>
                            <tr className="quotation-table-row">
                                <td className="quotation-table-left-data" style={{borderBottom:'1px solid'}}>Unit Price:</td>
                                <td className="quotation-table-right-data" style={{borderLeft:'1px solid'}}>
                                USD {this.state.customerOrder!=null?this.state.customerOrder.Quotation.TierBasePrice:""}                                 
                                </td>
                            </tr>
                            <tr className="quotation-table-row">
                                <td className="quotation-table-left-data" style={{borderBottom:'1px solid'}}>No. of Assets:</td>
                                <td className="quotation-table-right-data" style={{borderLeft:'1px solid'}}>                                
                                {this.state.customerOrder!=null?this.state.customerOrder.Quotation.NoOfUsers:""}
                                </td>
                            </tr>
                            <tr className="quotation-table-row">
                                <td className="quotation-table-left-data">Total Price:</td>
                                <td className="quotation-table-right-data" style={{borderLeft:'1px solid' , borderBottom:'0px'}}>                                
                                USD {this.state.customerOrder!=null?this.state.customerOrder.Quotation.TotalPrice:""} 
                                </td>
                            </tr>
                        </tbody>
                    </table>



                    </div>
                    

                    
                    <h2 className="mb-4 mt-4">
                        Place an order based on this Quote
                                </h2>
                    <p className="quotaion-secondary-text mb-5">
                        If you would like to place an order based on this quote, please
                        click this link below. If you would like to change the contents
                        of your order on edit your delivery or billing address,
                        
                            </p>

                    <div className="row">
                    <div className="col col-md-6">
                        <span>
                            
                        </span>
                    </div>
                    <div className="col col-md-6">
                            <div className="row">
                                <div className="col col-md-8">
                                    <button type="button" style={{marginLeft:'49%'}} className='button button-cta is-bold btn-align secondary-btn rounded raised' onClick={this.downloadPayment}>Download Quotation</button>
                                </div>
                                <div className="col col-md-4">
                                {this.state.customerOrder!=null?
                        <>
                            {this.state.customerOrder.FileName!=null?<button type="button" style={{marginLeft:'7%'}} className='button button-cta is-bold btn-align secondary-btn rounded raised' onClick={this.downlaodSOW}>Download SOW</button>:''}
                        </>:""}
                                </div>
                            </div>
                            
                    </div>
                    </div>       
                    <div className='row mb-4'>
                        <div className='col-2'>
                        <span>
                            {/* <a href={"../downlaod/"+this.customerOrderId} 
                            target="popup" 
                            onclick={"window.open('../downlaod/'"+this.customerOrderId+",'popup','width=600,height=600'); return false;"}>
                                Open Link in Popup
                            </a> */}
                                                        
                        </span></div>
                        <div className='col-2 offset-8'>
                            <span>
                            </span>
                        </div>
                        <div className='card col-12 mt-5' style={{padding:'0'}}>
                            <div className='card-header col-12 row'><h4 className='col-10'>Payments</h4> <button type="button" className='button  col-2 button-cta  is-bold btn-align success-btn rounded raised  col-2' onClick={this.doPayment}>Pay Online</button></div>
                            {/* <div className='card-body'>
                            <div className='row'>
                            <span className='col-2 mt-2'><h4>Upload Pay Order</h4></span>
                            <input id='payorder' type="file" className='form-control col-4'  accept="application/pdf,application/msword,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={this.sendPayorderEmail}></input>
                            <span className='col-1 offset-1 mt-2'><h4>OR</h4></span>
                            <button type="button" className='button  button-cta  is-bold btn-align success-btn rounded raised  col-2' onClick={this.doPayment}>Pay Online</button>
                        </div>
                            </div> */}
                        </div>
                    </div>


                    <h1 className=" mb-4">
                            Thank you for choosing ManageX
                    </h1>
                    <h1 className=" mb-4">Technical Support</h1>
                    <p className="quotaion-secondary-text mb-4">
                        If you have any technical or product-related inquries, please
                        contact ManageX. using the following information:
        </p>
                    <div className="quotation-contact-section mb-2">
                    <span><b>Email: </b> support@managex.ae</span>                        
                    </div>
                    <div className="quotation-contact-section mb-2">
                        <span><b>Phone No: </b> +971 4 447 4594</span>                        
                    </div>
                </div>


            </div>}
        </>
        
        return(
            data
        )
    }
}
export default Qoutation;