import React from 'react';
import './BreadCrumb.css';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

function BreadCrumb(props){
    // Getting current page and making its first letter capital.
    const pageName = props.match.url.split("/")[1].split("").map((a,i)=> i===0 ? a.toUpperCase() : a);
    const allPages = props.location.pathname.split("/");
    let previousRoute = "/";
    const navigation = allPages.map((a,i)=>{
        if(i>1){
            previousRoute = previousRoute + "/" + a;
        }
        else{
            previousRoute = previousRoute + a;
        }
        return(
            i === 0 ?
            <Link className={`mx-pagination-link ${i === allPages.length - 1 ? "current" : "" }`} to='/'><i className='zmdi zmdi-home'></i></Link>
            : isNaN(a) ?
            <Link className={`mx-pagination-link ${i === allPages.length - 1 ? "current" : "" }`} to={previousRoute}>{a}</Link>
            :""
        )
    });
    return(
        <div className='mx-pagination-main'>
            {
                props.location.pathname === "/" ?
                <>
                    <h1 className='mx-page-title'>Dashboard</h1>
                    <div className='mx-pagination'>
                    <Link className={`mx-pagination-link`} to='/'><i className='zmdi zmdi-home'></i> </Link>
                    <span>/ dashboard</span>
                    </div>
                </>
                :
                <>
                    <h1 className='mx-page-title'>{pageName}</h1>
                    <div className='mx-pagination'>
                        {navigation}
                    </div>
                </>
            }
        </div>
    )
}
export default withRouter(BreadCrumb);