import React, { Component } from "react";
import "../section/SectionLoaderStyle.css";
class SectionLoader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        // <div className="loader"></div>
        <div className="lds-facebook">
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        </div>
        
    );
  }
}
export default SectionLoader;