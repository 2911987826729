import Chat from './Chat';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from "opentok-react";
import { useState, useRef, useEffect } from "react";
import managexImage from "./managex-banner.png";
import { Redirect } from 'react-router-dom';
import "./opentok.css";
import React from "react";
function OpenTokGuest(props) {

    const [screenSource, setScreenSource] = useState(false);
    const [publishAudio, setAudio] = useState(true);
    const [publishVideo, setVideo] = useState(false);
    const [isChatPanelActive, setIsChatPanelActive] = useState(false);
    const userName = props.userName;
    const messages = props.messages;
    const SessionObj = props.SessionObj;
    const { callAlert } = props;
    // 'currentUser' is a temporary variable that is used to identify which user has sent the message
    // if the message sender is the same as this variable then we need to render it on the right side of the screen


    const endCall = () =>{
        const data = JSON.stringify({sentBy:userName});
        SessionObj.signal({
            type:"leftCall",
            data:data
          },(e)=>{
            if(!e){
            }
          });
          props.endCall();
    }
    const toogleScreenShare = async () => {
        setScreenSource(!screenSource);
    };
    const toogleAudio = async () => {
        setAudio(!publishAudio);
    };

    const toogleVideo = async () => {
        setVideo(!publishVideo);
    };
    const publisherEventHandlers = {
        exceptionHandler:e=>{
            alert(e);
        },
        exception:e=>{
            alert(e);
        }
    }
    return (
        <>
            <div className="opentok-main-wrap">
                {
                    callAlert ?
                    <div className="call-alert">{callAlert}</div>
                    :""
                }
                <div className="opentok-left">
                    <div className="subscriber-streams-holder">

                        {props.streams.length <1 ? <h4 className='waiting-text'>Meeting room is empty</h4>:""}
                        {props.SessionObj !== null
                            ? props.streams.map((streamobj, i) => {
                                if (streamobj.videoType !== "screen") {
                                    const data = JSON.parse(streamobj.connection.data);
                                    const { name } = data;
                                    return (
                                        <div className="custom-subscriber-wrap">
                                            <span className="custom-subscriber-name">{name}</span>
                                            <OTSubscriber
                                                key={streamobj.id}
                                                session={props.SessionObj}
                                                stream={streamobj}
                                            />
                                        </div>
                                    );
                                }
                            })
                            : ""}
                        {/* </OTStreams> */}
                    </div>
                    <div className="publisher-streams-holder">
                        <div className="screen-share-wrap">
                            <img src={managexImage} className="banner-image" />
                            {screenSource ? (
                                <OTPublisher properties={{ videoSource: "screen" }} eventHandlers={publisherEventHandlers}/>
                            ) : (
                                ""
                            )}
                            {props.SessionObj !== null
                                ? props.streams.map((streamobj) => {
                                    if (streamobj.videoType === "screen") {
                                        return (
                                            <OTSubscriber
                                                key={streamobj.id}
                                                session={props.SessionObj}
                                                stream={streamobj}
                                            />
                                        );
                                    }
                                })
                                : ""}
                        </div>
                        <OTPublisher
                            properties={{
                                publishAudio: publishAudio,
                                publishVideo: publishVideo,
                            }}
                        />
                    </div>
                    <div className="controls-holder">
                        <i className={`zmdi zmdi-mic opentok-control-button ${publishAudio ? "" : "button-off" }`} onClick={toogleAudio}></i>
                        <i className={`zmdi zmdi-videocam opentok-control-button ${publishVideo ? "" : "button-off"}`} onClick={toogleVideo}></i>
                        <i className={`opentok-control-button ${screenSource ? "" : "button-off"}`} onClick={toogleScreenShare} >
                            <img src="https://cdn-icons-png.flaticon.com/512/6570/6570412.png"/>
                        </i>
                        <i className="zmdi zmdi-phone opentok-control-button end-call" onClick={endCall}></i>
                        <i
                            className="zmdi zmdi-comment-text opentok-control-button toggle-chat"
                            onClick={() => setIsChatPanelActive(!isChatPanelActive)}
                        ></i>
                    </div>
                </div>
                <Chat currentUser={userName} messages={messages} SessionObj={SessionObj} isChatPanelActive={isChatPanelActive} />
            </div>
        </>
    );
}
export default OpenTokGuest;
