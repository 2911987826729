import React from "react";
import Service from '../../components/Service/Api/Api'
import queryString from 'query-string'
import Swal from "sweetalert2";
import CyberSecurity  from "./CyberSecurityListItems";
import OfficeService from './OfficeServicesListItem';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default class ConnectEngineer extends React.Component {
    
    constructor(props){
        super(props);
    }

    componentDidMount(){
        
    }

    Dashboard = () =>{
        try{
            //this.props.history.push("/dashboard");
            window.location = "/#/dashboard";
        }
        catch(e){
            console.log(e);
        }
        
    }
    
    render(){
        return(
            <>
            <div className="container">
                <div className="row">
                <div className="card" style={{width:'100%'}}>
                    <div className="card-header">
                       <b>Payment successful ! Please click on the link below to connect with engineer</b>
                    </div>
                    <div className="card-body">
                        <button className="button btnPayNow" onClick={this.Dashboard} >Connect</button>
                    </div>
                </div>
                </div>
            </div> 
            </>
        )
    }
}

