import React from "react";
import Service from '../../components/Service/Api/Api'
import queryString from 'query-string'
import Swal from "sweetalert2";

import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
export default class EmailVerification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEmailVerified: false
        }
    }

    componentDidMount() {
        this.ValidateRequest();
    }

    ValidateRequest() {
        try {
            const queryParam = queryString.parse(this.props.location.search);
            var payload = {
                userid: queryParam.userId,
                token: queryParam.Token
            }
            Service.ActivateUser(payload).then(response => {
                if (response.status === "Success") {
                    this.setState({ isEmailVerified: true });
                }
            }).catch(err => {
                alert(err);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                });
            })
        }
        catch (e) {
            alert(e);
            //this.setState({ isEmailVerified : false});
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isEmailVerified === true ?
                        <div className="hero-body ">
                            <div className="container has-text-centered">
                                <div className="columns is-vcentered">
                                    <div className="column is-8 is-offset-2 has-text-centered">
                                        <h1 className="parallax-hero-title">
                                            Email Verification
                                        </h1>
                                        <h2 className="subtitle is-5">
                                            You have successfully verified your Email.
                                        </h2>
                                        <br />
                                        <p className="has-text-centered">
                                            <Link to="/login">Click here for login </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="hero-body ">
                            <div className="container has-text-centered">
                                <div className="columns is-vcentered">
                                    <div className="column is-8 is-offset-2 has-text-centered">
                                        <h1 className="parallax-hero-title">
                                            Email Verification processing
                                        </h1>
                                        <h2 className="subtitle is-5">
                                            Please wait we are validating your request.
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </>
        )
    }
}

